import {toRaw} from 'vue';
import axios from 'axios';
import Highcharts from 'highcharts';
import clone from 'lodash-es/clone';
import size from 'lodash-es/size';
import BaseColors from 'Utilities/colors';
import * as Utils from 'Utilities/utils';
import {uuidPattern} from 'Server/api';
import {closeAllTooltips, isEmptyArray} from 'Utilities/utils';
import { SelectionMode } from 'Components/common/selection/geographic-selection.vue';
// import {isEmpty} from 'lodash';
// import {tagString} from "Utilities/utils";

export const segmentSeparator = '@';
export const GEOGRAPHIC_SELECTION_PREFIX = 'GEO-SELECTION::';
export const NON_CHART_PREFIX = 'NON-GRAPH::';
export const MAX_SUBCATEGORY_COUNT = 15; // Maximum number of subcategories that will be visible in a chart
export const affinitySocialComparisonPattern = new RegExp(`^affinity_([^_]+)_(?:brands|interests)_(twitter)_comparison$`, 'i');
export const affinitySocialPattern = new RegExp(`^affinity_([^_]+)_(?:brands|interests)_(twitter)$`, 'i');
export const affinitySocialUniqueFactsPattern = new RegExp(`^${NON_CHART_PREFIX}unique_facts_affinity_([^_]+)_(?:brands|interests)_(twitter)$`, 'i');
export const audienceSegmentAssociatedDataPattern = new RegExp(`^audience_segment_index_([a-z0-9_]+)$`, 'i');
export const briefSummarySocialPattern = new RegExp(`^brief_summary_(brands|interests)_(twitter)$`, 'i');
export const conglomerateRfmMarketPattern = new RegExp(`^conglomerate_rfm_market:([0-9]+):([a-z0-9_-]+)$`, 'i');
export const conglomerateRfmMarketSummaryPattern = new RegExp(`^conglomerate_rfm_market_summary:(top|[a-z0-9_-]+)$`, 'i');
export const conglomerateRfmComparisonPattern = new RegExp(`^conglomerate_rfm_comparison:([a-z0-9_-]+)$`, 'i');
export const predictedSpendComparisonPattern = new RegExp(`^predicted_spend_comparison_([^_]+)_(.*)$`, 'i');
export const predictedSpendPattern = new RegExp(`^predicted_spend_category_([^_]+)_(.*)$`, 'i');
export const characteristicSegmentPattern = new RegExp(`^([a-z_]+):(${uuidPattern})$`, 'i');
export const demographicCompareCountPattern = new RegExp(`^demographic_([a-z0-9_]+)\\\|compare_count$`, 'i');
export const demographicCompareIndexPattern = new RegExp(`^demographic_([a-z0-9_]+)\\\|compare_index$`, 'i');
export const highLevelRfmPattern = new RegExp(`^high_level_rfm:([a-z0-9_]+):?([a-z_]+)?$`, 'i');
export const highLevelRfmCompareCountPattern = new RegExp(`^high_level_rfm:([a-z0-9_]+)\\\|compare_count$`, 'i');
export const highLevelRfmCompareIndexPattern = new RegExp(`^high_level_rfm:([a-z0-9_]+)\\\|compare_index$`, 'i');
export const pastPurchaseAssociatedDataPattern = new RegExp(`^past_purchases_(.*)$`, 'i');
export const pastPurchaseCompareCountPattern = new RegExp(`^past_purchase_([a-z0-9_]+)\\\|compare_count$`, 'i');
export const pastPurchaseCompareIndexPattern = new RegExp(`^past_purchase_([a-z0-9_]+)\\\|compare_index$`, 'i');
export const pastPurchaseRollupPattern = new RegExp(`^purch_rollup_(.*)$`, 'i');
export const politicalSummarySocialPattern = new RegExp(`^political_summary_(twitter)$`, 'i');
export const socialEngagementComparisonPattern = new RegExp(`^social_engagement_(twitter)_comparison$`, 'i');
export const socialEngagementPattern = new RegExp(`^social_engagement_(twitter)$`, 'i');
export const socialEngagementUniqueFactsPattern = new RegExp(`^${NON_CHART_PREFIX}unique_facts_social_engagement_(twitter)$`, 'i');
export const socialTopContentPattern = new RegExp(`^social_top_content_(.*)$`, 'i');
export const summaryRollupSocialComparisonPattern = new RegExp(`^summary_rollup_(brands|interests)_(twitter)_comparison$`, 'i');
export const summaryRollupSocialPattern = new RegExp(`^summary_rollup_(brands|interests)_(twitter)$`, 'i');
export const summarySocialComparisonPattern = new RegExp(`^summary_(brands|interests)_(twitter)_comparison$`, 'i');
export const summarySocialPattern = new RegExp(`^summary_(brands|interests)_(twitter)$`, 'i');
export const topSocialComparisonPattern = new RegExp(`^top_(?:brands|interests)_(twitter)_comparison$`, 'i');
export const topSocialPattern = new RegExp(`^top_(?:brands|interests)_(twitter)$`, 'i');
export const topSocialUniqueFactsPattern = new RegExp(`^${NON_CHART_PREFIX}unique_facts_top_(?:brands|interests)_(twitter)$`, 'i');
export const userDefinedSocialEngagementComparisonPattern = new RegExp(`^user_defined_social_engagement_comparison_(twitter)_(.*)$`, 'i');
export const userDefinedSocialEngagementPattern = new RegExp(`^user_defined_social_engagement_(twitter)_(.*)$`, 'i');
export const userDefinedSocialEngagementUniqueFactsPattern = new RegExp(`^${NON_CHART_PREFIX}unique_facts_user_defined_social_engagement_(twitter)_(.*)$`, 'i');

export const geographicSelectionData = {
    region: ['1', '2', '3', '4', '5', '6', '7', '8', '9'],
    state: ['AK' ,'AL' ,'AR' ,'AZ' ,'CA' ,'CO' ,'CT' ,'DC' ,'DE' ,'FL' ,'GA' ,'HI' ,'IA' ,'ID' ,'IL' ,'IN' ,'KS' ,'KY' ,'LA' ,'MA' ,'MD' ,'ME' ,'MI' ,'MN' ,'MO' ,'MS' ,'MT' ,'NC' ,'ND' ,'NE' ,'NH' ,'NJ' ,'NM' ,'NV' ,'NY' ,'OH' ,'OK' ,'OR' ,'PA' ,'PR' ,'RI' ,'SC' ,'SD' ,'TN' ,'TX' ,'UT' ,'VA' ,'VT' ,'WA' ,'WI' ,'WV' ,'WY'],
};
export const geographicRegions = [
    {
        regionCode: '1',
        name: 'New England',
        labelTarget: 'NH',
        position: 5,
        states: ['CT', 'ME', 'MA', 'NH', 'RI', 'VT'],
    },
    {
        regionCode: '2',
        name: 'Middle Atlantic',
        labelTarget: 'PA',
        position: 3,
        states: ['NY', 'NJ', 'PA'],
    },
    {
        regionCode: '3',
        name: 'East North Central',
        labelTarget: 'MI',
        position: 1,
        states: ['IN', 'IL', 'MI', 'OH', 'WI'],
    },
    {
        regionCode: '4',
        name: 'West North Central',
        labelTarget: 'NE',
        position: 8,
        states: ['IA', 'KS', 'MN', 'MO', 'NE', 'ND', 'SD'],
    },
    {
        regionCode: '5',
        name: 'South Atlantic',
        position: 7,
        labelTarget: 'SC',
        states: ['DE', 'DC', 'FL', 'GA', 'MD', 'NC', 'SC', 'VA', 'WV'],
    },
    {
        regionCode: '6',
        name: 'East South Central',
        labelTarget: 'TN',
        position: 2,
        states: ['AL', 'KY', 'MS', 'TN'],
    },
    {
        regionCode: '7',
        name: 'West South Central',
        labelTarget: 'TX',
        position: 9,
        states: ['AR', 'LA', 'OK', 'TX'],
    },
    {
        regionCode: '8',
        name: 'Mountain',
        labelTarget: 'UT',
        position: 4,
        states: ['AZ', 'CO', 'ID', 'NM', 'MT', 'UT', 'NV', 'WY'],
    },
    {
        regionCode: '9',
        name: 'Pacific',
        labelTarget: 'CA',
        position: 6,
        states: ['AK', 'CA', 'HI', 'OR', 'WA'],
    },
];

export const barSize: number = 24;
export const groupRatioLabel: string = `Persona Share`;
export const headerSize: number = 87; // TODO: can we get this dynamically?
export const indexLabel: string = `Relative Index`;
export const minGroupRatioThreshold: number = 0.1;
const outOfRangeLabel: string = `<em>NOT IN RANGE</em>`;

export enum ReportLoaders {
    SHARING_REPORT = 'sharing report'
}

export type ComparisonDataSortOptions = {
    filterGroup: string,
    filterSettings: any,
    limit?: number,
    matchProperty: string,
    sortGroup: string
};
// export const territoryCodes = ['AS','GU','PR'];
export const usAlaskaHawaiiInsets = [
    {
        id: 'us-alaska',
        borderWidth: 0,
        field: {
            type: 'Polygon',
            coordinates: [
                [
                    [0, 60],
                    [25, 80],
                    [25, 100],
                    [0, 100]
                ]
            ]
        },
        geoBounds: {
            type: 'Polygon',
            coordinates: [
                [
                    [-179.5, 50],
                    [-129, 50],
                    [-129, 72],
                    [-179.5, 72]
                ]
            ]
        },
        padding: ['30%', '5%', 0, 0],
        projection: {
            name: 'LambertConformalConic',
            parallels: [55, 65],
            rotation: [154]
        }
    },
    {
        id: 'us-hawaii',
        borderWidth: 0,
        field: {
            type: 'Polygon',
            coordinates: [
                [
                    [25, 80],
                    [40, 92],
                    [40, 100],
                    [25, 100]
                ]
            ]
        },
        geoBounds: {
            type: 'Polygon',
            coordinates: [
                [
                    [-162, 23],
                    [-152, 23],
                    [-152, 18],
                    [-162, 18]
                ]
            ]
        },
        padding: ['30%', '20%', 0, '10%'],
        projection: {
            name: 'LambertConformalConic',
            parallels: [8, 18],
            rotation: [157]
        }
    }
];
export const usTerritoryInsets = [
    {
        id: 'us-puerto-rico',
        isTerritory: true,
        borderWidth: 0,
        field: {
            type: 'Polygon',
            coordinates: [
                [
                    [56, 82],
                    [56, 91],
                    [68, 91],
                    [68, 82]
                ]
            ]
        },
        geoBounds: {
            type: 'Polygon',
            coordinates: [
                [
                    [-68, 19],
                    [-68, 17],
                    [-65, 17],
                    [-65, 19]
                ]
            ]
        },
        projection: {
            name: 'LambertConformalConic',
            parallels: [18],
            rotation: [68]
        }
    },
    // Hide all other territories
    // {id: 'us-guam', isTerritory: true, borderWidth: 0, units: 'pixels'},
    // {id: 'us-samoa', isTerritory: true, borderWidth: 0, units: 'pixels'},
    // {id: 'us-mariana-1', isTerritory: true, borderWidth: 0, units: 'pixels'},
    // {id: 'us-mariana-2', isTerritory: true, borderWidth: 0, units: 'pixels'},
    // {id: 'us-virgin-1', isTerritory: true, borderWidth: 0, units: 'pixels'},
    // {id: 'us-virgin-2', isTerritory: true, borderWidth: 0, units: 'pixels'},
];
export const usFocusTerritoryInsets = [].concat(usAlaskaHawaiiInsets as [], usTerritoryInsets as []);
export const personIconDimensions = {
    width: 19,
    height: 19
}
export const heatmapStops = [
    [0, '#4466a4'], // Nick's blue-75
    [0.15, BaseColors.teal['75']],
    [0.40, BaseColors.green['75']],
    [0.60, '#fbe35f'], // Nick's yellow-75
    [0.85, BaseColors.orange['75']], // orange-75
    [1, BaseColors.red['75']], // red-75
];
export const reportColors = {
    axis: {
        low: {
            min: BaseColors.red['75'],
            max: BaseColors.red['25'],
        },
        high: {
            min: BaseColors.green['25'],
            max: BaseColors.green['75'],
        },

        min: 0,
        minColor: BaseColors.red['75'],
        maxColor: BaseColors.green['75'],
    },
    barIndex: {
        groupCount: BaseColors.teal['50'],
        groupCountLabel: BaseColors.teal['100'],
        groupRatio: BaseColors.blue['75'],
        groupRatioLabel: BaseColors.blue['100'],
        baselineRatio: BaseColors.blue['25'],
        index: BaseColors.green['75'],
        indexLabel: BaseColors.green['100'],
    },
    barStacked: [
        BaseColors.blue['75'],
        BaseColors.orange['75'],
        BaseColors.green['75'],
        BaseColors.purple['75'],
        BaseColors.teal['75'],
    ],
    // comparisonBar: {
    //     baseline: BaseColors.blue['25'],
    //     0: BaseColors.blue['75'],
    //     1: BaseColors.yellow['100'],
    // },
    comparison: {
        baseline: BaseColors.blue['25'],
        index: BaseColors.orange['75'],
        filter: {
            index: 'green',
            groupRatio: 'blue',
            groupPenetrationRatio: 'purple',
        },
        sort: {
            independent: 'orange',
            0: 'blue',
            1: 'yellow',
        },
        0: BaseColors.blue['75'],
        1: BaseColors.yellow['100'],
    },
    choropleth: {
        selection: {
            colorAxis: {
                dataClasses: [
                    {
                        color: BaseColors.red['75'],
                        name: 'Omitted',
                        to: 0,
                    },
                    {
                        color: 'white',
                        from: 0,
                        name: 'Ignored',
                        to: 1,
                    },
                    {
                        color: BaseColors.green['75'],
                        // color: 'black',
                        from: 1,
                        name: 'Selected',
                    },
                ]
            },
        },
        states: {
            hover: {
                color: BaseColors.blue['75'],
                borderColor: BaseColors.blue['25'],
            },
            hoverOmitted: {
                color: BaseColors.red['25'],
                borderColor: BaseColors.red['75'],
            },
            hoverSelected: {
                color: BaseColors.green['25'],
                borderColor: BaseColors.green['75'],
            },
        },
    },
    donut: {
        standard: [
            BaseColors.blue['75'],
            BaseColors.yellow['75'],
            BaseColors.teal['75'],
            BaseColors.orange['75'],
            BaseColors.purple['75'],
            BaseColors.red['75'],
            BaseColors.green['75'],

            BaseColors.yellow['25'],
            BaseColors.teal['50'],
            BaseColors.orange['25'],
            BaseColors.purple['25'],
            BaseColors.red['50'],
            BaseColors.green['25'],

            BaseColors.blue['100'],
            BaseColors.yellow['100'],
            BaseColors.teal['100'],
            BaseColors.orange['100'],
            BaseColors.purple['100'],
            BaseColors.red['100'],
            BaseColors.green['100'],
        ],
        baselineRatio: [
            BaseColors.blue['50'],
            BaseColors.yellow['50'],
            BaseColors.teal['50'],
            BaseColors.orange['50'],
            BaseColors.purple['50'],
            BaseColors.red['50'],
            BaseColors.green['50'],
        ],
        groupRatio: [
            BaseColors.blue['75'],
            BaseColors.yellow['75'],
            BaseColors.teal['75'],
            BaseColors.orange['75'],
            BaseColors.purple['75'],
            BaseColors.red['75'],
            BaseColors.green['75'],
        ]
    },
    gauge: {
        low: BaseColors.red[75],
        mid: BaseColors.yellow[75],
        high: BaseColors.green[75],
    },
    heatmap: {
        labels: {
            formatter: function () {
                return `${Utils.formatValue(this['value'], 'percent')}`;
            },
        },
        stops: heatmapStops,
        endOnTick: true,
        startOnTick: true,
        minColor: '#FFF',
        states: {
            hover: {
                color: BaseColors.gray['25'],
                borderColor: BaseColors.gray['100'],
            }
        },
    },
    indexBreadthDepthSummary: {
        breadth: BaseColors.blue['75'],
        breadthLabel: BaseColors.blue['100'],
        depth: BaseColors.yellow['75'],
        depthLabel: BaseColors.yellow['100'],
        index: BaseColors.green['75'],
        indexLabel: BaseColors.green['100'],
    },
    wedge: {
        fill: BaseColors.yellow['100'],
        fillSequence: [
            BaseColors.yellow['100'],
            BaseColors.orange['100'],
            BaseColors.purple['100'],
            BaseColors.teal['100'],
            BaseColors.green['100'],
            BaseColors.red['100'],
        ],
        stripes: BaseColors.gray['25'],
    },
    zone: {
        positiveNegative: [
            {
                value: 0,
                color: BaseColors.red['75'],
            },
            {
                color: BaseColors.green['75'],
            }
        ],
        relativeIndex: [
            {
                value: 1,
                color: BaseColors.red['75'],
            },
            {
                color: BaseColors.green['75'],
            }
        ],
    }
};
export const chartAxisOptions = {
    bar: [
        {
            min: 0,
            title: {
                text: null
            },
            labels: {
                format: '{value}%',
            },
        }
    ],
    barGroupPercent: [
        {
            min: 0,
            title: {
                text: 'Percent of Group'
            },
            labels: {
                format: '{value}%',
            },
        },
    ],
    barIndex: [
        {
            min: 0,
            title: {
                text: 'Depth of Interest'
            },
            labels: {
                format: '{value}%',
            },
        },
        {
            title: {
                text: indexLabel,
            },
            opposite: true,
        }
    ],
    barIndexGroupPercent: [
        {
            min: 0,
            title: {
                text: 'Percent of Group',
            },
            labels: {
                format: '{value}%',
            },
        },
        {
            title: {
                text: indexLabel,
            },
            opposite: true,
        }
    ],
    relativeIndex: [
        {
            title: {
                text: indexLabel,
            },
            plotLines: [{
                color: reportColors.comparison.index,
                value: 1,
                width: 2,
            }],
        },
    ],
    sIndex: [
        {
            title: {
                text: indexLabel,
            },
        }
    ],
};
export const chartDataLabelOptions = {
    map: {
        // allowOverlap: true,
        // backgroundColor: 'rgba(255, 255, 255, .4)',
        // color: '#000000',
        backgroundColor: 'rgba(0, 0, 0, .4)',
        color: '#ffffff',
        // crop: false,
        // overflow: 'none',
        padding: 3,
        useHTML: true,
        verticalAlign: 'bottom',
    },
};
export const chartPlotOptions = {
    bar: {
        column: {
            grouping: false,
            shadow: false,
            borderWidth: 0,
        }
    },
    barGroup: {
        series: {
            groupPadding: 0,
        },
        column: {
            grouping: true,
            shadow: false,
            borderWidth: 0,
        },
    },
    barPositiveNegative: {
        bar: {
            zones: reportColors.zone.relativeIndex,
        },
    },
    barStacked: {
        bar: {
            stacking: 'normal',
        },
    },
    donut: {
        pie: {
            allowPointSelect: true,
            // borderWidth: 0.5,
            // borderColor: 'transparent',
            center: ['50%', '50%'],
            cursor: 'pointer',
            dataLabels: {
                enabled: false,
            },
            shadow: false,
            showInLegend: true,
            size: '100%',
            startAngle: 270,
            states: {
                hover: {
                    brightness: 0,
                    halo: null,
                },
                inactive: {
                    opacity: 0.5,
                },
            },
        }
    },
    radial: {
        column: {
            stacking: 'normal',
            borderWidth: 0,
            pointPadding: 0,
            groupPadding: 0.15
        }
    }
};
export const defaultChartOptions = {
    chart: {
        backgroundColor: 'transparent',
    },
    credits: {
        enabled: false,
    },
    responsive: {
        rules: [
            {
                condition: {
                    maxWidth: 250,
                }
            }
        ],
    },
    title: {
        style: {
            color: BaseColors.gray['75]'],
            fontSize: '18px',
            fontWeight: 'normal',
        },
        useHTML: true,
        widthAdjust: -10,
    },
    tooltip: {
        outside: true,
        shared: true,
        useHTML: true,
    },
};
export const iconPath = {
    personaBuilder: '/assets/images/logo-icon-only-gray-75.svg',
    // personaBuilder: '../../../../assets/images/logo-icon-only-gray-75.svg',
    wiland: '/assets/images/wiland-logo-icon-only-gray-75.svg',
    // wiland: '../../../../assets/images/wiland-logo-icon-only-gray-75.svg',
};

const compositeChartSourceFields = {
    conglomerateRFM: {
        metrics: ['active_buyer', 'mem_buyer_num', 'incep_source_l12_num'],
        participation: ['ord_num', 'chan_web_num'],
    }
}

/* Functions */

const baseOutlines = async (includeTerritories: boolean = false, returnRawData: boolean = false) => {
    let baseOutlineData = require(includeTerritories ?
        '@highcharts/map-collection/countries/us/custom/us-all-territories.topo.json' :
        '@highcharts/map-collection/countries/us/us-all.topo.json'
    );

    baseOutlineData.objects.default.geometries = baseOutlineData.objects.default.geometries
        .filter(feature => {
            // Take out any areas that are not necessary for our mapping needs (i.e. US states + Puerto Rico)
            return feature.id.match(/^(?:US\.|PR.)/);
        })
    // .map(feature => {
    //     // Fix South Carolina / St. Croix collision issue
    //     if (feature.properties['hc-a2'] === 'SC' && feature.properties['type'] !== 'State') {
    //         feature.properties['hc-a2'] = 'SCX';
    //     }
    //
    //     return feature;
    // })
    ;

    return returnRawData ? baseOutlineData : formatOutlineSeries(baseOutlineData);
}
export {baseOutlines}

export const chartAssociatedData = (associatedId: string, insights: any, options?: any) => {
    if (!insights?.is_data_available) {
        console.error(`🔴 Insights data not available for "${associatedId}"!`, options);
        return false;
    }

    switch (associatedId) {
        case 'age_avg':
            return {
                title: 'Average Age',
                detail: `Your persona is {{ value }} years {{ direction }} than average.`,
                positive_label: 'older',
                negative_label: 'younger',
                baseline_value: insights.offline_standard?.demographics[associatedId][0].baselineRatio,
                persona_value: insights.offline_standard?.demographics[associatedId][0].groupRatio,
            };

        // case 'cc_amt':
        // case 'cc_major_amt':
        // case 'cc_major_ord_num':
        // case 'cc_ord_num': {
        //     let detail = '';
        //     switch (associatedId) {
        //         case 'cc_amt':
        //             detail = `Your persona is {{ value }}% {{ direction }} to have {{ label }} spending using a debit or credit card.`;
        //             break;
        //
        //         case 'cc_major_amt':
        //             detail = `Your persona is {{ value }}% {{ direction }} to have {{ label }} spending using a major debit or credit card.`;
        //             break;
        //
        //         case 'cc_major_ord_num':
        //             detail = `Your persona is {{ value }}% {{ direction }} to have made a {{ label }} number of purchases and donations using a major debit or credit card.`;
        //             break;
        //
        //         case 'cc_ord_num':
        //             detail = `Your persona is {{ value }}% {{ direction }} to have made a {{ label }} number of purchases and donations using a debit or credit card.`;
        //             break;
        //     }
        //
        //     const greatestValue = chartAssociatedGreatestValue(
        //         '',
        //         detail,
        //         insights.high_level_rfm.highLevelRfmVariables[associatedId]
        //     );
        //
        //     return Object.assign({},
        //         greatestValue,
        //         {
        //             baseline_label: 'Spenders in Baseline',
        //             persona_label: 'Spenders in Persona',
        //             value_format: 'percent',
        //         }
        //     );
        // }

        case 'children_age_range_index':
            return chartAssociatedGreatestValue(
                `Age of Children ${indexLabel}`,
                `Your persona is {{ value }}% {{ direction }} to have children {{ label }} in the household.`,
                dictionaryLookup(
                    options.fieldDictionary.standard,
                    insights.offline_standard?.demographics['children_age_range'],
                    'children_age_range'
                )
            );

        case 'children_present_in_hh_index':
            return chartAssociatedGreatestValue(
                `Presence of Children ${indexLabel}`,
                `Your persona is {{ value }}% {{ direction }} to have {{ label }} in the household.`,
                dictionaryLookup(
                    options.fieldDictionary.standard,
                    insights.offline_standard?.demographics['children_present_in_hh'],
                    'children_present_in_hh'
                )
            );

        case 'gender_index':
            return chartAssociatedGreatestValue(
                `Gender ${indexLabel}`,
                `Your persona is {{ value }}% {{ direction }} to be {{ label }}.`,
                dictionaryLookup(
                    options.fieldDictionary.standard,
                    insights.offline_standard?.demographics['gender'],
                    'gender'
                )
            );

        case 'home_value_avg':
            return {
                title: 'Average Home Value',
                detail: `Your persona's home value is {{ value }} {{ direction }} than average.`,
                value_format: 'currency',
                positive_label: 'more',
                negative_label: 'less',
                baseline_value: insights.offline_standard?.demographics[associatedId][0].baselineRatio,
                persona_value: insights.offline_standard?.demographics[associatedId][0].groupRatio,
            };

        case 'length_of_residence_avg':
            return {
                title: 'Average Length of Residence',
                detail: `Your persona has lived in their residence {{ value }} years {{ direction }} than average.`,
                positive_label: 'longer',
                negative_label: 'shorter',
                baseline_value: insights.offline_standard?.demographics[associatedId][0].baselineRatio,
                persona_value: insights.offline_standard?.demographics[associatedId][0].groupRatio,
            };

        case 'marital_status_index':
            return chartAssociatedGreatestValue(
                `Marriage ${indexLabel}`,
                `Your persona is {{ value }}% {{ direction }} to be {{ label }}.`,
                dictionaryLookup(
                    options.fieldDictionary.standard,
                    insights.offline_standard?.demographics['marital_status'],
                    'marital_status'
                )
            );

        case 'occupation_type_highest_count':
            return chartAssociatedGreatestValue(
                'Occupation Type Highest Count',
                `Your persona is {{ value }}% {{ direction }} to be {{ label }}.`,
                dictionaryLookup(
                    options.fieldDictionary.standard,
                    insights.offline_standard?.demographics['occupation_type'],
                    'occupation_type'
                ),
                'groupCount'
            );

        case 'occupation_type_highest_index':
            return chartAssociatedGreatestValue(
                'Occupation Type Highest Index',
                `Your persona is {{ value }}% {{ direction }} to be {{ label }}.`,
                dictionaryLookup(
                    options.fieldDictionary.standard,
                    insights.offline_standard?.demographics['occupation_type'],
                    'occupation_type'
                ),
            );

        case 'persona_influence': {
            return {
                title: 'Persona Influence',
                detail: `Your persona is {{ value }} {{ direction }} to represent high influencers.`,
                index: insights.social?.groupMetrics.twitterStats.highInfluenceIndex,
                value_format: 'percent',
                positive_label: 'more likely',
                negative_label: 'less likely',
                baseline_label: 'High Influencers in Baseline',
                baseline_value: insights.social?.baselineMetrics.twitterStats.percentHighInfluence,
                persona_label: 'High Influencers in Persona',
                persona_value: insights.social?.groupMetrics.twitterStats.percentHighInfluence,
            };
        }

        case 'rfm_idx': {
            const data = insights.high_level_rfm?.highLevelRfmVariables[associatedId]?.find(item => item.value === 'very_high');

            if (data) {
                return {
                    // title: categoryDefinition.shortDescription,
                    title: null,
                    // detail: `Your persona is {{ value }} more likely to have a {{ direction }} RFM score.`,
                    detail: `Your persona is {{ value }} {{ direction }} likely to have extreme RFM activity.`,
                    index: data.index,
                    value_format: 'percent',
                    positive_label: 'more',
                    negative_label: 'less',
                    baseline_label: 'Spenders in Baseline',
                    baseline_value: data.baselineRatio,
                    persona_label: 'Spenders in Persona',
                    persona_value: data.groupRatio,
                };
            } else {
                return false;
            }
        }

        case 'typical_social_following_activity': {
            // This one's a little different, since both values are the BASELINE - just manually override everything...
            // const followingDifference = ((insights.social.groupMetrics.twitterStats.followingIndex - 1) * insights.social.baselineMetrics.twitterStats.medianFollowing).toFixed(2);
            const followingDifference = insights.social?.groupMetrics.twitterStats.medianFollowing - insights.social?.baselineMetrics.twitterStats.medianFollowing;
            const directionLabel = followingDifference >= 0 ? 'more' : 'fewer'; // TODO: implement based on values
            const detail_class = followingDifference >= 0 ? 'value-greater' : 'value-less'; // TODO: implement based on values

            return {
                title: 'Typical Social Following Activity',
                // detail: `Your persona follows ${Math.round(Math.abs(followingDifference))} ${directionLabel} Twitter accounts than typical.`,
                detail: `Your persona follows ${Math.round(Math.abs(followingDifference))} ${directionLabel} accounts than typical.`,
                detail_class,
                value_format: 'separated',
                baseline_label: 'Median Followers in Baseline',
                baseline_value: Math.round(insights.social?.baselineMetrics.twitterStats.medianFollowers),
                persona_label: 'Median Following in Baseline',
                persona_value: Math.round(insights.social?.baselineMetrics.twitterStats.medianFollowing),
            };
        }

        case (associatedId.match(audienceSegmentAssociatedDataPattern) || {}).input: {
            const [, demographic] = associatedId.match(audienceSegmentAssociatedDataPattern),
                demographicName = options.fieldDictionary.standard[demographic].shortDescription,
                demographicData = insights.offline_standard?.demographics[demographic];
            let translatedData: any[];
            switch (demographic) {
                case 'dma_code':
                    translatedData = demographicData.map((dataItem: any) => {
                        const dmaCode = options.dmaCodes?.find((dmaCode: any) => dmaCode.code === dataItem.value);
                        if (dmaCode) {
                            dataItem.value = `${dmaCode.code} - ${dmaCode.description}`;
                            dataItem.retainCase = true;
                        }

                        return dataItem;
                    });
                    break;

                case 'postal_code':
                    // Leave as-is
                    translatedData = demographicData;
                    break;

                default:
                    translatedData = dictionaryLookup(
                        options.fieldDictionary.standard,
                        demographicData,
                        demographic
                    )
            }
            // translatedData = translatedData.map((item: any) => Object.assign(item, {retainCase: true}))

            const associatedData = chartAssociatedGreatestValue(
                `${demographicName} Relative Index`,
                `Your audience is {{ value }}% {{ direction }} to be {{ label }}.`,
                translatedData
            );

            if (associatedData) {
                associatedData.persona_label = associatedData.persona_label.replace(/^(.*) in Persona$/, '$1 in Audience');
            }

            return associatedData;
        }

        case (associatedId.match(conglomerateRfmMarketPattern) || {}).input: {
            let [, marketId, chartContext] = associatedId.match(conglomerateRfmMarketPattern);
            if (!marketId || !chartContext || !insights.hasOwnProperty('conglomerate_rfm') || !Object.keys(insights.conglomerate_rfm?.markets || {}).length) {
                // No data for this!
                return false;
            }
            marketId = parseInt(marketId);

            const market: any | null = insights.conglomerate_rfm.markets
                .find((sourceMarket: any) => sourceMarket.id === marketId);
            if (!market) {
                // No match found for market name
                console.error(`No market found for "${marketId}" (context: ${chartContext})`);
                return false;
            }

            let detail: string;
            let translatedData: any[] = [];
            switch (chartContext) {
                case 'metrics':
                case 'participation':
                    detail = `Your persona is {{ value }}% {{ direction }} to have {{ label }}`;
                    const attributes: string[] = compositeChartSourceFields.conglomerateRFM[chartContext];
                    for (const attribute of attributes) {
                        const dictionarySource = dictionaryLookup(
                            options.fieldDictionary.conglomerateRFM,
                            market.values[attribute],
                            attribute
                        );
                        translatedData.push(dictionarySource[0]);
                    }
                    break;

                default:
                    const field = options.fieldDictionary.conglomerateRFM[chartContext];
                    detail = `Your persona is {{ value }}% {{ direction }} to have {{ vowelize label "a|an" }} {{ label }} {{ if label "average" then "sized" }} ${field.shortDescription.toLowerCase()} in this market.`;
                    const dictionaryData = dictionaryLookup(
                        options.fieldDictionary.conglomerateRFM,
                        market.values[chartContext],
                        chartContext
                    );

                    translatedData = Utils.sortByProperty(
                        dictionaryData,
                        'position',
                        'asc'
                    );
                    break;
            }

            const associatedData = chartAssociatedGreatestValue(
                null, // Blank title,
                detail,
                translatedData
            );

            return associatedData;
        }

        case (associatedId.match(highLevelRfmPattern) || {}).input: {
            let [, fieldName, chartType] = associatedId.match(highLevelRfmPattern);
            if (!chartType) {
                chartType = 'column';
            }

            let data = insights.high_level_rfm?.highLevelRfmVariables[fieldName];
            if (data) {
                if (options?.fieldDictionary?.highLevelRFM?.hasOwnProperty(fieldName)) {
                    // Translate from the dictionary
                    data = dictionaryLookup(
                        options.fieldDictionary.highLevelRFM,
                        insights.high_level_rfm?.highLevelRfmVariables[fieldName],
                        fieldName
                    );
                } else {
                    console.error(`🔴 No field dictionary item found for "${fieldName}"`, options?.fieldDictionary?.highLevelRFM);
                }

                switch (chartType) {
                    case 'gauge': {
                        const targetData = data.find(item => item.value === 'very_high');

                        let detail: string = '';
                        switch (fieldName) {
                            case 'recency_idx':
                                detail = `Your persona is {{ value }} {{ direction }} likely to have extreme recency behavior.`;
                                break;

                            case 'rfm_idx':
                                detail = `Your persona is {{ value }} {{ direction }} likely to have extreme RFM activity.`;
                                break;
                        }

                        return {
                            title: null,
                            detail,
                            index: targetData?.index,
                            value_format: 'percent',
                            positive_label: 'more',
                            negative_label: 'less',
                            baseline_label: 'Spenders in Baseline',
                            baseline_value: targetData?.baselineRatio,
                            persona_label: 'Spenders in Persona',
                            persona_value: targetData?.groupRatio,
                        };
                    }

                    default: {
                        const detailSentencePatterns: any = {
                            cc_amt: `Your persona is {{ value }}% {{ direction }} to have {{ label }} spending using a debit or credit card.`,
                            cc_major_amt: `Your persona is {{ value }}% {{ direction }} to have {{ label }} spending using a major debit or credit card.`,
                            cc_major_ord_num: `Your persona is {{ value }}% {{ direction }} to have made {{ vowelize label "a|an" }} {{ label }} number of purchases and donations using a major debit or credit card.`,
                            cc_ord_num: `Your persona is {{ value }}% {{ direction }} to have made {{ if label "average" then "an" }} {{ label }} {{ if label "average" then "number of" }} purchases and donations using a debit or credit card.`,
                            incep_source_l12_num: `Your persona is {{ value }}% {{ direction }} to have transacted with {{ vowelize label "a|an" }} {{ label }} number of new sources in the past 12 months.`,
                            lat_incep_months: `Your persona is {{ value }}% {{ direction }} to have made their most recent NTF transaction {{ if label "average" then "an" }} {{ label }} {{ if label "average" then "number of" }} months ago.`,
                            mem_buyer_num: `Your persona is {{ value }}% {{ direction }} likely to have transacted with {{ if label "average" then "an" }} {{ label }} {{ if label "average" then "number of" }} brands and organizations.`,
                            ord_avg_amt: `Your persona is {{ value }}% {{ direction }} to have {{ vowelize label "a|an" }} {{ label }} {{ if label "average" then "sized" }} average transaction amount.`,
                            ord_lat_amt: `Your persona is {{ value }}% {{ direction }} to have {{ vowelize label "a|an" }} {{ label }} latest transaction amount.`,
                            ord_lrg_amt: `Your persona is {{ value }}% {{ direction }} to have {{ vowelize label "a|an" }} {{ label }} largest transaction amount.`,
                            ord_num: `Your persona is {{ value }}% {{ direction }} to have made {{ if label "average" then "an" }} {{ label }} {{ if label "average" then "number of" }} retail purchases.`,
                            recency: `Your persona is {{ value }}% {{ direction }} to have {{ label }} transaction recency.`,
                            tot_amt:`Your persona is {{ value }}% {{ direction }} to have spent {{ if label "average" then "an" }} {{ label }} {{ if label "average" then "number of" }} dollars across all purchases and donations.`,
                            tot_amt_per_mo: `Your persona is {{ value }}% {{ direction }} to have {{ label }} spending each month.`,
                        };
                        const detail = detailSentencePatterns.hasOwnProperty(fieldName) ? detailSentencePatterns[fieldName]: `NO DETAIL AVAILABLE FOR "${fieldName}"...`;
                        const greatestValue = chartAssociatedGreatestValue(
                            '',
                            detail,
                            data
                        );

                        return Object.assign({},
                            greatestValue,
                            {
                                baseline_label: 'Spenders in Baseline',
                                persona_label: 'Spenders in Persona',
                                value_format: 'percent',
                            }
                        );
                    }
                }
            } else {
                return false;
            }
        }

        case (associatedId.match(pastPurchaseAssociatedDataPattern) || {}).input: {
            const [, property] = associatedId.match(pastPurchaseAssociatedDataPattern);
            const categoryDefinition = options.fieldDictionary.standard[property];
            const data = insights.offline_standard?.demographics[property].find(item => item.value == 1);
            return {
                title: categoryDefinition?.shortDescription,
                detail: `Your persona is {{ value }} {{ direction }} to have purchased in this category.`,
                index: data?.index,
                value_format: 'percent',
                positive_label: 'more likely',
                negative_label: 'less likely',
                baseline_label: 'Category Buyers in Baseline',
                baseline_value: data?.baselineRatio,
                persona_label: 'Category Buyers in Persona',
                persona_value: data?.groupRatio,

            };
        }

        case (associatedId.match(predictedSpendPattern) || {}).input: {
            const [, context, displayOrder] = associatedId.match(predictedSpendPattern);
            const category = insights.consumer_spend?.categories
                .find((category: any) => Utils.slug(category.name) === context);
            const subCategory = category.subCategories
                .find((subCategory: any) => subCategory.displayOrder === parseInt(displayOrder));

            return {
                title: subCategory?.name,
                detail: `Your persona is {{ value }} {{ direction }} to spend on ${subCategory.name}.`,
                index: subCategory?.index,
                value_format: 'percent',
                positive_label: 'more likely',
                negative_label: 'less likely',
                baseline_label: 'Spenders in Baseline',
                baseline_value: subCategory?.baselineRatio,
                persona_label: 'Spenders in Persona',
                persona_value: subCategory?.groupRatio,
            };
        }

        default:
            console.error(`🟡 No associated data handler for "${associatedId}"`);
            return false;
    }
}


/**
 * Get ChartAssociatedData parameters for the data item with the greatest difference between multiple sets
 *
 * @param title
 * @param detail
 * @param data
 * @param property
 * @param labelProperty
 */
export const chartAssociatedGreatestDifference = (
    title: string,
    detail: string,
    data: any[],
    property: string = 'index',
    labelProperty: string = 'value'
) => {
    // property = property || 'index';
    // labelProperty = labelProperty || 'value';
    let differences: any[] = [];
    // console.debug(`🧮🟢 CHART ASSOCIATED GREATEST DIFFERENCE (${property}): ${title} --- ${detail}`, data);

    // Step 1: Calculate the differences for each item
    let i = 0;
    for (const dataSegment of data) {
        for (const item of dataSegment.series) {
            let differenceItemIndex = differences.findIndex(existingItem => existingItem.index === item.originalValue);
            if (differenceItemIndex > -1) {
                // Update the difference if applicable
                const differenceItem = differences[differenceItemIndex];
                if (item[property] < differenceItem.minValue) {
                    differenceItem.minIndex = i;
                    differenceItem.minValue = item[property];
                }
                if (item[property] > differenceItem.maxValue) {
                    differenceItem.maxIndex = i;
                    differenceItem.maxValue = item[property];
                }
                differenceItem.difference = Math.abs(differenceItem.minValue - differenceItem.maxValue);
            } else {
                // Add the new item
                differences.push({
                    index: item.originalValue,
                    minIndex: i,
                    minValue: item[property],
                    maxIndex: i,
                    maxValue: item[property],
                });
            }
        }
        ++i;
    }
    // console.debug(`🧮🟡 ${title} DIFFERENCES:`, differences);

    // Step 2: Return the greatest difference
    const greatestDifference: any = Utils.sortByProperty(differences, 'difference', 'desc')[0];
    const direction = greatestDifference.minIndex > greatestDifference.maxIndex ? 'left' : 'right';
    let value = greatestDifference.difference;
    if (property === 'index') {
        // Convert to a percentage
        value *= 100;
    }
    // let value = (greatestDifference.difference / (
    //         greatestDifference.minIndex < greatestDifference.maxIndex ?
    //             greatestDifference.minValue :
    //             greatestDifference.maxValue
    //     )
    // ) * 100;
    if (value === Infinity) {
        value = 100
    }
    const firstData = data[0];
    const lastData = data[data.length - 1];

    // console.debug(`🧮🔴 ${title} DIFFERENCE VALUE: ${value} (property: ${property})`, greatestDifference, firstData, lastData);

    const positive_label = property === 'index' ? 'higher' : 'more';
    const negative_label = property === 'index' ? 'lower' : 'less';
    const baseline_data = firstData.series.find(dataItem => dataItem.originalValue === greatestDifference.index);
    const baseline_value = baseline_data?.hasOwnProperty(property) ? baseline_data[property] : 0;
    const persona_data = lastData.series.find(dataItem => dataItem.originalValue === greatestDifference.index);
    const persona_value = persona_data?.hasOwnProperty(property) ? persona_data[property] : 0;
    const value_format = property === 'groupRatio' ? 'percent' : 'decimal',
        value_label = firstData.series.find(dataItem => dataItem.originalValue === greatestDifference.index)[labelProperty];

    // console.debug('VALUE LABEL:', value_label, data[0].find(dataItem => dataItem.originalValue === greatestDifference.index))

    return {
        title,
        detail,
        detail_class: greatestDifference.minIndex > greatestDifference.maxIndex ? 'value-greater' : 'value-less',
        detail_format: 'decimal',
        detail_format_decimals: 0,
        direction,
        value,
        value_format,
        value_label,
        positive_label,
        negative_label,
        // baseline_label: `Persona ${Utils.numberToAlpha(1)}`,
        baseline_label: firstData.label,
        // persona_label: `Persona ${Utils.numberToAlpha(data.length)}`,
        persona_label: lastData.label,
        baseline_value,
        persona_value,
        data_invalid: isNaN(value),
    };
}

/**
 * Get ChartAssociatedData parameters for the data item with the greatest value
 */
export const chartAssociatedGreatestValue = (title: string | null, detail: string, data: any[], property: string = 'index') => {
    let greaterKey = 0;
    let greaterValue = 0;

    if (!isEmptyArray(data)) {
        for (let i = 0, end = data.length; i < end; ++i) {
            const currentValue = data[i][property];
            if (currentValue > greaterValue) {
                greaterValue = currentValue;
                greaterKey = i;
            }
        }
        const greaterData = data[greaterKey];
        const value: number = parseFloat((100 * (greaterData.index - 1)).toFixed(1));
        const value_label = (
            greaterData.retainCase ?
                greaterData.value :
                greaterData.value?.toLowerCase()
        ) || 'Unknown';
        // const direction = value >= 0 ? 'more likely' : 'less likely';

        return {
            // greaterData,
            title,
            detail,
            detail_format: 'decimal',
            detail_format_decimals: 1,
            value,
            // value_label: greaterData.value.toLowerCase(),
            value_label,
            positive_label: 'more likely',
            negative_label: 'less likely',
            baseline_label: `${greaterData.value} in Baseline`,
            persona_label: `${greaterData.value} in Persona`,
            baseline_value: greaterData.baselineRatio,
            persona_value: greaterData.groupRatio,
        };
    }
}

export const chartDataToSeries = (type: string, data: any, options: any = {}) => {
    if (!data) {
        return false;
    }

    options = Object.assign({
        chartLabels: {},
        property: null,
    }, options);
    options.chartLabels = Object.assign({
        baseline: 'Baseline',
        group: 'Persona',
    }, options.chartLabels);

    switch (type) {
        case 'bar':
            return [
                {
                    name: options.chartLabels.baseline,
                    type: 'column',
                    // yAxis: 0,
                    color: reportColors.barIndex.baselineRatio,
                    data: data.map(item => item.baselineRatio)
                    // data: data.map(item => item.baseline)
                },
                {
                    name: options.chartLabels.group,
                    type: 'column',
                    pointPadding: 0.3,
                    pointPlacement: 0,
                    // yAxis: 0,
                    color: reportColors.barIndex.groupRatio,
                    data: data.map(item => item.groupRatio)
                }
            ];

        case 'barIndex':
            return [
                {
                    name: options.chartLabels.baseline,
                    type: 'column',
                    yAxis: 0,
                    color: reportColors.barIndex.baselineRatio,
                    data: data.map(item => item.baselineRatio)
                },
                {
                    name: options.chartLabels.group,
                    type: 'column',
                    pointPadding: 0.3,
                    pointPlacement: 0,
                    yAxis: 0,
                    color: reportColors.barIndex.groupRatio,
                    data: data.map(item => item.groupRatio)
                },
                {
                    name: indexLabel,
                    type: 'line',
                    yAxis: 1,
                    color: reportColors.barIndex.index,
                    zones: reportColors.zone.relativeIndex,
                    data: data.map(item => item.index)
                }
            ];

        case 'demographicComparison': {
            let [fieldName, dataProperty] = options.property.split('|');
            if (!dataProperty) {
                dataProperty = 'groupRatio';
            }
            let series: any[] = [];
            let xAxis = {};
            let titleText = '';
            for (let i = 0, end = data.insights.length; i < end; ++i) {
                const sourceData: any[] = Utils.sortByProperty(
                    dictionaryLookup(
                        data.fieldDictionary.standard,
                        data.insights[i].offline_standard?.demographics[fieldName],
                        fieldName
                    ),
                    'position',
                    'asc'
                );

                if (i === 0) {
                    // Set up the X axis
                    xAxis = {
                        categories: sourceData.map(item => item.value),
                    };
                    titleText = data.fieldDictionary.standard[fieldName]?.shortDescription || `NO DICTIONARY ENTRY FOUND FOR '${fieldName}'`;

                    // Add the baseline
                    if (dataProperty === 'index') {
                        titleText = `${titleText} ${indexLabel}`;
                    } else {
                        // Use the baseline ratio
                        series.push({
                            color: reportColors.comparison.baseline,
                            data: sourceData.map(item => item.baselineRatio),
                            grouping: false,
                            name: `Baseline`,
                            pointPadding: -0.066,
                            type: 'column',
                        });
                    }
                }

                series.push({
                    color: reportColors.comparison[i],
                    data: sourceData.map(item => item[dataProperty]),
                    grouping: sourceData.length <= 2,
                    // name: `Persona ${Utils.numberToAlpha(i + 1)}`,
                    name: data.insights[i].personaName,
                    type: sourceData.length > 2 ? 'line' : 'column',
                });
            }

            return {series, xAxis, titleText};
        }

        case 'donut':
            return data.map((item, index) => {
                return {
                    name: item.value,
                    y: item[options.property],
                    // i: index,
                    color: reportColors.donut['standard'][index]
                }
            });

        // case 'donutSingle':
        //     return data.map((item, index) => {
        //         return {
        //             name: item.value,
        //             y: item[property],
        //             color: reportColors.donut.baselineRatio[index]
        //         }
        //     });

        case 'highLevelRfmComparison': {
            let [fieldName, dataProperty] = options.property.split('|');
            if (!dataProperty) {
                dataProperty = 'groupRatio';
            }
            let series: any[] = [];
            let xAxis = {};
            const dictionaryItem: any = data.fieldDictionary.highLevelRFM[fieldName];
            let titleText = dictionaryItem?.shortDescription;

            if (dataProperty === 'index') {
                titleText = `${titleText} ${indexLabel}`;
            }

            for (let i = 0, end = data.insights.length; i < end; ++i) {
                let fieldData: any[] = data.insights[i].high_level_rfm?.highLevelRfmVariables[fieldName]
                    .map((item: any) => Object.assign({}, item, {
                        value: dictionaryItem.values.find((field: any) => field.value === item.value)?.shortDescription || item.value
                    }));

                const sourceData: any[] = Utils.sortByProperty(
                    fieldData,
                    'position', // 'value',
                    'asc'
                );

                if (i === 0) {
                    // Set up the X axis
                    xAxis = {
                        categories: sourceData.map(item => item.value),
                    };

                    // Add the baseline
                    if (dataProperty !== 'index') {
                        // Use the baseline ratio
                        series.push({
                            color: reportColors.comparison.baseline,
                            data: sourceData.map(item => item.baselineRatio),
                            grouping: false,
                            name: `Baseline`,
                            pointPadding: -0.066,
                            type: 'column',
                        });
                    }
                }

                series.push({
                    color: reportColors.comparison[i],
                    data: sourceData.map(item => item[dataProperty]),
                    grouping: sourceData.length <= 2,
                    // name: `Persona ${Utils.numberToAlpha(i + 1)}`,
                    name: data.insights[i].personaName,
                    type: sourceData.length > 2 ? 'line' : 'column',
                });
            }

            return {series, xAxis, titleText};
        }

        case 'pastPurchaseComparison': {
            let [fieldName, dataProperty] = options.property.split('|');
            if (!dataProperty) {
                dataProperty = 'groupRatio';
            }
            let series: any[] = [];
            let xAxis = {};
            const rollupId = `purch_rollup_${fieldName}`;
            const categoryDefinition = data.fieldDictionary.standard[rollupId];
            let titleText = categoryDefinition?.shortDescription;

            if (dataProperty === 'index') {
                titleText = `${titleText} ${indexLabel}`;
            }

            for (let i = 0, end = data.insights.length; i < end; ++i) {
                let dictionaryData: any[] = [];
                categoryDefinition.children.forEach(dictionaryId => {
                    const dictionaryItem: any = data.fieldDictionary.standard[dictionaryId] || null;
                    const dictionaryElement = data.insights[i].offline_standard?.demographics[dictionaryId] || null;
                    if (!dictionaryItem || !dictionaryElement) {
                        return;
                    }

                    dictionaryData.push(Object.assign({}, dictionaryElement[0], {
                        // originalValue: dictionaryItem.value,
                        // position: dictionaryItem.position,
                        value: dictionaryItem?.shortDescription,
                    }));
                })

                const sourceData: any[] = Utils.sortByProperty(
                    dictionaryData,
                    'value',
                    'asc'
                );

                if (i === 0) {
                    // Set up the X axis
                    xAxis = {
                        categories: sourceData.map(item => item.value),
                    };

                    // Add the baseline
                    if (dataProperty !== 'index') {
                        // Use the baseline ratio
                        series.push({
                            color: reportColors.comparison.baseline,
                            data: sourceData.map(item => item.baselineRatio),
                            grouping: false,
                            name: `Baseline`,
                            pointPadding: -0.066,
                            type: 'column',
                        });
                    }
                }

                series.push({
                    color: reportColors.comparison[i],
                    data: sourceData.map(item => item[dataProperty]),
                    grouping: sourceData.length <= 2,
                    // name: `Persona ${Utils.numberToAlpha(i + 1)}`,
                    name: data.insights[i].personaName,
                    type: sourceData.length > 2 ? 'line' : 'column',
                });
            }

            return {series, xAxis, titleText};
        }

        case 'sIndex':
            return [
                {
                    name: indexLabel,
                    showInLegend: false,
                    type: 'bar',
                    zones: reportColors.zone.positiveNegative,
                    data: data.map(item => item.index)
                }
            ];
    }
}

/**
 * Render overlay data onto a chart
 * TODO: should this be a base method that can be extended in Persona/Audience report?
 *
 * @param chart
 * @param overlayType
 * @param clearElements
 */
export const chartOverlayCallback = (chart, overlayType: string, clearElements: boolean = true) => {
    try {
        if (chart) {
            if (clearElements) {
                // Remove existing overlay data on this chart, in case of redraw callbacks
                chart.container.querySelectorAll('[intent="overlay-data"]')?.forEach(element => {
                    element.parentNode.removeChild(element);
                });
            }

            switch (overlayType) {
                case 'predictedSpendSummary':
                case 'pastPurchaseSummary': {
                    const chartPadding = 10,
                        chartWidth = chart.plotBox.width;
                    const groupContainer = chart.userOptions.rawData,
                        indicatorPercent = Math.round(groupContainer.groupRatio),
                        indexPercent = groupContainer.index > 2 ?
                            Math.round(groupContainer.index) :
                            Utils.formatValue(Utils.indexPercent(groupContainer.index), 'percent', 0);

                    // Move the legend just below the gauge
                    const ringBottom = chart.pane[0].center[1] + (chart.plotBox.width / 2);
                    chart.legend.destroy();
                    chart.legend.options.y = ringBottom + chartPadding;
                    chart.legend.render();

                    // Render the slider
                    const indexText = groupContainer.index > 2 ?
                        'x as' :
                        groupContainer.index > 1 ?
                            ' more' :
                            ' less';
                    chart.renderer
                        .text(
                            `<div class="text-center value-${groupContainer.index > 1 ? 'greater' : 'less'}"
                                  style="width: ${chartWidth - (chartPadding * 2)}px; font-size: 10px;">
                                ${indexPercent}${indexText} ${chart.userOptions.sliderPhrase}
                            </div>
                            `,
                            chartPadding,
                            chart.plotBox.y + chartPadding / 2,
                            true
                        )
                        .attr({
                            intent: 'overlay-data',
                        })
                        .add()
                    chart.renderer
                        .text(
                            `
                            <div class="group-size-display mb-2"
                                 style="width: ${chartWidth - (chartPadding * 2)}px"
                                 :title="${indicatorPercent}% ${chart.userOptions.tooltipPhrase}" v-tooltip
                            >
                                <div class="group-size-indicator" 
                                     style="left: ${indicatorPercent}%"
                                >&#9650;
                                </div>
                            </div>
                            `,
                            chartPadding,
                            chart.plotBox.y + chartPadding * 2,
                            true
                        )
                        .attr({
                            intent: 'overlay-data',
                        })
                        .add()
                }
                    break;

                case 'geographicReport': // Fix label positioning so they do not exceed the bounds of the visible chart
                    for (const series of chart.series) {
                        if (!series.userOptions?.seriesType) {
                            continue;
                        }
                        series.data.forEach(city => {
                            const label = city.dataLabel || null;
                            if (!label) {
                                return;
                            }
                            const coords = {
                                x: label.x,
                                y: label.y,
                                width: label.width,
                                height: label.height,
                            };

                            if (label.x < 0) {
                                // Too far left
                                coords.x = 0;
                            } else if (chart.plotWidth < (label.x + label.width)) {
                                // Too far right
                                coords.x = chart.plotWidth - label.width;
                            } else {
                                return;
                            }

                            label.translate(coords.x, coords.y);
                        });
                    }

                    break;

                case 'icon': {
                    // Add the Font Awesome icon to the chart
                    // const container = chart.container.closest('[data-chart-container]');
                    const xPos = chart.plotBox.x + chart.pane[0].center[0];
                    const yPos = chart.plotBox.y + chart.pane[0].center[1];

                    chart.renderer
                        .text(
                            `<i class="${chart.userOptions.icon[0]} fa-${chart.userOptions.icon[1]} fa-lg"></i>`,
                            xPos,
                            yPos,
                            true
                        )
                        .attr({
                            intent: 'overlay-data',
                        })
                        .css({
                            color: defaultChartOptions.title.style.color,
                            // fontSize: chart.pane.size * .15,
                            transform: 'translate(-50%, 33%)',
                        })
                        .add();
                }
                    break;

                case 'personaSocialGraph': {
                    const rawData = chart.userOptions.rawData;
                    const numColumns = 6;
                    const numRows = 6;
                    const minPersons = 3;
                    const offsetTop = 30;
                    const iconStackWidth = numColumns * personIconDimensions.width;

                    const followerCount = rawData.groupMetrics.twitterStats.medianFollowers;
                    const followingCount = rawData.groupMetrics.twitterStats.medianFollowing;

                    // Find the appropriate base 10 value for the larger count
                    const base10 = (followerCount > followingCount)
                        ? Number('1e+' + followerCount.toExponential().split('e+')[1])
                        : Number('1e+' + followerCount.toExponential().split('e+')[1]);
                    const largerCount = Math.max(followerCount, followingCount);

                    // Determine count value for each "person" icon
                    const personValue = (Math.round(largerCount / base10) * base10) / (numRows * numColumns);
                    const followerPeople = Math.round(followerCount / personValue);
                    const followingPeople = (Math.round(followingCount / personValue)) > 1
                        ? Math.round(followingCount / personValue)
                        : minPersons;
                    const consumerPeople = 1; // Hard-coded per PersonaBuilder 2.x

                    const arrow = [
                        'M', 17.77, -0,
                        'C', 12.74, -0, 7.38, 2.05, 0, 6.01,
                        'L', 0.95, 7.77,
                        'C', 8.23, 3.87, 13.29, 2, 17.77, 2,
                        'C', 21.8, 2, 25.50, 3.49, 30.17, 6.6,
                        'L', 26.29, 9.49,
                        'L', 39.52, 11.2,
                        'L', 31.52, 0.53,
                        'L', 30.98, 4.74,
                        'C', 26.26, 1.63, 22.20, -0, 17.77, -0,
                        'z'
                    ];
                    const arrowWidth = 39;

                    drawPeopleIconStack(chart, {
                        rows: numRows,
                        columns: numColumns,
                        left: 20,
                        top: offsetTop,
                        count: Utils.formatValue(followerPeople, 'separated', 0),
                        total: Math.round(followerCount),
                        label: 'Median Followers',
                        color: BaseColors.purple['100'], // purple
                    });

                    chart.renderer
                        .path(arrow)
                        .attr({
                            fill: 'black',
                            translateX: (chart.plotWidth * .33) - (arrowWidth / 2) - 5,
                            translateY: offsetTop + 100,
                            intent: 'overlay-data',
                        })
                        .add();

                    drawPeopleIconStack(chart, {
                        rows: numRows,
                        columns: numColumns,
                        left: (chart.plotWidth / 2) - (iconStackWidth / 2),
                        top: offsetTop,
                        count: Math.round(consumerPeople),
                        includeTotal: false,
                        // total: Utils.formatValue(consumerPeople, 'separated', 0),
                        label: 'Typical Individual',
                        color: BaseColors.blue['75'], // blue-75
                    });

                    chart.renderer
                        .path(arrow)
                        .attr({
                            fill: 'black',
                            translateX: (chart.plotWidth * .66) - (arrowWidth / 2) + 10,
                            translateY: offsetTop + 100,
                            intent: 'overlay-data',
                        })
                        .add();

                    drawPeopleIconStack(chart, {
                        rows: numRows,
                        columns: numColumns,
                        left: chart.plotWidth - iconStackWidth - 20,
                        top: offsetTop,
                        count: Math.round(followingPeople),
                        // count: following_people,
                        total: Utils.formatValue(followingCount, 'separated', 0),
                        label: 'Median Following',
                        color: BaseColors.teal['100'],
                    });
                }
                    break;

                case 'politicalPartyAffiliation': {
                    const numColumns = 6;
                    const numRows = 8;
                    const offsetTop = 0;
                    const iconStackWidth = numColumns * personIconDimensions.width;
                    const stackLeft = [
                        0,
                        (chart.plotWidth / 2) - (iconStackWidth / 2),
                        chart.plotWidth - iconStackWidth,
                    ];
                    const labelPos = [
                        {x: 0, transform: null},
                        {x: (chart.plotWidth / 2), transform: 'translateX(-50%)'},
                        {x: chart.plotWidth, transform: 'translateX(-100%)'},
                    ];
                    const affiliationColor = {
                        D: BaseColors.blue['75'],
                        U: BaseColors.purple['75'],
                        R: BaseColors.red['75'],
                    }
                    const baselineBarPos = chart.plotHeight - 25,
                        baselineLabelPos = chart.plotHeight;

                    let index = 0,
                        barLeft = 0;
                    for (const affiliation of chart.userOptions.rawData) {
                        drawPeopleIconStack(chart, {
                            rows: numRows,
                            columns: numColumns,
                            left: stackLeft[index],
                            top: offsetTop,
                            count: Math.round(Math.min(affiliation.groupRatio, 96) / 2), // Cap at 96 per requirements
                            label: `${affiliation.value}: <strong>${Utils.formatValue(affiliation.groupRatio, 'percent', 1)}</strong>`,
                            color: affiliationColor[affiliation.originalValue],
                        });

                        // Baseline bar
                        let barWidth = chart.plotWidth * (affiliation.baselineRatio / 100)
                        chart.renderer
                            .rect({
                                x: barLeft,
                                y: baselineBarPos,
                                width: barWidth,
                                height: 8,
                            })
                            .attr({
                                fill: affiliationColor[affiliation.originalValue],
                                intent: 'overlay-data',
                            })
                            .add();

                        // Baseline label
                        let textLabel = `${affiliation.originalValue}: ${Utils.formatValue(affiliation.baselineRatio, 'percent', 1)}`;
                        if (index === 0) {
                            textLabel = `Baseline: ${textLabel}`;
                        }
                        chart.renderer
                            .text(
                                textLabel,
                                labelPos[index].x,
                                baselineLabelPos,
                                true
                            )
                            .attr({
                                intent: 'overlay-data',
                            })
                            .css({
                                color: defaultChartOptions.title.style.color,
                                transform: labelPos[index].transform,
                            })
                            .add();
                        barLeft += barWidth + 1; // 1px buffer between elements

                        ++index;
                    }
                }
                    break;

                case 'topAccounts':
                    const zIndex = 1;
                    const elementPadding = 10;
                    const topAccountsData = chart.series[3].userOptions.topAccounts;
                    const barSizeLocal = chart.series[0].data[0].shapeArgs.width;

                    let plotArea = chart.container.querySelector('.highcharts-plot-border'),
                        xOffset = chart.container.offsetWidth - parseInt(plotArea.getAttribute('width'));
                    chart.series[2].data.forEach((point, pointIndex) => {
                        const accountData = topAccountsData[pointIndex];
                        if (!accountData.accounts?.length) {
                            // Nothing to render!
                            return false;
                        }

                        let itemOffset = 0;
                        accountData.accounts.forEach((account, accountIndex) => {
                            if (accountIndex > 2) return;
                            let xPos = xOffset + itemOffset;
                            let yPos = chart.container.offsetHeight - point.shapeArgs.x;

                            // Rank & circle
                            const rankText = chart.renderer
                                .text(
                                    `${accountIndex + 1}`,
                                    xPos,
                                    yPos,
                                    true
                                )
                                .attr({
                                    class: 'rank-label',
                                    zIndex,
                                    intent: 'overlay-data',
                                })
                                .css({
                                    lineHeight: `${barSizeLocal}px`,
                                    minHeight: `${barSizeLocal}px`,
                                    minWidth: `${barSizeLocal}px`,
                                })
                                .add();
                            let elementOffset = rankText.getBBox().width + (elementPadding / 2);
                            itemOffset += elementOffset;

                            // Account image & label
                            let accountName = account.dataType === 'twitterFollow' ? account.item : account.displayName;
                            // let accountImage = account.hasOwnProperty('imageUrl') ? `<img src="${account.imageUrl}" width="${barSize}" height="${barSize}" onerror="${Utils.imageFallback('twitter')}"/>` : '';
                            if (account.dataType === 'twitterFollow') {
                                accountName = `<a :title="${account.displayName}" v-tooltip target="_new" href="https://twitter.com/${account.item}">${accountName}</a>`;
                                // accountImage = accountImage.length ?
                                //     `<a title="${account.displayName}" target="_new" href="https://twitter.com/${account.item}">${accountImage}</a>` :
                                //     accountImage;
                            }
                            const accountLabel = chart.renderer
                                .text(
                                    `${accountName}`,
                                    xPos + elementOffset,
                                    yPos,
                                    true
                                )
                                .attr({
                                    zIndex,
                                    intent: 'overlay-data',
                                })
                                .css({
                                    lineHeight: `${barSize}px`,
                                })
                                .add();
                            itemOffset += accountLabel.getBBox().width + elementPadding;
                        });

                    });
                    break;

                case 'twitterFollowFrequency':
                    const labelPadding = 30;
                    const twitterStats = chart.userOptions.rawData.twitterStats;

                    // Center the legend vertically
                    const chartHeight = chart.plotBox.height - chart.options.chart.marginBottom;
                    chart.legend.destroy();
                    chart.legend.options.y = chart.plotBox.y + (chartHeight / 2);
                    chart.legend.render();

                    chart.renderer
                        .text(
                            `
                                        Average Followers: <strong>${Utils.formatValue(twitterStats.avgFollowers, 'separated', 0)}</strong><br>
                                        Average Following: <strong>${Utils.formatValue(twitterStats.avgFollowing, 'separated', 0)}</strong>
                                    `,
                            (chart.renderTo.clientWidth * .25) + labelPadding,
                            chart.renderTo.clientHeight - labelPadding,
                            true
                        )
                        .attr({
                            intent: 'overlay-data',
                        })
                        .css({
                            color: defaultChartOptions.title.style.color,
                            transform: 'translateX(-50%)'
                        })
                        .add();

                    chart.renderer
                        .text(
                            `
                                        Max Followers: <strong>${Utils.formatValue(twitterStats.maxFollowers, 'separated', 0)}</strong><br>
                                        Max Following: <strong>${Utils.formatValue(twitterStats.maxFollowing, 'separated', 0)}</strong>
                                    `,
                            (chart.renderTo.clientWidth * .75) - labelPadding,
                            chart.renderTo.clientHeight - labelPadding,
                            true
                        )
                        .attr({
                            intent: 'overlay-data',
                        })
                        .css({
                            color: defaultChartOptions.title.style.color,
                            transform: 'translateX(-50%)'
                        })
                        .add();
                    break;


            }
        }
    } catch (error) {
        console.error(error);
    }
}

/**
 * Sort and filter comparison data (multi-array)
 *
 * @param sourceData
 * @param options
 */
export const comparisonDataSort = (sourceData: any[], options: ComparisonDataSortOptions) => {
    const {filterGroup, filterSettings, matchProperty, sortGroup} = options;
    let series: any[] = [];
    let seriesCategories: any[] = [];

    const numericSortGroup = Utils.isNumeric(sortGroup) ? parseInt(sortGroup) : -1;
    if (numericSortGroup > 0) {
        // Move the specified group to the FRONT of the stack so that it can be used as the sorting source when needed
        sourceData.unshift(sourceData.splice(numericSortGroup, 1)[0]);
    }

    let chartElementCount = 0;
    let i = 0;
    for (let {personaIndex, chartSourceData} of sourceData) {
        if (!chartSourceData || !filterGroup || !sortGroup) {
            break;
        }

        // Add the original index position of the items
        chartSourceData = Utils.sortByProperty(chartSourceData, filterGroup, 'desc')
            .map((item: any, sortIndex) => Object.assign(item, {originalPosition: sortIndex + 1}));

        switch (sortGroup) {
            case 'independent':
                // No action needed, we already sorted the data
                break;

            default: // Numeric sort group
                if (i !== 0) {
                    // Sort by the values in the opposing group
                    let remappedChartSourceData: any[] = [];
                    for (let sourceDataItem of sourceData[0].chartSourceData) {
                        // Find the matching item in this series
                        remappedChartSourceData.push(chartSourceData.find((item: any) => {
                            const itemMatch = item[matchProperty],
                                sourceMatch = sourceDataItem[matchProperty];
                            // console.debug(`Attempting to find sort match item on "${matchProperty}"`, itemMatch, sourceMatch);

                            if (typeof itemMatch === 'object' && itemMatch !== null) {
                                // Use the ID property of the match
                                return itemMatch.id === sourceMatch.id
                            } else {
                                return itemMatch === sourceMatch;
                            }
                        }));
                    }
                    chartSourceData = remappedChartSourceData;
                }
                break;
        }

        seriesCategories.push({
            personaIndex,
            labels: chartSourceData.map((category: any) => {
                return {
                    category,
                    originalPosition: category?.originalPosition || '',
                }
            }),
        });

        let seriesData: any = {
            type: 'bar',
            color: reportColors.comparison[personaIndex],
            dataLabels: [{
                align: 'left',
                format: '{point.rank}',
                style: {
                    fontSize: '1em',
                }
            }],
            formatter: (value: any) => {
                return `${value.toFixed(2)}`;
            },
        };
        seriesData.data = chartSourceData.map((item: any) => {
            let rank: string = '';
            if (item) {
                rank = item.originalPosition ? `#${item.originalPosition}` : '';
            }

            return {
                rank,
                y: item ? item[filterGroup] || 0 : 0,
            }
        });

        // const limit = filterSettings.count || options.limit || false;
        if (filterSettings.count) {
            seriesData.data.splice(filterSettings.count, seriesData.data.length - filterSettings.count);
            // seriesCategories = seriesCategories.map((seriesCategoryData: any) => {
            //     seriesCategoryData.labels.splice(filterSettings.count, seriesCategoryData.labels.length - filterSettings.count);
            //     return seriesCategoryData;
            // });
        }

        seriesData.personaIndex = personaIndex;
        series.push(seriesData);
        if (chartElementCount < seriesData.data.length) {
            chartElementCount = seriesData.data.length;
        }
        ++i;
    }

    // Reorder the series by original indexing
    seriesCategories = Utils.sortByProperty(seriesCategories, 'personaIndex', 'asc');
    series = Utils.sortByProperty(series, 'personaIndex', 'asc');

    return {
        chartElementCount,
        series,
        seriesCategories,
    };
}

/**
 * Import descriptors from the field dictionary
 *
 * @param fieldDictionary
 * @param data
 * @param context
 * @param property
 */
export const dictionaryLookup = (
    fieldDictionary: any,
    data: any,
    context: string,
    property: string = 'shortDescription'
) => {
    if (data && fieldDictionary?.hasOwnProperty(context)) {
        // Translate the values into dictionary-specified values
        return data
            .map((dataItem: any) => {
                if (dataItem.hasOwnProperty('dictionaryApplied') && dataItem.dictionaryApplied) {
                    return dataItem;
                }

                const dictValue = dictionaryValue(fieldDictionary, dataItem.value, context, property);
                return dictValue ?
                    Object.assign({}, dataItem, {
                        originalValue: dataItem.value,
                        value: dictValue[property],
                        position: dictValue.position || null,
                        dictionaryApplied: true,
                    }) :
                    null;
            })
            // Remove any data that was filtered out
            .filter((data: any) => data !== null);
    }

    return data;
}

/**
 * Apply the field dictionary to a dataset, to translate codes into human-readable values
 */
export const dictionaryValue = (fieldDictionary: any, data: any, context: string, property: string = 'shortDescription') => {
    //try {
    if (fieldDictionary && fieldDictionary.hasOwnProperty(context)) {
        const dictionary = fieldDictionary[context];
        if (!dictionary.values?.length) {
            // No mapping available, return the original data set
            return data;
        }
        const dictionaryItem = dictionary.values.find((item: any) => item.value === data);

        if (dictionaryItem) {
            // Do the replacement
            data = {};
            data[property] = dictionaryItem[property] || null;
            if (dictionaryItem.hasOwnProperty('position')) {
                data.position = dictionaryItem.position;
            }
        } else {
            // Nothing to display-we should filter out this data point!
            data = null;
        }
    }
    //} catch (error) {
    //console.error(error);
    //}

    return data;
}

export const drawPeopleIconStack = (chart, options) => {
    options = Object.assign({
        includeTotal: true,
    }, options);
    const userIcon = chart.renderer.path([
        'M', 8.53, 10.89,
        'c', '2.31-0.16', '4.14-2.09', '4.14-4.44',
        'C', 12.66, 4, 10.67, 2, 8.22, 2,
        'S', 3.78, 4, 3.78, 6.45,
        'c', 0, 2.35, 1.83, 4.28, 4.14, 4.44, -1.73, '0.07-3.25', '0.69-4.31', 1.78, -1.09, '1.11-1.64', '2.66-1.6', 4.46,
        'C', 2.01, 17.61, 2.41, 18, 2.9, 18,
        'h', 10.64,
        'c', 0.49, 0, '0.89-0.39', '0.9-0.88', '0.04-1.81-0.51-3.35-1.6-4.46',
        'C', 11.77, 11.58, 10.26, 10.95, 8.53, 10.89,
        'z'
    ]);

    // let rowCount = +numRows;
    let rowCount = 0;
    let colCount = 0;
    // const h = 19;
    // const w = 16;
    const bottom = (options.rows - 1) * personIconDimensions.height;
    const iconStackWidth = personIconDimensions.width * options.columns;
    let x, y;

    for (let p = 1; p <= options.count; ++p) {
        if (options.count < options.columns) {
            x = ((options.columns * personIconDimensions.width) - (options.count * personIconDimensions.width)) / 2 + ((p - 1) * personIconDimensions.width);
        } else {
            x = colCount * personIconDimensions.width;
        }

        y = rowCount * personIconDimensions.height;

        chart.renderer.path(userIcon)
            .attr({
                fill: options.color,
                translateX: options.left + x,
                translateY: options.top + (bottom - y),
                intent: 'overlay-data',
            })
            .add()
        ;

        ++colCount;

        if (p % options.columns === 0) {
            ++rowCount;
            colCount = 0;
        }
    }

    // Add the line and label
    const stackBottomY = options.top + bottom;
    chart.renderer
        .rect({
            // x: options.top + x,
            // y: options.left,
            x: 0,
            y: 0,
            width: iconStackWidth,
            height: 1,
            strokeWidth: 1,
        })
        .attr({
            fill: '#707070', // gray-75
            translateX: options.left,
            translateY: stackBottomY + personIconDimensions.height + 2,
            intent: 'overlay-data',
        })
        .add();
    let label = options.label;
    if (options.includeTotal && options.hasOwnProperty('total')) {
        label += `: <strong>${Utils.formatValue(options.total, 'separated')}</strong>`;
    }
    chart.renderer
        .text(label, options.left + (iconStackWidth / 2), stackBottomY + 40, true)
        .attr({
            intent: 'overlay-data',
        })
        .css({
            color: defaultChartOptions.title.style.color,
            transform: 'translateX(-50%)',
        })
        .add();

    // return chart;
}

export const formatOutlineSeries = (outlineData: any) => {
    if (!outlineData) {
        return false;
    }

    return {
        name: 'State Lines',
        type: 'mapline',
        data: Highcharts['geojson'](outlineData),
        // data: Highcharts['topojson'](outlineData),
        lineWidth: 0.5,
        color: BaseColors.gray['75'],
        enableMouseTracking: false,
        nullInteraction: false,
        showInLegend: false,
        // mapView: {
        //     projection: {
        //         name: 'LambertConformalConic',
        //         parallels: [33, 45],
        //         rotation: [96],
        //     }
        // }
    }
}

export const hasTweets = (topic) => {
    return topic.twitter?.topByCount?.reduce((total, account) => {
        return total + ((account.tweetCount > 0 || false) ? 1 : 0);
    }, 0) || false;
}

export const mapRegionData = ({baseOutlineData, chartSourceData, regionData}, property: string = 'index'): any[] => {
    return chartSourceData.map((item: any) => {
        const code = item.value,
            name = baseOutlineData.data.find((feature: any) => feature.properties['hc-a2'] === code)?.properties.name || null,
            region = geographicRegions.find(r => r.states.includes(code)) || null;

        if (!region) {
            return {};
        }

        const regionDataItem = regionData.find((rd: any) => (rd.originalValue || rd.value) === region.regionCode),
            value = regionDataItem[property],
            groupRatio = regionDataItem.groupRatio;
        const dataLabels = {
            range: value > 1 ? 'high' : 'low',
            enabled: region.labelTarget === code
        };
        let itemData: any = {
            code,
            dataLabels,
            groupRatio,
            name,
            regionCode: region.regionCode,
            regionName: region.name,
            value,
        }
        itemData[property] = item[property] || regionDataItem[property] || null;
        for (const key of Object.keys(itemData)) {
            // Remove blank data
            if (itemData[key] === undefined) {
                delete itemData[key];
            }
        }

        return itemData;
    });
}

/**
 * Prepare chart data for display
 */
export const prepareChartData = (options: any) => {
    options = Object.assign({
        asyncData: {},
        chartId: null,
        fieldDictionary: {},
        filterGroup: '',
        filterSettings: {},
        Highcharts: {},
        insights: {},
        params: {},
        printMode: false,
        returnData: true,
        sortGroup: {},
    }, options);
    options.params.chartLabels = Object.assign({
        baseline: 'Baseline',
        group: 'Persona',
    }, options.params.chartLabels || {});

    const {
        asyncData,
        chartId,
        fieldDictionary,
        filterGroup,
        filterSettings,
        Highcharts,
        insights,
        params,
        printMode,
        returnData,
        sortGroup,
    } = options;

    let chartData: any = {};
    let chartSourceData;
    let dictionaryKey: string = '';

    switch (chartId) {
        case 'age_range':
            chartSourceData = Utils.sortByProperty(
                dictionaryLookup(
                    fieldDictionary.standard,
                    insights.offline_standard?.demographics[chartId],
                    chartId
                ),
                'position',
                'asc'
            );
            if (!chartSourceData) {
                return false;
            }

            chartData = {
                chart: {
                    type: 'column',
                },
                plotOptions: chartPlotOptions.bar,
                xAxis: {
                    categories: chartSourceData.map(item => item.value),
                },
                yAxis: chartAxisOptions.barIndexGroupPercent,
                series: chartDataToSeries('barIndex', chartSourceData, {
                    chartLabels: params.chartLabels,
                }),
                tooltip: {
                    formatter: tooltipFormatter('percent'),
                    shared: true,
                },
            };
            break;

        case 'age_range_summary':
            dictionaryKey = 'age_range';
            chartSourceData = Utils.sortByProperty(
                dictionaryLookup(
                    fieldDictionary.standard,
                    insights.offline_standard?.demographics[dictionaryKey],
                    dictionaryKey
                ),
                'position',
                'asc'
            );
            if (!chartSourceData) {
                return false;
            }

            chartData = {
                icon: Utils.getDictionaryIcon(fieldDictionary.standard, dictionaryKey),
                series: [
                    {
                        name: 'Age Range',
                        size: '100%',
                        innerSize: '85%',
                        data: chartDataToSeries('donut', chartSourceData, {property: 'groupRatio'}),
                    },
                    {
                        name: 'Index',
                        data: chartSourceData.map(item => {
                            return {
                                y: item.index
                            };
                        }),
                        linkedTo: ':previous',
                        visible: false,
                        showInLegend: false,
                    },
                ]
            };
            break;

        // case 'cc_amt':
        // case 'cc_major_amt':
        // case 'cc_major_ord_num':
        // case 'cc_ord_num':
        //     chartSourceData = Utils.sortByProperty(
        //         dictionaryLookup(
        //             fieldDictionary,
        //             insights.high_level_rfm.highLevelRfmVariables[chartId],
        //             chartId
        //         ),
        //         'position',
        //         'asc'
        //     );
        //     if (!chartSourceData) {
        //         return false;
        //     }
        //
        //     chartData = {
        //         chart: {
        //             type: 'column',
        //         },
        //         plotOptions: chartPlotOptions.bar,
        //         xAxis: {
        //             categories: chartSourceData.map(item => item.value),
        //             // labels: {
        //             //     useHTML: true,
        //             //     rotation: -45,
        //             //     formatter: function () {
        //             //         return `<span class="text-nowrap">${this.value}</span>`;
        //             //     }
        //             // },
        //         },
        //         yAxis: chartAxisOptions.barIndexGroupPercent,
        //         series: chartDataToSeries('barIndex', chartSourceData),
        //         tooltip: {
        //             formatter: tooltipFormatter('percent'),
        //             shared: true,
        //         },
        //     };
        //     break;

        case 'children_age_range':
            chartSourceData = Utils.sortByProperty(
                dictionaryLookup(
                    fieldDictionary.standard,
                    insights.offline_standard?.demographics[chartId],
                    chartId
                ),
                'position',
                'asc'
            );
            if (!chartSourceData) {
                return false;
            }

            chartData = {
                chart: {
                    type: 'column',
                },
                plotOptions: chartPlotOptions.bar,
                xAxis: {
                    categories: chartSourceData.map(item => item.value),
                    labels: {
                        useHTML: true,
                        rotation: -45,
                        formatter: function () {
                            return `<span class="text-nowrap">${this.value}</span>`;
                        }
                    },
                },
                yAxis: chartAxisOptions.barIndexGroupPercent,
                series: chartDataToSeries('barIndex', chartSourceData),
                tooltip: {
                    formatter: tooltipFormatter('percent'),
                    shared: true,
                },
            };
            break;

        // These all use the same pie chart settings
        case 'children_present_in_hh':
        case 'dwelling_type':
        case 'gender':
        case 'marital_status':
        case 'owner_renter':
            chartSourceData = dictionaryLookup(
                fieldDictionary.standard,
                insights.offline_standard?.demographics[chartId],
                chartId
            );
            if (!chartSourceData) {
                return false;
            }

            chartData = {
                chart: {
                    type: 'pie',
                },
                plotOptions: chartPlotOptions.donut,
                series: [
                    {
                        name: params.chartLabels.group,
                        size: '80%',
                        data: chartDataToSeries('donut', chartSourceData, {property: 'groupRatio'})
                            .map((item, index) => Object.assign(item, {color: reportColors.donut.groupRatio[index]})),
                    },
                    {
                        name: params.chartLabels.baseline,
                        size: '100%',
                        innerSize: '85%',
                        data: chartDataToSeries('donut', chartSourceData, {property: 'baselineRatio'})
                            .map((item, index) => Object.assign(item, {color: reportColors.donut.baselineRatio[index]})),
                        showInLegend: false
                    }
                ],
                tooltip: {
                    formatter: tooltipFormatter('piePercent'),
                },
            };

            break;

        // case 'children_present_in_hh':
        //     chartSourceData = dictionaryLookup(
        //         fieldDictionary,
        //         insights.offline_standard.demographics[chartId],
        //         chartId
        //     );
        //     if (!chartSourceData) {
        //         return false;
        //     }
        //
        //     chartData = {
        //         chart: {
        //             plotBackgroundColor: null,
        //             plotBorderWidth: null,
        //             plotShadow: false,
        //             type: 'pie',
        //         },
        //         plotOptions: chartPlotOptions.donut,
        //         series: [
        //             {
        //                 name: params.chartLabels.group,
        //                 size: '80%',
        //                 data: chartDataToSeries('donut', chartSourceData, {property: 'groupRatio'})
        //                     .map((item, index) => Object.assign(item, {color: reportColors.donut.groupRatio[index]})),
        //             },
        //             {
        //                 name: params.chartLabels.baseline,
        //                 size: '100%',
        //                 innerSize: '85%',
        //                 data: chartDataToSeries('donut', chartSourceData, {property: 'baselineRatio'})
        //                     .map((item, index) => Object.assign(item, {color: reportColors.donut.baselineRatio[index]})),
        //                 showInLegend: false
        //             }
        //         ],
        //         tooltip: {
        //             formatter: tooltipFormatter('piePercent'),
        //         },
        //     };
        //     break;

        case 'children_present_in_hh_summary':
            dictionaryKey = 'children_present_in_hh';
            chartSourceData = dictionaryLookup(
                fieldDictionary.standard,
                insights.offline_standard?.demographics[dictionaryKey],
                dictionaryKey
            );
            if (!chartSourceData) {
                return false;
            }

            chartData = {
                icon: Utils.getDictionaryIcon(fieldDictionary.standard, dictionaryKey),
                series: [
                    {
                        name: 'Presence of Children',
                        size: '100%',
                        innerSize: '85%',
                        data: chartDataToSeries('donut', chartSourceData, {property: 'groupRatio'}),
                    },
                    {
                        name: 'Index',
                        data: chartSourceData.map(item => {
                            return {
                                y: item.index
                            };
                        }),
                        linkedTo: ':previous',
                        visible: false,
                        showInLegend: false,
                    },
                ]
            };
            break;

        case 'conglomerate_rfm_comparison_market_overview_standout': {
            if (!returnData) {
                return false;
            }

            // Data preparation
            let sourceData: any[] = [];
            for (let i = 0, end = insights.length; i < end; ++i) {
                const chartSourceData = (insights[i].conglomerate_rfm?.markets || {})
                    .map((market: any) => {
                        const marketInfo = {
                            category: market.category,
                            id: market.id,
                            index: 0,
                            name: market.name,
                            subCategory: market.subCategory,
                        };
                        const activeValue = market.values.active_buyer?.find((item: any) => item.value === 'active') || {};

                        return Object.assign({}, marketInfo, activeValue);
                    });

                sourceData.push({
                    personaIndex: i,
                    chartSourceData: Utils.isEmptyArray(chartSourceData) ? false : chartSourceData,
                });
            }

            const {series, seriesCategories, chartElementCount} = comparisonDataSort(
                sourceData,
                {
                    filterGroup: 'index',
                    filterSettings: Object.assign({}, filterSettings, {count: 10}),
                    matchProperty: 'id',
                    sortGroup
                }
            );
            if (!series.length) {
                // TODO: why does this ever happen?
                return false;
            }
            const categoryCount = Math.max(...seriesCategories.map(series => series.labels.length));

            for (let seriesData of series) {
                seriesData.name = indexLabel;
            }

            // Chart sizing-adjust as needed
            const chartHeight = `${(barSize * chartElementCount * 2.5) + headerSize}`;

            // Convert categories into readable labels
            let categories: any[] = [];
            let i = 0;
            for (const {personaIndex, labels} of seriesCategories) {
                for (let labelIndex = 0; labelIndex < categoryCount; ++labelIndex) {
                    const {category} = labels[labelIndex]|| {category: null};

                    let label = category ?
                        `<div class="overflow-ellipsis"><strong>${category.category}</strong></div><div class="overflow-ellipsis">${category.name}</div>` :
                        outOfRangeLabel;

                    let categoryLabel = categories[labelIndex] || '';
                    categoryLabel += `<div class="py-1 text-end ${personaIndex < (series.length - 1) ? 'clearfix' : ''}" style="color: ${reportColors.comparison[personaIndex]}">${label}</div>`;
                    categories[labelIndex] = categoryLabel;
                }
                ++i;
            }

            chartData = {
                chart: {
                    type: 'bar',
                    height: chartHeight,
                },
                legend: false,
                title: {
                    text: 'Top 10 Standout Markets in these Personas',
                },
                plotOptions: Highcharts.merge(chartPlotOptions.barGroup, {
                    series: {
                        dataLabels: {
                            enabled: true,
                            inside: true,
                        },
                    }
                }),
                xAxis: {
                    categories,
                    labels: {
                        useHTML: true,
                    },
                },
                yAxis: [
                    {
                        min: 0,
                        margin: 0,
                        title: {
                            style: {
                                color: reportColors.barIndex.index,
                            },
                            text: indexLabel,
                        },
                        labels: {
                            style: {
                                color: reportColors.barIndex.indexLabel,
                            },
                        },
                        opposite: true,
                    },
                ],
                series,
                tooltip: {
                    formatter: tooltipFormatter('flex', false),
                },
            };
        }
            break;

        case 'conglomerate_rfm_comparison_market_overview_top': {
            if (!returnData) {
                return false;
            }

            // Data preparation
            let sourceData: any[] = [];
            for (let i = 0, end = insights.length; i < end; ++i) {
                const chartSourceData = insights[i].conglomerate_rfm?.categories || [];

                sourceData.push({
                    personaIndex: i,
                    chartSourceData: Utils.isEmptyArray(chartSourceData) ? false : chartSourceData,
                });
            }

            const {series, seriesCategories, chartElementCount} = comparisonDataSort(
                sourceData,
                {
                    filterGroup: 'groupRatio',
                    filterSettings: Object.assign({}, filterSettings, {count: 10}),
                    matchProperty: 'id',
                    sortGroup
                }
            );
            if (!series.length) {
                // TODO: why does this ever happen?
                return false;
            }
            const categoryCount = Math.max(...seriesCategories.map(series => series.labels.length));

            for (let seriesData of series) {
                seriesData.name = groupRatioLabel;
            }

            // Chart sizing-adjust as needed
            const chartHeight = `${(barSize * chartElementCount * 2.5) + headerSize}`;

            // Convert categories into readable labels
            let categories: any[] = [];
            let i = 0;
            for (const {personaIndex, labels} of seriesCategories) {
                for (let labelIndex = 0; labelIndex < categoryCount; ++labelIndex) {
                    const {category} = labels[labelIndex]|| {category: null};
                    let label = category ? `<strong>${category.name}</strong>` : outOfRangeLabel;
                    label = `<div class="overflow-ellipsis">${label}</div>`;

                    let categoryLabel = categories[labelIndex] || '';
                    categoryLabel += `<div class="py-1 ${personaIndex < (series.length - 1) ? 'clearfix' : ''}" style="color: ${reportColors.comparison[personaIndex]}">${label}</div>`;
                    categories[labelIndex] = categoryLabel;
                }
                ++i;
            }

            chartData = {
                chart: {
                    type: 'bar',
                    height: chartHeight,
                },
                legend: false,
                title: {
                    text: 'Top 10 Market Categories in these Personas',
                },
                plotOptions: Highcharts.merge(chartPlotOptions.barGroup, {
                    series: {
                        dataLabels: {
                            enabled: true,
                            inside: true,
                        },
                    }
                }),
                xAxis: {
                    categories,
                    labels: {
                        useHTML: true,
                    },
                },
                yAxis: [
                    {
                        min: 0,
                        margin: 0,
                        title: {
                            style: {
                                color: reportColors.barIndex.groupRatio,
                            },
                            text: groupRatioLabel,
                        },
                        labels: {
                            style: {
                                color: reportColors.barIndex.groupRatioLabel,
                            },
                        },
                        opposite: true,
                    },
                ],
                series,
                tooltip: {
                    formatter: tooltipFormatter('percent'),
                },
            };
        }
            break;

        case 'conglomerate_rfm_market_overview_standout': {
            if (!returnData) {
                return false;
            }

            // Data preparation
            const sourceData = (insights.conglomerate_rfm?.markets || {})
                .map((market: any) => {
                    const marketInfo = {
                        category: market.category,
                        id: market.id,
                        index: 0,
                        name: market.name,
                        subCategory: market.subCategory,
                    };
                    const activeValue = market.values.active_buyer?.find((item: any) => item.value === 'active') || {};

                    return Object.assign({}, marketInfo, activeValue);
                });
            const chartSourceData = Utils.sortByProperty(
                sourceData,
                'index',
                'desc'
            ).slice(0, 10);
            if (!chartSourceData) {
                return false;
            }

            const series = [
                {
                    name: indexLabel,
                    type: 'bar',
                    color: reportColors.barIndex.index,
                    data: chartSourceData.map(item => item.index),
                },
            ];

            chartData = {
                chart: {
                    type: 'bar',
                    height: '67%',
                },
                legend: false,
                title: {
                    text: 'Top 10 Standout Markets in this Persona',
                },
                plotOptions: chartPlotOptions.bar,
                xAxis: {
                    categories: chartSourceData.map(item => {
                        return `<strong>${item.category}</strong><br/>${item.name}`;
                    }),
                },
                yAxis: [
                    {
                        min: 0,
                        margin: 0,
                        title: {
                            style: {
                                color: reportColors.barIndex.index,
                            },
                            text: indexLabel,
                        },
                        labels: {
                            style: {
                                color: reportColors.barIndex.indexLabel,
                            },
                        },
                        opposite: true,
                    },
                ],
                series,
                tooltip: {
                    formatter: tooltipFormatter('flex'),
                },
            };
        }
            break;

        case 'conglomerate_rfm_market_overview_top': {
            if (!returnData) {
                return false;
            }

            // Data preparation
            const chartSourceData = Utils.sortByProperty(
                insights.conglomerate_rfm?.categories || [],
                'groupRatio',
                'desc'
            ).slice(0, 10);
            if (!chartSourceData.length) {
                return false;
            }

            const series = [
                {
                    name: groupRatioLabel,
                    type: 'bar',
                    color: reportColors.barIndex.groupRatio,
                    data: chartSourceData.map(item => item.groupRatio),
                }
            ];

            chartData = {
                chart: {
                    type: 'bar',
                    height: '67%',
                },
                legend: false,
                title: {
                    text: 'Top 10 Market Categories in this Persona',
                },
                plotOptions: chartPlotOptions.bar,
                xAxis: {
                    categories: chartSourceData.map(item => {
                        return `<strong>${item.name}</strong>`;
                    }),
                },
                yAxis: [
                    {
                        min: 0,
                        margin: 0,
                        title: {
                            style: {
                                color: reportColors.barIndex.groupRatio,
                            },
                            text: groupRatioLabel,
                        },
                        labels: {
                            format: '{value}%',
                            style: {
                                color: reportColors.barIndex.groupRatioLabel,
                            },
                        },
                        opposite: true,
                    },
                ],
                series,
                tooltip: {
                    formatter: tooltipFormatter('percent'),
                },
            };
        }
            break;

        case 'conglomerate_rfm_market_overview_treemap': {
            if (!returnData) {
                return false;
            }

            // Data preparation
            const chartSourceData: any = insights.conglomerate_rfm?.categories;
            const markets: any[] = insights.conglomerate_rfm?.markets;
            if (!chartSourceData.length || !markets.length) {
                return false;
            }

            let points: any[] = [];
            let colorIndex = 0;
            for (const categorySource of Utils.sortByProperty(chartSourceData, 'groupRatio', 'desc')) {
                const color = reportColors.donut.standard[colorIndex];
                let category: any = {
                    color,
                    id: categorySource.id,
                    name: categorySource.name,
                    value: categorySource.groupRatio,
                };
                points.push(category);
                ++colorIndex;

                for (const subCategorySource of categorySource.subCategories) {
                    let subCategory: any = {
                        id: subCategorySource.id,
                        name: subCategorySource.name,
                        parent: category.id,
                        value: subCategorySource.groupRatio,
                    };
                    points.push(subCategory);

                    for (const marketSource of subCategorySource.markets) {
                        const marketData = markets.find((market: any) => market.id === marketSource.id);
                        const marketPath = `${marketSource.category} > ${marketSource.subCategory} > ${marketSource.name}`;
                        if (marketData) {
                            const activeValue = marketData.values.active_buyer?.find((item: any) => item.value === 'active');
                            if (activeValue?.groupRatio >= minGroupRatioThreshold) {
                                let market: any = {
                                    id: marketSource.id,
                                    name: marketSource.name,
                                    parent: subCategory.id,
                                    value: activeValue?.groupRatio || 0,
                                };
                                points.push(market);
                            }
                        } else {
                            console.error(`⛔ Market not found: ${marketPath}`)
                        }
                    }
                }
            }

            const series = [{
                accessibility: {
                    exposeAsGroupOnly: true,
                },
                allowDrillToNode: true,
                allowTraversingTree: true,
                animationLimit: 1000,
                // borderRadius: 6,
                data: points,
                dataLabels: {
                    enabled: false,
                    style: {
                        color: '#ffffff',
                        fontSize: '14px',
                        // textOutline: 'solid 5px #000',
                        // textOutline: 'none',
                    },
                },
                layoutAlgorithm: 'squarified',
                levels: [
                    {
                        level: 1,
                        dataLabels: {
                            enabled: true,
                        },
                        borderWidth: 3,
                        levelIsConstant: false,
                    },
                    {
                        level: 1,
                        // dataLabels: {
                        //     style: {
                        //         fontSize: '14px',
                        //     },
                        // },
                    },
                ],
                name: 'Categories',
                type: 'treemap',
            }];

            chartData = {
                id: chartId,
                chart: {
                    type: 'treemap',
                    height: '67%',
                },
                legend: false,
                title: false,
                plotOptions: {
                    // colorByPoint: true,
                    // treemap: {
                    //     colors: reportColors.donut.standard,
                    // },
                },
                series,
                tooltip: {
                    formatter: tooltipFormatter('percent', 'Persona Share with Affinity'),
                    // includeHeader: `string:Persona Share with Affinity`,
                    // includeHeader:
                    // shared: true,
                },
                // tooltip: {
                //     formatter: tooltipFormatter('flex', false),
                // },
            };
        }
            break;

        case 'predicted_spend_summary_comparison': {
            const context = filterGroup === 'index' ? 'topByIndex' : 'topByCount';
            const labelColor = filterGroup ? BaseColors[reportColors.comparison.filter[filterGroup]]['100'] : BaseColors.gray['75'];
            // const labelWidth = 250;

            let yAxis: any = {
                labels: {
                    format: '{value}',
                    style: {
                        color: labelColor,
                    },
                },
                title: {
                    style: {
                        color: labelColor,
                    },
                },
            };

            // Data preparation
            let sourceData: any[] = [];
            for (let i = 0, end = insights.length; i < end; ++i) {
                let chartSourceData = insights[i].consumer_spend.summary[context]
                    .filter(category => category.displayComparison ?? false);
                sourceData.push({
                    personaIndex: i,
                    chartSourceData: Utils.isEmptyArray(chartSourceData) ? false : chartSourceData,
                });
            }
            if (!sourceData.some(data => data.chartSourceData !== false)) {
                return false;
            }

            const {series, seriesCategories, chartElementCount} = comparisonDataSort(sourceData, {
                filterGroup,
                filterSettings,
                matchProperty: 'subCategory',
                sortGroup
            });
            if (!series.length) {
                // TODO: why does this ever happen?
                return false;
            }
            const categoryCount = Math.max(...seriesCategories.map(series => series.labels.length));

            for (let seriesData of series) {
                switch (filterGroup) {
                    case 'groupRatio':
                        seriesData.name = groupRatioLabel;
                        seriesData.formatter = value => {
                            return `${value.toFixed(2)}%`;
                        };

                        if (!yAxis.title?.text) {
                            // Set up the Y axis
                            yAxis = Highcharts.merge(yAxis, {
                                min: 0,
                                margin: 0,
                                title: {
                                    text: seriesData.name,
                                },
                                labels: {
                                    format: '{value}%',
                                },
                                opposite: true,
                            });
                        }
                        break;

                    case 'index':
                        seriesData.name = indexLabel;

                        if (!yAxis.title?.text) {
                            // Set up the Y axis
                            yAxis = Highcharts.merge(yAxis, {
                                margin: 0,
                                title: {
                                    text: indexLabel,
                                },
                                opposite: true,
                            });
                        }
                        break;
                }
            }

            // Chart sizing-adjust as needed
            const chartHeight = `${(barSize * chartElementCount * 2.5) + headerSize}`;

            // Convert categories into readable labels
            let categories: any[] = [];
            for (const {personaIndex, labels} of seriesCategories) {
                for (let labelIndex = 0; labelIndex < categoryCount; ++labelIndex) {
                    const {category} = labels[labelIndex] || {category: null};

                    let label = category ? `${category.subCategory?.name || outOfRangeLabel}` : outOfRangeLabel;
                    label = `<div class="float-end">${label}</div>`

                    let categoryLabel = categories[labelIndex] || '';
                    categoryLabel += `<div class="py-2 ${personaIndex < (series.length - 1) ? 'clearfix' : ''}" style="color: ${reportColors.comparison[personaIndex]}">${label}</div>`;
                    categories[labelIndex] = categoryLabel;
                }
            }

            chartData = {
                id: chartId,
                chart: {
                    type: 'bar',
                    height: chartHeight,
                },
                legend: false,
                title: false,
                plotOptions: Highcharts.merge(chartPlotOptions.barGroup, {
                    series: {
                        dataLabels: {
                            enabled: true,
                            inside: true,
                        },
                    }
                }),
                xAxis: {
                    categories,
                    // gridLineColor: '#000000',
                    // gridLineWidth: 1,
                    // tickColor: '#cccccc',
                    // tickWidth: 1,
                    labels: {
                        useHTML: true,
                    },
                },
                yAxis,
                series,
                tooltip: {
                    formatter: tooltipFormatter('flex', false),
                },
            };
        }
            break;

        case 'predicted_spend_summary': {
            const context = sortGroup === 'index' ? 'topByIndex' : 'topByCount';
            const sourceData = insights.consumer_spend.summary[context]
                .filter(predictedSpendCategory => {
                    return predictedSpendCategory.displayPersona;
                });

            chartSourceData = Utils.sortByProperty(
                sourceData,
                [sortGroup, 'groupCount'],
                'desc'
            );
            if (!chartSourceData) {
                return false;
            }

            const series = [
                {
                    yAxis: 0,
                    name: indexLabel,
                    type: 'bar',
                    color: reportColors.barIndex.index,
                    data: chartSourceData.map(item => item.index),
                },
                {
                    yAxis: 1,
                    name: groupRatioLabel,
                    type: 'bar',
                    color: reportColors.barIndex.groupRatio,
                    data: chartSourceData.map(item => item.groupRatio),
                }
            ];

            chartData = {
                chart: {
                    type: 'bar',
                    height: '100%',
                },
                legend: false,
                title: false,
                plotOptions: chartPlotOptions.barGroup,
                xAxis: {
                    categories: chartSourceData.map(item => {
                        return `<strong>${item.category.name}` + (item.subCategory?.hasOwnProperty('name') ? `:</strong><br>${item.subCategory.name}` : '</strong>');
                    }),
                },
                yAxis: [
                    {
                        min: 0,
                        margin: 0,
                        title: {
                            style: {
                                color: reportColors.barIndex.index,
                            },
                            text: indexLabel,
                        },
                        labels: {
                            style: {
                                color: reportColors.barIndex.indexLabel,
                            },
                        },
                        opposite: true,
                        visible: sortGroup === 'index',
                    },
                    {
                        min: 0,
                        margin: 0,
                        title: {
                            style: {
                                color: reportColors.barIndex.groupRatio,
                            },
                            text: groupRatioLabel,
                        },
                        labels: {
                            format: '{value}%',
                            style: {
                                color: reportColors.barIndex.groupRatioLabel,
                            },
                        },
                        opposite: true,
                        visible: sortGroup === 'groupRatio',
                    },
                ],
                series,
                tooltip: {
                    formatter: tooltipFormatter('percent'),
                },
            };
        }
            break;

        case 'predicted_spend_top': {
            const context = sortGroup === 'index' ? 'topByIndex' : 'topByCount';
            const sourceData = insights.consumer_spend.topSpendingIndicators[context]
                .filter(predictedSpendCategory => {
                    return predictedSpendCategory.displayPersona;
                })
            ;

            chartSourceData = Utils.sortByProperty(
                sourceData,
                [sortGroup, 'groupCount'],
                'desc'
            );
            if (!chartSourceData) {
                return false;
            }

            const series = [
                {
                    yAxis: 0,
                    name: indexLabel,
                    type: 'bar',
                    color: reportColors.barIndex.index,
                    data: chartSourceData.map(item => item.index),
                },
                {
                    yAxis: 1,
                    name: groupRatioLabel,
                    type: 'bar',
                    color: reportColors.barIndex.groupRatio,
                    data: chartSourceData.map(item => item.groupRatio),
                }
            ];

            chartData = {
                chart: {
                    type: 'bar',
                    height: '100%',
                },
                legend: false,
                title: false,
                plotOptions: chartPlotOptions.barGroup,
                xAxis: {
                    categories: chartSourceData.map(item => {
                        return `<strong>${item.shortDescription}</strong>`;
                    }),
                },
                yAxis: [
                    {
                        min: 0,
                        margin: 0,
                        title: {
                            style: {
                                color: reportColors.barIndex.index,
                            },
                            text: indexLabel,
                        },
                        labels: {
                            style: {
                                color: reportColors.barIndex.indexLabel,
                            },
                        },
                        opposite: true,
                        visible: sortGroup === 'index',
                    },
                    {
                        min: 0,
                        margin: 0,
                        title: {
                            style: {
                                color: reportColors.barIndex.groupRatio,
                            },
                            text: groupRatioLabel,
                        },
                        labels: {
                            format: '{value}%',
                            style: {
                                color: reportColors.barIndex.groupRatioLabel,
                            },
                        },
                        opposite: true,
                        visible: sortGroup === 'groupRatio',
                    },
                ],
                series,
                tooltip: {
                    formatter: tooltipFormatter('percent'),
                },
            };
        }
            break;

        case 'county_size':
            chartSourceData = Utils.sortByProperty(
                dictionaryLookup(
                    fieldDictionary.standard,
                    insights.offline_standard?.demographics[chartId],
                    chartId
                ),
                'position',
                'asc'
            );
            if (!chartSourceData) {
                return false;
            }

            chartData = {
                chart: {
                    type: 'column',
                },
                plotOptions: chartPlotOptions.bar,
                xAxis: {
                    categories: chartSourceData.map(item => item.value)
                },
                yAxis: chartAxisOptions.barIndexGroupPercent,
                series: chartDataToSeries('barIndex', chartSourceData, {
                    chartLabels: params.chartLabels,
                }),
                tooltip: {
                    formatter: tooltipFormatter('percent'),
                    shared: true,
                },
            };
            break;

        case 'county_size_summary':
            dictionaryKey = 'county_size';
            chartSourceData = dictionaryLookup(
                fieldDictionary.standard,
                insights.offline_standard?.demographics[dictionaryKey],
                dictionaryKey
            );
            if (!chartSourceData) {
                return false;
            }

            chartData = {
                icon: Utils.getDictionaryIcon(fieldDictionary.standard, dictionaryKey),
                series: [
                    {
                        name: 'Urbanicity',
                        size: '100%',
                        innerSize: '85%',
                        data: chartDataToSeries('donut', chartSourceData, {property: 'groupRatio'}),
                    },
                    {
                        name: 'Index',
                        data: chartSourceData.map(item => {
                            return {
                                y: item.index
                            };
                        }),
                        linkedTo: ':previous',
                        visible: false,
                        showInLegend: false,
                    },
                ]
            };
            break;

        case 'dma_code': { // Media markets
            if (!returnData) {
                return {
                    asyncData: async () => {
                        // TODO: Current Nielsen DMA file is missing Alaska + Hawaii, need full data if possible!
                        const response = await axios.get('/assets/topo-json/nielsen-dma.topo.json');
                        Highcharts.maps['dma_code_mapData'] = response.data;
                        Highcharts.maps['dma_code_baseOutlines'] = await baseOutlines();
                    }
                }
            }
            if (!insights.hasOwnProperty('offline_standard')) {
                return false;
            }

            const mapData = Highcharts.maps['dma_code_mapData'];
            const baseOutlineData = Highcharts.maps['dma_code_baseOutlines'];

            chartSourceData = insights.offline_standard?.demographics.dma_code;
            if (!chartSourceData) {
                return false;
            }
            const data = chartSourceData
                .map(item => {
                    const code = +item.value;
                    const name = mapData?.objects.nielsen_dma.geometries.find(feature => feature.properties.dma === code)?.properties.dma1 || null;

                    return {
                        code,
                        name,
                        value: item.index,
                        index: item.index,
                        groupRatio: item.groupRatio,
                    }
                })
                .filter(feature => feature.hasOwnProperty('name') && feature.name !== null)
            ;
            chartSourceData
                .map(feature => {
                    const dmaCode = parseInt(feature.value);
                    feature.name = data.find(dataItem => parseInt(dataItem.code) === dmaCode)?.name || `Unknown DMA: ${dmaCode}`;
                });
            const lowData = data.filter(item => item.value < 1);
            const highData = data.filter(item => item.value >= 1);
            const maxIndex = Math.min(100, Math.max(...chartSourceData.map(item => item.index))); // Cap the max value at 100, in case of weird outliers

            // Enable data labels for the top 5 and bottom 5 items
            const sortedValues = Utils.sortByProperty(data, 'value', 'desc');
            sortedValues.slice(0, 5).forEach((item: any) => {
                data.find(dataItem => dataItem.code === item.code).dataLabels = {
                    range: 'high',
                    enabled: true,
                    align: 'right'
                };
            });
            sortedValues.reverse().slice(0, 5).forEach((item: any) => {
                data.find(dataItem => dataItem.code === item.code).dataLabels = {
                    range: 'low',
                    enabled: true
                };
            });

            const series = [
                {
                    name: 'Index Low',
                    seriesType: 'low',
                    type: 'map',
                    joinBy: ['dma', 'code'],
                    borderWidth: 0.5,
                    // borderColor: BaseColors.gray['25'],
                    borderColor: 'white',
                    states: reportColors.choropleth.states,
                    shadow: false,
                    mapData,
                    data: lowData,
                    dataLabels: Highcharts.merge(chartDataLabelOptions.map, {
                        format: '{point.options.name}: {point.value:.2f}x',
                    }),
                    colorAxis: 0,
                },
                {
                    name: 'Index High',
                    seriesType: 'high',
                    type: 'map',
                    joinBy: ['dma', 'code'],
                    borderWidth: 0.5,
                    // borderColor: BaseColors.gray['25'],
                    borderColor: 'white',
                    states: reportColors.choropleth.states,
                    shadow: false,
                    mapData,
                    data: highData,
                    dataLabels: Highcharts.merge(chartDataLabelOptions.map, {
                        format: '{point.options.name}: {point.value:.2f}x',
                    }),
                    colorAxis: 1,
                },
                baseOutlineData,
            ];

            chartData = {
                rawData: chartSourceData,

                title: false,
                chart: {
                    animation: false,
                    events: {
                        load: chart => {
                            chartOverlayCallback(chart.target, 'geographicReport');
                        },
                        redraw: chart => {
                            chartOverlayCallback(chart.target, 'geographicReport');
                        },
                    },
                    height: '80%',
                },
                mapView: {
                    // For some reason, the DMA data is mucking up the projection used by the outline, so we must explicitly set it again
                    insets: usAlaskaHawaiiInsets,
                    projection: {
                        name: 'LambertConformalConic',
                        parallels: [33, 45],
                        rotation: [96],
                    }
                },
                series,
                plotOptions: {
                    map: {
                        allAreas: false,
                        nullColor: 'rgba(0, 0, 0, 0)',
                    }
                },
                colorAxis: [
                    {
                        // Low range
                        endOnTick: false,
                        startOnTick: false,
                        min: 0,
                        max: 1,
                        minColor: reportColors.axis.low.min,
                        maxColor: reportColors.axis.low.max,
                    },
                    {
                        // High range
                        endOnTick: false,
                        startOnTick: false,
                        min: 1,
                        max: maxIndex,
                        minColor: reportColors.axis.high.min,
                        maxColor: reportColors.axis.high.max,
                    }
                ],
                tooltip: {
                    formatter: tooltipFormatter('map'),
                },
            };
        }
            break;

        // case 'dwelling_type':
        //     chartSourceData = dictionaryLookup(
        //         fieldDictionary,
        //         insights.offline_standard.demographics.dwelling_type,
        //         'dwelling_type'
        //     );
        //     if (!chartSourceData) {
        //         return false;
        //     }
        //
        //     chartData = {
        //         chart: {
        //             type: 'pie',
        //         },
        //         title: {
        //             text: 'Dwelling Type'
        //         },
        //         plotOptions: chartPlotOptions.donut,
        //         series: [
        //             {
        //                 name: params.chartLabels.group,
        //                 size: '80%',
        //                 data: chartDataToSeries('donut', chartSourceData, {property: 'groupRatio'})
        //                     .map((item, index) => Object.assign(item, {color: reportColors.donut.groupRatio[index]})),
        //             },
        //             {
        //                 name: params.chartLabels.baseline,
        //                 size: '100%',
        //                 innerSize: '85%',
        //                 data: chartDataToSeries('donut', chartSourceData, {property: 'baselineRatio'})
        //                     .map((item, index) => Object.assign(item, {color: reportColors.donut.baselineRatio[index]})),
        //                 showInLegend: false
        //             }
        //         ],
        //         tooltip: {
        //             formatter: tooltipFormatter('piePercent'),
        //         },
        //     };
        //     break;

        case 'dwelling_type_summary':
            dictionaryKey = 'dwelling_type';
            chartSourceData = dictionaryLookup(
                fieldDictionary.standard,
                insights.offline_standard?.demographics[dictionaryKey],
                dictionaryKey
            );
            if (!chartSourceData) {
                return false;
            }

            chartData = {
                icon: Utils.getDictionaryIcon(fieldDictionary.standard, dictionaryKey),
                series: [
                    {
                        name: 'Dwelling Type',
                        size: '100%',
                        innerSize: '85%',
                        data: chartDataToSeries('donut', chartSourceData, {property: 'groupRatio'}),
                    },
                    {
                        name: 'Index',
                        data: chartSourceData.map(item => {
                            return {
                                y: item.index
                            };
                        }),
                        linkedTo: ':previous',
                        visible: false,
                        showInLegend: false,
                    },
                ]
            };
            break;

        case 'gender_summary':
            dictionaryKey = 'gender';
            chartSourceData = dictionaryLookup(
                fieldDictionary.standard,
                insights.offline_standard?.demographics[dictionaryKey],
                dictionaryKey
            );
            if (!chartSourceData) {
                return false;
            }

            chartData = {
                icon: Utils.getDictionaryIcon(fieldDictionary.standard, dictionaryKey),
                series: [
                    {
                        name: 'Gender',
                        size: '100%',
                        innerSize: '85%',
                        data: chartDataToSeries('donut', chartSourceData, {property: 'groupRatio'}),
                    },
                    {
                        name: 'Index',
                        data: chartSourceData.map(item => {
                            return {
                                y: item.index
                            };
                        }),
                        linkedTo: ':previous',
                        visible: false,
                        showInLegend: false,
                    },
                ]
            };
            break;

        case 'home_value_range':
            chartSourceData = Utils.sortByProperty(
                dictionaryLookup(
                    fieldDictionary.standard,
                    insights.offline_standard?.demographics[chartId],
                    chartId
                ),
                'position',
                'asc'
            );
            if (!chartSourceData) {
                return false;
            }

            chartData = {
                chart: {
                    type: 'column',
                },
                plotOptions: chartPlotOptions.bar,
                xAxis: {
                    categories: chartSourceData.map(item => item.value)
                },
                yAxis: chartAxisOptions.barIndexGroupPercent,
                series: chartDataToSeries('barIndex', chartSourceData),
                tooltip: {
                    formatter: tooltipFormatter('percent'),
                    shared: true,
                },
            };
            break;

        case 'household_composition':
            chartSourceData = Utils.sortByProperty(
                dictionaryLookup(
                    fieldDictionary.standard,
                    insights.offline_standard?.demographics[chartId],
                    chartId
                ),
                'position',
                'asc'
            );
            if (!chartSourceData) {
                return false;
            }

            chartData = {
                chart: {
                    type: 'column',
                },
                plotOptions: chartPlotOptions.bar,
                xAxis: {
                    categories: chartSourceData.map(item => item.value)
                },
                yAxis: chartAxisOptions.barIndexGroupPercent,
                series: chartDataToSeries('barIndex', chartSourceData),
                tooltip: {
                    formatter: tooltipFormatter('percent'),
                    shared: true,
                },
            };
            break;

        case 'income_range':
            chartSourceData = Utils.sortByProperty(
                dictionaryLookup(
                    fieldDictionary.standard,
                    insights.offline_standard?.demographics[chartId],
                    chartId
                ),
                'position',
                'asc'
            );
            if (!chartSourceData) {
                return false;
            }

            chartData = {
                chart: {
                    type: 'column',
                },
                plotOptions: chartPlotOptions.bar,
                xAxis: {
                    categories: chartSourceData.map(item => item.value)
                },
                yAxis: chartAxisOptions.barIndexGroupPercent,
                series: chartDataToSeries('barIndex', chartSourceData, {
                    chartLabels: params.chartLabels,
                }),
                tooltip: {
                    formatter: tooltipFormatter('percent'),
                    shared: true,
                },
            };
            break;

        case 'income_range_summary':
            dictionaryKey = 'income_range'
            chartSourceData = dictionaryLookup(
                fieldDictionary.standard,
                insights.offline_standard?.demographics[dictionaryKey],
                dictionaryKey
            );
            if (!chartSourceData) {
                return false;
            }

            chartData = {
                icon: Utils.getDictionaryIcon(fieldDictionary.standard, dictionaryKey),
                series: [
                    {
                        name: 'Income',
                        size: '100%',
                        innerSize: '85%',
                        data: chartDataToSeries('donut', chartSourceData, {property: 'groupRatio'}),
                    },
                    {
                        name: 'Index',
                        data: chartSourceData.map(item => {
                            return {
                                y: item.index
                            };
                        }),
                        linkedTo: ':previous',
                        visible: false,
                        showInLegend: false,
                    },
                ]
            };
            break;

        case 'length_of_residence_range':
            chartSourceData = Utils.sortByProperty(
                dictionaryLookup(
                    fieldDictionary.standard,
                    insights.offline_standard?.demographics[chartId],
                    chartId
                ),
                'position',
                'asc'
            );
            if (!chartSourceData) {
                return false;
            }

            chartData = {
                chart: {
                    type: 'column',
                },
                plotOptions: chartPlotOptions.bar,
                xAxis: {
                    categories: chartSourceData.map(item => item.value)
                },
                yAxis: chartAxisOptions.barIndexGroupPercent,
                series: chartDataToSeries('barIndex', chartSourceData),
                tooltip: {
                    formatter: tooltipFormatter('percent'),
                    shared: true,
                },
            };
            break;

        case 'length_of_residence_range_summary':
            dictionaryKey = 'length_of_residence_range'
            chartSourceData = dictionaryLookup(
                fieldDictionary.standard,
                insights.offline_standard?.demographics[dictionaryKey],
                dictionaryKey
            );
            if (!chartSourceData) {
                return false;
            }

            chartData = {
                icon: Utils.getDictionaryIcon(fieldDictionary.standard, dictionaryKey),
                series: [
                    {
                        name: 'Length of Residence',
                        size: '100%',
                        innerSize: '85%',
                        data: chartDataToSeries('donut', chartSourceData, {property: 'groupRatio'}),
                    },
                    {
                        name: 'Index',
                        data: chartSourceData.map(item => {
                            return {
                                y: item.index
                            };
                        }),
                        linkedTo: ':previous',
                        visible: false,
                        showInLegend: false,
                    },
                ]
            };
            break;

        // case 'marital_status':
        //     chartSourceData = dictionaryLookup(
        //         fieldDictionary,
        //         insights.offline_standard.demographics[chartId],
        //         chartId
        //     );
        //     if (!chartSourceData) {
        //         return false;
        //     }
        //
        //     chartData = {
        //         chart: {
        //             type: 'pie',
        //         },
        //         plotOptions: chartPlotOptions.donut,
        //         series: [
        //             {
        //                 name: params.chartLabels.group,
        //                 size: '80%',
        //                 data: chartDataToSeries('donut', chartSourceData, {property: 'groupRatio'})
        //                     .map((item, index) => Object.assign(item, {color: reportColors.donut.groupRatio[index]})),
        //             },
        //             {
        //                 // name: 'Baseline',
        //                 name: params.chartLabels.baseline,
        //                 size: '100%',
        //                 innerSize: '85%',
        //                 data: chartDataToSeries('donut', chartSourceData, {property: 'baselineRatio'})
        //                     .map((item, index) => Object.assign(item, {color: reportColors.donut.baselineRatio[index]})),
        //                 showInLegend: false
        //             }
        //         ],
        //         tooltip: {
        //             formatter: tooltipFormatter('piePercent'),
        //         },
        //     };
        //
        //     break;

        case 'marital_status_summary':
            dictionaryKey = 'marital_status'
            chartSourceData = dictionaryLookup(
                fieldDictionary.standard,
                insights.offline_standard?.demographics[dictionaryKey],
                dictionaryKey
            );
            if (!chartSourceData) {
                return false;
            }

            chartData = {
                icon: Utils.getDictionaryIcon(fieldDictionary.standard, dictionaryKey),
                series: [
                    {
                        name: 'Married',
                        size: '100%',
                        innerSize: '85%',
                        data: chartDataToSeries('donut', chartSourceData, {property: 'groupRatio'}),
                    },
                    {
                        name: 'Index',
                        data: chartSourceData.map(item => {
                            return {
                                y: item.index
                            };
                        }),
                        linkedTo: ':previous',
                        visible: false,
                        showInLegend: false,
                    },
                ]
            };
            break;

        case 'metro_area': {
            if (!returnData) {
                return {
                    asyncData: async () => {
                        // Highcharts.maps['metro_area_baseOutlines'] = await baseOutlines(false, true);
                        Highcharts.maps['metro_area_baseOutlines'] = await baseOutlines(true, true);
                    }
                }
            }

            const baseOutlineData = Highcharts.maps['metro_area_baseOutlines'];
            chartSourceData = insights.offline_standard?.demographics[chartId]
                .filter(item => item.index > 0); // From 2.x: remove any items with an index of 0
            if (!chartSourceData) {
                return false;
            }

            const data = chartSourceData.map(item => {
                return {
                    header: 'Metro Area',
                    lat: item.geo.location.lat,
                    lon: item.geo.location.lon,
                    name: item.value,
                    value: item.index,
                    groupRatio: item.groupRatio,
                    z: item.index,
                }
            });

            // Enable data labels for the top 5 and bottom 5 items
            const sortedValues = Utils.sortByProperty(data, 'z', 'desc');
            sortedValues.slice(0, 5).forEach(item => {
                data.find(dataItem => dataItem.name === item.name).dataLabels = {
                    // range: 'high',
                    enabled: true
                };
            });
            sortedValues.reverse().slice(0, 5).forEach(item => {
                data.find(dataItem => dataItem.name === item.name).dataLabels = {
                    // range: 'low',
                    enabled: true
                };
            });
            // Split into low and high series
            const lowData = data.filter(item => item.z < 1);
            const highData = data.filter(item => item.z >= 1);
            // const minIndex = Math.max(...lowData.map(item => item.z));
            const maxIndex = Math.max(...highData.map(item => item.z));

            const series = [
                {
                    name: 'Basemap',
                    mapData: baseOutlineData,
                    borderColor: '#606060',
                    nullColor: '#f6f6f6',
                },
                {
                    name: 'Index Low',
                    seriesType: 'low',
                    type: 'mapbubble',
                    data: lowData,
                    dataLabels: Highcharts.merge(chartDataLabelOptions.map, {
                        format: '{point.options.name}: {point.value:.2f}x',
                    }),
                    minSize: '2px',
                    maxSize: '30px',
                    colorAxis: 0,
                },
                {
                    name: 'Index High',
                    seriesType: 'high',
                    type: 'mapbubble',
                    data: highData,
                    dataLabels: Highcharts.merge(chartDataLabelOptions.map, {
                        format: '{point.options.name}: {point.value:.2f}x',
                    }),
                    minSize: '2px',
                    maxSize: '30px',
                    colorAxis: 1,
                },
            ];

            chartData = {
                rawData: chartSourceData,

                title: false,
                chart: {
                    animation: false,
                    height: '70%',
                },
                mapView: {
                    insets: usFocusTerritoryInsets,
                },
                series,
                plotOptions: {
                    mappoint: {
                        enabled: true,
                        allowOverlap: false,
                        animation: {
                            duration: 450
                        },
                        layoutAlgorithm: {
                            type: 'grid',
                            gridSize: 70
                        },
                    }
                },
                // colorAxis: {
                //     doNotOverride: true,
                //     min: 0,
                //     max: maxIndex,
                //     stops: [
                //         [0, reportColors.axis.minColor],
                //         [(1 / maxIndex) * .99, BaseColors.red['25']], // Just below 1
                //         [1 / maxIndex, '#ffffff'], // Index of 1 - shifts based on the max index for this series
                //         [(1 / maxIndex) * 1.01, BaseColors.green['25']], // Just above 1
                //         [1, reportColors.axis.minColor],
                //     ]
                // },
                colorAxis: [
                    {
                        // Low range
                        endOnTick: false,
                        startOnTick: false,
                        min: 0,
                        max: 1,
                        minColor: reportColors.axis.low.min,
                        maxColor: reportColors.axis.low.max,
                    },
                    {
                        // High range
                        endOnTick: false,
                        startOnTick: false,
                        min: 1,
                        max: maxIndex,
                        minColor: reportColors.axis.high.min,
                        maxColor: reportColors.axis.high.max,
                    }
                ],
                tooltip: {
                    formatter: tooltipFormatter('map', 'header'),
                    // pointFormat: "<strong>Metro Area: {point.name}</strong><br>Baseline: {point.baseline}%<br>Audience: {point.audience}%<br>Relative Index: {point.z}<br>"
                },
            };
        }
            break;

        case 'net_worth_range':
            chartSourceData = Utils.sortByProperty(
                dictionaryLookup(
                    fieldDictionary.standard,
                    insights.offline_standard?.demographics[chartId],
                    chartId
                ),
                'position',
                'asc'
            );
            if (!chartSourceData) {
                return false;
            }

            chartData = {
                chart: {
                    type: 'column',
                },
                plotOptions: chartPlotOptions.bar,
                xAxis: {
                    categories: chartSourceData.map(item => item.value)
                },
                yAxis: chartAxisOptions.barIndexGroupPercent,
                series: chartDataToSeries('barIndex', chartSourceData, {
                    chartLabels: params.chartLabels,
                }),
                tooltip: {
                    formatter: tooltipFormatter('percent'),
                    shared: true,
                },
            };
            break;

        case 'net_worth_range_summary':
            dictionaryKey = 'net_worth_range'
            chartSourceData = dictionaryLookup(
                fieldDictionary.standard,
                insights.offline_standard?.demographics[dictionaryKey],
                dictionaryKey
            );
            if (!chartSourceData) {
                return false;
            }

            chartData = {
                icon: Utils.getDictionaryIcon(fieldDictionary.standard, dictionaryKey),
                series: [
                    {
                        name: 'Net Worth',
                        size: '100%',
                        innerSize: '85%',
                        data: chartDataToSeries('donut', chartSourceData, {property: 'groupRatio'}),
                    },
                    {
                        name: 'Index',
                        data: chartSourceData.map(item => {
                            return {
                                y: item.index
                            };
                        }),
                        linkedTo: ':previous',
                        visible: false,
                        showInLegend: false,
                    },
                ]
            };
            break;

        case 'occupation_type':
            chartSourceData = Utils.sortByProperty(
                dictionaryLookup(
                    fieldDictionary.standard,
                    insights.offline_standard?.demographics[chartId],
                    chartId
                ),
                'index',
                'desc',
            );
            if (!chartSourceData) {
                return false;
            }

            chartData = {
                chart: {
                    type: 'bar',
                },
                plotOptions: chartPlotOptions.bar,
                xAxis: {
                    categories: chartSourceData.map(item => item.value)
                },
                yAxis: chartAxisOptions.bar,
                series: chartDataToSeries('bar', chartSourceData, {
                    chartLabels: params.chartLabels,
                }),
                tooltip: {
                    formatter: tooltipFormatter('percent'),
                    shared: true,
                },
            };
            break;

        case 'occupation_type_summary':
            dictionaryKey = 'occupation_type'
            chartSourceData = dictionaryLookup(
                fieldDictionary.standard,
                insights.offline_standard?.demographics[dictionaryKey],
                dictionaryKey
            );
            if (!chartSourceData) {
                return false;
            }

            chartData = {
                icon: Utils.getDictionaryIcon(fieldDictionary.standard, dictionaryKey),
                series: [
                    {
                        name: 'Occupation Type',
                        size: '100%',
                        innerSize: '85%',
                        data: chartDataToSeries('donut', chartSourceData, {property: 'groupRatio'}),
                    },
                    {
                        name: 'Index',
                        data: chartSourceData.map(item => {
                            return {
                                y: item.index
                            };
                        }),
                        linkedTo: ':previous',
                        visible: false,
                        showInLegend: false,
                    },
                ]
            };
            break;

        case 'occupation_type_index':
            chartSourceData = Utils.sortByProperty(
                dictionaryLookup(
                    fieldDictionary.standard,
                    insights.offline_standard?.demographics['occupation_type'],
                    'occupation_type'
                ),
                'index',
                'desc',
            );
            if (!chartSourceData) {
                return false;
            }

            chartData = {
                chart: {
                    type: 'bar',
                },
                title: {
                    text: `Occupation Type ${indexLabel}`
                },
                plotOptions: chartPlotOptions.bar,
                xAxis: {
                    categories: chartSourceData.map(item => item.value)
                },
                yAxis: chartAxisOptions.sIndex,
                series: chartDataToSeries('sIndex', chartSourceData),
                tooltip: {
                    formatter: tooltipFormatter(),
                    shared: true,
                },
            };
            break;

        // case 'owner_renter':
        //     chartSourceData = dictionaryLookup(
        //         fieldDictionary,
        //         insights.offline_standard.demographics[chartId],
        //         chartId
        //     );
        //     if (!chartSourceData) {
        //         return false;
        //     }
        //
        //     chartData = {
        //         chart: {
        //             type: 'pie',
        //         },
        //         plotOptions: chartPlotOptions.donut,
        //         series: [
        //             {
        //                 name: params.chartLabels.group,
        //                 size: '80%',
        //                 data: chartDataToSeries('donut', chartSourceData, {property: 'groupRatio'})
        //                     .map((item, index) => Object.assign(item, {color: reportColors.donut.groupRatio[index]})),
        //             },
        //             {
        //                 name: params.chartLabels.baseline,
        //                 size: '100%',
        //                 innerSize: '85%',
        //                 data: chartDataToSeries('donut', chartSourceData, {property: 'baselineRatio'})
        //                     .map((item, index) => Object.assign(item, {color: reportColors.donut.baselineRatio[index]})),
        //                 showInLegend: false
        //             }
        //         ],
        //         tooltip: {
        //             formatter: tooltipFormatter('piePercent'),
        //         },
        //     };
        //     break;

        case 'owner_renter_summary':
            dictionaryKey = 'owner_renter';
            chartSourceData = dictionaryLookup(
                fieldDictionary.standard,
                insights.offline_standard?.demographics[dictionaryKey],
                dictionaryKey
            );
            if (!chartSourceData) {
                return false;
            }

            chartData = {
                icon: Utils.getDictionaryIcon(fieldDictionary.standard, dictionaryKey),
                series: [
                    {
                        name: 'Homeowner',
                        size: '100%',
                        innerSize: '85%',
                        data: chartDataToSeries('donut', chartSourceData, {property: 'groupRatio'}),
                    },
                    {
                        name: 'Index',
                        data: chartSourceData.map(item => {
                            return {
                                y: item.index
                            };
                        }),
                        linkedTo: ':previous',
                        visible: false,
                        showInLegend: false,
                    },
                ]
            };
            break;

        case 'persona_social_graph_profile':
            chartData = {
                rawData: {
                    groupMetrics: insights.social?.groupMetrics,
                },

                chart: {
                    events: {
                        load: chart => {
                            chartOverlayCallback(chart.target, 'personaSocialGraph');
                        },
                        redraw: chart => {
                            chartOverlayCallback(chart.target, 'personaSocialGraph');
                        },
                    },
                    height: 200,
                    margin: [0, 0, 0, 0],
                    // marginLeft: 10,
                },
                title: {
                    text: 'Persona Social Graph Profile'
                },
            };
            break;

        case 'political_party':
            chartSourceData = dictionaryLookup(
                fieldDictionary.standard,
                insights.offline_standard?.demographics[chartId],
                chartId
            );
            if (!chartSourceData) {
                return false;
            }

            chartData = {
                chart: {
                    type: 'column',
                },
                plotOptions: chartPlotOptions.bar,
                xAxis: {
                    categories: chartSourceData.map((item: any) => item.value)
                },
                yAxis: chartAxisOptions.barIndexGroupPercent,
                series: chartDataToSeries('barIndex', chartSourceData, {
                    chartLabels: params.chartLabels,
                }),
                tooltip: {
                    formatter: tooltipFormatter('percent'),
                    shared: true,
                },
            };
            break;

        case 'political_party_summarized':
            let politicalAffiliation = chartSourceData = Utils.sortByProperty(
                dictionaryLookup(
                    fieldDictionary.standard,
                    insights.offline_standard?.demographics[chartId],
                    chartId
                ),
                'position',
                'asc'
            );
            if (!chartSourceData) {
                return false;
            }

            chartData = {
                rawData: politicalAffiliation,

                chart: {
                    events: {
                        load: (chart: any) => {
                            chartOverlayCallback(chart.target, 'politicalPartyAffiliation');
                        },
                        redraw: chart => {
                            chartOverlayCallback(chart.target, 'politicalPartyAffiliation');
                        },
                    },
                    height: 225,
                    margin: [0, 0, 0, 0],
                },
                title: false,
            };
            break;

        case `${GEOGRAPHIC_SELECTION_PREFIX}region`:
        case 'region': {
            if (!returnData) {
                return {
                    asyncData: async () => {
                        const mapData = await baseOutlines(false, true),
                            baseOutlineData = formatOutlineSeries(mapData);

                        return {
                            region: mapData,
                            baseOutlineData,
                        }
                    }
                }
            }

            const geoSelectionMode = chartId.indexOf(GEOGRAPHIC_SELECTION_PREFIX) > -1;
            const mapData = asyncData.region,
                baseOutlineData = asyncData.baseOutlineData;
            let series: any[] = [];
            let rawData: any;
            let additionalChartSettings: any = {};

            if (geoSelectionMode) {
                // Static map for select/omit
                if (!baseOutlineData) {
                    return false;
                }

                // console.debug('🌎 Geo selection mode active - generate functional map!', options);
                chartSourceData = geographicSelectionData.state.map((stateCode: string) => {
                    return {
                        value: stateCode,
                    };
                });
                const regionSourceData = geographicSelectionData.region.map((regionCode: number) => {
                    const omitted = params.omitted.some((selection: any) => selection.code === regionCode);
                    const selected = params.selected.some((selection: any) => selection.code === regionCode);
                    let selectionValue = omitted ? -1 : selected ? 1 : 0;

                    return {
                        omitted,
                        selected,
                        selectionValue,
                        value: regionCode,
                    };
                });
                const regionData = dictionaryLookup(
                    fieldDictionary.standard,
                    regionSourceData,
                    chartId
                );

                // Map states to regions
                const data = mapRegionData({
                    baseOutlineData,
                    chartSourceData,
                    regionData
                }, 'selectionValue');

                const hoverState = `hover${params.omitted.length ? 'Omitted' : 'Selected'}`;
                const selectionData = {
                    name: 'Selection',
                    type: 'map',
                    joinBy: ['hc-a2', 'code'],
                    borderWidth: 0.5,
                    borderColor: BaseColors.gray['75'],
                    states: reportColors.choropleth.states,
                    shadow: false,
                    mapData,
                    data,
                    dataLabels: Highcharts.merge(chartDataLabelOptions.map, {
                        format: '{point.options.regionName}',
                    }),
                    point: {
                        animation: false,
                        events: {
                            mouseOver: (e: MouseEvent) => {
                                const regionName = e.target.regionName;
                                for (let point of e.target.series.points) {
                                    if (point.regionName === regionName) {
                                        point.setState(hoverState);
                                    }
                                }
                            },
                            mouseOut: (e: MouseEvent) => {
                                const regionName = e.target.regionName;
                                for (let point of e.target.series.points) {
                                    if (point.regionName === regionName) {
                                        point.setState('normal');
                                    }
                                }
                            },
                        },
                    },
                    showInTooltip: false,
                };

                series = [
                    selectionData,
                    baseOutlineData
                ];

                additionalChartSettings = {
                    colorAxis: reportColors.choropleth.selection.colorAxis,
                }
            } else {
                // Standard insights-driven report
                if (!insights.hasOwnProperty('offline_standard')) {
                    return false;
                }
                chartSourceData = insights.offline_standard?.demographics['state'];
                if (!chartSourceData || !baseOutlineData) {
                    return false;
                }
                const regionData = dictionaryLookup(
                    fieldDictionary.standard,
                    insights.offline_standard?.demographics[chartId],
                    chartId
                );
                rawData = regionData;

                // Map states to regions
                const data = mapRegionData({
                    baseOutlineData,
                    chartSourceData,
                    regionData
                });
                const lowData = data.filter(item => item.value < 1);
                const highData = data.filter(item => item.value >= 1);
                const maxIndex = Math.min(100, Math.max(...regionData.map(item => item.index))); // Cap the max value at 100, in case of weird outliers

                additionalChartSettings = {
                    colorAxis: [
                        {
                            // Low range
                            endOnTick: false,
                            startOnTick: false,
                            min: 0,
                            max: 1,
                            minColor: reportColors.axis.low.min,
                            maxColor: reportColors.axis.low.max,
                        },
                        {
                            // High range
                            endOnTick: false,
                            startOnTick: false,
                            min: 1,
                            max: maxIndex,
                            minColor: reportColors.axis.high.min,
                            maxColor: reportColors.axis.high.max,
                        }
                    ],
                }
                series = [
                    {
                        name: 'Index Low',
                        seriesType: 'low',
                        type: 'map',
                        joinBy: ['hc-a2', 'code'],
                        borderWidth: 0.5,
                        borderColor: BaseColors.gray['75'],
                        states: reportColors.choropleth.states,
                        shadow: false,
                        mapData,
                        data: lowData,
                        dataLabels: Highcharts.merge(chartDataLabelOptions.map, {
                            format: '{point.options.regionName}',
                        }),
                        point: {
                            animation: false,
                            events: {
                                mouseOver: e => {
                                    const regionName = e.target.regionName;
                                    for (let point of e.target.series.points) {
                                        if (point.regionName === regionName) {
                                            point.setState('hover');
                                        }
                                    }
                                },
                                mouseOut: e => {
                                    const regionName = e.target.regionName;
                                    for (let point of e.target.series.points) {
                                        if (point.regionName === regionName) {
                                            point.setState('normal');
                                        }
                                    }
                                },
                            }
                        },
                        colorAxis: 0,
                    },
                    {
                        name: 'Index High',
                        seriesType: 'high',
                        type: 'map',
                        joinBy: ['hc-a2', 'code'],
                        borderWidth: 0.5,
                        borderColor: BaseColors.gray['75'],
                        states: reportColors.choropleth.states,
                        shadow: false,
                        mapData,
                        data: highData,
                        dataLabels: Highcharts.merge(chartDataLabelOptions.map, {
                            format: '{point.options.regionName}',
                        }),
                        point: {
                            animation: false,
                            events: {
                                mouseOver: e => {
                                    const regionName = e.target.regionName;
                                    for (let point of e.target.series.points) {
                                        if (point.regionName === regionName) {
                                            point.setState('hover');
                                        }
                                    }
                                },
                                mouseOut: e => {
                                    const regionName = e.target.regionName;
                                    for (let point of e.target.series.points) {
                                        if (point.regionName === regionName) {
                                            point.setState('normal');
                                        }
                                    }
                                },
                            }
                        },
                        colorAxis: 1,
                    },
                    baseOutlineData,
                ];
            }

            chartData = Highcharts.merge({
                rawData,

                title: false,
                chart: {
                    animation: false,
                    height: '80%',
                },
                mapView: {
                    insets: usAlaskaHawaiiInsets,
                },
                series,
                plotOptions: {
                    map: {
                        allAreas: false,
                        nullColor: 'rgba(0, 0, 0, 0)',
                    }
                },
                tooltip: {
                    formatter: geoSelectionMode ?
                        tooltipFormatter('flex', 'name') :
                        tooltipFormatter('map') ,
                },
            }, additionalChartSettings);
        }
            break;

        case 'region_summary':
            dictionaryKey = 'region'
            chartSourceData = dictionaryLookup(
                fieldDictionary.standard,
                insights.offline_standard?.demographics[dictionaryKey],
                dictionaryKey
            );
            if (!chartSourceData) {
                return false;
            }

            chartData = {
                icon: Utils.getDictionaryIcon(fieldDictionary.standard, dictionaryKey),
                series: [
                    {
                        name: 'Region',
                        size: '100%',
                        innerSize: '85%',
                        data: chartDataToSeries('donut', chartSourceData, {property: 'groupRatio'}),
                    },
                    {
                        name: 'Index',
                        data: chartSourceData.map(item => {
                            return {
                                y: item.index
                            };
                        }),
                        linkedTo: ':previous',
                        visible: false,
                        showInLegend: false,
                    },
                ]
            };
            break;

        case 'rfm_credit_all':
        case 'rfm_credit_major':
        case 'rfm_ord_all': {
            let chartTitle: string | boolean = false;
            // let bins: string[] = [];
            let fieldNames: string[] = [];
            switch (chartId) {
                case 'rfm_credit_all':
                    chartTitle = 'Usage of All Credit Cards';
                    // bins = ['Very Low', 'Low', 'Average', 'High', 'Very High']; // TODO: this SHOULD come from the dictionary!
                    fieldNames = ['cc_amt', 'cc_ord_num'];
                    break;

                case 'rfm_credit_major':
                    chartTitle = 'Usage of Major Credit Cards';
                    // bins = ['Below Average', 'Average', 'Above Average']; // TODO: this SHOULD come from the dictionary!
                    fieldNames = ['cc_major_amt', 'cc_major_ord_num'];
                    break;

                case 'rfm_ord_all':
                    chartTitle = false;
                    // bins = ['Below Average', 'Average', 'Above Average']; // TODO: this SHOULD come from the dictionary!
                    fieldNames = ['ord_avg_amt', 'ord_lrg_amt', 'ord_lat_amt'];
                    break;
            }

            // const dictionaryMapping = fieldNames.map(fieldName =>
            //     dictionaryLookup(
            //         fieldDictionary,
            //         insights.high_level_rfm.highLevelRfmVariables[fieldName],
            //         fieldName
            //     )
            // );

            // const bins = ['Very Low', 'Low', 'Average', 'High', 'Very High']; // TODO: this SHOULD come from the dictionary!
            const bins = dictionaryLookup(
                fieldDictionary.highLevelRFM,
                insights.high_level_rfm?.highLevelRfmVariables[fieldNames[0]],
                fieldNames[0]
            );

            if ( bins ) {
                let series: any[] = [];
                bins.forEach((bin, i) => {
                    series.push({
                        name: bin.value,
                        data: fieldNames.map(fieldName => Math.round(insights.high_level_rfm.highLevelRfmVariables[fieldName][i]?.index * 100) / 100),
                    });
                });
                series.reverse();

                chartData = {
                    columnWidth: 2,
                    // rawData: {
                    //     twitterStats: insights.social.groupMetrics.twitterStats,
                    // },

                    chart: {
                        type: 'bar',
                    },
                    title: {
                        text: chartTitle
                    },
                    plotOptions: {
                        bar: {
                            stacking: 'percent',
                            height: 50,
                            borderWidth: 0,
                        },
                        series: {
                            dataLabels: {
                                format: '{point:.2f}',
                            },
                        },
                    },
                    colors: reportColors.barStacked,
                    xAxis: {
                        categories: fieldNames.map(fieldName => fieldDictionary.highLevelRFM[fieldName].shortDescription),
                    },
                    yAxis: {
                        title: {
                            text: indexLabel,
                        },
                        min: 0,
                        max: 100,
                        visible: false,
                    },
                    series,
                    legend: {
                        reversed: true,
                    },
                    tooltip: {
                        // formatter: tooltipFormatter('piePercent'),
                        shared: false,
                    },
                };
            } else {
                return false;
            }
        }
            break;

        // case 'rfm_idx': {
        //     chartSourceData = insights.high_level_rfm.highLevelRfmVariables[chartId].find(item => item.value === 'very_high');
        //
        //     let series = [{
        //         name: 'Median Index',
        //         data: [chartSourceData.index],
        //     }];
        //
        //     chartData = {
        //         columnWidth: 2,
        //         chart: {
        //             type: 'solidgauge',
        //         },
        //         title: {
        //             text: fieldDictionary[chartId]?.shortDescription,
        //         },
        //         pane: {
        //             center: ['50%', '85%'],
        //             size: '140%',
        //             startAngle: -90,
        //             endAngle: 90,
        //             background: {
        //                 backgroundColor:
        //                     Highcharts.defaultOptions.legend.backgroundColor || '#EEE',
        //                 innerRadius: '60%',
        //                 outerRadius: '100%',
        //                 shape: 'arc'
        //             }
        //         },
        //         yAxis: {
        //             min: 0,
        //             max: 2,
        //             title: {
        //                 text: 'Median Index',
        //                 y: -135,
        //             },
        //             stops: [
        //                 [0, reportColors.gauge.low],
        //                 [0.65, reportColors.gauge.mid],
        //                 [1.65, reportColors.gauge.high],
        //             ],
        //             lineWidth: 1,
        //             tickWidth: 1,
        //             tickInterval: 1,
        //             minorTickInterval: null,
        //             labels: {
        //                 y: 16,
        //             }
        //         },
        //         series,
        //     };
        // }
        //     break;

        case 'social_weekly_activity': {
            chartSourceData = insights.social?.socialActivity.activity;
            if (!chartSourceData) {
                return false;
            }
            let xAxisCategories = [];
            let yAxisCategories = ['12:00am', '1:00am', '2:00am', '3:00am', '4:00am', '5:00am', '6:00am', '7:00am', '8:00am', '9:00am', '10:00am', '11:00am', '12:00pm', '1:00pm', '2:00pm', '3:00pm', '4:00pm', '5:00pm', '6:00pm', '7:00pm', '8:00pm', '9:00pm', '10:00pm', '11:00pm'].reverse();
            let data: any[] = [];
            let segments: any[] = [];
            let maxValue = 0;

            chartSourceData.forEach((day: any, x) => {
                xAxisCategories.push(day.dayOfWeek);
                day.activity.forEach((hour, hourIndex) => {
                    const y = 23 - hourIndex; // reversed so the first hour is at the top of the chart
                    const value = (hour * 100);
                    maxValue = Math.max(maxValue, value);
                    data.push([x, y, value]);
                    segments.push({
                        label: xAxisCategories[x],
                        description: `${yAxisCategories[y]}&ndash;${yAxisCategories[y > 0 ? y - 1 : 23]}`,
                        value
                    })
                });
            });
            segments = Utils.sortByProperty(segments, 'value', 'desc');

            const series = [{
                name: 'activity period',
                data,
            }];

            let sidebarItems = {
                topRanked: segments.slice(0, 7).map((item, index) => {
                    return {
                        index: index + 1,
                        label: item.label,
                        value: item.description,
                    }
                }),
                bottomRanked: segments.reverse().slice(0, 7).map((item, index) => {
                    return {
                        index: index + 1,
                        label: item.label,
                        value: item.description,
                    }
                }),
            };

            chartData = {
                sidebarItems,

                chart: {
                    type: 'heatmap',
                    backgroundColor: 'transparent',
                    height: '100%',
                },
                title: false,
                xAxis: {
                    categories: xAxisCategories,
                    labels: {
                        rotation: -45,
                    },
                    opposite: true,
                },
                yAxis: {
                    title: {
                        text: 'Time of Day (EST)',
                    },
                    categories: yAxisCategories,
                    labels: {
                        style: {
                            fontSize: '10px',
                        },
                        x: -10,
                    },
                },
                colorAxis: Highcharts.merge(reportColors.heatmap, {
                    max: maxValue,
                    tickPositions: [0, maxValue * .5, maxValue],
                }),
                series,
                tooltip: {
                    enabled: true,
                    formatter: function () {
                        return `<strong>${this.point.value.toFixed(2)}%</strong> of activity at<br><strong>${this.series.yAxis.categories[this.point.y]}</strong> on <strong>${this.series.xAxis.categories[this.point.x]}</strong>`;
                    },
                },
                legend: {
                    enabled: true,
                    padding: 0,
                },
            };
        }
            break;

        case `${GEOGRAPHIC_SELECTION_PREFIX}state`:
        case 'state': {
            if (!returnData) {
                return {
                    asyncData: async () => {
                        const mapData = await baseOutlines(true, true),
                            baseOutlineData = formatOutlineSeries(mapData);

                        return {
                            state: mapData,
                            baseOutlineData,
                        }
                    },
                }
            }

            const geoSelectionMode = chartId.indexOf(GEOGRAPHIC_SELECTION_PREFIX) > -1;
            const mapData = asyncData.state,
                baseOutlineData = asyncData.baseOutlineData;
            let series: any[] = [];
            let rawData: any;
            let additionalChartSettings: any = {};

            if (geoSelectionMode) {
                // Static map for select/omit
                if (!baseOutlineData) {
                    return false;
                }

                const dictionary = fieldDictionary.standard['state'];
                chartSourceData = geographicSelectionData.state.map((stateCode: string) => {
                    const field = dictionary.values.find((state: any) => state.value === stateCode);
                    const omitted = params.omitted.some((selection: any) => selection.code === stateCode);
                    const selected = params.selected.some((selection: any) => selection.code === stateCode);
                    let value = omitted ? -1 : selected ? 1 : 0;

                    return {
                        code: stateCode,
                        name: field.shortDescription,
                        // Don't use "selected" here or Highcharts will make the field gray...
                        // omitted,
                        // selected,
                        value,
                    };
                });

                const hoverState = params.omitted.length ? 'Omitted' : 'Selected';
                // const normalState = (params.omitted.length || params.selected.length) ? hoverState : '';
                const selectionData = {
                    name: 'Selection',
                    type: 'map',
                    joinBy: ['hc-a2', 'code'],
                    borderWidth: 0.5,
                    borderColor: BaseColors.gray['75'],
                    states: reportColors.choropleth.states,
                    shadow: false,
                    mapData,
                    data: chartSourceData,
                    point: {
                        animation: false,
                        events: {
                            mouseOver: (e: MouseEvent) => {
                                e.target.setState(`hover${hoverState}`);
                            },
                            // mouseOut: (e: MouseEvent) => {
                            //     e.target.setState(`normal`);
                            // },
                        },
                    },
                    showInTooltip: false,
                };

                series = [
                    selectionData,
                    baseOutlineData,
                ];

                additionalChartSettings = {
                    colorAxis: reportColors.choropleth.selection.colorAxis,
                }
            } else {
                // Standard insights-driven report
                if (!insights.hasOwnProperty('offline_standard')) {
                    return false;
                }

                chartSourceData = insights.offline_standard?.demographics['state'];
                if (!chartSourceData || !baseOutlineData) {
                    return false;
                }
                let dataItem = (chartSourceData[0].hasOwnProperty(sortGroup) ? sortGroup : undefined) || 'index';
                // if (!dataItem) {
                //     dataItem = 'index';
                // }
                const data = chartSourceData.map(item => {
                    const code = item.value;
                    const name = baseOutlineData.data.find(feature => feature.properties['hc-a2'] === code)?.properties.name || null;

                    return {
                        code,
                        name,
                        value: item[dataItem] || item.index,
                        relativeIndex: item.index,
                        groupRatio: item.groupRatio,
                    }
                });
                // Copy human-readable state names back into the "source" data for use later
                chartSourceData.map(item => {
                    item.name = data.find(dataItem => dataItem.code === item.value)?.name;
                });
                rawData = chartSourceData;
                const maxDataValue = Math.min(100, Math.max(...data.map(item => item.value))); // Cap the max value at 100, in case of weird outliers
                // const maxGroupRatio = Math.min(100, Math.max(...chartSourceData.map(item => item.groupRatio))); // Cap the max value at 100, in case of weird outliers
                const axisBreakpoint = dataItem === 'index' ? 1 : maxDataValue / 2;
                const lowData = data.filter(item => item.value < axisBreakpoint);
                const highData = data.filter(item => item.value >= axisBreakpoint);

                // Enable data labels for the top 5 and bottom 5 items
                const sortedValues = Utils.sortByProperty(data, dataItem, 'desc');
                sortedValues.slice(0, 5).forEach((item: any) => {
                    data.find(dataItem => dataItem.code === item.code).dataLabels = {
                        range: 'high',
                        enabled: true
                    };
                });
                sortedValues.reverse().slice(0, 5).forEach((item: any) => {
                    data.find(dataItem => dataItem.code === item.code).dataLabels = {
                        range: 'low',
                        enabled: true
                    };
                });

                let borderColor = BaseColors.gray['75'];
                let colorAxis: any[] = [
                    {
                        // Low range
                        endOnTick: false,
                        startOnTick: false,
                        min: 0,
                        max: axisBreakpoint,
                        ceiling: axisBreakpoint,
                        minColor: null,
                        maxColor: null,
                        stops: null,
                    },
                    {
                        // High range
                        endOnTick: false,
                        startOnTick: false,
                        min: axisBreakpoint,
                        max: maxDataValue,
                        ceiling: maxDataValue,
                        minColor: null,
                        maxColor: null,
                        stops: null,
                    },
                ];
                let dataLabels;
                let seriesLabel;
                switch (sortGroup) {
                    case 'groupRatio':
                        borderColor = 'white';
                        // colorAxis[0].stops = [
                        //     [0, BaseColors.orange['100']],
                        //     [0.33, BaseColors.orange['50']],
                        //     [1, BaseColors.orange['25']],
                        // ];
                        // colorAxis[1].stops = [
                        //     [0, BaseColors.teal['25']],
                        //     [0.66, BaseColors.teal['50']],
                        //     [1, BaseColors.teal['100']],
                        // ];
                        colorAxis[0] = Highcharts.merge(colorAxis[0], {
                            stops: [
                                [0, heatmapStops[0][1]],
                                [0.5, heatmapStops[1][1]],
                                [1, heatmapStops[2][1]],
                            ],
                        });
                        colorAxis[1] = Highcharts.merge(colorAxis[1], {
                            stops: [
                                [0, heatmapStops[3][1]],
                                [0.5, heatmapStops[4][1]],
                                [1, heatmapStops[5][1]],
                            ],
                        });
                        dataLabels = Highcharts.merge(chartDataLabelOptions.map, {
                            format: '{point.options.name}: {point.value:.2f}%',
                        });
                        seriesLabel = ['% of Persona Low', '% of Persona High'];
                        break;

                    case 'index':
                    default:
                        colorAxis[0] = Object.assign({}, colorAxis[0], {
                            minColor: reportColors.axis.low.min,
                            maxColor: reportColors.axis.low.max,
                        });
                        colorAxis[1] = Object.assign({}, colorAxis[1], {
                            minColor: reportColors.axis.high.min,
                            maxColor: reportColors.axis.high.max,
                        });
                        dataLabels = Highcharts.merge(chartDataLabelOptions.map, {
                            format: '{point.options.name}: {point.value:.2f}x',
                        });
                        seriesLabel = ['Index Low', 'Index High'];
                        break;
                }

                const chartStateSettings = dataItem === 'groupRatio' ?
                    reportColors.heatmap.states :
                    reportColors.choropleth.states;

                additionalChartSettings = {
                    colorAxis,
                }
                series = [
                    baseOutlineData,
                    {
                        name: seriesLabel[0],
                        seriesType: 'low',
                        type: 'map',
                        // keys: ['postal-code', 'value'],
                        joinBy: ['hc-a2', 'code'],
                        // joinBy: ['postal-code'/*, 'hc-a2', 'code'*/],
                        borderWidth: 0.5,
                        borderColor,
                        states: chartStateSettings,
                        shadow: false,
                        mapData,
                        data: lowData,
                        dataLabels,
                        colorAxis: 0,
                    },
                    {
                        name: seriesLabel[1],
                        seriesType: 'high',
                        type: 'map',
                        joinBy: ['hc-a2', 'code'],
                        borderWidth: 0.5,
                        borderColor,
                        states: chartStateSettings,
                        shadow: false,
                        mapData,
                        data: highData,
                        dataLabels,
                        colorAxis: 1,
                    },
                ];
            }

            chartData = Highcharts.merge({
                rawData,

                title: false,
                chart: {
                    animation: false,
                    height: '80%',
                },
                mapView: {
                    insets: usFocusTerritoryInsets,
                },
                series,
                plotOptions: {
                    map: {
                        allAreas: false,
                        nullColor: 'rgba(0, 0, 0, 0)',
                    }
                },
                tooltip: {
                    formatter: geoSelectionMode ?
                        tooltipFormatter('flex', 'name') :
                        tooltipFormatter('map') ,
                },
            }, additionalChartSettings);
        }
            break;

        case 'twitter_follow_frequency':
            const bins = ['0-50', '51-100', '101-500', '501-1000', '1000+'];
            let series: any[] = [];
            bins.forEach((name, i) => {
                series.push({
                    name,
                    data: [
                        insights.social?.groupMetrics.twitterStats.followersHistogram[i],
                        insights.social?.groupMetrics.twitterStats.followingHistogram[i]
                    ],
                    pointWidth: 10,
                });
            });
            series.reverse();

            chartData = {
                rawData: {
                    twitterStats: insights.social?.groupMetrics.twitterStats,
                },

                chart: {
                    type: 'bar',
                    events: {
                        load: chart => {
                            chartOverlayCallback(chart.target, 'twitterFollowFrequency');
                        },
                        redraw: chart => {
                            chartOverlayCallback(chart.target, 'twitterFollowFrequency');
                        },
                    },
                    height: 200,
                    marginBottom: 50,
                },
                title: {
                    // text: 'Twitter Follower and Following Frequency',
                    text: 'Social Follower and Following Frequency',
                },
                plotOptions: chartPlotOptions.barStacked,
                colors: reportColors.barStacked,
                xAxis: {
                    categories: ['Followers', 'Following']
                },
                yAxis: {
                    // title: {
                    //     text: Config.display_strings.AXIS_LABEL_PERCENT,
                    // },
                    min: 0,
                    max: 100,
                    visible: false,
                },
                series,
                legend: {
                    reversed: true,
                    floating: true,
                    x: 30,
                    // y: -75,
                    itemStyle: {
                        color: defaultChartOptions.title.style.color,
                        cursor: 'pointer',
                        fontSize: '12px',
                        fontWeight: 'normal',
                    },
                    verticalAlign: 'top',
                },
                tooltip: {
                    // formatter: tooltipFormatter('percent'),
                    formatter: tooltipFormatter('piePercent'),
                    shared: false,
                },
            };
            break;

        /** Pattern-based chart IDs below **/

        case (chartId.match(affinitySocialComparisonPattern) || {}).input:
        case (chartId.match(socialEngagementComparisonPattern) || {}).input:
        case (chartId.match(summaryRollupSocialComparisonPattern) || {}).input:
        case (chartId.match(summarySocialComparisonPattern) || {}).input:
        case (chartId.match(topSocialComparisonPattern) || {}).input:
        case (chartId.match(userDefinedSocialEngagementComparisonPattern) || {}).input: {
            // Brands & Interests-summary charts (Twitter)
            let affinity,
                context,
                includesTwitter = false,
                mode,
                platform,
                topicName = null;

            if (chartId.match(socialEngagementComparisonPattern)) {
                mode = 'social-engagement';
                includesTwitter = true;
                [, platform] = chartId.match(socialEngagementComparisonPattern);
            } else if (chartId.match(summaryRollupSocialComparisonPattern)) {
                mode = 'summary-rollup';
                [, context, platform] = chartId.match(summaryRollupSocialComparisonPattern);
            } else if (chartId.match(summarySocialComparisonPattern)) {
                mode = 'summary';
                [, context, platform] = chartId.match(summarySocialComparisonPattern);
            } else if (chartId.match(topSocialComparisonPattern)) {
                mode = 'top';
                context = params.tab;
                [, platform] = chartId.match(topSocialComparisonPattern);
            } else if (chartId.match(userDefinedSocialEngagementComparisonPattern)) {
                mode = 'user-defined';
                context = params.tab;
                includesTwitter = true;
                [, platform, topicName] = chartId.match(userDefinedSocialEngagementComparisonPattern);
            } else {
                // Brand/Interest affinity
                mode = 'affinity';
                context = params.tab;
                includesTwitter = true;
                [, affinity, platform] = chartId.match(affinitySocialComparisonPattern);
            }

            // const icon = ['brands', platform];
            // if (!returnData) {
            //     return {
            //         icon
            //     }
            // }

            const contextLabel = Utils.titleCase(context || '');
            const platformLabel = Utils.titleCase(platform || '');
            let chartTitle;
            switch (mode) {
                case 'social-engagement':
                case 'user-defined':
                    // chartTitle = `Persona Social Engagement via ${Utils.titleCase(platform)}`;
                    chartTitle = `Persona Social Engagement`;
                    break;

                case 'summary':
                    // chartTitle = `${contextLabel} by Subcategory via ${platformLabel}`;
                    chartTitle = `${contextLabel} by Subcategory`;
                    break;

                case 'summary-rollup':
                    // chartTitle = `${contextLabel} by Category via ${platformLabel}`;
                    chartTitle = `${contextLabel} by Category`;
                    break;

                default:
                    // chartTitle = `${contextLabel} Engaged via ${platformLabel}`;
                    chartTitle = `${contextLabel} Engaged`;
                    break;
            }
            const labelColor = filterGroup ? BaseColors[reportColors.comparison.filter[filterGroup]]['100'] : BaseColors.gray['75'];
            const labelWidth = 250;
            let yAxis: any = {
                labels: {
                    format: '{value}',
                    style: {
                        color: labelColor,
                    },
                },
                title: {
                    style: {
                        color: labelColor,
                    },
                },
            };

            // Data preparation
            let sourceData: any[] = [];
            for (let i = 0, end = insights.length; i < end; ++i) {
                const sortContext = filterGroup === 'index' ? 'topByIndex' : 'topByCount';
                let chartSourceData: any[] = [];
                switch (mode) {
                    case 'affinity': {
                        // Ensure that the expected data is available - sometimes routing causes gaps in coverage that must be handled in the next cache pass
                        const contextParent = insights[i].social[context]?.defaultTopics[platform]?.find(topic => Utils.slug(topic.name) === affinity || Utils.slug(topic.category) === affinity)
                        if (!contextParent) {
                            return false;
                        }
                        chartSourceData = contextParent[sortContext];
                    }
                        break;

                    case 'social-engagement':
                        // console.debug(`SOCIAL ENGAGEMENT DATA #${i} FOR ${platform}:`, insights[i].social, insights[i].social.customTopics.groupDefinitionTopic[platform])
                        // chartSourceData = insights[i].social[context].topicsSummary[platform];
                        if (insights[i].social.customTopics.groupDefinitionTopic.hasOwnProperty(platform)) {
                            chartSourceData = insights[i].social.customTopics.groupDefinitionTopic[platform][sortContext];
                        }
                        break;

                    case 'summary':
                        chartSourceData = insights[i].social[context].topicsSummary[platform];
                        break;

                    case 'summary-rollup':
                        const hasRollupData = insights[i].social[context].hasOwnProperty('topicRollupsSummary');
                        chartSourceData = hasRollupData ? insights[i].social[context].topicRollupsSummary[platform] : false;
                        break;

                    case 'top':
                        chartSourceData = insights[i].social[context][sortContext][platform];
                        break;

                    case 'user-defined':
                        chartSourceData = insights[i].social.customTopics.userDefinedTopics
                            .find(topic => Utils.slug(topic.name) === topicName)[platform][sortContext];
                        // chartSourceData = insights[i].social[context][sortContext][platform];
                        break;
                }

                sourceData.push({
                    personaIndex: i,
                    chartSourceData: Utils.isEmptyArray(chartSourceData) ? false : chartSourceData,
                });
            }
            if (!sourceData.some(data => data.chartSourceData !== false)) {
                return false;
            }

            let matchProperty = 'item';
            switch (mode) {
                case 'summary':
                    matchProperty = 'id';
                    break;
                case 'summary-rollup':
                    matchProperty = 'category';
                    break;

                // default:
            }
            // const matchProperty = [, 'summary-rollup'].includes(mode) ? 'id' : 'item';
            const {series, seriesCategories, chartElementCount} = comparisonDataSort(sourceData, {
                filterGroup,
                filterSettings,
                matchProperty,
                sortGroup
            });
            const categoryCount = Math.max(...seriesCategories.map(series => series.labels.length));

            for (let seriesData of series) {
                switch (filterGroup) {
                    case 'groupPenetrationRatio':
                        seriesData.name = 'Depth of Interest';

                        if (!yAxis.title?.text) {
                            // Set up the Y axis
                            yAxis = Highcharts.merge(yAxis, {
                                min: 0,
                                margin: 0,
                                title: {
                                    text: 'Depth of Interest',
                                },
                                opposite: true,
                            });
                        }
                        break;

                    case 'groupRatio':
                        seriesData.name = mode === 'summary' ? groupRatioLabel : 'Follower Count';
                        seriesData.formatter = (value: any) => {
                            return `${value.toFixed(2)}%`;
                        };

                        if (!yAxis.title?.text) {
                            // Set up the Y axis
                            yAxis = Highcharts.merge(yAxis, {
                                min: 0,
                                margin: 0,
                                title: {
                                    text: seriesData.name,
                                },
                                labels: {
                                    format: '{value}%',
                                },
                                opposite: true,
                            });
                        }
                        break;

                    case 'index':
                        seriesData.name = indexLabel;

                        if (!yAxis.title?.text) {
                            // Set up the Y axis
                            yAxis = Highcharts.merge(yAxis, {
                                margin: 0,
                                title: {
                                    text: seriesData.name,
                                },
                                opposite: true,
                            });
                        }
                        break;
                }
            }

            // Chart sizing-adjust as needed
            const chartHeight = `${(barSize * chartElementCount * 2.5) + headerSize}`;

            // Convert categories into readable labels
            // const showImages = includesTwitter || filterSettings.count === 100;
            let categories: any[] = [];
            for (const {personaIndex, labels} of seriesCategories) {
                for (let labelIndex = 0; labelIndex < categoryCount; ++labelIndex) {
                    const {category} = labels[labelIndex] || {category: null};

                    let label: string;
                    let addSpace = false;

                    if (category) {
                        if (mode === 'summary-rollup') {
                            label = `<div class="float-end">${category.category}</div>`;
                        } else if (category.hasOwnProperty('subCategory')) {
                            addSpace = true;
                            label = `<div class="float-end">${category.subCategory}</div>`;
                        } else {
                            // Account image & link
                            // let accountImage = showImages && category.hasOwnProperty('imageUrl') ?
                            //     `<img src="${category.imageUrl}" width="${barSize}" height="${barSize}"
                            //     class="ms-2 fallback-image-twitter"
                            //     onerror="${Utils.imageFallback('twitter')}"/>` :
                            //     '';
                            const itemUrl = socialActionUrl(category);
                            if (itemUrl) {
                                // accountImage = accountImage.length ?
                                //     `<a title="${category.displayName}" target="_new" href="${itemUrl}">${accountImage}</a>` :
                                //     accountImage;
                            } else {
                                addSpace = true;
                            }

                            const actionLabel = socialActionLabel(category);

                            label = `
                                <div class="d-flex align-items-center float-end">
                                    <div class="flex-shrink text-end" style="">${actionLabel}</div>
                                </div>
                            `.trim();
                        }
                    } else {
                        label = `<div class="float-end">${outOfRangeLabel}</div>`;
                        addSpace = true;
                    }

                    let categoryLabel = categories[labelIndex] || '';
                    categoryLabel += `<div class="${personaIndex < (series.length - 1) ? 'clearfix' : ''} ${addSpace ? 'py-2' : ''}" style="color: ${reportColors.comparison[personaIndex]}">${label}</div>`;
                    categories[labelIndex] = categoryLabel;
                }
            }

            chartData = {
                // icon,
                id: chartId,
                chart: {
                    type: 'bar',
                    // height: `${(chartElementCount * 10)}%`,
                    height: chartHeight,
                    marginLeft: labelWidth,
                },
                legend: false,
                title: {
                    text: chartTitle,
                },
                // plotOptions: chartPlotOptions.barGroup,
                plotOptions: Highcharts.merge(chartPlotOptions.barGroup, {
                    series: {
                        dataLabels: {
                            enabled: true,
                            inside: true,
                        },
                    }
                }),
                xAxis: {
                    categories,
                    gridLineColor: '#000000',
                    gridLineWidth: 1,
                    tickColor: '#cccccc',
                    tickWidth: 1,
                    labels: {
                        useHTML: true,
                    },
                },
                yAxis,
                series,
                tooltip: {
                    formatter: tooltipFormatter('flex', false),
                },
            };
        }
            break;

        case (chartId.match(briefSummarySocialPattern) || {}).input:
        case (chartId.match(politicalSummarySocialPattern) || {}).input: {
            // Brands & Interests-summary info (Twitter)
            let context, platform;
            // let isPolitical = false;
            if (chartId.match(briefSummarySocialPattern)) {
                // Summary topics
                [, context, platform] = chartId.match(briefSummarySocialPattern);
                if (!insights.social[context]?.topicsSummary?.hasOwnProperty(platform)) {
                    return false;
                }

                chartSourceData = Utils
                    .sortByProperty(
                        insights.social[context].topicsSummary[platform],
                        'index',
                        'desc'
                    )
                    .slice(0, 5)
                ;
                if (!chartSourceData) {
                    return false;
                }

                return chartSourceData;
            } else {
                // Political topics
                [, platform] = chartId.match(politicalSummarySocialPattern);
                // if (!insights.social.interests.defaultTopics.hasOwnProperty(platform)) {
                //     return false;
                // }

                return insights.social?.interests.defaultTopics[platform]?.filter(topic => topic.category === 'US Politics') || false;
            }
        }

        case (chartId.match(conglomerateRfmComparisonPattern) || {}).input: {
            if (!filterGroup) {
                return false;
            }

            // const [, category] = chartId.match(conglomerateRfmComparisonPattern);
            const [, categoryId] = chartId.match(conglomerateRfmComparisonPattern);
            const labelColor = filterGroup ? BaseColors[reportColors.comparison.filter[filterGroup]]['100'] : BaseColors.gray['75'];
            let yAxis: any = {
                labels: {
                    format: '{value}',
                    style: {
                        color: labelColor,
                    },
                },
                title: {
                    style: {
                        color: labelColor,
                    },
                },
            };

            // Data preparation
            let sourceData: any[] = [];
            for (let i = 0, end = insights.length; i < end; ++i) {
                chartSourceData = insights[i].conglomerate_rfm.markets
                    .filter((market: any) => market.categoryId === categoryId)
                    .map((market: any) => {
                        const marketDetail = structuredClone(toRaw(market));
                        const sourceDetail = marketDetail.values.active_buyer.find((item: any) => item.value === 'active');
                        for (const key of Object.keys(sourceDetail)) {
                            marketDetail[key] = sourceDetail[key];
                        }

                        return marketDetail;
                    });

                sourceData.push({
                    personaIndex: i,
                    chartSourceData: Utils.isEmptyArray(chartSourceData) ? false : chartSourceData,
                });
            }
            if (!sourceData.some(data => data.chartSourceData !== false)) {
                return false;
            }

            const chartHeight = `${(barSize * chartSourceData.length * 2) + headerSize /*+ Math.min(0, (headerSize * 2)-chartSourceData.length)*/}`;
            const {series, seriesCategories, chartElementCount} = comparisonDataSort(
                sourceData, {
                    filterGroup,
                    filterSettings,
                    matchProperty: 'name',
                    sortGroup
                }
            );

            for (let seriesData of series) {
                switch (filterGroup) {
                    case 'groupRatio':
                        seriesData.name = groupRatioLabel;
                        seriesData.formatter = value => {
                            return `${value.toFixed(2)}%`;
                        };

                        if (!yAxis.title?.text) {
                            // Set up the Y axis
                            yAxis = Highcharts.merge(yAxis, {
                                min: 0,
                                margin: 0,
                                title: {
                                    text: seriesData.name,
                                },
                                labels: {
                                    format: '{value}%',
                                },
                                opposite: true,
                            });
                        }
                        break;

                    case 'index':
                        seriesData.name = indexLabel;

                        if (!yAxis.title?.text) {
                            // Set up the Y axis
                            yAxis = Highcharts.merge(yAxis, {
                                margin: 0,
                                title: {
                                    text: seriesData.name,
                                },
                                opposite: true,
                            });
                        }
                        break;
                }
            }

            // Convert categories into readable labels
            let categories: any[] = [];
            for (const {personaIndex, labels} of seriesCategories) {
                // for (let labelIndex = 0; labelIndex < Math.min(categoryCount, MAX_SUBCATEGORY_COUNT); ++labelIndex) {
                let labelIndex = 0;
                for (const {category} of labels) {
                    // const {category} = labelItem;
                    let categoryLabel = categories[labelIndex] || '';
                    categoryLabel += `<div class="text-end py-1" style="color: ${reportColors.comparison[personaIndex]}">${category?.name || outOfRangeLabel}</div>`;
                    // categories.push(categoryLabel);
                    categories[labelIndex] = categoryLabel;
                    ++labelIndex;
                }
            }

            chartData = {
                id: chartId,
                chart: {
                    type: 'bar',
                    // height: '115%',
                    height: chartHeight,
                },
                legend: false,
                title: {
                    // text: chartTitle,
                    text: false,
                },
                plotOptions: Highcharts.merge(chartPlotOptions.barGroup, {
                    series: {
                        dataLabels: {
                            enabled: true,
                            inside: true,
                        },
                    }
                }),
                xAxis: {
                    categories,
                    gridLineColor: '#000000',
                    gridLineWidth: 1,
                    tickColor: '#cccccc',
                    tickWidth: 1,
                    labels: {
                        useHTML: true,
                    },
                },
                yAxis,
                series,
                tooltip: {
                    formatter: tooltipFormatter('percent'),
                },
            };
        }
            break;

        case (chartId.match(conglomerateRfmMarketPattern) || {}).input: {
            let [, marketId, chartContext] = chartId.match(conglomerateRfmMarketPattern);
            if (!marketId || !chartContext || !insights.hasOwnProperty('conglomerate_rfm') || !Object.keys(insights.conglomerate_rfm?.markets || {}).length) {
                // No data for this!
                return false;
            }
            marketId = parseInt(marketId);

            const market: any | null = insights.conglomerate_rfm.markets
                .find((sourceMarket: any) => sourceMarket.id === marketId);
            if (!market) {
                // No match found for market name
                console.error(`No market found for "${marketId}" (context: ${chartContext})`);
                return false;
            }

            let chartSourceData: any[] = [];
            switch (chartContext) {
                case 'metrics':
                case 'participation':
                    const attributes: string[] = compositeChartSourceFields.conglomerateRFM[chartContext];
                    let titleText: string = '';
                    // Weird to use the same switch twice, but this avoids repeating common functions
                    switch (chartContext) {
                        case 'metrics':
                            titleText = 'Key Market Metrics';
                            break;
                        case 'participation':
                            titleText = 'Market Participation';
                            break;
                    }

                    for (const attribute of attributes) {
                        const targetAttribute = market.values[attribute];
                        const dictionarySource = dictionaryLookup(
                            fieldDictionary.conglomerateRFM,
                            targetAttribute,
                            attribute
                        );

                        chartSourceData.push(dictionarySource[0]);
                    }

                    const series = [
                        {
                            yAxis: 0,
                            name: indexLabel,
                            type: 'bar',
                            color: reportColors.barIndex.index,
                            data: chartSourceData.map(item => item.index),
                        },
                        {
                            yAxis: 1,
                            name: groupRatioLabel,
                            type: 'bar',
                            color: reportColors.barIndex.groupRatio,
                            data: chartSourceData.map(item => item.groupRatio),
                        }
                    ];

                    chartData = {
                        id: chartId,
                        chart: {
                            type: 'bar',
                            height: '75%',
                        },
                        legend: false,
                        plotOptions: chartPlotOptions.barGroup,
                        series,
                        title: {
                            text: titleText,
                        },
                        tooltip: {
                            formatter: tooltipFormatter('percent'),
                        },
                        xAxis: {
                            categories: chartSourceData.map((item: any) => item.value),
                        },
                        yAxis: [
                            {
                                min: 0,
                                margin: 0,
                                title: {
                                    style: {
                                        color: reportColors.barIndex.index,
                                    },
                                    text: indexLabel,
                                },
                                labels: {
                                    style: {
                                        color: reportColors.barIndex.indexLabel,
                                    },
                                },
                                opposite: true,
                                // visible: sortGroup === 'index',
                            },
                            {
                                min: 0,
                                margin: 0,
                                title: {
                                    style: {
                                        color: reportColors.barIndex.groupRatio,
                                    },
                                    text: groupRatioLabel,
                                },
                                labels: {
                                    format: '{value}%',
                                    style: {
                                        color: reportColors.barIndex.groupRatioLabel,
                                    },
                                },
                                opposite: true,
                                visible: false,
                            },
                        ],
                    };
                    break;

                default:
                    chartSourceData = Utils.sortByProperty(
                        dictionaryLookup(
                            fieldDictionary.conglomerateRFM,
                            market.values[chartContext],
                            chartContext
                        ),
                        'position',
                        'asc'
                    );
                    chartData = {
                        chart: {
                            type: 'column',
                        },
                        plotOptions: chartPlotOptions.bar,
                        series: chartDataToSeries('barIndex', chartSourceData),
                        title: {
                            text: fieldDictionary.conglomerateRFM[chartContext]?.shortDescription || `NO DICTIONARY ENTRY FOR '${chartContext}'`,
                        },
                        xAxis: {
                            categories: chartSourceData.map((item: any) => item.value),
                        },
                        yAxis: chartAxisOptions.barIndexGroupPercent,
                        tooltip: {
                            formatter: tooltipFormatter('percent'),
                            shared: true,
                        },
                    };
            }
            if (!chartSourceData.length) {
                return false;
            }
        }
            break;

        case (chartId.match(conglomerateRfmMarketSummaryPattern) || {}).input: {
            if (!returnData) {
                return true;
            }

            const [, categoryId] = chartId.match(conglomerateRfmMarketSummaryPattern);
            if (!categoryId || !insights?.hasOwnProperty('conglomerate_rfm')) {
                return false;
            }

            let rawSourceData: any[] = insights.conglomerate_rfm.markets
                // Copy metadata into the decorated market
                .map((market: any) => {
                    const sourceDetail = market.values.active_buyer.find((item: any) => item.value === 'active');
                    for (const key of Object.keys(sourceDetail)) {
                        market[key] = sourceDetail[key];
                    }

                    return market;
                })
                // Filter out anything under the group representation threshold
                .filter((market: any) => market.groupRatio >= minGroupRatioThreshold);

            let isReportSummary = false;
            switch (categoryId) {
                case 'top':
                    isReportSummary = true;
                    rawSourceData = Utils.sortByProperty(
                        rawSourceData,
                        'index',
                        'desc'
                    ).slice(0, 5);
                    break;

                default:
                    rawSourceData = rawSourceData.filter((market: any) => Utils.slug(market.categoryId) === categoryId);
                    break;
            }

            chartSourceData = Utils.sortByProperty(
                rawSourceData,
                [sortGroup, 'groupCount'],
                'desc'
            );
            if (!chartSourceData) {
                return false;
            }

            let series = [
                {
                    yAxis: 0,
                    name: `${indexLabel} of Market-Active Buyers`,
                    type: 'bar',
                    color: reportColors.barIndex.index,
                    data: chartSourceData.map((item: any) => item.index),
                }
            ];

            let yAxis = [
                {
                    min: 0,
                    margin: 0,
                    title: {
                        style: {
                            color: isReportSummary ? null : reportColors.barIndex.index,
                        },
                        text: `${indexLabel} of Market-Active Buyers`,
                    },
                    labels: {
                        style: {
                            color: isReportSummary ? null : reportColors.barIndex.indexLabel,
                        },
                    },
                    opposite: !isReportSummary,
                    visible: sortGroup === 'index',
                },
            ];

            if (!isReportSummary) {
                series.push({
                    yAxis: 1,
                    name: groupRatioLabel,
                    type: 'bar',
                    color: reportColors.barIndex.groupRatio,
                    data: chartSourceData.map((item: any) => item.groupRatio),
                });

                yAxis.push({
                    min: 0,
                    margin: 0,
                    title: {
                        style: {
                            color: reportColors.barIndex.groupRatio,
                        },
                        text: groupRatioLabel,
                    },
                    labels: {
                        // format: '{value}%',
                        style: {
                            color: reportColors.barIndex.groupRatioLabel,
                        },
                    },
                    opposite: true,
                    visible: sortGroup === 'groupRatio',
                });
            }
            let chartHeight = (barSize * chartSourceData.length * series.length) + headerSize /*+ Math.min(0, (headerSize * 2)-chartSourceData.length)*/;
            if (isReportSummary) {
               chartHeight += (headerSize * .75);
            }

            chartData = {
                chart: {
                    type: 'bar',
                    // height: '100%',
                    height: chartHeight,
                },
                legend: false,
                title: isReportSummary ? {text: 'Top 5 Markets'} : false,
                plotOptions: chartPlotOptions.barGroup,
                xAxis: {
                    categories: chartSourceData.map((item: any) => `<strong>${item.subCategory}:</strong><br>${item.name}`),
                },
                yAxis,
                series,
                tooltip: {
                    formatter: tooltipFormatter('percent'),
                },
            };
        }
            break;

        // case (chartId.match(comparisonChartPattern) || {}).input: {
        //     const [, originalChartId, personaIndex] = chartId.match(comparisonChartPattern);
        //     console.debug(`COMPARISON CHART PATTERN - original chart: "${originalChartId}" / index ${personaIndex}`);
        //
        //     // Call the existing chart preparation
        //     const chartOptions = Object.assign({}, options, {
        //         chartId: originalChartId,
        //         insights: insights[personaIndex],
        //     })
        //     chartData = prepareChartData(chartOptions);
        // }
        //     break;

        case (chartId.match(predictedSpendComparisonPattern) || {}).input: {
            if (!returnData) {
                return {};
            }

            const [, , subcategoryId] = chartId.match(predictedSpendComparisonPattern);
            const labelColor = filterGroup ? BaseColors[reportColors.comparison.filter[filterGroup]]['100'] : BaseColors.gray['75'];
            let yAxis: any = {
                labels: {
                    format: '{value}',
                    style: {
                        color: labelColor,
                    },
                },
                title: {
                    style: {
                        color: labelColor,
                    },
                },
            };
            let chartTitle: string = '';

            // Data preparation
            let sourceData: any[] = [];
            let subCategory: any | null = null;
            for (let i = 0, end = insights.length; i < end; ++i) {
                // Find the specified subcategory
                const category = insights[i].consumer_spend.categories
                    .find((category: any) => Utils.slug(category.name) === params.pageContext);
                subCategory = category.subCategories.find((subCategory: any) => subCategory.id === subcategoryId);
                if (!subCategory) {
                    console.error(`🔴 No match found for subcategory ID ${subcategoryId}!`);
                    return false;
                }
                chartSourceData = subCategory.spendingIndicators;
                chartTitle = subCategory.name || chartTitle;

                sourceData.push({
                    personaIndex: i,
                    chartSourceData: Utils.isEmptyArray(chartSourceData) ? false : chartSourceData,
                });
            }
            if (!sourceData.some(data => data.chartSourceData !== false)) {
                return false;
            }

            const {
                series: sourceSeries,
                seriesCategories,
                chartElementCount
            } = comparisonDataSort(
                sourceData,
                {
                    filterGroup,
                    filterSettings,
                    matchProperty: 'id',
                    sortGroup
                }
            );
            if (!sourceSeries.length) {
                // TODO: why does this ever happen?
                return false;
            }
            // Trim the data series down to a maximum number of subcategories to ensure the chart displays correctly.
            // This SHOULD already be happening on the back end, but some have slipped through and must be fixed here.
            const series = sourceSeries.map(sourceSeriesItem => {
                const seriesItem = clone(sourceSeriesItem);
                seriesItem.data = seriesItem.data.slice(0, MAX_SUBCATEGORY_COUNT);

                return seriesItem;
            });
            const categoryCount = Math.max(...seriesCategories.map(series => series.labels.length));

            for (let seriesData of series) {
                switch (filterGroup) {
                    case 'groupRatio':
                        seriesData.name = groupRatioLabel;
                        seriesData.formatter = value => {
                            return `${value.toFixed(2)}%`;
                        };

                        if (!yAxis.title?.text) {
                            // Set up the Y axis
                            yAxis = Highcharts.merge(yAxis, {
                                min: 0,
                                margin: 0,
                                title: {
                                    text: seriesData.name,
                                },
                                labels: {
                                    format: '{value}%',
                                },
                                opposite: true,
                            });
                        }
                        break;

                    case 'index':
                        seriesData.name = indexLabel;

                        if (!yAxis.title?.text) {
                            // Set up the Y axis
                            yAxis = Highcharts.merge(yAxis, {
                                margin: 0,
                                title: {
                                    text: seriesData.name,
                                },
                                opposite: true,
                            });
                        }
                        break;
                }
            }

            // Convert categories into readable labels
            let categories: any[] = [];
            for (const {personaIndex, labels} of seriesCategories) {
                for (let labelIndex = 0; labelIndex < Math.min(categoryCount, MAX_SUBCATEGORY_COUNT); ++labelIndex) {
                    const {category} = labels[labelIndex] || {category: null};
                    let label = category ? category.shortDescription : outOfRangeLabel;
                    let categoryLabel = categories[labelIndex] || '';
                    categoryLabel += `<div class="text-end py-1" style="color: ${reportColors.comparison[personaIndex]}">${label}</div>`;
                    categories[labelIndex] = categoryLabel;
                }
            }

            chartData = {
                id: chartId,
                chart: {
                    type: 'bar',
                    height: '115%',
                },
                legend: false,
                title: {
                    text: chartTitle,
                },
                plotOptions: Highcharts.merge(chartPlotOptions.barGroup, {
                    series: {
                        dataLabels: {
                            enabled: true,
                            inside: true,
                        },
                    }
                }),
                xAxis: {
                    categories,
                    gridLineColor: '#000000',
                    gridLineWidth: 1,
                    tickColor: '#cccccc',
                    tickWidth: 1,
                    labels: {
                        useHTML: true,
                    },
                },
                yAxis,
                series,
                tooltip: {
                    formatter: tooltipFormatter('percent'),
                },
            };
        }
            break;

        case (chartId.match(predictedSpendPattern) || {}).input: {
            const [, categorySlug, displayOrder] = chartId.match(predictedSpendPattern);
            const category = insights.consumer_spend.categories
                    .find((category: any) => Utils.slug(category.name) === categorySlug);
            const subCategory = category.subCategories
                .find((subCategory: any) => subCategory.displayOrder === parseInt(displayOrder));

            chartSourceData = Utils.sortByProperty(
                subCategory?.spendingIndicators,
                [sortGroup, 'groupCount'],
                'desc'
            );
            if (!chartSourceData) {
                return false;
            }

            const series = [
                {
                    yAxis: 0,
                    name: indexLabel,
                    type: 'bar',
                    color: reportColors.barIndex.index,
                    data: chartSourceData.map(item => item.index),
                },
                {
                    yAxis: 1,
                    name: groupRatioLabel,
                    type: 'bar',
                    color: reportColors.barIndex.groupRatio,
                    data: chartSourceData.map(item => item.groupRatio),
                }
            ];

            chartData = {
                // icon,
                id: chartId,
                chart: {
                    type: 'bar',
                    height: '75%',
                },
                legend: false,
                plotOptions: chartPlotOptions.barGroup,
                series,
                title: {
                    text: subCategory.name,
                },
                tooltip: {
                    formatter: tooltipFormatter('percent'),
                },
                xAxis: {
                    categories: chartSourceData.map((item: any) => item.shortDescription),
                },
                yAxis: [
                    {
                        min: 0,
                        margin: 0,
                        title: {
                            style: {
                                color: reportColors.barIndex.index,
                            },
                            text: indexLabel,
                        },
                        labels: {
                            style: {
                                color: reportColors.barIndex.indexLabel,
                            },
                        },
                        opposite: true,
                        visible: sortGroup === 'index',
                    },
                    {
                        min: 0,
                        margin: 0,
                        title: {
                            style: {
                                color: reportColors.barIndex.groupRatio,
                            },
                            text: groupRatioLabel,
                        },
                        labels: {
                            format: '{value}%',
                            style: {
                                color: reportColors.barIndex.groupRatioLabel,
                            },
                        },
                        opposite: true,
                        visible: sortGroup === 'groupRatio',
                    },
                ],
            };
        }
            break;

        case (chartId.match(demographicCompareCountPattern) || {}).input:
        case (chartId.match(demographicCompareIndexPattern) || {}).input: {
            let key, property, tooltipFormat, yAxis;
            let isIndex = false;
            if (chartId.match(demographicCompareCountPattern)) {
                [, key] = chartId.match(demographicCompareCountPattern);
                property = key;
                tooltipFormat = tooltipFormatter('percent', true);
                yAxis = chartAxisOptions.barGroupPercent;
            } else {
                isIndex = true;
                [, key] = chartId.match(demographicCompareIndexPattern);
                property = `${key}|index`;
                tooltipFormat = tooltipFormatter('flex', true);
                yAxis = chartAxisOptions.relativeIndex;
            }

            let {series, xAxis, titleText} = chartDataToSeries(
                'demographicComparison',
                {
                    insights,
                    fieldDictionary
                },
                {
                    property
                }
            );

            chartData = {
                chart: {
                    type: 'column',
                },
                title: {
                    text: titleText
                },
                xAxis,
                yAxis,
                series,
                tooltip: {
                    // formatter: tooltipFormat,
                    formatter: tooltipFormat,
                    shared: true,
                },
            };
        }
            break;

        case (chartId.match(highLevelRfmPattern) || {}).input: {
            let [, fieldName, chartType] = chartId.match(highLevelRfmPattern);
            if (!chartType) {
                chartType = 'column';
            }
            if (!insights.high_level_rfm || !insights.high_level_rfm.highLevelRfmVariables?.hasOwnProperty(fieldName)) {
                // No data for this!
                console.error(`🔴 Insights data not available for "${fieldName}" (${chartId})`);
                return false;
            }

            chartData = {
                chart: {
                    type: 'column',
                },
                title: {
                    text: fieldDictionary.highLevelRFM[fieldName]?.shortDescription || `NO DICTIONARY ENTRY FOR '${fieldName}'`,
                },
            };

            switch (chartType) {
                case 'gauge':
                    chartSourceData = insights.high_level_rfm.highLevelRfmVariables[fieldName]
                        .find((item: any) => item.value === 'very_high');
                    break;

                default:
                    chartSourceData = Utils.sortByProperty(
                        dictionaryLookup(
                            fieldDictionary.highLevelRFM,
                            insights.high_level_rfm.highLevelRfmVariables[fieldName],
                            fieldName
                        ),
                        'position',
                        'asc'
                    );
                    chartData = Highcharts.merge(chartData, {
                        xAxis: {
                            categories: chartSourceData.map((item: any) => item.value),
                        }
                    });
            }

            if (!chartSourceData) {
                return false;
            }

            switch (chartType) {
                case 'column':
                    chartData = Highcharts.merge(chartData, {
                        plotOptions: chartPlotOptions.bar,
                        yAxis: chartAxisOptions.barIndexGroupPercent,
                        series: chartDataToSeries('barIndex', chartSourceData),
                        tooltip: {
                            formatter: tooltipFormatter('percent'),
                            shared: true,
                        },
                    })
                    break;

                case 'gauge': {
                    const thickness = 50;
                    delete chartData.xAxis;
                    chartData = Highcharts.merge(chartData, {
                        chart: {
                            type: 'gauge',
                            plotBackgroundColor: null,
                            plotBackgroundImage: null,
                            plotBorderWidth: 0,
                            plotShadow: false,
                            height: '70%',
                        },
                        pane: {
                            startAngle: -90,
                            endAngle: 90,
                            background: null,
                            center: ['50%', '75%'],
                            size: '115%'
                        },
                        series: [{
                            data: [{
                                y: Math.min(2, chartSourceData.index),
                                realValue: chartSourceData.index, // Allows us to place a hard cap on the gauge display but still show a REAL value above this
                            }],
                            dataLabels: {
                                borderWidth: 0,
                                color: (
                                    Highcharts.defaultOptions.title &&
                                    Highcharts.defaultOptions.title.style &&
                                    Highcharts.defaultOptions.title.style.color
                                ) || '#333333',
                                format: '{point.realValue:.2f}', // Avoids using a y-value that extends beyond the axis range
                                style: {
                                    fontSize: '16px'
                                }
                            },
                            dial: {
                                radius: '80%',
                                backgroundColor: 'gray',
                                baseWidth: 12,
                                baseLength: '0%',
                                rearLength: '0%'
                            },
                            pivot: {
                                backgroundColor: 'gray',
                                radius: 6
                            }
                        }],
                        yAxis: {
                            labels: {
                                distance: 20,
                            },
                            lineWidth: 0,
                            max: 2,
                            min: 0,
                            minorTickInterval: null,
                            plotBands: [{
                                from: 0,
                                to: 0.75,
                                color: reportColors.gauge.low,
                                thickness
                            }, {
                                from: .75,
                                to: 1.5,
                                color: reportColors.gauge.mid,
                                thickness
                            }, {
                                from: 1.5,
                                to: 2,
                                color: reportColors.gauge.high,
                                thickness
                            }],
                            tickColor: Highcharts.defaultOptions.chart.backgroundColor || '#FFFFFF',
                            tickInterval: 0.25,
                            tickLength: thickness,
                            tickPosition: 'inside',
                            tickWidth: 2,
                        },
                        tooltip: false,
                    });
                }
                    break;

                case 'radial':
                    chartData = Highcharts.merge(chartData, {
                        chart: {
                            polar: true,
                            inverted: true,
                            height: '100%',
                        },
                        legend: false,
                        pane: {
                            size: '85%',
                            innerSize: '20%',
                            endAngle: 270
                        },
                        plotOptions: chartPlotOptions.radial,
                        series: [
                            {
                                name: indexLabel,
                                colors: reportColors.donut.standard,
                                colorByPoint: true,
                                data: chartSourceData.map(item => Math.round(item.index * 100) / 100),
                            }
                        ],
                        xAxis: {
                            tickInterval: 1,
                            labels: {
                                align: 'right',
                                useHTML: true,
                                allowOverlap: true,
                                step: 1,
                                y: 3,
                                style: {
                                    fontSize: '13px'
                                }
                            },
                            lineWidth: 0,
                        },
                        yAxis: [
                            {
                                endOnTick: true,
                                lineWidth: 0,
                                plotLines: [{
                                    color: reportColors.comparison.index,
                                    value: 1,
                                    width: 2,
                                }],
                                showLastLabel: true,
                            },
                        ]
                    });
                    break;
            }
        }
            break;

        case (chartId.match(highLevelRfmCompareCountPattern) || {}).input:
        case (chartId.match(highLevelRfmCompareIndexPattern) || {}).input: {
            let key, property, tooltipFormat, yAxis;
            let isIndex = false;
            if (chartId.match(highLevelRfmCompareCountPattern)) {
                [, key] = chartId.match(highLevelRfmCompareCountPattern);
                property = key;
                tooltipFormat = tooltipFormatter('percent', true);
                yAxis = chartAxisOptions.barGroupPercent;
            } else {
                isIndex = true;
                [, key] = chartId.match(highLevelRfmCompareIndexPattern);
                property = `${key}|index`;
                tooltipFormat = tooltipFormatter('flex', true);
                yAxis = chartAxisOptions.relativeIndex;
            }

            let {series, xAxis, titleText} = chartDataToSeries(
                'highLevelRfmComparison',
                {
                    fieldDictionary,
                    insights,
                },
                {
                    property
                }
            );

            chartData = {
                chart: {
                    type: 'column',
                },
                // plotOptions: chartPlotOptions.bar,
                xAxis,
                yAxis,
                series,
                tooltip: {
                    // formatter: tooltipFormat,
                    formatter: tooltipFormat,
                    shared: true,
                },
            };

            chartData = Highcharts.merge(chartData, {
                title: {text: titleText},
            });
        }
            break;

        case (chartId.match(pastPurchaseCompareCountPattern) || {}).input:
        case (chartId.match(pastPurchaseCompareIndexPattern) || {}).input: {
            let key, property, tooltipFormat, yAxis;
            let isIndex = false;
            if (chartId.match(pastPurchaseCompareCountPattern)) {
                [, key] = chartId.match(pastPurchaseCompareCountPattern);
                property = key;
                tooltipFormat = tooltipFormatter('percent', true);
                yAxis = chartAxisOptions.barGroupPercent;
            } else {
                isIndex = true;
                [, key] = chartId.match(pastPurchaseCompareIndexPattern);
                property = `${key}|index`;
                tooltipFormat = tooltipFormatter('flex', true);
                yAxis = chartAxisOptions.relativeIndex;
            }

            let {series, xAxis, titleText} = chartDataToSeries(
                'pastPurchaseComparison',
                {
                    fieldDictionary,
                    insights,
                },
                {
                    property
                }
            );

            chartData = {
                chart: {
                    type: 'column',
                },
                // plotOptions: chartPlotOptions.bar,
                xAxis,
                yAxis,
                series,
                tooltip: {
                    // formatter: tooltipFormat,
                    formatter: tooltipFormat,
                    shared: true,
                },
            };

            chartData = Highcharts.merge(chartData, {
                title: {text: titleText},
            });
        }
            break;

        case (chartId.match(pastPurchaseRollupPattern) || {}).input: {
            // Past purchases charts
            const categoryDefinition = fieldDictionary.standard[chartId];

            let chartSourceData: any[] = [];
            categoryDefinition.children.forEach(dictionaryId => {
                const dictionaryItem = fieldDictionary.standard[dictionaryId] || null;
                const data = insights.offline_standard?.demographics[dictionaryId] || null;
                if (!dictionaryItem || !data) {
                    return;
                }

                chartSourceData.push(Object.assign({}, data[0], {
                    displayName: dictionaryItem.shortDescription,
                    position: dictionaryItem.position,
                }));
            })
            chartSourceData = Utils.sortByProperty(chartSourceData, 'groupRatio', 'desc');
            if (!chartSourceData) {
                return false;
            }

            chartData = {
                chart: {
                    type: 'bar',
                },
                plotOptions: chartPlotOptions.bar,
                xAxis: {
                    categories: chartSourceData.map(item => item.displayName),
                },
                yAxis: [
                    {
                        min: 0,
                        title: false,
                        labels: {
                            format: '{value}%',
                            style: {
                                color: reportColors.barIndex.groupRatioLabel,
                            },
                        },
                        margin: 0,
                    },
                    {
                        title: false,
                        labels: {
                            style: {
                                color: reportColors.barIndex.indexLabel,
                            },
                        },
                        margin: 0,
                    }
                ],
                series: chartDataToSeries('barIndex', chartSourceData),
                tooltip: {
                    formatter: tooltipFormatter('percent'),
                    shared: true,
                },
            };

        }
            break;

        case (chartId.match(socialEngagementPattern) || {}).input:
        case (chartId.match(userDefinedSocialEngagementPattern) || {}).input: {
            const sortContext = sortGroup === 'index' ? 'topByIndex' : 'topByCount';
            let userDefined = false,
                platform,
                topicName = null;
            if (chartId.match(userDefinedSocialEngagementPattern)) {
                userDefined = true;
                [, platform, topicName] = chartId.match(userDefinedSocialEngagementPattern);
            } else {
                [, platform] = chartId.match(socialEngagementPattern);
            }
            // const icon = ['brands', platform];

            // const chartTitle = `Persona Social Engagement via ${Utils.titleCase(platform)}`;
            const chartTitle = `Persona Social Engagement`;
            if (!userDefined && !insights.social?.customTopics.groupDefinitionTopic.hasOwnProperty(platform)) {
                return false;
            }
            const rootContext = userDefined ?
                insights.social?.customTopics.userDefinedTopics.find(topic => Utils.slug(topic.name) === topicName)[platform][sortContext] :
                insights.social?.customTopics.groupDefinitionTopic[platform][sortContext];

            chartSourceData = Utils.sortByProperty(
                rootContext,
                [sortGroup, 'groupCount'],
                'desc'
            );
            if (!chartSourceData) {
                return false;
            }
            // if (!returnData) {
            //     return {
            //         icon
            //     }
            // }

            // Chart sizing-adjust as needed
            const chartHeight = `${(barSize * chartSourceData.length * 2) + headerSize /*+ Math.min(0, (headerSize * 2)-chartSourceData.length)*/}`;
            const series = [
                {
                    yAxis: 0,
                    name: indexLabel,
                    type: 'bar',
                    color: reportColors.barIndex.index,
                    data: chartSourceData.map(item => item.index),
                },
                {
                    yAxis: 1,
                    name: 'Follower Count',
                    type: 'bar',
                    formatter: value => {
                        return `${value.toFixed(2)}%`;
                    },
                    color: reportColors.barIndex.groupRatio,
                    data: chartSourceData.map(item => item.groupRatio),
                },
                {
                    yAxis: 2,
                    name: 'Tweet Count',
                    type: 'bar',
                    formatter: value => {
                        return value.toLocaleString();
                    },
                    showInTooltip: hasTweets(rootContext),
                    color: reportColors.barIndex.groupCount,
                    data: chartSourceData.map(item => item.tweetCount || 0),
                }
            ];

            chartData = {
                // icon,
                id: chartId,
                chart: {
                    type: 'bar',
                    height: chartHeight,
                },
                legend: false,
                title: {
                    text: chartTitle,
                },
                plotOptions: Highcharts.merge(chartPlotOptions.barGroup, {
                    plotWidth: barSize,
                    series: {
                        groupPadding: 0.1,
                    },
                }),
                xAxis: {
                    categories: chartSourceData,
                    labels: {
                        useHTML: true,
                        formatter: (category) => {
                            const account = category.value,
                                accountName = socialActionLabel(account),
                                indicator = socialActionIcon(account, {
                                    class: 'ms-2',
                                    style: `width: ${barSize}px; height: ${barSize}px;`
                                })
                            ;

                            return `
                                <div class="d-flex align-items-center">
                                    <div class="flex-shrink text-end social-action-label">${accountName}</div>
                                    ${indicator}
                                </div>
                            `.trim();
                        },
                    },
                    gridLineWidth: 0,
                    tickColor: '#cccccc',
                    tickWidth: 1,
                },
                yAxis: [
                    {
                        min: 0,
                        // margin: 0,
                        title: {
                            style: {
                                color: reportColors.barIndex.index,
                            },
                            text: indexLabel,
                        },
                        labels: {
                            style: {
                                color: reportColors.barIndex.indexLabel,
                            },
                        },
                        opposite: true,
                        visible: sortGroup === 'index',
                    },
                    {
                        min: 0,
                        title: {
                            style: {
                                color: reportColors.barIndex.groupRatio,
                            },
                            text: 'Follower Count',
                        },
                        labels: {
                            format: '{value}%',
                            style: {
                                color: reportColors.barIndex.groupRatioLabel,
                            },
                        },
                        opposite: true,
                        visible: sortGroup === 'groupRatio',
                    },
                    {
                        min: 0,
                        title: {
                            style: {
                                color: reportColors.barIndex.groupRatio,
                            },
                            text: 'Tweet Count',
                        },
                        labels: {
                            // format: '{value.localeFormat()}',
                            style: {
                                color: reportColors.barIndex.groupCountLabel,
                            },
                        },
                        opposite: true,
                        visible: sortGroup === 'tweetCount',
                    },
                ],
                series,
                tooltip: {
                    formatter: tooltipFormatter('affinity'),
                },
            };
        }
            break;

        case (chartId.match(summaryRollupSocialPattern) || {}).input:
        case (chartId.match(summarySocialPattern) || {}).input: {
            // Brands & Interests summary / rollup charts (Twitter)
            let context, platform, dataProperty, propertyLabel;
            if (chartId.match(summaryRollupSocialPattern)) {
                [, context, platform] = chartId.match(summaryRollupSocialPattern);
                dataProperty = 'topicRollupsSummary';
                propertyLabel = 'Category';
            } else {
                [, context, platform] = chartId.match(summarySocialPattern);
                dataProperty = 'topicsSummary';
                propertyLabel = 'Subcategory';
            }
            if (!insights.social.hasOwnProperty(context)
                || !insights.social[context].hasOwnProperty(dataProperty)
                || !insights.social[context][dataProperty].hasOwnProperty(platform)
            ) {
                return false;
            }

            const contextLabel = Utils.titleCase(context),
                platformLabel = Utils.titleCase(platform);

            // const icon = ['brands', platform];
            // if (!returnData) {
            //     return {
            //         icon
            //     }
            // }

            chartSourceData = Utils
                .sortByProperty(
                    insights.social[context][dataProperty][platform],
                    sortGroup,
                    'desc'
                );
            if (!chartSourceData) {
                return false;
            }

            const series = [
                {
                    yAxis: 2,
                    name: indexLabel,
                    type: 'bar',
                    color: reportColors.indexBreadthDepthSummary.index,
                    data: chartSourceData.map(item => item.index),
                },
                {
                    yAxis: 1,
                    name: groupRatioLabel,
                    type: 'bar',
                    formatter: value => {
                        return `${value.toFixed(2)}%`;
                    },
                    color: reportColors.indexBreadthDepthSummary.breadth,
                    data: chartSourceData.map(item => item.groupRatio)
                },
                {
                    yAxis: 0,
                    name: 'Depth of Interest',
                    type: 'bar',
                    color: reportColors.indexBreadthDepthSummary.depth,
                    data: chartSourceData.map(item => item.groupPenetrationRatio)
                },
                {
                    // Add space in the chart series for account display, and add account data
                    topAccounts: chartSourceData.map(item => {
                        return {
                            name: item.name,
                            accounts: item[sortGroup === 'index' ? 'topByIndex' : 'topByCount']
                        };
                    }),

                    name: 'Top Accounts',
                    type: 'bar',
                    showInTooltip: false,
                    showInLegend: false,
                    data: chartSourceData.map(() => 0),
                }
            ];

            chartData = {
                // icon,
                id: chartId,
                chart: {
                    events: {
                        load: chart => {
                            chartOverlayCallback(chart.target, 'topAccounts');
                        },
                        redraw: chart => {
                            chartOverlayCallback(chart.target, 'topAccounts');
                        },
                    },
                    type: 'bar',
                    height: '320%',
                    marginLeft: 120,
                },
                legend: false,
                title: {
                    // text: `${contextLabel} by ${propertyLabel} w/ top 3 ${contextLabel} via ${platformLabel}`,
                    text: `${contextLabel} by ${propertyLabel} w/ top 3 ${contextLabel}`,
                },
                plotOptions: chartPlotOptions.barGroup,
                xAxis: {
                    categories: chartSourceData.map(item => {
                        // const [category, subcategory] = Utils.humanReadableCategoryName(item.name).split('-');
                        return `<strong>${item.category}` + (item.subCategory ? `:</strong><br>${item.subCategory}` : '</strong>');
                    }),
                    gridLineColor: '#000000',
                    gridLineWidth: 1,
                    tickColor: '#cccccc',
                    tickWidth: 1,
                },
                yAxis: [
                    {
                        min: 0,
                        margin: 0,
                        title: {
                            style: {
                                color: reportColors.indexBreadthDepthSummary.depthLabel,
                            },
                            text: 'Depth of Interest',
                        },
                        labels: {
                            style: {
                                color: reportColors.indexBreadthDepthSummary.depthLabel,
                            },
                        },
                        opposite: true,
                        visible: sortGroup === 'groupPenetrationRatio',
                    },
                    {
                        min: 0,
                        margin: 0,
                        title: {
                            style: {
                                color: reportColors.indexBreadthDepthSummary.breadthLabel,
                            },
                            text: groupRatioLabel,
                        },
                        labels: {
                            format: '{value}%',
                            style: {
                                color: reportColors.indexBreadthDepthSummary.breadthLabel,
                            },
                        },
                        opposite: true,
                        visible: sortGroup === 'groupRatio',
                    },
                    {
                        margin: 0,
                        title: {
                            style: {
                                color: reportColors.indexBreadthDepthSummary.indexLabel,
                            },
                            text: indexLabel,
                        },
                        labels: {
                            style: {
                                color: reportColors.indexBreadthDepthSummary.indexLabel,
                            },
                        },
                        opposite: true,
                        visible: sortGroup === 'index',
                    },
                ],
                series,
                tooltip: {
                    formatter: tooltipFormatter('flex', false),
                },
            };
        }
            break;

        case (chartId.match(affinitySocialPattern) || {}).input:
        case (chartId.match(topSocialPattern) || {}).input: {
            // Affinity chart, e.g. Top Brands/Interests via Twitter
            const context = params.tab,
                contextLabel = Utils.titleCase(context);
            const sortContext = sortGroup === 'index' ? 'topByIndex' : 'topByCount';
            let affinity,
                platform;
            if (chartId.match(affinitySocialPattern)) {
                [, affinity, platform] = chartId.match(affinitySocialPattern);
            } else {
                [, platform] = chartId.match(topSocialPattern);
            }

            // const icon = ['brands', platform];
            // if (!returnData) {
            //     return {
            //         icon
            //     }
            // }

            // const platformLabel = Utils.titleCase(platform);
            let rootContext: any;
            if (affinity) {
                // Ensure that the expected data is available - sometimes routing causes gaps in coverage that must be handled in the next cache pass
                const contextParent = insights.social[context]?.defaultTopics[platform]?.find(topic => Utils.slug(topic.name) === affinity || Utils.slug(topic.category) === affinity);
                if (!contextParent) {
                    return false;
                }
                rootContext = contextParent[sortContext];
            } else {
                rootContext = insights.social[context][sortContext][platform];
            }
            // const rootContext = affinity ?
            //     insights.social[context].defaultTopics[platform].find(topic => Utils.slug(topic.name) === affinity || Utils.slug(topic.category) === affinity)[sortContext] :
            //     insights.social[context][sortContext][platform];
            if (!size(rootContext)) {
                return false;
            }
            // const showImages = affinity || filterSettings.count === 100;
            chartSourceData = Utils.sortByProperty(
                rootContext,
                sortGroup,
                'desc'
            );
            if (!chartSourceData) {
                return false;
            }

            if (filterSettings.count) {
                chartSourceData = chartSourceData.slice(0, filterSettings.count);
            }

            // Chart sizing-adjust as needed
            let barSizeLocal = barSize;
            if (!affinity) {
                barSizeLocal = barSize * (filterSettings.count === 100 ? 1 : 0.5);
            }
            const chartHeight = `${(barSizeLocal * chartSourceData.length * 2) + headerSize /*+ Math.min(0, (headerSize * 2)-chartSourceData.length)*/}`;
            const series = [
                {
                    yAxis: 0,
                    name: indexLabel,
                    type: 'bar',
                    color: reportColors.barIndex.index,
                    data: chartSourceData.map(item => item.index),
                },
                {
                    yAxis: 1,
                    name: 'Follower Count',
                    type: 'bar',
                    color: reportColors.barIndex.groupRatio,
                    data: chartSourceData.map(item => item.groupRatio),
                }
            ];

            chartData = {
                // icon,
                id: chartId,
                chart: {
                    type: 'bar',
                    height: chartHeight,
                },
                legend: false,
                title: {
                    // text: `${contextLabel} Engaged via ${platformLabel}`,
                    text: `${contextLabel} Engaged`,
                },
                plotOptions: Highcharts.merge({}, chartPlotOptions.barGroup, {
                    plotWidth: barSize,
                    series: {
                        groupPadding: 0.1,
                    },
                }),
                xAxis: {
                    categories: chartSourceData,
                    labels: {
                        useHTML: true,
                        formatter: (category) => {
                            const account = category.value;

                            // Account image & link
                            let accountName = account.displayName;
                                // let accountImage = showImages && account.hasOwnProperty('imageUrl') ?
                                //     `<img src="${account.imageUrl}" width="${barSize}" height="${barSize}" class="ms-2 fallback-image-twitter" onerror="${Utils.imageFallback('twitter')}"/>` :
                                //     '';
                            const itemUrl = socialActionUrl(account);
                            if (itemUrl) {
                                accountName = `<a :title="${account.displayName}" v-tooltip target="_new" href="${itemUrl}">${account.displayName}</a>`;
                                // accountImage = accountImage.length ?
                                //     `<a title="${account.displayName}" target="_new" href="${itemUrl}">${accountImage}</a>` :
                                //     accountImage;
                            }

                            return `<div class="d-flex align-items-center">
                                        <div class="flex-shrink text-end" style="">${affinity ? 'Follows ' : ''}${accountName}</div>
                                    </div>`;
                        },
                    },
                    gridLineWidth: 0,
                    tickColor: '#cccccc',
                    tickWidth: 1,
                },
                yAxis: [
                    {
                        min: 0,
                        margin: 0,
                        title: {
                            style: {
                                color: reportColors.barIndex.index,
                            },
                            text: indexLabel,
                        },
                        labels: {
                            style: {
                                color: reportColors.barIndex.indexLabel,
                            },
                        },
                        opposite: true,
                        visible: sortGroup === 'index',
                    },
                    {
                        min: 0,
                        margin: 0,
                        title: {
                            style: {
                                color: reportColors.barIndex.groupRatio,
                            },
                            text: 'Follower Count',
                        },
                        labels: {
                            format: '{value}%',
                            style: {
                                color: reportColors.barIndex.groupRatioLabel,
                            },
                        },
                        opposite: true,
                        visible: sortGroup === 'groupRatio',
                    },
                ],
                series,
                tooltip: {
                    formatter: tooltipFormatter('affinity'),
                },
            };
        }
            break;

        case (chartId.match(socialTopContentPattern) || {}).input: {
            // Top hashtags, mentions, etc.
            const [, contentType] = chartId.match(socialTopContentPattern);
            if (!insights.social?.topContent.hasOwnProperty(contentType) || !insights.social?.topContent[contentType].length) {
                return false;
            }

            // const contentTypeLabel = Utils.titleCase(contentType);
            chartSourceData = Utils.sortByProperty(
                insights.social?.topContent[contentType],
                'index',
                'desc'
            );
            if (!chartSourceData) {
                return false;
            }
            let sidebarItems = chartSourceData.map((item, i) => {
                return {
                    label: item.displayName,
                    index: i + 1,
                    indexValue: item.index.toFixed(2),
                    countValue: item.tweetCount,
                }
            });

            const series = [
                {
                    yAxis: 0,
                    name: indexLabel,
                    type: 'bar',
                    color: reportColors.barIndex.index,
                    data: chartSourceData.map(item => item.index),
                },
                {
                    yAxis: 1,
                    name: 'Total Post Count',
                    type: 'bar',
                    formatter: value => value,
                    color: reportColors.barIndex.groupCount,
                    data: chartSourceData.map(item => item.tweetCount)
                },
            ];

            chartData = {
                sidebarItems,

                id: chartId,
                chart: {
                    type: 'bar',
                },
                legend: false,
                title: false,
                plotOptions: chartPlotOptions.bar,
                xAxis: {
                    categories: chartSourceData.map(item => item.displayName),
                },
                yAxis: [
                    {
                        title: false,
                        labels: {
                            style: {
                                color: reportColors.barIndex.indexLabel,
                            },
                        },
                        margin: 0,
                    },
                    {
                        title: false,
                        labels: {
                            style: {
                                color: reportColors.barIndex.groupCountLabel,
                            },
                        },
                        margin: 0,
                    },
                ],
                series,
                tooltip: {
                    formatter: tooltipFormatter('flex', false),
                },
                // gridLineWidth: 0,
                // tickColor: '#cccccc',
                // tickWidth: 1,
            };
        }
    }

    return prepareChartDefaults(chartData, options);
}

export const prepareChartDefaults = (chartData: any, options: any) => {
    const {
        // asyncData,
        chartId,
        fieldDictionary,
        // filterGroup,
        // filterSettings,
        Highcharts,
        // insights,
        // params,
        printMode,
        // returnData,
        // sortGroup,
    } = options;

    // if (chartData.hasOwnProperty('tooltip')) {
    //     chartData.tooltip = Highcharts.merge(defaultChartOptions.tooltip, chartData.tooltip);
    // }
    chartData.id = chartId;
    if (!chartData.hasOwnProperty('chart') && !chartData.hasOwnProperty('series')) {
        console.error(`🔴 No chart data available: ${chartId}`);
        return false;
    }

    // Ensure tooltips don't get stuck!
    if (chartData.series?.length) {
        for (const serie of chartData.series) {
            serie.stickyTracking = false;
        }
    }

    // Automatically apply a chart title using the field diectionary, if none was provided
    if (!chartData.hasOwnProperty('title')) {
        let title: string;
        for (const source of Object.keys(fieldDictionary)) {
            if (chartId.indexOf('|')) {
                const [key] = chartId.split('|');
                title = fieldDictionary[source][key]?.shortDescription;
                // break;
            } else {
                title = fieldDictionary[source][chartId]?.shortDescription;
                // break;
            }

            if ((title?.length || 0) > 0) {
                break;
            }
        }

        chartData.title = {text: title || `NO TITLE FOUND FOR ${chartId}`};
    }

    if (printMode) {
        chartData = Highcharts.merge(
            {
                chart: {
                    animation: false,
                },
                plotOptions: {
                    series: {
                        animation: false,
                    }
                },
            },
            chartData
        )
    }

    // console.debug(`[prepareChartDefaults] ${chartId}`, Highcharts.merge(defaultChartOptions, chartData));

    return Highcharts.merge(defaultChartOptions, chartData);
}

export const socialActionLabel = (account: any) => {
    let accountName = '',
        accountUrl = socialActionUrl(account);
    switch (account.dataType) {
        case 'twitterFollow':
            accountName = `Follows <a :title="${account.displayName}" v-tooltip target="_new" href="${accountUrl}">${account.displayName}</a>`;
            break;
        case 'twitterHashtag':
            accountName = `Used ${account.item}`;
            break;
        case 'twitterMention':
            accountName = `Mentioned <a :title="${account.displayName}" v-tooltip target="_new" href="${accountUrl}">${account.displayName}</a>`;
            break;
        case 'twitterTerm':
            accountName = `Tweeted ${account.item}`;
            break;
        default:
            console.error(`Unknown social label data type for "${account.displayName || account.item}" -  ${account.dataType}`, account);
            break;
    }

    return accountName;
}

export const socialActionIcon = (account: any, params: any = {}) => {
    let iconParameters: any = {
        context: null,
        icon: null,
        useImage: false,
    };

    switch (account.dataType) {
        case 'twitterFollow':
            iconParameters.context = 'twitter';
            iconParameters.icon = 'fad fa-users';
            // iconParameters.useImage = true;
            break;

        case 'twitterHashtag':
            iconParameters.context = 'twitter';
            iconParameters.icon = 'fas fa-hashtag';
            // iconParameters.useImage = false;
            break;

        case 'twitterMention':
            iconParameters.context = 'twitter';
            iconParameters.icon = 'fas fa-at';
            // iconParameters.useImage = false;
            break;

        case 'twitterTerm':
            iconParameters.context = 'twitter';
            iconParameters.icon = 'fas fa-quote-left';
            // iconParameters.useImage = false;
            break;

        default:
            console.debug(`Unknown social icon data type for "${account.displayName || account.item}" - ${account.dataType}`, account);
    }

    // return `<div class="social-action-indicator ${Utils.kebabCase(account.dataType)} ${params?.class || ''}"
    //           style="${params?.style || ''}"
    //         >
    //         <i class="${iconParameters.icon} fa-fw"></i>
    //     </div>`;

    return iconParameters.useImage ?
        `<div class="${params?.class || ''}" style="${params?.style || ''}">
            <img alt="${account.displayName || account.item}"
                 class="img-fluid fallback-image-${iconParameters.context}"
                 onerror="${Utils.imageFallback(iconParameters.context)}"
                 src="${account.imageUrl}"
            />
        </div>` :
        `<div class="social-action-indicator ${Utils.kebabCase(account.dataType)} ${params?.class || ''}"
              style="${params?.style || ''}"
            >
            <i class="${iconParameters.icon} fa-fw"></i>
        </div>`;
}

export const socialActionUrl = (account: any) => {
    const accountName = account.item.replace('@', '');

    // Currently only twitter items are linked
    return ['twitterFollow', 'twitterMention'].includes(account.dataType) ?
        `https://twitter.com/${accountName}` :
        null;
}

export const tooltipFormatter = (type?: string, includeHeader?: boolean | string): Highcharts.TooltipFormatterCallbackFunction => {
    let headerCallback = (typeof includeHeader === 'boolean' && !includeHeader) ?
        () => {
            return '';
        } :
        label => {
            return label;
        };
    if (typeof includeHeader === 'string') {
        headerCallback = obj => {
            if (obj.hasOwnProperty(includeHeader)) {
                // Use property
                return obj[includeHeader];
            }

            // Use header string as-is
            return includeHeader;
        }
    }

    switch (type) {
        case 'affinity':
            return function () {
                const account: any = this.x;
                let str = '';

                this.points!.forEach((point) => {
                    const value = point.y;
                    let verbPresentTense = '',
                        verbPastTense = ''
                    // @ts-ignore
                    switch (point.x.dataType) {
                        case 'twitterFollow':
                            verbPresentTense = 'follow';
                            verbPastTense = 'followed';
                            break;
                        case 'twitterHashtag':
                            verbPresentTense = 'use';
                            verbPastTense = 'used';
                            break;
                        case 'twitterMention':
                            verbPresentTense = 'mention';
                            verbPastTense = 'mentioned';
                            break;
                        case 'twitterTerm':
                            verbPresentTense = 'tweet';
                            verbPastTense = 'tweeted';
                            break;
                    }

                    switch (point.series.userOptions.yAxis) {
                        case 0: // Relative index
                            str += `<span style="color: ${point.color}">\u25CF</span>
                                    People in this Persona are <strong style="color: ${point.color}">${Utils.formatValue(value, 'indexLikelihood', 1)}</strong> to ${verbPresentTense} <strong>${account.displayName}</strong><br>
                                    <span style="opacity: 0">\u25CF</span>
                                    <em style="font-size: 90%">as the PersonaBuilder baseline</em><br>`;
                            break;
                        case 1: // Follow count
                            str += `<span style="color: ${point.color}">\u25CF</span>
                                    <strong style="color: ${point.color}">${value.toFixed(2)}%</strong> of people in this Persona ${verbPastTense} <strong>${account.displayName}</strong><br>`;
                            break;
                        case 2: // Tweet count
                            if (value) {
                                str += `<span style="color: ${point.color}">\u25CF</span>
                                        Together, they ${verbPastTense} it <strong style="color: ${point.color}">${Utils.formatValue(value, 'separated', 0)}</strong> times in total<br>`;
                            }
                            break;
                    }
                });

                return str;
            };

        case 'demographicSummary':
            return function () {
                const indexValue = this.point.series['linkedSeries'][0].data[this.point.index].y;

                return `
                    <strong>${this.series.userOptions.name}</strong><br>
                    <span style="color: ${this.point.color}">\u25CF</span> ${this.key}: ${Utils.formatValue(this.y, 'percent')}<br>
                    ${Utils.formatValue(indexValue, 'indexLikelihood')}
                `;
            };

        case 'flex':
            // A flexible tooltip formatter driven by properties included in the series
            return function () {
                let str = '';

                (this.points || [this.point]).forEach((point) => {
                    if (point.series.userOptions['showInTooltip'] === false) {
                        return;
                    }

                    let formattedValue;
                    if (point.series.userOptions.hasOwnProperty('formatter')) {
                        // @ts-ignore
                        formattedValue = point.series.userOptions.formatter(point.y);
                    } else {
                        formattedValue = point.y.toFixed(2);
                    }
                    str += `<span style="color: ${point.color}">\u25CF</span> ${point.series.name}: <strong>${formattedValue}</strong><br>`;
                });

                if (includeHeader) {
                    str = `${headerCallback(this.x || this.point)}<br>${str}`;
                }

                return str;
            };

        case 'map':
            return function () {
                let str = `<strong>${this.point.options.name}</strong>`;
                if (this.point.hasOwnProperty('relativeIndex') || this.point.hasOwnProperty('value')) {
                    str = `
                        ${str}<br>
                        ${indexLabel}: ${(this.point.relativeIndex || this.point.value).toFixed(2)}x
                    `;
                }
                if (this.point.hasOwnProperty('groupRatio')) {
                    str = `
                        ${str}<br>
                        ${groupRatioLabel}: ${this.point.groupRatio.toFixed(2)}%
                    `
                }

                if (includeHeader) {
                    str = `${headerCallback(this.point)}<br>${str}`;
                }

                return str;
            };

        case 'percent':
            return function () {
                let header = this.x;
                let str: string = '';
                let formattedValue: string = '';

                if (this.hasOwnProperty('points')) {
                    this.points!.forEach((point) => {
                        if (point.series.userOptions['showInTooltip'] === false) {
                            return;
                        }

                        formattedValue = point.y?.toFixed(2) || '0';
                        if (point.series.name.indexOf('Index') === -1) {
                            formattedValue = `${formattedValue}%`;
                        }
                        str += `<span style="color: ${point.color}">\u25CF</span> ${point.series.name}: <strong>${formattedValue}</strong><br>`;
                    });
                } else if (this.hasOwnProperty('point')) {
                    header = headerCallback(this.point);
                    formattedValue = this.point.value?.toFixed(2) || '0';
                    if (this.point.series.name.indexOf('Index') === -1) {
                        formattedValue = `${formattedValue}%`;
                    }
                    str += `<span style="color: ${this.point.color}">\u25CF</span> ${this.point.name}: <strong>${formattedValue}</strong><br>`;
                }

                if (includeHeader) {
                    str = `<strong>${header}</strong><br>${str}`
                }

                return str;
            };

        case 'piePercent':
            return function () {
                return `<span style="color: ${this.color}">\u25CF</span> ${this.series.name}: <strong>${this.y.toFixed(2)}% ${this.key} </strong>`;
            };

        default:
            return function () {
                return `<span style="color: ${this.points![0].color}">\u25CF</span> ${this.x}: <strong>${this.y.toFixed(2)}</strong>`;
            };
    }
}

export const dataSourceItemToolTipFormatter = (items: any[]) => {
    let tooltip: string = '';

    if (!isEmptyArray(items)) {
        tooltip = `${items.join(', ')}, among others`;
    } else {
        tooltip = `Engagement likelihood is a measure of persona engagement vs. the baseline.`;
    }

    return tooltip;
}

