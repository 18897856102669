<template>
    <main>
        <section class="d-print-none">
            <spinner v-if="!allImagesLoaded" text="Building..."/>
        </section>

        <section class="d-print-block print-area mb-n3" :class="{'d-none': !allImagesLoaded}">
            <article class="sheet cover-page">
                <img class="cover-image img-fluid" :src="imageSources.cover"/>
                <div class="cover-stripe"></div>
                <div class="cover-content h-100 w-100 d-flex flex-column justify-content-between">
                    <div class="cover-header ms-auto text-end">
                        <img class="img-fluid w-25" :src="imageSources.wilandIconBox"/>
                    </div>

                    <div class="flex-grow-1 d-flex flex-column justify-content-center">
                        <div class="cover-title">
                            <div>
                                <h1 class="mb-3">{{ chartData.comparison.name }}</h1>
                                <h2>A PersonaBuilder&trade; Comparison Report</h2>
                            </div>
                        </div>
                        <div class="w-75 p-3 ms-auto mt-5 h3 bg-info">
                            <div class="text-center">
                                <h3>{{ Utils.dateFormat(chartData.comparison.updated.timestamp, {format: 'date'}) }}</h3>
                            </div>
                        </div>
                    </div>

                    <img class="cover-footer img-fluid w-25 mx-auto"
                         :onerror="Utils.imageFallback(imageSources.wilandLogo)"
                         :src="logoPath"
                    />
                </div>
            </article>

            <!-- Executive Overview -->
            <article class="sheet content-page">
                <page-header :content="headerContent('Executive Overview')"/>

                <h1 id="executiveOverview">Executive Overview</h1>
                <p class="mt-3">
                    This Comparison report shows you the similarities and differences between two Persona reports. Throughout the report the blue and gold color scheme will help you quickly distinguish between them.
                    At the beginning of each major section you'll see the report header as shown below with the pictures and Persona names associated with the blue and yellow highlights.
                </p>

                <p>List of characteristics used to define each Persona:</p>

                <comparison-header :comparison="comparison" :mode="ComparisonMode.PRINT"/>
                <characteristics :mode="ComparisonMode.REPORT"/>

                <page-footer :content="footerContent()"/>
            </article>

            <article class="sheet cover-page section-cover">
                <img class="cover-image img-fluid" :src="imageSources.section"/>
                <div class="cover-stripe"></div>
                <div class="cover-content h-75 w-100 d-flex flex-column justify-content-end">
                    <div class="cover-title ms-auto">
                        <h1>Methodology</h1>
                    </div>
                </div>
            </article>

            <article class="sheet content-page">
                <page-header :content="headerContent('Methodology')"/>

                <p>Customer personas have long provided a way to understand and segment consumer cohorts. But marketers have struggled to make personas actionable when it comes to real-world media targeting.</p>

                <p>That's why Wiland PersonaBuilder&trade;&mdash;<strong>the world's most powerful persona creation tool</strong>&mdash;is also designed to produce ready-to-deliver audiences for activation in digital advertising campaigns. The intuitive PersonaBuilder application makes it easy to create robust, detailed prospect and customer personas based on relevant, continuously refreshed data&mdash;vast predicted spending signals, social media engagement data, and other information.
                </p>

                <blockquote>Bridge the gap between your strategy and media buying teams</blockquote>

                <p>PersonaBuilder enables you to create robust, detailed personas that can immediately be converted into audiences ready for activation in digital advertising campaigns. By closing the gap between your strategy and media buying teams, the insights you gain become actionable in the form of large audiences ready to activate across all platforms, channels, and devices.</p>

                <p>PersonaBuilder insights are driven by the power of GraphMassive&trade;,
                    <strong>the largest independent consumer graph</strong> of scored social data anonymously indexed to actionable offline information. GraphMassive is the indusry's only searchable consumer graph that maps tens of billions of consumer relationships and interests across social media and joins them to offline demographics, purchase transactions, spending intensities, and location. GraphMassive is built specifically for data-driven marketers seeking to understand who is behind social activity related to their brands or their competitor's brands.
                </p>

                <blockquote>How PersonaBuilder Works</blockquote>
                <img alt="PersonaBuilder&trade; methodology"
                     class="img-fluid"
                     :src="`${this.imageSourceRoot}/methodology.png`"
                />
                <p>Personas created using the application are analyzed to produce high-performance audiences that can be delivered to any leading platform or DSP for activation.</p>

                <p>This Comparison gives you a direct look at two different Personas, sometimes side by side, and sometimes on common charts. Sorts and callouts specific to your comparison have been included and allow you to look at every dimension of the two different Personas quickly and in depth.</p>

                <div class="clearfix"></div>

                <page-footer :content="footerContent()"/>
            </article>

            <!-- Summary -->
            <article class="sheet cover-page section-cover">
                <img class="cover-image img-fluid" :src="imageSources.section"/>
                <div class="cover-stripe"></div>
                <div class="cover-content h-75 w-100 d-flex flex-column justify-content-end">
                    <div class="cover-title ms-auto">
                        <h1>Summary</h1>
                    </div>
                </div>
            </article>

            <article class="sheet content-page">
                <page-header :content="headerContent('Summary')"/>

                <comparison-header :comparison="comparison" :mode="ComparisonMode.PRINT"/>
                <div class="row">
                    <div v-for="(summarySection, personaIndex) of chartData.summary" :key="personaIndex"
                         class="col-6"
                    >
                        <summary-section data-intent="persona-color" :data-index="personaIndex">
                            <template #icon>
                                <font-awesome-icon fixed-width :icon="['duotone', FeatureIcons.DEMOGRAPHICS]"/>
                            </template>
                            <template #title>{{ personaAtIndex(personaIndex).name }} Demographics</template>
                            <template #content>
                                <div class="row g-0 m-n3">
                                    <div v-for="(chart, index) of summarySection.demographics" :key="index"
                                         class="col-6"
                                         data-chart-container
                                    >
                                        <highcharts :options="chart"/>
                                    </div>
                                </div>
                            </template>
                        </summary-section>
                    </div>
                </div>

                <page-footer :content="footerContent()"/>
            </article>

            <article class="sheet content-page">
                <page-header :content="headerContent('Summary')"/>

                <p>Significant characteristics of these personas are captured in the Unique Facts chart, while the top brand engagement and personal interest categories are reflected in these social engagement summaries.</p>

                <div class="row">
                    <div v-for="(summarySection, personaIndex) of chartData.summary" :key="personaIndex"
                         class="col-6"
                    >
                        <summary-section data-intent="persona-color" :data-index="personaIndex">
                            <template #icon>
                                <img class="logo-icon"
                                     :src="iconPath['personaBuilder']"
                                     alt=""
                                     tabindex="-1"
                                >
                            </template>
                            <template #title>{{ personaAtIndex(personaIndex).name }} Unique Facts</template>
                            <template #content>
                                <wedge-chart-section :data="{color: sectionColors[personaIndex], items: summarySection.uniqueFacts}"/>
                            </template>
                        </summary-section>
                    </div>
                </div>

                <div class="row">
                    <div v-for="(summarySection, personaIndex) of chartData.summary" :key="personaIndex"
                         class="col-6"
                    >
                        <div class="row">
                            <div class="col-6">
                                <summary-section data-intent="persona-color" :data-index="personaIndex">
                                    <template #icon>
                                        <font-awesome-icon fixed-width :icon="['duotone', FeatureIcons.SOCIAL_AFFINITY]"/>
                                    </template>
                                    <template #title>{{ personaAtIndex(personaIndex).name }} Brand Summary</template>
                                    <template #content>
                                        <summary-widget
                                            v-show="itemContext['brand'] === 'twitter'"
                                            :summaryItems="summarySection.brandSummary.twitter"
                                            summaryType="twitter"
                                            tooltipPattern="[[ percent ]] of the group is interested"
                                        />
                                    </template>
                                </summary-section>
                            </div>
                            <div class="col-6">
                                <summary-section data-intent="persona-color" :data-index="personaIndex">
                                    <template #icon>
                                        <font-awesome-icon fixed-width :icon="['duotone', FeatureIcons.SOCIAL_AFFINITY]"/>
                                    </template>
                                    <template #title>{{ personaAtIndex(personaIndex).name }} Interest Summary</template>
                                    <template #content>
                                        <summary-widget
                                            v-show="itemContext['interest'] === 'twitter'"
                                            :summaryItems="summarySection.interestSummary.twitter"
                                            summaryType="twitter"
                                            tooltipPattern="[[ percent ]] of the group is interested"
                                        />
                                    </template>
                                </summary-section>
                            </div>
                        </div>
                    </div>
                </div>

                <page-footer :content="footerContent()"/>
            </article>

            <article class="sheet content-page">
                <page-header :content="headerContent('Summary')"/>

                <h2>Spending Behaviors</h2>
                <div v-if="allowFeature('conglomerateRfm')" class="row">
                    <div
                        v-for="(summarySection, personaIndex) of chartData.summary"
                        class="col-6"
                        :key="personaIndex"
                    >
                        <summary-section data-intent="persona-color" :data-index="personaIndex">
                            <template #icon>
                                <font-awesome-icon fixed-width :icon="['duotone', FeatureIcons.CONGLOMERATE_RFM_MARKETS]"/>
                            </template>
                            <template #title>{{ personaAtIndex(personaIndex).name }} Market Affinity, by Spend</template>
                            <template #content>
                                <highcharts
                                    :key="`market_affinity_summary`"
                                    :options="summarySection.conglomerateRfmMarket"
                                />
                            </template>
                        </summary-section>
                    </div>
                </div>
                <div v-else class="row">
                    <div
                        v-for="(summarySection, personaIndex) of chartData.summary"
                        class="col-6"
                        :key="personaIndex"
                    >
                        <summary-section data-intent="persona-color" :data-index="personaIndex">
                            <template #icon>
                                <font-awesome-icon fixed-width :icon="['duotone', FeatureIcons.PAST_PURCHASES]"/>
                            </template>
                            <template #title>{{ personaAtIndex(personaIndex).name }} Past Purchase Activity</template>
                            <template #content>
                                <highcharts
                                    v-for="(pastPurchaseChart, index) of summarySection.pastPurchaseActivity"
                                    :key="`past_purchase_${index}`"
                                    :options="pastPurchaseChart"
                                />
                            </template>
                        </summary-section>
                    </div>
                </div>

                <div v-if="hasPredictedSpend" class="row">
                    <div v-for="(summarySection, personaIndex) of chartData.summary" class="col-6" :key="personaIndex">
                        <summary-section data-intent="persona-color" :data-index="personaIndex">
                            <template #icon>
                                <font-awesome-icon fixed-width :icon="['duotone', FeatureIcons.PREDICTED_SPEND]"/>
                            </template>
                            <template #title>{{ personaAtIndex(personaIndex).name }} Predicted Spend</template>
                            <template #content>
                                <highcharts
                                    v-for="(predictedSpendChart, index) of summarySection.predictedSpendActivity"
                                    :key="`past_purchase_${index}`"
                                    :options="predictedSpendChart"
                                />
                            </template>
                        </summary-section>
                    </div>
                </div>

                <page-footer :content="footerContent()"/>
            </article>

            <article class="sheet content-page">
                <page-header :content="headerContent('Summary')"/>

                <h2>Consumer Topics</h2>
                <div class="row">
                    <div v-for="(summarySection, personaIndex) of chartData.summary" :key="personaIndex"
                         class="col-6"
                    >
                        <h4 class="persona-color" :data-index="personaIndex">
                            {{ personaAtIndex(personaIndex).name }}
                        </h4>
                        <div class="row">
                            <div v-for="topic of summarySection.socialTopics"
                                 class="col-6"
                                 :key="`${topic.section}_${personaIndex}`"
                            >
                                <summary-section data-intent="persona-color" :data-index="personaIndex">
                                    <template #icon>
                                        <font-awesome-icon fixed-width :icon="['duotone', FeatureIcons.SOCIAL_AFFINITY]"/>
                                    </template>
                                    <template #title>
                                        {{ topic.category || topic.name }}
                                    </template>
                                    <template #content>
                                        <persona-topic-widget
                                            :topic="topic"
                                            :context="itemContext[`topic-${topic.key}_${personaIndex}`]"
                                        />
                                    </template>
                                </summary-section>
                            </div>
                        </div>
                    </div>
                </div>

                <page-footer :content="footerContent()"/>
            </article>

            <article v-if="allowFeature('highLevelRfm')"
                 class="sheet content-page"
                 data-test="high-level rfm summary page"
            >
                <page-header :content="headerContent('Summary')"/>

                <h2>Recency, Frequency, and Monetary</h2>
                <div class="row">
                    <div v-for="(summarySection, personaIndex) of chartData.summary" class="col-6" :key="personaIndex">
                        <summary-section data-intent="persona-color" :data-index="personaIndex" :key="`highLevelRfm_summary_${personaIndex}`">
                            <template #icon>
                                <font-awesome-icon fixed-width :icon="['duotone', FeatureIcons.HIGH_LEVEL_RFM]"/>
                            </template>
                            <template #title>
                                {{ personaAtIndex(personaIndex).name }} RFM
                            </template>
                            <template #content>
                                <div class="row g-0 m-n3">
                                    <div data-chart-container>
                                        <highcharts :options="summarySection.highLevelRfm"/>
                                    </div>
                                </div>
                            </template>
                        </summary-section>
                    </div>
                </div>

                <page-footer :content="footerContent()"/>
            </article>

            <article class="sheet content-page">
                <page-header :content="headerContent('Summary')"/>

                <h2>Political Engagement and Affiliation</h2>
                <div class="row">
                    <div v-for="(summarySection, personaIndex) of chartData.summary" :key="personaIndex"
                         class="col-6"
                    >
                        <summary-section data-intent="persona-color" :data-index="personaIndex">
                            <template #icon>
                                <font-awesome-icon fixed-width :icon="['duotone', FeatureIcons.POLITICAL]"/>
                            </template>
                            <template #title>
                                {{ personaAtIndex(personaIndex).name }} Political Social Engagement
                            </template>
                            <template #content>
                                <div class="row">
                                    <summary-widget
                                        v-show="itemContext['politicalSocialEngagement'] === 'twitter'"
                                        class="col-6"
                                        :summaryItems="[summarySection.politicalSocialSummary.twitter[1]]"
                                        summaryType="twitter"
                                        tooltipPattern="[[ percent ]] of the group has engaged"
                                    />
                                    <summary-widget
                                        v-show="itemContext['politicalSocialEngagement'] === 'twitter'"
                                        class="col-6"
                                        :summaryItems="[summarySection.politicalSocialSummary.twitter[0]]"
                                        summaryType="twitter"
                                        tooltipPattern="[[ percent ]] of the group has engaged"
                                    />
                                </div>
                            </template>
                        </summary-section>

                        <summary-section data-intent="persona-color" :data-index="personaIndex">
                            <template #icon>
                                <font-awesome-icon fixed-width :icon="['duotone', FeatureIcons.POLITICAL]"/>
                            </template>
                            <template #title>
                                {{ personaAtIndex(personaIndex).name }} Political Party Affiliation
                            </template>
                            <template #content>
                                <highcharts :options="summarySection.politicalPartyAffiliation"/>
                            </template>
                        </summary-section>
                    </div>
                </div>

                <h2>Times of High and Low Social Media Activity</h2>
                <div class="row">
                    <div v-for="(summarySection, personaIndex) of chartData.summary" :key="personaIndex"
                         class="col-6"
                    >
                        <summary-section data-intent="persona-color" :data-index="personaIndex">
                            <template #icon>
                                <font-awesome-icon fixed-width :icon="['duotone', FeatureIcons.SOCIAL_AFFINITY]"/>
                            </template>
                            <template #title>{{ personaAtIndex(personaIndex).name }} Weekly Activity</template>
                            <template #content>
                                <highcharts :options="summarySection.socialWeeklyActivity"/>
                            </template>
                        </summary-section>
                    </div>
                </div>

                <page-footer :content="footerContent()"/>
            </article>

            <article class="sheet content-page">
                <page-header :content="headerContent('Summary')"/>

                <h2>Media Market Analysis</h2>
                <div class="row">
                    <div v-for="(summarySection, personaIndex) of chartData.summary" :key="personaIndex"
                         class="col-6"
                    >
                        <summary-section data-intent="persona-color" :data-index="personaIndex">
                            <template #icon>
                                <font-awesome-icon fixed-width :icon="['duotone', FeatureIcons.GEOGRAPHY]"/>
                            </template>
                            <template #title>{{ personaAtIndex(personaIndex).name }} Media Markets</template>
                            <template #content>
                                <div class="row">
                                    <div class="col-12">
                                        <highcharts
                                            constructor-type="mapChart"
                                            :options="summarySection.geography.chart"
                                            style="overflow: visible !important;"
                                        />
                                    </div>

                                    <div v-for="(detailSection, index) of summarySection.geography.detail" :key="index"
                                         class="col-6"
                                    >
                                        <div class="card bg-blue-10 border-0 p-3 mb-3 overflow-hidden">
                                            <u class="h5 text-primary mb-3 mx-auto">{{ detailSection.name }}</u>
                                            <div>
                                                <table class="text-primary w-100">
                                                    <tr v-for="item of detailSection.items.index.slice(0, 20)"
                                                        :key="`${item.label}_${personaIndex}`">
                                                        <td class="align-top">{{ item.index }}. {{ item.label }}</td>
                                                        <td class="align-top text-end text-nowrap">{{ item.value }}</td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </summary-section>
                    </div>
                </div>

                <page-footer :content="footerContent()"/>
            </article>

            <!-- Demographics -->
            <article class="sheet cover-page section-cover">
                <img class="cover-image img-fluid" :src="imageSources.section"/>
                <div class="cover-stripe"></div>
                <div class="cover-content h-75 w-100 d-flex flex-column justify-content-end">
                    <div class="cover-title ms-auto">
                        <h1>Demographic Details</h1>
                    </div>
                </div>
            </article>

            <article
                v-for="(chartPage, index) of paginatedCharts('demographics', [2, 4])"
                :key="index"
                class="sheet content-page">
                <page-header :content="headerContent('Demographics')"/>

                <template v-if="index === 0">
                    <p>Wiland's data and analytics use real consumer demographic data from the GraphMassive consumer graph to ensure the highest level of accuracy and usefulness. We accomplish this by deterministically joining all the data available for each consumer in GraphMassive in order to build comprehensive profiles of the characteristics exhibited by the consumers in each group segments.</p>

                    <comparison-header :comparison="comparison" :mode="ComparisonMode.PRINT"/>
                </template>

                <chart-grid
                    class="m-n3"
                    :charts="chartPage.charts"
                    :charts-associated-data="chartData.demographics.associatedData"
                />

                <page-footer :content="footerContent()"/>
            </article>

            <!-- Brands & Interests -->
            <article class="sheet cover-page section-cover">
                <img class="cover-image img-fluid" :src="imageSources.section"/>
                <div class="cover-stripe"></div>
                <div class="cover-content h-75 w-100 d-flex flex-column justify-content-end">
                    <div class="cover-title ms-auto">
                        <h1>Brand and Interest Engagement</h1>
                    </div>
                </div>
            </article>

            <article
                v-for="(chartPage, index) of paginatedCharts('brandInterestEngagement', 1)"
                :key="index"
                class="sheet content-page">
                <page-header :content="headerContent('Brand and Interest Engagement')"/>

                <template v-if="index === 0">
                    <h2>Top Categories Followed</h2>
                    <p>Index values are calculated using a baseline built from the active social accounts in the U.S. Brand and Interest handles are curated based on Wiland's proprietary taxonomy.</p>

                    <comparison-header :comparison="comparison" :mode="ComparisonMode.PRINT"/>
                </template>

                <chart-grid
                    chart-wrapper-class="col-12"
                    :charts="chartPage.charts"
                />

                <page-footer :content="footerContent()"/>
            </article>

            <!-- Topics / Social Characteristics -->
            <section
                v-if="chartData.hasOwnProperty('socialEngagement') || chartData.hasOwnProperty('userDefinedTopics')"
            >
                <article class="sheet cover-page section-cover">
                    <img class="cover-image img-fluid" :src="imageSources.section"/>
                    <div class="cover-stripe"></div>
                    <div class="cover-content h-75 w-100 d-flex flex-column justify-content-end">
                        <div class="cover-title ms-auto">
                            <h1>{{ sectionTitle('topics') }}</h1>
                        </div>
                    </div>
                </article>

                <article v-if="chartData.hasOwnProperty('socialEngagement')" class="sheet content-page">
                    <page-header :content="headerContent(sectionTitle('topics'))"/>

                    <h3>Analysis of the Social Characteristics used to define these Personas</h3>
                    <comparison-header :comparison="comparison" :mode="ComparisonMode.PRINT"/>

                    <chart-grid
                        :chart-wrapper-class="[chartData.socialEngagement.charts.length > 1 ? 'col-6 px-5' : 'col-12']"
                        :charts="chartData.socialEngagement.charts"
                    />
                    <p class="text-size-sm">This chart contains the top 20 social characteristics used to define the Personas. If more characteristics were selected they are available in the online report but have been dropped from this print version.</p>

                    <page-footer :content="footerContent()"/>
                </article>

                <section
                    v-if="chartData.hasOwnProperty('userDefinedTopics') && chartData.userDefinedTopics.charts.length > 0"
                >
                    <article v-for="(chartPage, index) of paginatedCharts('userDefinedTopics', 1)" class="sheet content-page">
                        <page-header :content="headerContent(sectionTitle('topics'))"/>

                        <h3 v-if="index === 0">Topics Found in Common</h3>
                        <h4>{{ chartPage.charts[0].topicName }}</h4>

                        <chart-grid
                            :chart-wrapper-class="[chartPage.charts > 1 ? 'col-6 px-5' : 'col-12']"
                            chart-wrapper-class-ignore="col-12"
                            :charts="chartPage.charts"
                        />
                        <p class="text-size-sm">This chart contains the top 20 characteristics in this custom topic. If this topic contained more than characteristics they are available in the online report but have been dropped from this print version.</p>

                        <page-footer :content="footerContent()"/>
                    </article>
                </section>
            </section>

            <!-- Market Affinity -->
            <section v-if="allowFeature('conglomerateRfm')">
                <article class="sheet cover-page section-cover" data-test="market affinity cover page">
                    <img class="cover-image img-fluid" :src="imageSources.section"/>
                    <div class="cover-stripe"></div>
                    <div class="cover-content h-75 w-100 d-flex flex-column justify-content-end">
                        <div class="cover-title ms-auto">
                            <h1>Market Affinity</h1>
                        </div>
                    </div>
                </article>

                <article class="sheet content-page" data-test="market affinity detail page">
                    <page-header :content="headerContent('Market Affinity')"/>

                    <p>Market Affinity data is based on actual historical credit card data sourced from 3rd party vendors. The data describes which individuals have had above-average credit card spending in a given market over the past 24 months. Groups with above-average historical spending in a market are likely to continue spending in that market moving forward.</p>

                    <chart-grid :charts="chartData.conglomerateRfmMarket.charts" chart-wrapper-class-IGNORE="col-10 offset-1"/>

                    <page-footer :content="footerContent()"/>
                </article>
            </section>

            <!-- Predicted Spend -->
            <section v-if="allowFeature('predictedSpend')">
                <article class="sheet cover-page section-cover">
                    <img class="cover-image img-fluid" :src="imageSources.section"/>
                    <div class="cover-stripe"></div>
                    <div class="cover-content h-75 w-100 d-flex flex-column justify-content-end">
                        <div class="cover-title ms-auto">
                            <h1>Predicted Spend</h1>
                        </div>
                    </div>
                </article>

                <article class="sheet content-page">
                    <page-header :content="headerContent('Predicted Spend')"/>

                    <h2>Predicted Spend: Top Categories</h2>
                    <p>Unique to the Wiland data universe, predicted spend is based on actual transaction data and modeled to find the most likely spending categories for these Personas.</p>

                    <comparison-header :comparison="comparison" :mode="ComparisonMode.PRINT"/>
                    <highcharts :options="chartData.predictedSpendSummary"/>

                    <page-footer :content="footerContent()"/>
                </article>
            </section>

            <!-- High-Level RFM -->
            <section v-if="allowFeature('highLevelRfm')">
                <article class="sheet cover-page section-cover" data-test="high-level rfm cover page">
                    <img class="cover-image img-fluid" :src="imageSources.section"/>
                    <div class="cover-stripe"></div>
                    <div class="cover-content h-75 w-100 d-flex flex-column justify-content-end">
                        <div class="cover-title ms-auto">
                            <h1>Recency, Frequency, and Monetary</h1>
                        </div>
                    </div>
                </article>

                <article v-for="(chartPage, index) of paginatedCharts('highLevelRfm')"
                     class="sheet content-page"
                     data-test="high-level rfm detail page"
                >
                    <page-header :content="headerContent('Recency, Frequency, and Monetary')"/>

                    <template v-if="index === 0">
                        <comparison-header :comparison="comparison" :mode="ComparisonMode.PRINT"/>
                    </template>

                    <chart-grid
                        :charts="chartPage.charts"
                        :charts-associated-data="chartData.highLevelRfm.associatedData"
                    />

                    <page-footer :content="footerContent()"/>
                </article>
            </section>

            <!-- Past Purchases -->
            <section v-if="!allowFeature('conglomerateRfm')">
                <article class="sheet cover-page section-cover">
                    <img class="cover-image img-fluid" :src="imageSources.section"/>
                    <div class="cover-stripe"></div>
                    <div class="cover-content h-75 w-100 d-flex flex-column justify-content-end">
                        <div class="cover-title ms-auto">
                            <h1>Past Purchases</h1>
                        </div>
                    </div>
                </article>

                <article v-for="(chartPage, index) of chartData.pastPurchases.pages" class="sheet content-page" :key="index">
                    <page-header :content="headerContent('Past Purchases')"/>

                    <template v-if="index === 0">
                        <p>The following graphs compare transaction level household purchases for the past 24 months, aggregated into product categories</p>

                        <comparison-header :comparison="comparison" :mode="ComparisonMode.PRINT"/>
                    </template>

                    <h3>{{ chartPage.title }}</h3>

                    <chart-grid
                        :charts="chartPage.charts"
                        :charts-associated-data="chartData.pastPurchases.associatedData"
                    />

                    <page-footer :content="footerContent()"/>
                </article>
            </section>

            <!-- Social Activity -->
            <article class="sheet cover-page section-cover">
                <img class="cover-image img-fluid" :src="imageSources.section"/>
                <div class="cover-stripe"></div>
                <div class="cover-content h-75 w-100 d-flex flex-column justify-content-end">
                    <div class="cover-title ms-auto">
                        <h1>Social Activity</h1>
                    </div>
                </div>
            </article>

            <article class="sheet content-page">
                <page-header :content="headerContent('Social Activity')"/>

                <h3>Engagement and Day Part Analysis</h3>
                <comparison-header :comparison="comparison" :mode="ComparisonMode.PRINT"/>
                <div class="row">
                    <div v-for="(socialActivitySection, personaIndex) of chartData.socialActivity" :key="personaIndex"
                         class="col-6"
                    >
                        <summary-section data-intent="persona-color" :data-index="personaIndex">
                            <template #icon>
                                <font-awesome-icon fixed-width :icon="['duotone', FeatureIcons.SOCIAL_AFFINITY]"/>
                            </template>
                            <template #title>{{ personaAtIndex(personaIndex).name }} Engagement</template>
                            <template #content>
                                <div class="bg-white m-n3">
                                    <div v-for="(chartElements, chartIndex) of socialActivitySection.engagement.charts"
                                         :data-chart-id="`${chartElements.chartId}:${personaIndex}`"
                                         :key="`${chartElements.chartId}:${personaIndex}`"
                                    >
                                        <div class="w-100">
                                            <div class="text-center">
                                                <highcharts :options="chartElements.data"/>
                                            </div>
                                        </div>

                                        <div class="d-flex align-items-end mt-3">
                                            <div class="additional-info p-3 m-3 w-100 bg-blue-10">
                                                <template v-if="socialActivitySection.engagement.associatedData[chartIndex] !== false">
                                                    <chart-associated-data :data="socialActivitySection.engagement.associatedData[chartIndex].data"/>
                                                </template>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </summary-section>
                    </div>
                </div>

                <page-footer :content="footerContent()"/>
            </article>

            <article class="sheet content-page">
                <page-header :content="headerContent('Social Activity')"/>

                <div class="row">
                    <div v-for="(socialActivitySection, personaIndex) of chartData.socialActivity" :key="personaIndex"
                         class="col-6"
                    >
                        <summary-section data-intent="persona-color" :data-index="personaIndex">
                            <template #icon>
                                <font-awesome-icon fixed-width :icon="['duotone', FeatureIcons.SOCIAL_AFFINITY]"/>
                            </template>
                            <template #title>{{ personaAtIndex(personaIndex).name }} Weekly Activity</template>
                            <template #content>
                                <div class="bg-white m-n3">
                                    <highcharts :options="socialActivitySection.weeklyActivity.chart"/>

                                    <div class="row row-eq-height">
                                        <div v-for="(detailSection, index) of socialActivitySection.weeklyActivity.detail"
                                            :key="index"
                                            class="col"
                                        >
                                            <div
                                                class="card bg-blue-10 border-0 p-3 mb-3">
                                                <u class="h5 text-primary mb-3 mx-auto text-nowrap" v-html="detailSection.name"></u>
                                                <div style="max-height: 17em; overflow: auto">
                                                    <table class="text-primary w-100">
                                                        <tr v-for="item of detailSection.items"
                                                            :key="item.index"
                                                        >
                                                            <td class="align-top">{{ item.index }}. {{ item.label }}</td>
                                                            <td class="align-top text-end text-nowrap" v-html="item.value"></td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </summary-section>
                    </div>
                </div>

                <page-footer :content="footerContent()"/>
            </article>

            <article
                v-if="displayTopSocialContent"
                v-for="(__ignored, pageIndex) of chartData.socialActivity[0].topContent"
                class="sheet content-page"
                :key="pageIndex"
            >
                <page-header :content="headerContent('Social Activity')"/>

                <template v-if="pageIndex === 0">
                    <h3>Top Content</h3>
                </template>

                <div class="row">
                    <div v-for="(__ignored_section, personaIndex) of chartData.socialActivity" :key="personaIndex"
                         class="col-6"
                    >
                        <summary-section data-intent="persona-color" :data-index="personaIndex">
                            <template #icon>
                                <font-awesome-icon fixed-width :icon="['duotone', FeatureIcons.SOCIAL_AFFINITY]"/>
                            </template>
                            <template #title>{{ personaAtIndex(personaIndex).name }} {{ contentSection('topContent', pageIndex, personaIndex).detail.contentTypeLabel }}</template>
                            <template #content>
                                <div class="bg-white">
                                    <highcharts :options="contentSection('topContent', pageIndex, personaIndex).chart"/>

                                    <div class="card bg-blue-10 border-0 p-3">
                                        <u class="h5 text-primary mb-3 mx-auto text-nowrap"
                                           v-html="`${contentSection('topContent', pageIndex, personaIndex).detail.contentTypeLabel} Used by Persona`"
                                        ></u>
                                        <table class="text-primary w-100">
                                            <thead>
                                            <tr>
                                                <th><u>{{ contentSection('topContent', pageIndex, personaIndex).detail.contentTypeLabel }}</u></th>
                                                <th class="text-center"><u>Index</u></th>
                                                <th class="text-center"><u>Total Post Count</u></th>
                                            </tr>
                                            </thead>

                                            <tbody>
                                            <tr v-for="item of contentSection('topContent', pageIndex, personaIndex).detail.items"
                                                :key="item.index"
                                            >
                                                <td class="align-top">{{ item.index }}. {{ item.label }}</td>
                                                <td class="align-top text-center" v-html="item.indexValue"></td>
                                                <td class="align-top text-center" v-html="item.countValue"></td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </template>
                        </summary-section>
                    </div>
                </div>

                <page-footer :content="footerContent()"/>
            </article>

            <!-- Geographic -->
            <article class="sheet cover-page section-cover">
                <img class="cover-image img-fluid" :src="imageSources.section"/>
                <div class="cover-stripe"></div>
                <div class="cover-content h-75 w-100 d-flex flex-column justify-content-end">
                    <div class="cover-title ms-auto">
                        <h1>Geographic Areas</h1>
                    </div>
                </div>
            </article>

            <article v-for="(__ignored, pageIndex) of chartData.geographicAreas[0]" :key="pageIndex"
                 class="sheet content-page"
            >
                <page-header :content="headerContent('Geographic Areas')"/>

                <section v-if="pageIndex === 0">
                    <p>The Geographic analysis includes the consumer's distribution by State, Metropolitan Area, and Region. Media Markets were included in the Summary section.</p>

                    <comparison-header :comparison="comparison" :mode="ComparisonMode.PRINT"/>
                </section>

                <div class="row">
                    <div v-for="(__ignored_section, personaIndex) of chartData.geographicAreas" :key="personaIndex"
                         class="col-6"
                    >
                        <summary-section :body-classes="['overflow-hidden']" data-intent="persona-color" :data-index="personaIndex">
                            <template #icon>
                                <font-awesome-icon fixed-width :icon="['duotone', FeatureIcons.GEOGRAPHY]"/>
                            </template>
                            <template #title>{{ personaAtIndex(personaIndex).name }} {{ contentSection('geographicAreas', pageIndex, personaIndex).title }}</template>
                            <template #content>
                                <div class="row bg-white">
                                    <div class="col-12">
                                        <highcharts
                                            constructor-type="mapChart"
                                            :options="contentSection('geographicAreas', pageIndex, personaIndex).chart"
                                            style="overflow: visible !important;"
                                        />
                                    </div>

                                    <div v-for="detailSection of contentSection('geographicAreas', pageIndex, personaIndex).detail"
                                         class="col-6"
                                         :class="{'offset-3': contentSection('geographicAreas', pageIndex, personaIndex).detail.length === 1}"
                                    >
                                        <div class="card bg-blue-10 border-0 p-3 mb-3 overflow-hidden">
                                            <u class="h5 text-primary mb-3 mx-auto">{{ detailSection.name }}</u>
                                            <div>
                                                <table class="text-primary w-100">
                                                    <tr v-for="item of detailSection.items.index.slice(0, 20)"
                                                        :key="item.label">
                                                        <td class="align-top">{{ item.index }}. {{ item.label }}</td>
                                                        <td class="align-top text-end text-nowrap">{{ item.value }}</td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </summary-section>
                    </div>
                </div>

                <page-footer :content="footerContent()"/>
            </article>

            <!-- Closing page -->
            <article class="sheet cover-page final">
                <img class="cover-image img-fluid" :src="imageSources.backPage"/>
                <div class="cover-stripe" :src="imageSources.cover"></div>
                <div class="cover-content h-100 w-100 d-flex flex-column justify-content-between">
                    <div class="flex-grow-1 d-flex flex-column justify-content-center">
                        <div class="cover-title">
                            <h2 class="mb-3">This report was generated using the advanced analytics found in Wiland's PersonaBuilder application and the vast data resources found in Wiland's GraphMassive consumer graph.</h2>
                        </div>
                        <div class="w-75 p-3 ms-auto mt-5 h3 bg-info">
                            <div class="text-center">
                                <h2>Wiland</h2>
                                <h3>The best predictor of consumer response</h3>
                            </div>
                        </div>
                    </div>
                    <div class="text-center cover-footer">
                        <img class="w-25 m-3" :src="imageSources.wilandLogo"/>
                        <ul class="list-inline list-piped lead m-3">
                            <li class="list-inline-item">Denver</li>
                            <li class="list-inline-item">New York</li>
                            <li class="list-inline-item">Washington, D.C.</li>
                            <li class="list-inline-item">Little Rock</li>
                        </ul>
                        <div class="text-primary fw-bold">
                            <span class="fa-stack">
                                <font-awesome-icon class="fa-stack-2x text-gray-25" icon="circle"/>
                                <font-awesome-icon class="fa-stack-1x text-light"
                                                   icon="mouse-pointer"
                                                   style="transform: rotate(-15deg)"
                                />
                            </span>
                            wiland.com
                        </div>
                    </div>
                </div>
            </article>
        </section>
    </main>
</template>

<script lang="ts">
    import {Vue, Component, Prop, Watch, toNative} from 'vue-facing-decorator';
    import {defineAsyncComponent} from 'vue';
    import uniqueId from 'lodash-es/uniqueId'
    import Highcharts from 'highcharts';
    import highchartsMore from 'highcharts/highcharts-more';
    import {Chart} from 'highcharts-vue';
    import HighchartsMap from 'highcharts/modules/map';
    import solidGaugeInit from 'highcharts/modules/solid-gauge';
    import FontAwesomeIcon from 'Components/common/font-awesome-icon.vue';
    import PageFooter from 'Components/common/report/page-footer.vue';
    import PageHeader from 'Components/common/report/page-header.vue';
    import SummarySection from 'Components/common/report/summary-section.vue';
    import SummaryWidget from 'Components/common/report/summary-widget.vue';
    import WedgeChartSection from 'Components/common/report/wedge-chart-section.vue';
    import Characteristics from 'Components/comparison/characteristics/characteristics.vue';
    import ComparisonHeader from 'Components/comparison/report/comparison-header.vue';
    import ChartAssociatedData from 'Components/persona/report/chart-associated-data.vue';
    import PersonaTopicWidget from 'Components/persona/report/custom-layout/persona-topic-widget.vue';
    import ChartGrid from 'Components/persona/report/chart-grid.vue';
    import {useAccountStore} from 'Stores/account';
    import {FeatureIcons/*, useAppStore*/} from 'Stores/common/app';
    import {sectionColors, ComparisonMode} from 'Stores/comparison';
    import {isArray} from 'Utilities/inspect';
    import {iconPath} from 'Utilities/reports';
    import * as Utils from 'Utilities/utils';
    import {Persona} from 'Stores/persona';

    const Spinner = () => import(/* webpackChunkName: "Spinner" */ 'Components/common/spinner/spinner.vue');

    highchartsMore(Highcharts);
    HighchartsMap(Highcharts);
    solidGaugeInit(Highcharts);

    @Component<ComparisonSummaryPdf>({
        setup() {
            return {
                accountStore: useAccountStore()
            };
        },
        components: {
            highcharts: Chart,
            Characteristics,
            ChartAssociatedData,
            ChartGrid,
            ComparisonHeader,
            FontAwesomeIcon,
            PageFooter,
            PageHeader,
            PersonaTopicWidget,
            Spinner,
            SummaryWidget,
            SummarySection,
            WedgeChartSection,
        }
    })
    class ComparisonSummaryPdf extends Vue {
        @Prop({
            type: Object,
            default: () => {
                return {};
            }
        })
        chartData: any;

        @Prop({
            type: Boolean,
            default: false
        })
        reportReady: boolean;

        ComparisonMode = ComparisonMode;
        defaultSocialContext = 'twitter';
        demographicsExpanded: boolean = false;
        iconPath = iconPath;
        imageCount: number = 0;
        imagesLoadedCount: number = 0;
        imageSourceRoot = '/assets/images/report-pdf';
        imageSources = {
            backPage: `${this.imageSourceRoot}/back-page.jpg`,
            cover: `${this.imageSourceRoot}/cover.jpg`,
            section: `${this.imageSourceRoot}/section.jpg`,
            stripe: `${this.imageSourceRoot}stripe-bar.png`,
            wilandLogo: `${this.imageSourceRoot}/../wiland-logo.svg`,
            wilandIconBox: `${this.imageSourceRoot}/wiland-icon-box-white.png`,
        }
        itemContext = {
            brand: this.defaultSocialContext,
            interest: this.defaultSocialContext,
            politicalSocialEngagement: this.defaultSocialContext,
        };
        sectionColors = sectionColors;
        uniqueId = uniqueId;

        FeatureIcons = FeatureIcons;
        Utils = Utils;

        created() {
            // Initialize item contexts
            for (const summarySection of this.chartData.summary) {
                for (const socialTopic of summarySection.socialTopics) {
                    this.itemContext[`topic-${socialTopic.key}`] = this.defaultSocialContext;
                }
            }
        }

        mounted() {
            this.prepareDocument();
        }

        unmounted() {
            const images: HTMLImageElement[] = document.querySelectorAll('.d-print-block img');
            this.imageCount = images.length;
            for (const image of images) {
                image.removeEventListener('load', this.incrementImagesLoadedCount);
                image.removeEventListener('error', this.handleImageCount);
            }
        }

        get account() {
            return this.accountStore.getAccount;
        }

        get allImagesLoaded() {
            return this.imagesLoadedCount >= this.imageCount; // Allow for some wiggle room...
        }

        get comparison() {
            return this.chartData.comparison;
        }

        // get comparisonBuiltBy(): string {
        //     return `${this.comparison.updated?.user?.firstName || 'unknown'} ${this.comparison.updated?.user?.lastName || 'unknown'}`;
        // }

        // get displayMarketAffinity() {
        //     return this.chartData.hasOwnProperty('conglomerateRfmMarket');
        //     // return this.account.dataClassSettings.conglomerateRfm?.isPermitted; // TODO: check the chart data per-Persona!
        // }

        get displayTopSocialContent() {
            return false; // PBUILDER-2782 - top content should NEVER appear
            // return this.chartData.socialActivity.every(socialActivity => socialActivity.topContent.every(chartSection => chartSection.chart !== false) );
        }

        get hasPredictedSpend() {
            return this.chartData.summary.every((summarySection: any) => (summarySection.predictedSpendActivity || []).length > 0)
        }

        get logoPath(): string {
            return (this.chartData.comparison.account?.logoFile?.filePathUri?.length || -1) > 0 ?
                Utils.getNonPrefixedFileUri(this.chartData.comparison.account.logoFile.filePathUri) :
                this.imageSources.wilandLogo;
        }

        // get personaCount(): string {
        //     return Utils.formatValue(this.chartData.insights.social?.groupMetrics?.consumerCount || 0, 'separated');
        // }

        allowFeature(featureType: string): boolean {
            let dataExists = true;
            switch (featureType) {
                case 'conglomerateRfm':
                    dataExists = this.chartData.hasOwnProperty('conglomerateRfmMarket');
                    break;
            }
            if (!dataExists) {
                return false;
            }

            return this.chartData.personas
                .every((persona: Persona) => this.accountStore.allowDataClass(featureType, persona.businessUseTypeIdentifier))
        }

        contentSection(context: string, pageIndex: number, personaIndex: number) {
            switch (context) {
                case 'geographicAreas':
                    return this.chartData.geographicAreas[personaIndex][pageIndex];

                case 'topContent':
                    return this.chartData.socialActivity[personaIndex].topContent[pageIndex];
            }
        }

        footerContent() {
            return {
                left: `<div class="page-number"></div>`,
                center: `<img class="img-fluid" src="/assets/images/powered-by-graphmassive-gray-50.svg" style="max-height: 0.2in;"/>`,
                right: `&copy;${new Date().getFullYear()} Wiland, Inc.`,
            };
        }

        headerContent(sectionTitle) {
            return {
                left: `
                    <div class="list-inline list-piped">
                        <li class="list-inline-item"><strong>PersonaBuilder</strong></li>
                        <li class="list-inline-item">${sectionTitle}</li>
                    </div>
                `,
            };
        }

        // itemContextIcon(context: string) {
        //     return ['brand', this.itemContext[context]];
        // }

        paginatedCharts(section: string, segmentSize: number | number[] = 4) {
            const chartSource = this.chartData[section]?.charts;
            if (!chartSource.length) {
                return [];
            }

            const pageCount = Math.ceil((chartSource.length / segmentSize));
            let pages: any[] = [];

            let chartsProcessed: number = 0;
            let pageNumber = 0;
            while (chartsProcessed < chartSource.length) {
                const currentSegmentSize = isArray(segmentSize) ?
                    segmentSize[pageNumber] || segmentSize[segmentSize.length - 1]:
                    segmentSize;
                const sliceStart = chartsProcessed,
                    sliceEnd = sliceStart + currentSegmentSize,
                    charts = chartSource.slice(sliceStart, sliceEnd);
                pages.push({charts});

                chartsProcessed += currentSegmentSize;
                ++pageNumber
            }

            // for (let i = 0; pages.length < pageCount; ++i) {
            //     pages.push({
            //         // page: i,
            //         charts: chartSource.slice(i * segmentSize, (i * segmentSize) + segmentSize),
            //     });
            // }

            return pages;
        }

        personaAtIndex(personaIndex: number) {
            return this.chartData.personas[personaIndex];
        }

        incrementImagesLoadedCount() {
            ++this.imagesLoadedCount;
        }

        handleImageCount(err) {
            if (err.target.src.indexOf('persona-builder-images') > -1) {
                // Ignore PB image host issues in case of missing asset
                --this.imageCount;

                return;
            }

            if (err.target.src.indexOf('twitter') > -1 || err.target.src.indexOf('twimg') > -1) {
                // If Twitter is continuing to fail, don't count this against the total
                --this.imageCount;

                return;
            }
        }

        prepareDocument() {
            // Wait until all images are loaded before printing
            const images: HTMLImageElement[] = document.querySelectorAll('.d-print-block img');
            this.imageCount = images.length;
            for (const image of images) {
                image.addEventListener('load', this.incrementImagesLoadedCount);
                image.addEventListener('error', this.handleImageCount);
            }
        }

        // printDocument() {
        //     window.print();
        // }

        sectionTitle(section: string) {
            let title = '';
            switch (section) {
                case 'topics':
                    const titleElements = [];
                    if (this.chartData.hasOwnProperty('userDefinedTopics')) {
                        titleElements.push('Custom Topics');
                    }
                    if (this.chartData.hasOwnProperty('socialEngagement')) {
                        titleElements.push('Social Characteristics');
                    }

                    title = titleElements.join(' and ');
                    break;

                // case 'summaryPastPurchases':
                //     title = 'Past Purchases'
                //     if (this.hasPredictedSpend) {
                //         title = `${title} and Spending Intensities`;
                //     }
                //     break;
            }

            return title;
        }

        setItemContext(context: string, value: string) {
            this.itemContext[context] = value;
        }

        // @Watch('allImagesLoaded')
        // onAllImagesLoaded(isLoaded) {
        //     if (isLoaded) {
        //         this.printDocument();
        //     }
        // }
    }
    export default toNative(ComparisonSummaryPdf);
</script>

<!--<style scoped lang="scss" src="../../../persona/report/custom-layout/persona-summary.scss"/>-->
<!--<style scoped lang="scss" src="../../../common/report.scss"/>-->
<style lang="scss" src="../../../common/report/report-pdf.scss"/>
<!--<style scoped lang="scss" src="./comparison-summary.scss"/>-->
