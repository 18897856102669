import { toDisplayString as _toDisplayString, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, withModifiers as _withModifiers, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "col" }
const _hoisted_2 = {
  class: "row",
  "data-reset": "persona-counter"
}
const _hoisted_3 = { class: "col" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { class: "row flex-row" }
const _hoisted_7 = {
  key: 0,
  class: "col"
}
const _hoisted_8 = { class: "row" }
const _hoisted_9 = {
  key: 1,
  class: "col"
}
const _hoisted_10 = { class: "mt-3" }
const _hoisted_11 = { key: 0 }
const _hoisted_12 = { class: "card topics bg-transparent border-0 mt-3" }
const _hoisted_13 = { class: "card-body p-0" }
const _hoisted_14 = {
  role: "button",
  class: "btn btn-lg detail-button filter-btn",
  disabled: ""
}
const _hoisted_15 = {
  key: 1,
  class: "fst-italic"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_persona_characteristics = _resolveComponent("persona-characteristics")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("div", { class: "required is-invalid mb-3" }, _toDisplayString(_ctx.errorMessageNoPersonasSelected), 513 /* TEXT, NEED_PATCH */), [
      [_vShow, _ctx.personas.length < _ctx.comparisonActivityCount]
    ]),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.comparisonActivityCount, (index) => {
        return (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("h6", null, [
            (_ctx.personaAtIndex(index))
              ? (_openBlock(), _createElementBlock("i", _hoisted_4, _toDisplayString(_ctx.personaAtIndex(index).name) + " characteristics", 1 /* TEXT */))
              : (_openBlock(), _createElementBlock("span", _hoisted_5, " "))
          ])
        ]))
      }), 256 /* UNKEYED_FRAGMENT */))
    ]),
    _createElementVNode("div", _hoisted_6, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.comparisonActivityCount, (index) => {
        return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
          (_ctx.personaAtIndex(index))
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  (_openBlock(), _createBlock(_component_persona_characteristics, {
                    "inner-container-class": "card-body mt-n3 bg-blue-10",
                    "activities-IGNORE": _ctx.activities,
                    key: _ctx.personaAtIndex(index).id,
                    mode: _ctx.PersonaMode.REPORT,
                    persona: _ctx.personaAtIndex(index),
                    "persona-index": index,
                    "show-topics": false
                  }, null, 8 /* PROPS */, ["activities-IGNORE", "mode", "persona", "persona-index"]))
                ])
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_9, _cache[0] || (_cache[0] = [
                _createElementVNode("div", { class: "card overflow-hidden h-100" }, [
                  _createElementVNode("div", { class: "card-body mt-n3 bg-gray-10 pt-3" }, [
                    _createElementVNode("div", { class: "mt-3 is-invalid" }, " None selected yet ")
                  ])
                ], -1 /* HOISTED */)
              ])))
        ], 64 /* STABLE_FRAGMENT */))
      }), 128 /* KEYED_FRAGMENT */))
    ]),
    _createElementVNode("div", _hoisted_10, [
      (!_ctx.Utils.isEmptyObject(_ctx.intersectionTopics))
        ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
            _createCommentVNode("            <i>Topics for Analysis and Reporting</i>"),
            _cache[1] || (_cache[1] = _createElementVNode("i", null, "These custom Topics are common between the Personas and are included in this comparison:", -1 /* HOISTED */)),
            _createElementVNode("div", _hoisted_12, [
              _createElementVNode("div", _hoisted_13, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.intersectionTopicChunks, (topicChunk, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: index,
                    class: "d-flex flex-row flex-fill"
                  }, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(topicChunk, (topic) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: topic.id,
                        class: _normalizeClass(["topic position-relative", `topic-display-${_ctx.topicStyle}`])
                      }, [
                        _createElementVNode("button", _hoisted_14, [
                          _createElementVNode("span", null, _toDisplayString(topic.name), 1 /* TEXT */)
                        ]),
                        _createElementVNode("span", {
                          class: _normalizeClass(["details", {selected: topic.selected}])
                        }, [
                          _createVNode(_component_font_awesome_icon, {
                            class: "detail-icon",
                            icon: [ 'solid', 'circle-info' ],
                            onClick: _withModifiers(($event: any) => (_ctx.$refs['personaCharacteristics'].showSelectedTopicModal(topic)), ["prevent"])
                          }, null, 8 /* PROPS */, ["onClick"])
                        ], 2 /* CLASS */)
                      ], 2 /* CLASS */))
                    }), 128 /* KEYED_FRAGMENT */)),
                    (_ctx.topicStyle !== 'text')
                      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList((_ctx.topicsPerRow - topicChunk.length), (n) => {
                          return (_openBlock(), _createElementBlock("div", {
                            key: n,
                            class: "topic-spacer"
                          }))
                        }), 128 /* KEYED_FRAGMENT */))
                      : _createCommentVNode("v-if", true)
                  ]))
                }), 128 /* KEYED_FRAGMENT */))
              ])
            ])
          ]))
        : (_openBlock(), _createElementBlock("i", _hoisted_15, " No custom Topics are included in this Comparison "))
    ])
  ]))
}