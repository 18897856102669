import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, withMemo as _withMemo, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, normalizeClass as _normalizeClass, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "d-print-none" }
const _hoisted_2 = {
  class: "sheet cover-page",
  "data-test": "main cover page"
}
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "cover-content h-100 w-100 d-flex flex-column justify-content-between" }
const _hoisted_5 = { class: "cover-header ms-auto text-end" }
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "flex-grow-1 d-flex flex-column justify-content-center" }
const _hoisted_8 = { class: "cover-title" }
const _hoisted_9 = { class: "mb-3" }
const _hoisted_10 = { class: "w-75 p-3 ms-auto mt-5 h3 bg-info" }
const _hoisted_11 = { class: "text-center" }
const _hoisted_12 = ["onerror", "src"]
const _hoisted_13 = {
  class: "sheet content-page",
  "data-test": "executive overview"
}
const _hoisted_14 = { class: "mt-3" }
const _hoisted_15 = {
  class: "sheet cover-page section-cover",
  "data-test": "methodology cover page"
}
const _hoisted_16 = ["src"]
const _hoisted_17 = {
  class: "sheet content-page",
  "data-test": "methodology page"
}
const _hoisted_18 = ["src"]
const _hoisted_19 = {
  class: "sheet cover-page section-cover",
  "data-test": "summary cover page"
}
const _hoisted_20 = ["src"]
const _hoisted_21 = {
  class: "sheet content-page",
  "data-test": "demographics summary page"
}
const _hoisted_22 = { class: "row g-0 m-n3" }
const _hoisted_23 = { class: "row" }
const _hoisted_24 = { class: "col-6" }
const _hoisted_25 = ["src"]
const _hoisted_26 = { class: "col-3" }
const _hoisted_27 = { class: "col-3" }
const _hoisted_28 = {
  class: "sheet content-page",
  "data-test": "consumer summary page"
}
const _hoisted_29 = { class: "row" }
const _hoisted_30 = { class: "col-6" }
const _hoisted_31 = {
  key: 0,
  class: "col-6"
}
const _hoisted_32 = { class: "row" }
const _hoisted_33 = {
  key: 0,
  class: "sheet content-page",
  "data-test": "high-level rfm summary page"
}
const _hoisted_34 = {
  class: "sheet content-page",
  "data-test": "political and social summary page"
}
const _hoisted_35 = { class: "row" }
const _hoisted_36 = {
  class: "sheet content-page",
  "data-test": "geography summary page"
}
const _hoisted_37 = { class: "row" }
const _hoisted_38 = { class: "col-12" }
const _hoisted_39 = { class: "col-6" }
const _hoisted_40 = { class: "card bg-blue-10 border-0 p-3 mb-3 overflow-hidden" }
const _hoisted_41 = { class: "h5 text-primary mb-3 mx-auto" }
const _hoisted_42 = { class: "text-primary w-100" }
const _hoisted_43 = { class: "align-top" }
const _hoisted_44 = { class: "align-top text-end text-nowrap" }
const _hoisted_45 = {
  class: "sheet cover-page section-cover",
  "data-test": "demographics cover page"
}
const _hoisted_46 = ["src"]
const _hoisted_47 = { key: 0 }
const _hoisted_48 = { class: "row" }
const _hoisted_49 = { class: "col-12" }
const _hoisted_50 = { class: "card bg-blue-10 border-0 p-3 mb-3 overflow-hidden" }
const _hoisted_51 = { class: "h5 text-primary mb-3 mx-auto" }
const _hoisted_52 = { class: "text-primary w-100" }
const _hoisted_53 = { class: "align-top" }
const _hoisted_54 = { class: "align-top text-end text-nowrap" }
const _hoisted_55 = {
  class: "sheet cover-page section-cover",
  "data-test": "social activity cover page"
}
const _hoisted_56 = ["src"]
const _hoisted_57 = {
  class: "sheet content-page",
  "data-test": "social activity engagement page"
}
const _hoisted_58 = { class: "row row-eq-height" }
const _hoisted_59 = { class: "col" }
const _hoisted_60 = { class: "card bg-blue-10 border-0 p-3 mb-3" }
const _hoisted_61 = ["innerHTML"]
const _hoisted_62 = { style: {"max-height":"17em","overflow":"auto"} }
const _hoisted_63 = { class: "text-primary w-100" }
const _hoisted_64 = { class: "align-top" }
const _hoisted_65 = ["innerHTML"]
const _hoisted_66 = {
  key: 1,
  class: "sheet content-page",
  "data-test": "social activity detail page"
}
const _hoisted_67 = ["data-chart-id"]
const _hoisted_68 = { class: "col-7" }
const _hoisted_69 = { class: "col-5" }
const _hoisted_70 = { class: "card bg-blue-10 border-0 p-3 mb-3" }
const _hoisted_71 = ["innerHTML"]
const _hoisted_72 = { class: "text-primary w-100" }
const _hoisted_73 = { class: "align-top" }
const _hoisted_74 = ["innerHTML"]
const _hoisted_75 = ["innerHTML"]
const _hoisted_76 = { key: 2 }
const _hoisted_77 = {
  class: "sheet cover-page section-cover",
  "data-test": "market affinity cover page"
}
const _hoisted_78 = ["src"]
const _hoisted_79 = {
  class: "sheet content-page",
  "data-test": "market affinity detail page"
}
const _hoisted_80 = { key: 0 }
const _hoisted_81 = { key: 3 }
const _hoisted_82 = {
  class: "sheet cover-page section-cover",
  "data-test": "predicted spend cover page"
}
const _hoisted_83 = ["src"]
const _hoisted_84 = {
  class: "sheet content-page",
  "data-test": "predicted spend detail page"
}
const _hoisted_85 = { key: 4 }
const _hoisted_86 = {
  class: "sheet cover-page section-cover",
  "data-test": "high-level rfm cover page"
}
const _hoisted_87 = ["src"]
const _hoisted_88 = { key: 5 }
const _hoisted_89 = {
  class: "sheet cover-page section-cover",
  "data-test": "past purchases cover page"
}
const _hoisted_90 = ["src"]
const _hoisted_91 = { key: 0 }
const _hoisted_92 = { key: 6 }
const _hoisted_93 = {
  class: "sheet cover-page section-cover",
  "data-test": "topics cover page"
}
const _hoisted_94 = ["src"]
const _hoisted_95 = {
  key: 0,
  class: "sheet content-page",
  "data-test": "social detail page"
}
const _hoisted_96 = { key: 1 }
const _hoisted_97 = {
  class: "sheet cover-page final",
  "data-test": "back page"
}
const _hoisted_98 = ["src"]
const _hoisted_99 = ["src"]
const _hoisted_100 = { class: "cover-content h-100 w-100 d-flex flex-column justify-content-between" }
const _hoisted_101 = { class: "text-center cover-footer" }
const _hoisted_102 = ["src"]
const _hoisted_103 = { class: "text-primary fw-bold" }
const _hoisted_104 = { class: "fa-stack" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_spinner = _resolveComponent("spinner")!
  const _component_page_header = _resolveComponent("page-header")!
  const _component_characteristics = _resolveComponent("characteristics")!
  const _component_page_footer = _resolveComponent("page-footer")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_highcharts = _resolveComponent("highcharts")!
  const _component_summary_section = _resolveComponent("summary-section")!
  const _component_wedge_chart_section = _resolveComponent("wedge-chart-section")!
  const _component_summary_widget = _resolveComponent("summary-widget")!
  const _component_persona_topic_widget = _resolveComponent("persona-topic-widget")!
  const _component_chart_grid = _resolveComponent("chart-grid")!

  return (_openBlock(), _createElementBlock("main", null, [
    _createElementVNode("section", _hoisted_1, [
      (!_ctx.allImagesLoaded)
        ? (_openBlock(), _createBlock(_component_spinner, {
            key: 0,
            text: "Building..."
          }))
        : _createCommentVNode("v-if", true)
    ]),
    _createElementVNode("section", {
      class: _normalizeClass(["d-print-block print-area mb-n3", {'d-none': !_ctx.allImagesLoaded}])
    }, [
      _createElementVNode("article", _hoisted_2, [
        _createElementVNode("img", {
          class: "cover-image img-fluid",
          src: _ctx.imageSources.cover
        }, null, 8 /* PROPS */, _hoisted_3),
        _cache[2] || (_cache[2] = _createElementVNode("div", { class: "cover-stripe" }, null, -1 /* HOISTED */)),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("img", {
              class: "img-fluid w-25",
              src: _ctx.imageSources.wilandIconBox
            }, null, 8 /* PROPS */, _hoisted_6)
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("div", null, [
                _createElementVNode("h1", _hoisted_9, _toDisplayString(_ctx.chartData.persona.name), 1 /* TEXT */),
                _cache[1] || (_cache[1] = _createElementVNode("h2", null, "A PersonaBuilder™ Report", -1 /* HOISTED */))
              ])
            ]),
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("h3", null, _toDisplayString(_ctx.Utils.dateFormat(_ctx.chartData.persona.updateDate, {format: 'date'})), 1 /* TEXT */)
              ])
            ])
          ]),
          _createElementVNode("img", {
            class: "cover-footer img-fluid w-25 mx-auto",
            onerror: _ctx.Utils.imageFallback(_ctx.imageSources.wilandLogo),
            src: _ctx.logoPath
          }, null, 8 /* PROPS */, _hoisted_12)
        ])
      ]),
      _createElementVNode("article", _hoisted_13, [
        _createVNode(_component_page_header, {
          content: _ctx.headerContent('Executive Overview')
        }, null, 8 /* PROPS */, ["content"]),
        _cache[3] || (_cache[3] = _createElementVNode("h1", { id: "executiveOverview" }, "Executive Overview", -1 /* HOISTED */)),
        _createElementVNode("p", _hoisted_14, "This Persona was built by " + _toDisplayString(_ctx.personaBuiltBy) + " for the " + _toDisplayString(_ctx.account.name) + " account using Wiland's powerful PersonaBuilder application. It is based on " + _toDisplayString(_ctx.personaCount) + " people possessing these characteristics:", 1 /* TEXT */),
        _withMemo([_ctx.persona], () => _createVNode(_component_characteristics, {
          class: "p-0 ms-5",
          "inner-container-class": "",
          mode: _ctx.personaMode.REPORT,
          "outer-container-class": "",
          persona: _ctx.persona,
          "topic-style": "text"
        }, null, 8 /* PROPS */, ["mode", "persona"]), _cache, 0),
        _createVNode(_component_page_footer, {
          content: _ctx.footerContent()
        }, null, 8 /* PROPS */, ["content"])
      ]),
      _createElementVNode("article", _hoisted_15, [
        _createElementVNode("img", {
          class: "cover-image img-fluid",
          src: _ctx.imageSources.section
        }, null, 8 /* PROPS */, _hoisted_16),
        _cache[4] || (_cache[4] = _createElementVNode("div", { class: "cover-stripe" }, null, -1 /* HOISTED */)),
        _cache[5] || (_cache[5] = _createElementVNode("div", { class: "cover-content h-75 w-100 d-flex flex-column justify-content-end" }, [
          _createElementVNode("div", { class: "cover-title ms-auto" }, [
            _createElementVNode("h1", null, "Methodology")
          ])
        ], -1 /* HOISTED */))
      ]),
      _createElementVNode("article", _hoisted_17, [
        _createVNode(_component_page_header, {
          content: _ctx.headerContent('Methodology')
        }, null, 8 /* PROPS */, ["content"]),
        _cache[6] || (_cache[6] = _createElementVNode("p", null, "Customer personas have long provided a way to understand and segment consumer cohorts. But marketers have struggled to make personas actionable when it comes to real-world media targeting.", -1 /* HOISTED */)),
        _cache[7] || (_cache[7] = _createElementVNode("p", null, [
          _createTextVNode("That's why Wiland PersonaBuilder™—"),
          _createElementVNode("strong", null, "the world's most powerful persona creation tool"),
          _createTextVNode("—is also designed to produce ready-to-deliver audiences for activation in digital advertising campaigns. The intuitive PersonaBuilder application makes it easy to create robust, detailed prospect and customer personas based on relevant, continuously refreshed data—vast predicted spend signals, social media engagement data, and other information. ")
        ], -1 /* HOISTED */)),
        _cache[8] || (_cache[8] = _createElementVNode("blockquote", null, "Bridge the gap between your strategy and media buying teams", -1 /* HOISTED */)),
        _cache[9] || (_cache[9] = _createElementVNode("p", null, "PersonaBuilder enables you to create robust, detailed personas that can immediately be converted into audiences ready for activation in digital advertising campaigns. By closing the gap between your strategy and media buying teams, the insights you gain become actionable in the form of large audiences ready to activate across all platforms, channels, and devices.", -1 /* HOISTED */)),
        _cache[10] || (_cache[10] = _createElementVNode("p", null, [
          _createTextVNode("PersonaBuilder insights are driven by the power of GraphMassive™, "),
          _createElementVNode("strong", null, "the largest independent consumer graph"),
          _createTextVNode(" of scored social data anonymously indexed to actionable offline information. GraphMassive is the indusry's only searchable consumer graph that maps tens of billions of consumer relationships and interests across social media and joins them to offline demographics, purchase transactions, spending intensities, and location. GraphMassive is built specifically for data-driven marketers seeking to understand who is behind social activity related to their brands or their competitor's brands. ")
        ], -1 /* HOISTED */)),
        _cache[11] || (_cache[11] = _createElementVNode("blockquote", null, "How PersonaBuilder Works", -1 /* HOISTED */)),
        _createElementVNode("img", {
          alt: "PersonaBuilder™ methodology",
          class: "img-fluid",
          src: `${this.imageSourceRoot}/methodology.png`
        }, null, 8 /* PROPS */, _hoisted_18),
        _cache[12] || (_cache[12] = _createElementVNode("p", null, "Personas created using the application are analyzed to produce high-performance audiences that can be delivered to any leading platform or DSP for activation.", -1 /* HOISTED */)),
        _createVNode(_component_page_footer, {
          content: _ctx.footerContent()
        }, null, 8 /* PROPS */, ["content"])
      ]),
      _createElementVNode("article", _hoisted_19, [
        _createElementVNode("img", {
          class: "cover-image img-fluid",
          src: _ctx.imageSources.section
        }, null, 8 /* PROPS */, _hoisted_20),
        _cache[13] || (_cache[13] = _createElementVNode("div", { class: "cover-stripe" }, null, -1 /* HOISTED */)),
        _cache[14] || (_cache[14] = _createElementVNode("div", { class: "cover-content h-75 w-100 d-flex flex-column justify-content-end" }, [
          _createElementVNode("div", { class: "cover-title ms-auto" }, [
            _createElementVNode("h1", null, "Summary")
          ])
        ], -1 /* HOISTED */))
      ]),
      _createElementVNode("article", _hoisted_21, [
        _createVNode(_component_page_header, {
          content: _ctx.headerContent('Summary')
        }, null, 8 /* PROPS */, ["content"]),
        _createVNode(_component_summary_section, null, {
          icon: _withCtx(() => [
            _createVNode(_component_font_awesome_icon, {
              icon: ['duotone', _ctx.FeatureIcons.DEMOGRAPHICS],
              size: "lg"
            }, null, 8 /* PROPS */, ["icon"])
          ]),
          title: _withCtx(() => _cache[15] || (_cache[15] = [
            _createTextVNode(" Demographics")
          ])),
          content: _withCtx(() => [
            _createElementVNode("div", _hoisted_22, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.chartData.summary.demographics, (chart, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: index,
                  class: "col-3",
                  "data-chart-container": ""
                }, [
                  _createVNode(_component_highcharts, { options: chart }, null, 8 /* PROPS */, ["options"])
                ]))
              }), 128 /* KEYED_FRAGMENT */))
            ])
          ]),
          _: 1 /* STABLE */
        }),
        _cache[19] || (_cache[19] = _createElementVNode("p", null, "Significant characteristics of this persona are captured in the Unique Facts chart, while the top brand engagement and personal interest categories are reflected in these social engagement summaries.", -1 /* HOISTED */)),
        _createElementVNode("div", _hoisted_23, [
          _createElementVNode("div", _hoisted_24, [
            _createVNode(_component_summary_section, null, {
              icon: _withCtx(() => [
                _createElementVNode("img", {
                  class: "logo-icon",
                  src: _ctx.iconPath['personaBuilder'],
                  alt: "",
                  tabindex: "-1"
                }, null, 8 /* PROPS */, _hoisted_25)
              ]),
              title: _withCtx(() => _cache[16] || (_cache[16] = [
                _createTextVNode(" Unique Facts ")
              ])),
              content: _withCtx(() => [
                _createVNode(_component_wedge_chart_section, {
                  data: {items: _ctx.chartData.summary.uniqueFacts}
                }, null, 8 /* PROPS */, ["data"])
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _createElementVNode("div", _hoisted_26, [
            _createVNode(_component_summary_section, null, {
              icon: _withCtx(() => [
                _createVNode(_component_font_awesome_icon, {
                  icon: ['duotone', _ctx.FeatureIcons.SOCIAL_AFFINITY],
                  size: "lg"
                }, null, 8 /* PROPS */, ["icon"])
              ]),
              title: _withCtx(() => _cache[17] || (_cache[17] = [
                _createTextVNode(" Brand Summary ")
              ])),
              content: _withCtx(() => [
                (_ctx.chartData.summary.brandSummary.hasOwnProperty('twitter'))
                  ? _withDirectives((_openBlock(), _createBlock(_component_summary_widget, {
                      key: 0,
                      summaryItems: _ctx.chartData.summary.brandSummary.twitter,
                      summaryType: "twitter",
                      tooltipPattern: "[[ percent ]] of the group is interested"
                    }, null, 8 /* PROPS */, ["summaryItems"])), [
                      [_vShow, _ctx.itemContext['brand'] === 'twitter']
                    ])
                  : _createCommentVNode("v-if", true)
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _createElementVNode("div", _hoisted_27, [
            _createVNode(_component_summary_section, null, {
              icon: _withCtx(() => [
                _createVNode(_component_font_awesome_icon, {
                  icon: ['duotone', _ctx.FeatureIcons.SOCIAL_AFFINITY],
                  size: "lg"
                }, null, 8 /* PROPS */, ["icon"])
              ]),
              title: _withCtx(() => _cache[18] || (_cache[18] = [
                _createTextVNode(" Interest Summary ")
              ])),
              content: _withCtx(() => [
                (_ctx.chartData.summary.interestSummary.hasOwnProperty('twitter'))
                  ? _withDirectives((_openBlock(), _createBlock(_component_summary_widget, {
                      key: 0,
                      summaryItems: _ctx.chartData.summary.interestSummary.twitter,
                      summaryType: "twitter",
                      tooltipPattern: "[[ percent ]] of the group is interested"
                    }, null, 8 /* PROPS */, ["summaryItems"])), [
                      [_vShow, _ctx.itemContext['interest'] === 'twitter']
                    ])
                  : _createCommentVNode("v-if", true)
              ]),
              _: 1 /* STABLE */
            })
          ])
        ]),
        _createVNode(_component_page_footer, {
          content: _ctx.footerContent()
        }, null, 8 /* PROPS */, ["content"])
      ]),
      _createElementVNode("article", _hoisted_28, [
        _createVNode(_component_page_header, {
          content: _ctx.headerContent('Summary')
        }, null, 8 /* PROPS */, ["content"]),
        _createElementVNode("section", null, [
          _cache[23] || (_cache[23] = _createElementVNode("h2", null, "Spending Behaviors", -1 /* HOISTED */)),
          _createElementVNode("div", _hoisted_29, [
            _createElementVNode("div", _hoisted_30, [
              (_ctx.allowFeature('conglomerateRfm'))
                ? (_openBlock(), _createBlock(_component_summary_section, { key: 0 }, {
                    icon: _withCtx(() => [
                      _createVNode(_component_font_awesome_icon, {
                        icon: ['duotone', _ctx.FeatureIcons.CONGLOMERATE_RFM_MARKETS],
                        size: "lg"
                      }, null, 8 /* PROPS */, ["icon"])
                    ]),
                    title: _withCtx(() => _cache[20] || (_cache[20] = [
                      _createTextVNode(" Market Affinity, by Spend ")
                    ])),
                    content: _withCtx(() => [
                      (_openBlock(), _createBlock(_component_highcharts, {
                        key: `market_affinity_summary`,
                        options: _ctx.chartData.summary.conglomerateRfmMarket
                      }, null, 8 /* PROPS */, ["options"]))
                    ]),
                    _: 1 /* STABLE */
                  }))
                : (_openBlock(), _createBlock(_component_summary_section, { key: 1 }, {
                    icon: _withCtx(() => [
                      _createVNode(_component_font_awesome_icon, {
                        icon: ['duotone', _ctx.FeatureIcons.PAST_PURCHASES],
                        size: "lg"
                      }, null, 8 /* PROPS */, ["icon"])
                    ]),
                    title: _withCtx(() => _cache[21] || (_cache[21] = [
                      _createTextVNode(" Past Purchase Activity ")
                    ])),
                    content: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.chartData.summary.pastPurchaseActivity, (pastPurchaseChart, index) => {
                        return (_openBlock(), _createBlock(_component_highcharts, {
                          key: `past_purchase_${index}`,
                          options: pastPurchaseChart
                        }, null, 8 /* PROPS */, ["options"]))
                      }), 128 /* KEYED_FRAGMENT */))
                    ]),
                    _: 1 /* STABLE */
                  }))
            ]),
            (_ctx.chartData.summary.predictedSpendActivity.length > 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_31, [
                  _createVNode(_component_summary_section, null, {
                    icon: _withCtx(() => [
                      _createVNode(_component_font_awesome_icon, {
                        icon: ['duotone', _ctx.FeatureIcons.PREDICTED_SPEND],
                        size: "lg"
                      }, null, 8 /* PROPS */, ["icon"])
                    ]),
                    title: _withCtx(() => _cache[22] || (_cache[22] = [
                      _createTextVNode(" Predicted Spend ")
                    ])),
                    content: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.chartData.summary.predictedSpendActivity, (predictedSpendChart, index) => {
                        return (_openBlock(), _createBlock(_component_highcharts, {
                          key: `consumer_spend_${index}`,
                          options: predictedSpendChart
                        }, null, 8 /* PROPS */, ["options"]))
                      }), 128 /* KEYED_FRAGMENT */))
                    ]),
                    _: 1 /* STABLE */
                  })
                ]))
              : _createCommentVNode("v-if", true)
          ])
        ]),
        _cache[24] || (_cache[24] = _createElementVNode("h2", null, "Consumer Topics", -1 /* HOISTED */)),
        _createElementVNode("div", _hoisted_32, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.chartData.summary.socialTopics, (topic) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "col-3",
              key: topic.key
            }, [
              _createVNode(_component_summary_section, null, {
                icon: _withCtx(() => [
                  _createVNode(_component_font_awesome_icon, {
                    icon: ['duotone', _ctx.FeatureIcons.SOCIAL_AFFINITY],
                    size: "lg"
                  }, null, 8 /* PROPS */, ["icon"])
                ]),
                title: _withCtx(() => [
                  _createTextVNode(_toDisplayString(topic.category || topic.name), 1 /* TEXT */)
                ]),
                content: _withCtx(() => [
                  _createVNode(_component_persona_topic_widget, {
                    topic: topic,
                    context: _ctx.itemContext[`topic-${topic.key}`]
                  }, null, 8 /* PROPS */, ["topic", "context"])
                ]),
                _: 2 /* DYNAMIC */
              }, 1024 /* DYNAMIC_SLOTS */)
            ]))
          }), 128 /* KEYED_FRAGMENT */))
        ]),
        _createVNode(_component_page_footer, {
          content: _ctx.footerContent()
        }, null, 8 /* PROPS */, ["content"])
      ]),
      (_ctx.chartData.summary.highLevelRfm !== false)
        ? (_openBlock(), _createElementBlock("article", _hoisted_33, [
            _createVNode(_component_page_header, {
              content: _ctx.headerContent('Summary')
            }, null, 8 /* PROPS */, ["content"]),
            _cache[26] || (_cache[26] = _createElementVNode("h2", null, "Recency, Frequency, and Monetary", -1 /* HOISTED */)),
            _createVNode(_component_summary_section, null, {
              icon: _withCtx(() => [
                _createVNode(_component_font_awesome_icon, {
                  icon: ['duotone', _ctx.FeatureIcons.HIGH_LEVEL_RFM],
                  size: "lg"
                }, null, 8 /* PROPS */, ["icon"])
              ]),
              title: _withCtx(() => _cache[25] || (_cache[25] = [
                _createTextVNode(" Recency, Frequency, and Monetary ")
              ])),
              content: _withCtx(() => [
                _createVNode(_component_highcharts, {
                  options: _ctx.chartData.summary.highLevelRfm
                }, null, 8 /* PROPS */, ["options"])
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_page_footer, {
              content: _ctx.footerContent()
            }, null, 8 /* PROPS */, ["content"])
          ]))
        : _createCommentVNode("v-if", true),
      _createElementVNode("article", _hoisted_34, [
        _createVNode(_component_page_header, {
          content: _ctx.headerContent('Summary')
        }, null, 8 /* PROPS */, ["content"]),
        _cache[30] || (_cache[30] = _createElementVNode("h2", null, "Political Engagement and Affiliation", -1 /* HOISTED */)),
        _createVNode(_component_summary_section, null, {
          icon: _withCtx(() => [
            _createVNode(_component_font_awesome_icon, {
              icon: ['duotone', _ctx.FeatureIcons.POLITICAL],
              size: "lg"
            }, null, 8 /* PROPS */, ["icon"])
          ]),
          title: _withCtx(() => _cache[27] || (_cache[27] = [
            _createTextVNode(" Political Social Engagement ")
          ])),
          content: _withCtx(() => [
            _createElementVNode("div", _hoisted_35, [
              (_ctx.chartData.summary.politicalSocialSummary.hasOwnProperty('twitter'))
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _withDirectives(_createVNode(_component_summary_widget, {
                      class: "col-6",
                      summaryItems: [_ctx.chartData.summary.politicalSocialSummary.twitter[1]],
                      summaryType: "twitter",
                      tooltipPattern: "[[ percent ]] of the group has engaged"
                    }, null, 8 /* PROPS */, ["summaryItems"]), [
                      [_vShow, _ctx.itemContext['politicalSocialEngagement'] === 'twitter']
                    ]),
                    _withDirectives(_createVNode(_component_summary_widget, {
                      class: "col-6",
                      summaryItems: [_ctx.chartData.summary.politicalSocialSummary.twitter[0]],
                      summaryType: "twitter",
                      tooltipPattern: "[[ percent ]] of the group has engaged"
                    }, null, 8 /* PROPS */, ["summaryItems"]), [
                      [_vShow, _ctx.itemContext['politicalSocialEngagement'] === 'twitter']
                    ])
                  ], 64 /* STABLE_FRAGMENT */))
                : _createCommentVNode("v-if", true)
            ])
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode(_component_summary_section, null, {
          icon: _withCtx(() => [
            _createVNode(_component_font_awesome_icon, {
              icon: ['duotone', _ctx.FeatureIcons.POLITICAL],
              size: "lg"
            }, null, 8 /* PROPS */, ["icon"])
          ]),
          title: _withCtx(() => _cache[28] || (_cache[28] = [
            _createTextVNode(" Political Party Affiliation ")
          ])),
          content: _withCtx(() => [
            _createVNode(_component_highcharts, {
              options: _ctx.chartData.summary.politicalPartyAffiliation
            }, null, 8 /* PROPS */, ["options"])
          ]),
          _: 1 /* STABLE */
        }),
        _cache[31] || (_cache[31] = _createElementVNode("h2", null, "Times of High and Low Social Media Activity", -1 /* HOISTED */)),
        _createVNode(_component_summary_section, null, {
          icon: _withCtx(() => [
            _createVNode(_component_font_awesome_icon, {
              icon: ['duotone', _ctx.FeatureIcons.SOCIAL_AFFINITY],
              size: "lg"
            }, null, 8 /* PROPS */, ["icon"])
          ]),
          title: _withCtx(() => _cache[29] || (_cache[29] = [
            _createTextVNode("Weekly Activity")
          ])),
          content: _withCtx(() => [
            _createVNode(_component_highcharts, {
              options: _ctx.chartData.summary.socialWeeklyActivity
            }, null, 8 /* PROPS */, ["options"])
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode(_component_page_footer, {
          content: _ctx.footerContent()
        }, null, 8 /* PROPS */, ["content"])
      ]),
      _createElementVNode("article", _hoisted_36, [
        _createVNode(_component_page_header, {
          content: _ctx.headerContent('Summary')
        }, null, 8 /* PROPS */, ["content"]),
        _cache[33] || (_cache[33] = _createElementVNode("h2", null, "Media Market Analysis", -1 /* HOISTED */)),
        _createVNode(_component_summary_section, null, {
          icon: _withCtx(() => [
            _createVNode(_component_font_awesome_icon, {
              icon: ['duotone', _ctx.FeatureIcons.GEOGRAPHY],
              size: "lg"
            }, null, 8 /* PROPS */, ["icon"])
          ]),
          title: _withCtx(() => _cache[32] || (_cache[32] = [
            _createTextVNode(" Geography ")
          ])),
          content: _withCtx(() => [
            _createElementVNode("div", _hoisted_37, [
              _createElementVNode("div", _hoisted_38, [
                _createVNode(_component_highcharts, {
                  "constructor-type": "mapChart",
                  options: _ctx.chartData.summary.geography.chart,
                  style: {"overflow":"visible !important"}
                }, null, 8 /* PROPS */, ["options"])
              ]),
              _createCommentVNode(" Detail sidebar "),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.chartData.summary.geography.detail, (detailSection) => {
                return (_openBlock(), _createElementBlock("div", _hoisted_39, [
                  _createElementVNode("div", _hoisted_40, [
                    _createElementVNode("u", _hoisted_41, _toDisplayString(detailSection.name), 1 /* TEXT */),
                    _createElementVNode("div", null, [
                      _createElementVNode("table", _hoisted_42, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(detailSection.items.index.slice(0, 20), (item) => {
                          return (_openBlock(), _createElementBlock("tr", {
                            key: item.label
                          }, [
                            _createElementVNode("td", _hoisted_43, _toDisplayString(item.index) + ". " + _toDisplayString(item.label), 1 /* TEXT */),
                            _createElementVNode("td", _hoisted_44, _toDisplayString(item.value), 1 /* TEXT */)
                          ]))
                        }), 128 /* KEYED_FRAGMENT */))
                      ])
                    ])
                  ])
                ]))
              }), 256 /* UNKEYED_FRAGMENT */))
            ])
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode(_component_page_footer, {
          content: _ctx.footerContent()
        }, null, 8 /* PROPS */, ["content"])
      ]),
      _createElementVNode("article", _hoisted_45, [
        _createElementVNode("img", {
          class: "cover-image img-fluid",
          src: _ctx.imageSources.section
        }, null, 8 /* PROPS */, _hoisted_46),
        _cache[34] || (_cache[34] = _createElementVNode("div", { class: "cover-stripe" }, null, -1 /* HOISTED */)),
        _cache[35] || (_cache[35] = _createElementVNode("div", { class: "cover-content h-75 w-100 d-flex flex-column justify-content-end" }, [
          _createElementVNode("div", { class: "cover-title ms-auto" }, [
            _createElementVNode("h1", null, "Demographic Details")
          ])
        ], -1 /* HOISTED */))
      ]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.paginatedCharts('demographics'), (chartPage, index) => {
        return (_openBlock(), _createElementBlock("article", {
          key: index,
          class: "sheet content-page",
          "data-test": "demographic detail page"
        }, [
          _createVNode(_component_page_header, {
            content: _ctx.headerContent('Demographics')
          }, null, 8 /* PROPS */, ["content"]),
          (index === 0)
            ? (_openBlock(), _createElementBlock("p", _hoisted_47, "Wiland's data and analytics use real consumer demographic data from the GraphMassive consumer graph to ensure the highest level of accuracy and usefulness. We accomplish this by deterministically joining all the data available for each consumer in GraphMassive in order to build comprehensive profiles of the characteristics exhibited by the consumers in thie group segment."))
            : _createCommentVNode("v-if", true),
          _createCommentVNode("                {{chartData.demographics.associatedData}}"),
          _createVNode(_component_chart_grid, {
            charts: chartPage.charts,
            "charts-associated-data": _ctx.chartData.demographics.associatedData
          }, null, 8 /* PROPS */, ["charts", "charts-associated-data"]),
          _createVNode(_component_page_footer, {
            content: _ctx.footerContent()
          }, null, 8 /* PROPS */, ["content"])
        ]))
      }), 128 /* KEYED_FRAGMENT */)),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.chartData.geographicAreas, (element, index) => {
        return (_openBlock(), _createElementBlock("article", {
          key: index,
          class: "sheet content-page",
          "data-test": "geography detail page"
        }, [
          _createVNode(_component_page_header, {
            content: _ctx.headerContent('Geographic Areas')
          }, null, 8 /* PROPS */, ["content"]),
          _createElementVNode("h3", null, _toDisplayString(element.title), 1 /* TEXT */),
          _createElementVNode("div", _hoisted_48, [
            _createElementVNode("div", _hoisted_49, [
              _createVNode(_component_highcharts, {
                "constructor-type": "mapChart",
                options: element.chart,
                style: {"overflow":"visible !important"}
              }, null, 8 /* PROPS */, ["options"])
            ]),
            _createCommentVNode(" Detail sidebar "),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(element.detail, (detailSection) => {
              return (_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(["col-6", {'offset-3': element.detail.length === 1}])
              }, [
                _createElementVNode("div", _hoisted_50, [
                  _createElementVNode("u", _hoisted_51, _toDisplayString(detailSection.name), 1 /* TEXT */),
                  _createElementVNode("div", null, [
                    _createElementVNode("table", _hoisted_52, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(detailSection.items.index.slice(0, 20), (item) => {
                        return (_openBlock(), _createElementBlock("tr", {
                          key: item.label
                        }, [
                          _createElementVNode("td", _hoisted_53, _toDisplayString(item.index) + ". " + _toDisplayString(item.label), 1 /* TEXT */),
                          _createElementVNode("td", _hoisted_54, _toDisplayString(item.value), 1 /* TEXT */)
                        ]))
                      }), 128 /* KEYED_FRAGMENT */))
                    ])
                  ])
                ])
              ], 2 /* CLASS */))
            }), 256 /* UNKEYED_FRAGMENT */))
          ]),
          _createVNode(_component_page_footer, {
            content: _ctx.footerContent()
          }, null, 8 /* PROPS */, ["content"])
        ]))
      }), 128 /* KEYED_FRAGMENT */)),
      _createElementVNode("article", _hoisted_55, [
        _createElementVNode("img", {
          class: "cover-image img-fluid",
          src: _ctx.imageSources.section
        }, null, 8 /* PROPS */, _hoisted_56),
        _cache[36] || (_cache[36] = _createElementVNode("div", { class: "cover-stripe" }, null, -1 /* HOISTED */)),
        _cache[37] || (_cache[37] = _createElementVNode("div", { class: "cover-content h-75 w-100 d-flex flex-column justify-content-end" }, [
          _createElementVNode("div", { class: "cover-title ms-auto" }, [
            _createElementVNode("h1", null, "Social Activity")
          ])
        ], -1 /* HOISTED */))
      ]),
      _createElementVNode("article", _hoisted_57, [
        _createVNode(_component_page_header, {
          content: _ctx.headerContent('Social Activity')
        }, null, 8 /* PROPS */, ["content"]),
        _cache[38] || (_cache[38] = _createElementVNode("h3", null, "Engagement and Day Part Analysis", -1 /* HOISTED */)),
        _createVNode(_component_chart_grid, {
          charts: _ctx.chartData.socialActivity.engagement.charts,
          "charts-associated-data": _ctx.chartData.socialActivity.engagement.associatedData
        }, null, 8 /* PROPS */, ["charts", "charts-associated-data"]),
        _createVNode(_component_highcharts, {
          options: _ctx.chartData.socialActivity.weeklyActivity.chart
        }, null, 8 /* PROPS */, ["options"]),
        _createElementVNode("div", _hoisted_58, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.chartData.socialActivity.weeklyActivity.detail, (detailSection) => {
            return (_openBlock(), _createElementBlock("div", _hoisted_59, [
              _createElementVNode("div", _hoisted_60, [
                _createElementVNode("u", {
                  class: "h5 text-primary mb-3 mx-auto text-nowrap",
                  innerHTML: detailSection.name
                }, null, 8 /* PROPS */, _hoisted_61),
                _createElementVNode("div", _hoisted_62, [
                  _createElementVNode("table", _hoisted_63, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(detailSection.items, (item) => {
                      return (_openBlock(), _createElementBlock("tr", {
                        key: item.index
                      }, [
                        _createElementVNode("td", _hoisted_64, _toDisplayString(item.index) + ". " + _toDisplayString(item.label), 1 /* TEXT */),
                        _createElementVNode("td", {
                          class: "align-top text-end text-nowrap",
                          innerHTML: item.value
                        }, null, 8 /* PROPS */, _hoisted_65)
                      ]))
                    }), 128 /* KEYED_FRAGMENT */))
                  ])
                ])
              ])
            ]))
          }), 256 /* UNKEYED_FRAGMENT */))
        ]),
        _createVNode(_component_page_footer, {
          content: _ctx.footerContent()
        }, null, 8 /* PROPS */, ["content"])
      ]),
      (_ctx.displayTopSocialContent)
        ? (_openBlock(), _createElementBlock("article", _hoisted_66, [
            _createVNode(_component_page_header, {
              content: _ctx.headerContent('Social Activity')
            }, null, 8 /* PROPS */, ["content"]),
            _cache[41] || (_cache[41] = _createElementVNode("h3", null, "Top Content", -1 /* HOISTED */)),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.chartData.socialActivity.topContent, (chartElements) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "row",
                "data-chart-id": chartElements.chart.id,
                key: chartElements.chart.id
              }, [
                _createElementVNode("div", _hoisted_68, [
                  _createVNode(_component_highcharts, {
                    options: chartElements.chart
                  }, null, 8 /* PROPS */, ["options"])
                ]),
                _createCommentVNode(" Detail sidebar "),
                _createElementVNode("div", _hoisted_69, [
                  _createElementVNode("div", _hoisted_70, [
                    _createElementVNode("u", {
                      class: "h5 text-primary mb-3 mx-auto text-nowrap",
                      innerHTML: `${chartElements.detail.contentTypeLabel} Used by Persona`
                    }, null, 8 /* PROPS */, _hoisted_71),
                    _createElementVNode("table", _hoisted_72, [
                      _createElementVNode("thead", null, [
                        _createElementVNode("tr", null, [
                          _createElementVNode("th", null, [
                            _createElementVNode("u", null, _toDisplayString(chartElements.detail.contentTypeLabel), 1 /* TEXT */)
                          ]),
                          _cache[39] || (_cache[39] = _createElementVNode("th", { class: "text-center" }, [
                            _createElementVNode("u", null, "Index")
                          ], -1 /* HOISTED */)),
                          _cache[40] || (_cache[40] = _createElementVNode("th", { class: "text-center" }, [
                            _createElementVNode("u", null, "Total Post Count")
                          ], -1 /* HOISTED */))
                        ])
                      ]),
                      _createElementVNode("tbody", null, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(chartElements.detail.items, (item) => {
                          return (_openBlock(), _createElementBlock("tr", {
                            key: item.index
                          }, [
                            _createElementVNode("td", _hoisted_73, _toDisplayString(item.index) + ". " + _toDisplayString(item.label), 1 /* TEXT */),
                            _createElementVNode("td", {
                              class: "align-top text-center",
                              innerHTML: item.indexValue
                            }, null, 8 /* PROPS */, _hoisted_74),
                            _createElementVNode("td", {
                              class: "align-top text-center",
                              innerHTML: item.countValue
                            }, null, 8 /* PROPS */, _hoisted_75)
                          ]))
                        }), 128 /* KEYED_FRAGMENT */))
                      ])
                    ])
                  ])
                ])
              ], 8 /* PROPS */, _hoisted_67))
            }), 128 /* KEYED_FRAGMENT */)),
            _createVNode(_component_page_footer, {
              content: _ctx.footerContent()
            }, null, 8 /* PROPS */, ["content"])
          ]))
        : _createCommentVNode("v-if", true),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.paginatedCharts('brandInterestEngagement', 1), (chartPage, index) => {
        return (_openBlock(), _createElementBlock("article", {
          key: index,
          class: "sheet content-page",
          "data-test": "brand and interest detail page"
        }, [
          _createVNode(_component_page_header, {
            content: _ctx.headerContent('Brand and Interest Engagement')
          }, null, 8 /* PROPS */, ["content"]),
          (index === 0)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _cache[42] || (_cache[42] = _createElementVNode("h2", null, "Top Categories Followed", -1 /* HOISTED */)),
                _cache[43] || (_cache[43] = _createElementVNode("p", null, "Index values are calculated using a baseline built from the active social accounts in the U.S. Brand and Interest handles are curated based on Wiland's proprietary taxonomy.", -1 /* HOISTED */))
              ], 64 /* STABLE_FRAGMENT */))
            : _createCommentVNode("v-if", true),
          _createVNode(_component_chart_grid, {
            "chart-wrapper-class": "col-12",
            charts: chartPage.charts
          }, null, 8 /* PROPS */, ["charts"]),
          _createVNode(_component_page_footer, {
            content: _ctx.footerContent()
          }, null, 8 /* PROPS */, ["content"])
        ]))
      }), 128 /* KEYED_FRAGMENT */)),
      (_ctx.allowFeature('conglomerateRfm'))
        ? (_openBlock(), _createElementBlock("section", _hoisted_76, [
            _createElementVNode("article", _hoisted_77, [
              _createElementVNode("img", {
                class: "cover-image img-fluid",
                src: _ctx.imageSources.section
              }, null, 8 /* PROPS */, _hoisted_78),
              _cache[44] || (_cache[44] = _createElementVNode("div", { class: "cover-stripe" }, null, -1 /* HOISTED */)),
              _cache[45] || (_cache[45] = _createElementVNode("div", { class: "cover-content h-75 w-100 d-flex flex-column justify-content-end" }, [
                _createElementVNode("div", { class: "cover-title ms-auto" }, [
                  _createElementVNode("h1", null, "Market Affinity")
                ])
              ], -1 /* HOISTED */))
            ]),
            _createElementVNode("article", _hoisted_79, [
              _createVNode(_component_page_header, {
                content: _ctx.headerContent('Market Affinity')
              }, null, 8 /* PROPS */, ["content"]),
              _cache[46] || (_cache[46] = _createElementVNode("p", null, "Market Affinity data is based on actual historical credit card data sourced from 3rd party vendors. The data describes which individuals have had above-average credit card spending in a given market over the past 24 months. Groups with above-average historical spending in a market are likely to continue spending in that market moving forward.", -1 /* HOISTED */)),
              _createVNode(_component_chart_grid, {
                charts: _ctx.chartData.conglomerateRfmMarket.charts,
                "chart-wrapper-class": "col-10 offset-1"
              }, null, 8 /* PROPS */, ["charts"]),
              _createVNode(_component_page_footer, {
                content: _ctx.footerContent()
              }, null, 8 /* PROPS */, ["content"])
            ]),
            (_ctx.chartData.conglomerateRfmMarket.selectedMarkets !== null)
              ? (_openBlock(), _createElementBlock("section", _hoisted_80, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.chartData.conglomerateRfmMarket.selectedMarkets, (selectedMarket, index) => {
                    return (_openBlock(), _createElementBlock("article", {
                      key: `selected_market_${index}`,
                      class: "sheet content-page",
                      "data-test": "market affinity selected market page"
                    }, [
                      _createVNode(_component_page_header, {
                        content: _ctx.headerContent(`Market Affinity`)
                      }, null, 8 /* PROPS */, ["content"]),
                      _createElementVNode("h3", null, _toDisplayString(selectedMarket.name), 1 /* TEXT */),
                      _createVNode(_component_chart_grid, {
                        charts: selectedMarket.charts,
                        "charts-associated-data": selectedMarket.associatedData
                      }, null, 8 /* PROPS */, ["charts", "charts-associated-data"]),
                      _createVNode(_component_page_footer, {
                        content: _ctx.footerContent()
                      }, null, 8 /* PROPS */, ["content"])
                    ]))
                  }), 128 /* KEYED_FRAGMENT */))
                ]))
              : _createCommentVNode("v-if", true)
          ]))
        : _createCommentVNode("v-if", true),
      (_ctx.allowFeature('predictedSpend'))
        ? (_openBlock(), _createElementBlock("section", _hoisted_81, [
            _createElementVNode("article", _hoisted_82, [
              _createElementVNode("img", {
                class: "cover-image img-fluid",
                src: _ctx.imageSources.section
              }, null, 8 /* PROPS */, _hoisted_83),
              _cache[47] || (_cache[47] = _createElementVNode("div", { class: "cover-stripe" }, null, -1 /* HOISTED */)),
              _cache[48] || (_cache[48] = _createElementVNode("div", { class: "cover-content h-75 w-100 d-flex flex-column justify-content-end" }, [
                _createElementVNode("div", { class: "cover-title ms-auto" }, [
                  _createElementVNode("h1", null, "Predicted Spend")
                ])
              ], -1 /* HOISTED */))
            ]),
            _createElementVNode("article", _hoisted_84, [
              _createVNode(_component_page_header, {
                content: _ctx.headerContent('Predicted Spend')
              }, null, 8 /* PROPS */, ["content"]),
              _cache[49] || (_cache[49] = _createElementVNode("h2", null, "Predicted Spend: Top Categories", -1 /* HOISTED */)),
              _cache[50] || (_cache[50] = _createElementVNode("p", null, "Unique to the Wiland data universe, predicted spend is based on actual transaction data and modeled to find the most likely spending categories for this Persona.", -1 /* HOISTED */)),
              _createVNode(_component_highcharts, {
                options: _ctx.chartData.predictedSpendSummary
              }, null, 8 /* PROPS */, ["options"]),
              _createVNode(_component_page_footer, {
                content: _ctx.footerContent()
              }, null, 8 /* PROPS */, ["content"])
            ])
          ]))
        : _createCommentVNode("v-if", true),
      (_ctx.allowFeature('highLevelRfm'))
        ? (_openBlock(), _createElementBlock("section", _hoisted_85, [
            _createElementVNode("article", _hoisted_86, [
              _createElementVNode("img", {
                class: "cover-image img-fluid",
                src: _ctx.imageSources.section
              }, null, 8 /* PROPS */, _hoisted_87),
              _cache[51] || (_cache[51] = _createElementVNode("div", { class: "cover-stripe" }, null, -1 /* HOISTED */)),
              _cache[52] || (_cache[52] = _createElementVNode("div", { class: "cover-content h-75 w-100 d-flex flex-column justify-content-end" }, [
                _createElementVNode("div", { class: "cover-title ms-auto" }, [
                  _createElementVNode("h1", null, "Recency, Frequency, and Monetary")
                ])
              ], -1 /* HOISTED */))
            ]),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.paginatedCharts('highLevelRfm'), (chartPage, index) => {
              return (_openBlock(), _createElementBlock("article", {
                key: index,
                class: "sheet content-page",
                "data-test": "high-level rfm detail page"
              }, [
                _createVNode(_component_page_header, {
                  content: _ctx.headerContent('Recency, Frequency, and Monetary')
                }, null, 8 /* PROPS */, ["content"]),
                _createVNode(_component_chart_grid, {
                  charts: chartPage.charts,
                  "charts-associated-data": _ctx.chartData.highLevelRfm.associatedData
                }, null, 8 /* PROPS */, ["charts", "charts-associated-data"]),
                _createVNode(_component_page_footer, {
                  content: _ctx.footerContent()
                }, null, 8 /* PROPS */, ["content"])
              ]))
            }), 128 /* KEYED_FRAGMENT */))
          ]))
        : _createCommentVNode("v-if", true),
      (!_ctx.allowFeature('conglomerateRfm'))
        ? (_openBlock(), _createElementBlock("section", _hoisted_88, [
            _createElementVNode("article", _hoisted_89, [
              _createElementVNode("img", {
                class: "cover-image img-fluid",
                src: _ctx.imageSources.section
              }, null, 8 /* PROPS */, _hoisted_90),
              _cache[53] || (_cache[53] = _createElementVNode("div", { class: "cover-stripe" }, null, -1 /* HOISTED */)),
              _cache[54] || (_cache[54] = _createElementVNode("div", { class: "cover-content h-75 w-100 d-flex flex-column justify-content-end" }, [
                _createElementVNode("div", { class: "cover-title ms-auto" }, [
                  _createElementVNode("h1", null, "Past Purchases")
                ])
              ], -1 /* HOISTED */))
            ]),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.chartData.pastPurchases.pages, (chartPage, index) => {
              return (_openBlock(), _createElementBlock("article", {
                key: index,
                class: "sheet content-page",
                "data-test": "past purchases detail page"
              }, [
                _createVNode(_component_page_header, {
                  content: _ctx.headerContent('Past Purchases')
                }, null, 8 /* PROPS */, ["content"]),
                (index === 0)
                  ? (_openBlock(), _createElementBlock("section", _hoisted_91, _cache[55] || (_cache[55] = [
                      _createElementVNode("p", null, "Past purchase data represents the transaction level household purchases for the past 24 months, aggregated into product categories.", -1 /* HOISTED */)
                    ])))
                  : _createCommentVNode("v-if", true),
                _createElementVNode("h3", null, "24 Month Purchase Transactions: " + _toDisplayString(chartPage.title), 1 /* TEXT */),
                _createVNode(_component_chart_grid, {
                  charts: chartPage.charts,
                  "charts-associated-data": _ctx.chartData.pastPurchases.associatedData
                }, null, 8 /* PROPS */, ["charts", "charts-associated-data"]),
                _createVNode(_component_page_footer, {
                  content: _ctx.footerContent()
                }, null, 8 /* PROPS */, ["content"])
              ]))
            }), 128 /* KEYED_FRAGMENT */))
          ]))
        : _createCommentVNode("v-if", true),
      (_ctx.chartData.hasOwnProperty('socialEngagement') || _ctx.chartData.hasOwnProperty('userDefinedTopics'))
        ? (_openBlock(), _createElementBlock("section", _hoisted_92, [
            _createElementVNode("article", _hoisted_93, [
              _createElementVNode("img", {
                class: "cover-image img-fluid",
                src: _ctx.imageSources.section
              }, null, 8 /* PROPS */, _hoisted_94),
              _cache[56] || (_cache[56] = _createElementVNode("div", { class: "cover-stripe" }, null, -1 /* HOISTED */)),
              _cache[57] || (_cache[57] = _createElementVNode("div", { class: "cover-content h-75 w-100 d-flex flex-column justify-content-end" }, [
                _createElementVNode("div", { class: "cover-title ms-auto" }, [
                  _createElementVNode("h1", null, "Added Charts")
                ])
              ], -1 /* HOISTED */))
            ]),
            (_ctx.chartData.hasOwnProperty('socialEngagement'))
              ? (_openBlock(), _createElementBlock("article", _hoisted_95, [
                  _createVNode(_component_page_header, {
                    content: _ctx.headerContent('Added Charts')
                  }, null, 8 /* PROPS */, ["content"]),
                  _cache[58] || (_cache[58] = _createElementVNode("h3", null, "Social Characteristics used to define this Persona", -1 /* HOISTED */)),
                  _createVNode(_component_chart_grid, {
                    "chart-wrapper-class": [_ctx.chartData.socialEngagement.charts.length > 1 ? 'col-6 px-5' : 'col-12'],
                    "chart-wrapper-class-ignore": "col-12",
                    charts: _ctx.chartData.socialEngagement.charts
                  }, null, 8 /* PROPS */, ["chart-wrapper-class", "charts"]),
                  _cache[59] || (_cache[59] = _createElementVNode("p", { class: "text-size-sm" }, "This chart contains the top 20 social characteristics used to define the Persona. If more characteristics were selected they are available in the online report but have been dropped from this print version.", -1 /* HOISTED */)),
                  _createVNode(_component_page_footer, {
                    content: _ctx.footerContent()
                  }, null, 8 /* PROPS */, ["content"])
                ]))
              : _createCommentVNode("v-if", true),
            (_ctx.chartData.hasOwnProperty('userDefinedTopics') && _ctx.chartData.userDefinedTopics.charts.length > 0)
              ? (_openBlock(), _createElementBlock("section", _hoisted_96, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.paginatedCharts('userDefinedTopics', 1), (chartPage, index) => {
                    return (_openBlock(), _createElementBlock("article", {
                      key: index,
                      class: "sheet content-page",
                      "data-test": "user-defined topic detail page"
                    }, [
                      _createVNode(_component_page_header, {
                        content: _ctx.headerContent('Added Charts')
                      }, null, 8 /* PROPS */, ["content"]),
                      _createElementVNode("h3", null, "Custom Topic: " + _toDisplayString(chartPage.charts[0].topicName), 1 /* TEXT */),
                      _createVNode(_component_chart_grid, {
                        "chart-wrapper-class": "col-12",
                        charts: chartPage.charts
                      }, null, 8 /* PROPS */, ["charts"]),
                      _cache[60] || (_cache[60] = _createElementVNode("p", { class: "text-size-sm" }, "This chart contains the top 20 characteristics in this custom topic. If this topic contained more than characteristics they are available in the online report but have been dropped from this print version.", -1 /* HOISTED */)),
                      _createVNode(_component_page_footer, {
                        content: _ctx.footerContent()
                      }, null, 8 /* PROPS */, ["content"])
                    ]))
                  }), 128 /* KEYED_FRAGMENT */))
                ]))
              : _createCommentVNode("v-if", true)
          ]))
        : _createCommentVNode("v-if", true),
      _createCommentVNode(" Back page "),
      _createElementVNode("article", _hoisted_97, [
        _createElementVNode("img", {
          class: "cover-image img-fluid",
          src: _ctx.imageSources.backPage
        }, null, 8 /* PROPS */, _hoisted_98),
        _createElementVNode("div", {
          class: "cover-stripe",
          src: _ctx.imageSources.cover
        }, null, 8 /* PROPS */, _hoisted_99),
        _createElementVNode("div", _hoisted_100, [
          _cache[63] || (_cache[63] = _createStaticVNode("<div class=\"flex-grow-1 d-flex flex-column justify-content-center\"><div class=\"cover-title\"><h2 class=\"mb-3\">This report was generated using the advanced analytics found in Wiland&#39;s PersonaBuilder application and the vast data resources found in Wiland&#39;s GraphMassive consumer graph.</h2></div><div class=\"w-75 p-3 ms-auto mt-5 h3 bg-info\"><div class=\"text-center\"><h2>Wiland</h2><h3>The best predictor of consumer response</h3></div></div></div>", 1)),
          _createElementVNode("div", _hoisted_101, [
            _createElementVNode("img", {
              class: "w-25 m-3",
              src: _ctx.imageSources.wilandLogo
            }, null, 8 /* PROPS */, _hoisted_102),
            _cache[62] || (_cache[62] = _createStaticVNode("<ul class=\"list-inline list-piped lead m-3\"><li class=\"list-inline-item\">Denver</li><li class=\"list-inline-item\">New York</li><li class=\"list-inline-item\">Washington, D.C.</li><li class=\"list-inline-item\">Little Rock</li></ul>", 1)),
            _createElementVNode("div", _hoisted_103, [
              _createElementVNode("span", _hoisted_104, [
                _createVNode(_component_font_awesome_icon, {
                  class: "fa-stack-2x text-gray-25",
                  icon: "circle"
                }),
                _createVNode(_component_font_awesome_icon, {
                  class: "fa-stack-1x text-light",
                  icon: "mouse-pointer",
                  style: {"transform":"rotate(-15deg)"}
                })
              ]),
              _cache[61] || (_cache[61] = _createTextVNode(" wiland.com "))
            ])
          ])
        ])
      ])
    ], 2 /* CLASS */)
  ]))
}