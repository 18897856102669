import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle, withKeys as _withKeys } from "vue"

const _hoisted_1 = { class: "ti-input" }
const _hoisted_2 = {
  key: 0,
  class: "ti-tags"
}
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "ti-content" }
const _hoisted_5 = {
  key: 0,
  class: "ti-tag-left"
}
const _hoisted_6 = ["onClick"]
const _hoisted_7 = {
  key: 1,
  class: "ti-tag-right"
}
const _hoisted_8 = { class: "ti-actions" }
const _hoisted_9 = ["onClick"]
const _hoisted_10 = ["onClick"]
const _hoisted_11 = { class: "ti-new-tag-input-wrapper" }
const _hoisted_12 = ["placeholder", "value", "maxlength", "disabled"]
const _hoisted_13 = ["onMouseover"]
const _hoisted_14 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tag_input = _resolveComponent("tag-input")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["vue-tags-input", [{ 'ti-disabled': _ctx.disabled }, { 'ti-focus': $data.focused }, _ctx.$attrs.class]]),
    style: _normalizeStyle(_ctx.$attrs.style)
  }, [
    _createElementVNode("div", _hoisted_1, [
      ($data.tagsCopy)
        ? (_openBlock(), _createElementBlock("ul", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.tagsCopy, (tag, index) => {
              return (_openBlock(), _createElementBlock("li", {
                class: _normalizeClass(["ti-tag", [
                        { 'ti-editing': $data.tagsEditStatus[index] },
                        tag.tiClasses,
                        tag.classes,
                        { 'ti-deletion-mark': $options.isMarked(index) }
                    ]]),
                key: index,
                style: _normalizeStyle(tag.style),
                tabindex: "0",
                onClick: ($event: any) => (_ctx.$emit('tag-clicked', { tag, index }))
              }, [
                _createElementVNode("div", _hoisted_4, [
                  (_ctx.$slots['tag-left'])
                    ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                        _renderSlot(_ctx.$slots, "tag-left", {
                          tag: tag,
                          index: index,
                          edit: $data.tagsEditStatus[index],
                          performSaveEdit: $options.performSaveTag,
                          performDelete: $options.performDeleteTag,
                          performCancelEdit: $options.cancelEdit,
                          performOpenEdit: $options.performEditTag,
                          deletionMark: $options.isMarked(index)
                        }, undefined, true)
                      ]))
                    : _createCommentVNode("v-if", true),
                  _createElementVNode("div", {
                    ref_for: true,
                    ref: $options.setTagCenter,
                    class: "ti-tag-center"
                  }, [
                    (!_ctx.$slots['tag-center'])
                      ? (_openBlock(), _createElementBlock("span", {
                          key: 0,
                          class: _normalizeClass({ 'ti-hidden': $data.tagsEditStatus[index] }),
                          onClick: ($event: any) => ($options.performEditTag(index))
                        }, _toDisplayString(tag.text), 11 /* TEXT, CLASS, PROPS */, _hoisted_6))
                      : _createCommentVNode("v-if", true),
                    (!_ctx.$slots['tag-center'])
                      ? (_openBlock(), _createBlock(_component_tag_input, {
                          key: 1,
                          scope: {
                                    edit: $data.tagsEditStatus[index],
                                    maxlength: _ctx.maxlength,
                                    tag,
                                    index,
                                    validateTag: $options.createChangedTag,
                                    performCancelEdit: $options.cancelEdit,
                                    performSaveEdit: $options.performSaveTag,
                                }
                        }, null, 8 /* PROPS */, ["scope"]))
                      : _createCommentVNode("v-if", true),
                    _renderSlot(_ctx.$slots, "tag-center", {
                      tag: tag,
                      index: index,
                      maxlength: _ctx.maxlength,
                      edit: $data.tagsEditStatus[index],
                      performSaveEdit: $options.performSaveTag,
                      performDelete: $options.performDeleteTag,
                      performCancelEdit: $options.cancelEdit,
                      validateTag: $options.createChangedTag,
                      performOpenEdit: $options.performEditTag,
                      deletionMark: $options.isMarked(index)
                    }, undefined, true)
                  ], 512 /* NEED_PATCH */),
                  (_ctx.$slots['tag-right'])
                    ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                        _renderSlot(_ctx.$slots, "tag-right", {
                          tag: tag,
                          index: index,
                          edit: $data.tagsEditStatus[index],
                          performSaveEdit: $options.performSaveTag,
                          performDelete: $options.performDeleteTag,
                          performCancelEdit: $options.cancelEdit,
                          performOpenEdit: $options.performEditTag,
                          deletionMark: $options.isMarked(index)
                        }, undefined, true)
                      ]))
                    : _createCommentVNode("v-if", true)
                ]),
                _createElementVNode("div", _hoisted_8, [
                  _createCommentVNode(" dont use v-if and v-else here -> different event calling on click?! "),
                  (!_ctx.$slots['tag-actions'])
                    ? _withDirectives((_openBlock(), _createElementBlock("i", {
                        key: 0,
                        class: "ti-icon-undo fa-solid fa-undo fa-sm",
                        onClick: ($event: any) => ($options.cancelEdit(index))
                      }, null, 8 /* PROPS */, _hoisted_9)), [
                        [_vShow, $data.tagsEditStatus[index]]
                      ])
                    : _createCommentVNode("v-if", true),
                  (!_ctx.$slots['tag-actions'])
                    ? _withDirectives((_openBlock(), _createElementBlock("i", {
                        key: 1,
                        class: "ti-icon-close fa-solid fa-xmark fa-sm",
                        onClick: ($event: any) => ($options.performDeleteTag(index))
                      }, null, 8 /* PROPS */, _hoisted_10)), [
                        [_vShow, !$data.tagsEditStatus[index]]
                      ])
                    : _createCommentVNode("v-if", true),
                  (_ctx.$slots['tag-actions'])
                    ? _renderSlot(_ctx.$slots, "tag-actions", {
                        key: 2,
                        tag: tag,
                        index: index,
                        edit: $data.tagsEditStatus[index],
                        performSaveEdit: $options.performSaveTag,
                        performDelete: $options.performDeleteTag,
                        performCancelEdit: $options.cancelEdit,
                        performOpenEdit: $options.performEditTag,
                        deletionMark: $options.isMarked(index)
                      }, undefined, true)
                    : _createCommentVNode("v-if", true)
                ])
              ], 14 /* CLASS, STYLE, PROPS */, _hoisted_3))
            }), 128 /* KEYED_FRAGMENT */)),
            _createElementVNode("li", _hoisted_11, [
              _createElementVNode("input", {
                ref: _ctx.inputRef,
                class: _normalizeClass([[$options.createClasses($data.newTag, _ctx.tags, _ctx.validation, _ctx.isDuplicate)], "ti-new-tag-input"]),
                placeholder: _ctx.placeholder,
                value: $data.newTag,
                maxlength: _ctx.maxlength,
                disabled: _ctx.disabled,
                type: "text",
                size: "1",
                onKeydown: [
                  _cache[0] || (_cache[0] = ($event: any) => ($options.performAddTags($options.filteredAutocompleteItems[$data.selectedItem] || $data.newTag, $event))),
                  _cache[2] || (_cache[2] = _withKeys(
//@ts-ignore
(...args) => ($options.invokeDelete && $options.invokeDelete(...args)), ["delete"])),
                  _cache[3] || (_cache[3] = _withKeys(
//@ts-ignore
(...args) => ($options.performBlur && $options.performBlur(...args)), ["tab"])),
                  _cache[4] || (_cache[4] = _withKeys(($event: any) => ($options.selectItem($event, 'before')), ["up"])),
                  _cache[5] || (_cache[5] = _withKeys(($event: any) => ($options.selectItem($event, 'after')), ["down"]))
                ],
                onPaste: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => ($options.addTagsFromPaste && $options.addTagsFromPaste(...args))),
                onInput: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => ($options.updateNewTag && $options.updateNewTag(...args))),
                onFocus: _cache[7] || (_cache[7] = ($event: any) => ($data.focused = true)),
                onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.addOnlyFromAutocomplete ? false : $data.selectedItem = null))
              }, null, 42 /* CLASS, PROPS, NEED_HYDRATION */, _hoisted_12)
            ])
          ]))
        : _createCommentVNode("v-if", true)
    ]),
    _renderSlot(_ctx.$slots, "between-elements", {}, undefined, true),
    ($options.autocompleteOpen)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "ti-autocomplete",
          onMouseout: _cache[9] || (_cache[9] = ($event: any) => ($data.selectedItem = null))
        }, [
          _renderSlot(_ctx.$slots, "autocomplete-header", {}, undefined, true),
          _createElementVNode("ul", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.filteredAutocompleteItems, (item, index) => {
              return (_openBlock(), _createElementBlock("li", {
                class: _normalizeClass(["ti-item", [
                        { 'ti-selected-item': $options.isSelected(index) },
                        item.tiClasses,
                        item.classes
                    ]]),
                key: index,
                style: _normalizeStyle(item.style),
                onMouseover: ($event: any) => (_ctx.disabled ? false : $data.selectedItem = index)
              }, [
                (!_ctx.$slots['autocomplete-item'])
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      onClick: ($event: any) => ($options.performAddTags(item, undefined, 'autocomplete'))
                    }, _toDisplayString(item.text), 9 /* TEXT, PROPS */, _hoisted_14))
                  : _renderSlot(_ctx.$slots, "autocomplete-item", {
                      key: 1,
                      item: item,
                      index: index,
                      performAdd: item => $options.performAddTags(item, undefined, 'autocomplete'),
                      selected: $options.isSelected(index)
                    }, undefined, true)
              ], 46 /* CLASS, STYLE, PROPS, NEED_HYDRATION */, _hoisted_13))
            }), 128 /* KEYED_FRAGMENT */))
          ]),
          _renderSlot(_ctx.$slots, "autocomplete-footer", {}, undefined, true)
        ], 32 /* NEED_HYDRATION */))
      : _createCommentVNode("v-if", true)
  ], 6 /* CLASS, STYLE */))
}