import api from 'Server/api';
import {cryptoError, generateSalt, getHash} from 'Server/crypto';
import {User} from 'Stores/user';
import {Account, AccountUserTokenAuth, useAccountStore} from 'Stores/account';
import {useAppStore} from 'Stores/common/app';
import {invalidCredentials} from 'Stores/common/defaults';
import {Actions, ObjectMode, RequestError, Status} from 'Utilities/immutables';
import {isValidUserAccount, sortByProperty} from 'Utilities/utils';
import cloneDeep from 'lodash-es/cloneDeep';
import {defineStore} from 'pinia';
import {getLocalTimeZoneByAbbr, systemTimeZoneAbbr} from 'Utilities/utils-static';
import {SecurityRole/*, SecurityRoleContext*/} from 'Stores/common/models';
import {isUndefinedOrNullOrEmpty} from 'Utilities/inspect';

export const sessionTimerSourceName = 'personabuilder.session-timer';

export interface UserState {
    user: User,
}

export interface User {
    id: string,
    firstName?: string,
    lastName?: string,
    email: string,
    password?: string,
    verification?: UserVerification,
    phone?: string,
    timezone?: string,
    companyName?: string,
    currentAccountId: string,
    inactiveTimeout?: number, // mins
    isActive?: boolean,
    isInternal?: boolean | null,
    isSuperUser?: boolean,
    lastLogin?: Date | null,
    lastUsed?: Date | null,
    accounts?: Array<any>,
    session?: UserSession,
    security: {
        roles: SecurityRole[];
        // context: SecurityRoleContext;
        // contexts: SecurityRoleContext[];
    },
    interaction?: {
        timestamp?: Date | string,
    }
    created?: UserTimestamp,
    updated?: UserTimestamp,
}

export interface UserCredentials {
    id?: string,
    accountId?: string,
    username?: string,
    password?: string,
    salt?: string,
    token?: string,
    token2fa?: string,
    code?: string,
    rememberMe?: boolean,
    action?: string,
}

export interface UserFeedback {
    id: string,
    userId: string,
    account: {
        id: string,
        name?: string,
    },
    ccEmail?: string,
    title: string,
    content: string,
    created?: UserTimestamp,
    updated?: UserTimestamp,
}

export interface UserInvitation {
    currentUserId: '',
    firstName: string,
    lastName: string,
    email: string,
    status?: string,
}

export interface UserPhoto {
    filePathUri: string,
    id: string,
    name: string,
}
export interface UserTimestamp {
    user?: {
        id?: string,
        firstName?: string,
        lastName?: string,
        email?: string,
    },
    timestamp?: Date | string,
}

export interface UserSession {
    source: string,
    active: boolean,
    timeout?: number, // mins
    now?: string,
    countdown?: number,// secs,
    action?: string,
    route?: string,
    noAuthRoutes?: string[],
}
export interface UserVerification {
    questions: {
        one: string,
        two: string,
        three: string,
    },
    answers?: {
        one: string,
        two: string,
        three: string,
    },
    populated?: boolean,
}

export enum UserLoaders {
    VALIDATING = 'validating user',
    SAVING = 'saving user',
    SENDING = 'sending',
}

export const UserMode = ObjectMode;
export const defaultUserVerification: UserVerification = { questions: { one: '', two: '', three:'' }, answers: { one: '', two: '', three:'' } };
export const defaultUserTimestamp = {
    user: {
        id: '',
        firstName: '',
        lastName: '',
    },
    timestamp: '',
}

export const defaultUserFeedback: UserFeedback = {
    id: '',
    userId: '',
    account: {
        id: '',
    },
    title: '',
    content: '',
};

export const defaultUserProfile: any = {
    id: '',
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    passwordMatch: false,
    salt: '',
    phone: '',
    timezone: getLocalTimeZoneByAbbr(systemTimeZoneAbbr),
    status: '',
    companyName: '',
    currentAccountId: '',
    verification: {
        questions: {
            one: '',
            two: '',
            three: '',
        },
        answers: {
            one: '',
            two: '',
            three: '',
        }
    },
    terms: {
        agreed: false,
        timestamp: '',
    },
};

export const defaultUser = {
    id: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    verification: {
        questions: {
            one: '',
            two: '',
            three: '',
        },
        answers: {
            one: '',
            two: '',
            three: '',
        },
    },
    timezone: '',
    companyName: '',
    currentAccountId: '',
    inactiveTimeout: 0, // mins
    isActive: false,
    isSuperUser: false,
    lastLogin: null,
    lastUsed: null,
    accounts: [],
    security: {
        roles: [],
        // context: SecurityRoleContext,
        // contexts: [],
    },
    interaction: {
        timestamp: new Date(),
    },
    created: {},
    updated: {},
};

export const useUserStore = defineStore('user', {
    state: (): UserState => ({
        user: cloneDeep( defaultUser )
    }),

    getters: {
        getLastValidUserAccount() {
            const lastActiveAccount: Account | null = useAccountStore().getActiveAccount ? useAccountStore().getActiveAccount : null;
            let validUserAccount: any = {};

            if (this.user.accounts) {
                if (lastActiveAccount && lastActiveAccount.hasOwnProperty('id')) {
                    if (isValidUserAccount(lastActiveAccount.id, this.user.accounts)) {
                        validUserAccount = lastActiveAccount;
                    }
                }
            }

            return validUserAccount;
        },
        getUser: (state: UserState) => state.user,
        getUserActiveAccounts(state: UserState) {
            let activeAccounts = state.user?.accounts?.filter(account => account.status === Status.ACTIVE);
            return sortByProperty(activeAccounts || [], 'name', 'asc');
        },
        getUserCurrentAccountId(state: UserState) {
            const sessionAccount: string = sessionStorage.getItem('account') || '';

            return state.user.currentAccountId
                ? state.user.currentAccountId : ((sessionAccount && sessionAccount !== 'undefined')
                    ? JSON.parse(sessionAccount).id : ''
                );
        },
        getUserInteractionTimestamp(state: UserState) {
            return state.user.interaction?.timestamp;
        },
        getUserProfileStatus(state: UserState): string {
            if (state.user.firstName && state.user.lastName && state.user.companyName && (state.user.verification && state.user.verification.populated)) {
                return Status.VALID;
            } else if (!state.user.verification || !state.user.verification.populated) {
                return `${Status.INVALID}-security`;
            } else {
                return Status.INVALID;
            }
        }
    },

    actions: {
        async getUserEmailValidation( value: string ) {
            const currentUser: User = this.user;
            const email: string = encodeURIComponent( value );

            try {
                const response = await api.getAxiosInstance.get( `/api/users/${currentUser.id}/emails/${email}` );

                return response.data?.data;
            } catch ( error ) {
                console.error( error );
            }
        },

        async sendUserFeedback(feedback: UserFeedback) {
            try {
                const currentUser: User = this.user;
                feedback.userId = currentUser.id;
                feedback.account = {id: currentUser.currentAccountId};
                const body: UserFeedback = feedback;
                const response = await api.getAxiosInstance.post( `/api/users/${currentUser.id}/feedback`, body );

                return response.data?.data;
            } catch ( error ) {
                console.error( error );
                return false;
            }
        },

        async setUserAuthentication() {
            const user: User = this.user;

            try {
                if ( user.id ) {
                    const body = { user };
                    const response = await api.getAxiosInstance.post( '/api/auth/check', body );
                    const authResponse = response.data;

                    if ( authResponse ) {
                        return authResponse;
                    } else {
                        return false;
                    }
                } else {
                    return false;
                }
              } catch ( error ) {
                // TODO: Need to add logger
                console.error( error );
            }
        },

        async setPersistedUser() {
            let persistedUser: any = {};

            try {
                const response = await api.getAxiosInstance.get('/api/auth/user?state=persisted');
                persistedUser = response.data || false

                if (persistedUser.id) {
                    const sessionAccounts: string | null = sessionStorage.getItem('accounts');

                    if (sessionAccounts) {
                        persistedUser.accounts = JSON.parse(sessionAccounts || '') || [];
                    } else if (persistedUser.accounts && persistedUser.accounts.length > 0) {
                        window.sessionStorage.setItem('accounts', JSON.stringify(persistedUser.accounts));
                    }

                    await this.setUser(persistedUser);

                    if (isUndefinedOrNullOrEmpty(useAccountStore().getAccount)) {
                        // No state or session account exits so refresh from API
                        await useAccountStore().setAccount({accountId: persistedUser.currentAccountId, isInitial: true, shared: false});
                    } else {
                        // state or session account exits so refresh from there
                        await useAccountStore().setAccount({accountId: persistedUser.currentAccountId, isInitial: false, shared: false});
                    }
                    
                    return true;
                } else {
                    throw `${RequestError.INVALID_USER}`;
                }
            } catch (error) {
                // TODO: Need to add logger
                console.error(error);
                return false;
            }

            return persistedUser;
        },

        async setTokenizedUser( auth: AccountUserTokenAuth ) {
            try {
                const response = await api.getAxiosInstance.get( `/api/auth/users/${auth.userId}/tokens/${auth.token}?accountId=${auth.accountId}&status=${auth.status}` );
                const user: any = response.data;

                if ( user && user.data ) {
                    await this.setUser( user.data );
                    return user.data;
                } else {
                    console.error(`🔥 Error getting tokenized account user ->`, user, response, auth);
                    return {reason: response.data}
                    // return false;
                }
            } catch ( error ) {
                // TODO: Need to add logger
                console.error(`🔥 Error getting tokenized account user...`);
                console.error( error );
            }
        },

        async setUser( user: User ) {
            try {
                this.user = user;
                this.setUserInteractionTimestamp();
                await this.setUserSecurityRoles(user);

                return true;
            } catch (error) {
                console.error(error);
                return false;
            }
        },

        async setUserAccounts( userId: string ) {
            try {
                const response = await api.getAxiosInstance.get(`/api/users/${userId}/accounts`);

                if ( response.data ) {
                    this.user.accounts = response.data.data;
                    window.sessionStorage.setItem( 'accounts', JSON.stringify( this.user.accounts ) );
                    return this.user.accounts;
                } else {
                    return await useAppStore().logout();
                }
            } catch (error) {
                console.error(error);
                return false;
            }
        },

        async setUserCurrentAccount( accountId: string ) {
            try {
                if ( this.user ) {
                    const body: any = { currentAccountId: accountId };
                    const response: any = await api.getAxiosInstance.patch( `/api/users/${this.user.id}`, body );
                    const user: any = response.data;

                    if ( user && user.hasOwnProperty( 'data' ) ) {
                        const sessionAccounts: string | null = sessionStorage.getItem( 'accounts' );
                        user.data.accounts = this.getUserActiveAccounts ? this.getUserActiveAccounts : sessionAccounts;
                        await this.setUser( user.data );
                        return true
                    } else {
                        return false;
                    }
                } else {
                    throw `${RequestError.UNABLE_TO_UPDATE} current account`;
                }
              } catch ( error ) {
                console.error( error );
                return false;
            }
	  	},

        setUserCurrentAccountId( accountId: string ) {
            try {
                this.user.currentAccountId = accountId;
                return true;
            } catch (error) {
                console.error(error);
                return false;
            }
        },

        async setUserEmail( credentials: UserCredentials ) {
            try {
                const currentUser: User = this.user;
                const currentAccountId: string = currentUser.currentAccountId;

                if ( currentUser ) {
                    const body: any = { currentAccountId, username: credentials.username };
                    const response: any = await api.getAxiosInstance.patch( `/api/users/${currentUser.id}`, body );
                    const user: any = response.data;

                    if ( user && user.hasOwnProperty( 'data' ) ) {
                        const sessionAccounts: string | null = sessionStorage.getItem( 'accounts' );
                        user.data.accounts = currentUser.accounts ? currentUser.accounts : sessionAccounts;
                        await this.setUser( user.data );
                        return response.data;
                    } else {
                        return false;
                    }
                } else {
                    throw `${RequestError.UNABLE_TO_UPDATE} user email`;
                }
            } catch (error) {
                console.error(error);
            }
        },

        async setUserEmail2faVerification( credentials: UserCredentials ) {
            try {
                let currentUser: any = this.user;

                const body: any = { userEmail: currentUser.email, newUserEmail: credentials.username };
                const response: any = await api.getAxiosInstance.post( `/api/users/${currentUser.id}/email/2fa`, body );
                const user: any = response.data;

                if ( user && user.hasOwnProperty( 'data' ) ) {
                    return user.data;
                } else {
                    return false;
                }
            } catch ( error ) {
                console.error( error );
            }
        },

        setUserInteractionTimestamp( userInteractionTimestamp: any = new Date() ) {
            try {
                this.user.interaction
                    ? this.user.interaction.timestamp = userInteractionTimestamp
                    : this.user.interaction = {
                        timestamp: userInteractionTimestamp
                    };
                return true;
            } catch (error) {
                console.error(error);
                return false;
            }
	  	},

        async setUserPassword( credentials: UserCredentials ) {
            try {
                const currentUser: User = this.user;

                if (credentials.password && (credentials.id === currentUser.id)) {
                    if (credentials.action === Actions.UPDATE) {
                        const response: any = await useAppStore().login({
                            username: credentials.username,
                            password: credentials.password,
                            salt: credentials.salt
                        });

                        if (response && response.hasOwnProperty('data')) {
                            return {code: 400, error: 'Password cannot match current password'};
                        }
                    }

                    credentials.salt = await generateSalt();
                    credentials.password = await getHash(credentials.password, credentials.salt || '');

                    if (credentials.salt && credentials.password) {
                        const body: any = {
                            currentAccountId: currentUser.currentAccountId,
                            currentUserId: currentUser.id,
                            salt: credentials.salt,
                            password: credentials.password,
                            token: credentials.token,
                            action: credentials.action
                        };
                        let route: any = '';

                        switch (credentials.action) {
                            case Actions.REGISTER:
                                route = `auth/users/${credentials.id}/${credentials.action}`;
                                break;
                            case Actions.USER_PASSWORD_RESET:
                                route = `auth/users/${credentials.id}/reset`;
                                break;
                            default:
                                route = `users/${credentials.id}`;
                                break;
                        }

                        const response = await api.getAxiosInstance.patch(`/api/${route}`, body);

                        if (response && response.hasOwnProperty('data')) {
                            return response.data;
                        } else {
                            return false;
                        }
                    } else {
                        throw cryptoError;
                    }
                } else {;
                    throw({error: invalidCredentials});
                }
            } catch (error) {
                console.error(error);
            }
        },

        async setUserProfile({profile, token, rememberMe}) {
            try {
                let body: any = {
                    currentAccountId: profile.currentAccountId,
                    userProfile: profile
                };
                let route: string = '';

                switch (profile.status) {
                    case Status.INVITED:
                        body.registrationToken = token;
                        body.rememberMe = rememberMe;
                        route = `auth/users/${profile.id}/register`;
                        break;
                    default:
                        route = `users/${profile.id}/profile`;
                        break;
                }

                const response = await api.getAxiosInstance.put(`/api/${route}`, body);
                let user: User = response?.data?.data;

                if (user) {
                    const currentUser: User = this.user;
                    const sessionAccounts: string | null = sessionStorage.getItem( 'accounts' );
                    user.accounts = currentUser.accounts ? currentUser.accounts : sessionAccounts;
                    await this.setUser( user );

                    return response.data;
                } else {
                    return false;
                }
            } catch (error) {
                console.error(error);
                return false;
            }
        },

        async setUserSecurityRoles(user: User) {
            if (!user?.hasOwnProperty('id') || !user.id) {
                return false;
            }

            try {
                // TODO: include this in INITIAL user response somehow?
                user = Object.assign(
                    {
                        security: {
                            roles: []
                        }
                    },
                    user
                );
                const response = await api.getAxiosInstance.get(`/api/users/${user.id}`);
                const userData = response.data?.data?.user;
                if (userData?.hasOwnProperty('roles')) {
                    // Set the roles
                    user.security.roles = userData.roles
                    this.user = user;

                    return true;
                } else {
                    return false;
                }
            } catch (error) {
                console.error(error);
                return false;
            }
        },

        async setUserVerification( verification: any ) {
            try {
                let currentUser: any = this.user;
                let currentAccountId: string = useUserStore().getUser.currentAccountId;

                const body: any = { currentAccountId, verification, currentUserId: currentUser.id };
                const response: any = await api.getAxiosInstance.patch( `/api/users/${currentUser.id}`, body );
                const user: any = response.data;

                if ( user && user.hasOwnProperty( 'data' ) ) {
                    await this.setUser( user.data );
                    return user.data;
                } else {
                    return false;
                }
            } catch ( error ) {
                console.error( error );
            }
        }
    }
});

