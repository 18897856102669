import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withMemo as _withMemo, Suspense as _Suspense, withCtx as _withCtx, createBlock as _createBlock, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, toDisplayString as _toDisplayString, vShow as _vShow, withModifiers as _withModifiers, resolveDirective as _resolveDirective, resolveDynamicComponent as _resolveDynamicComponent, renderList as _renderList, Fragment as _Fragment, createSlots as _createSlots } from "vue"

const _hoisted_1 = {
  class: "container-fluid p-3",
  "data-content-type": "PERSONA"
}
const _hoisted_2 = { class: "bg-white" }
const _hoisted_3 = { class: "container-fluid p-0" }
const _hoisted_4 = {
  key: 0,
  class: "p-5 text-center"
}
const _hoisted_5 = { class: "report-section bg-white" }
const _hoisted_6 = {
  key: 0,
  class: "row p-2"
}
const _hoisted_7 = {
  class: "col-auto position-relative align-self-center",
  "data-intent": "representative-photo"
}
const _hoisted_8 = { class: "col ps-0 align-self-center" }
const _hoisted_9 = { class: "text-primary header-title d-flex" }
const _hoisted_10 = { class: "py-0 px-1 flex-grow-1 align-self-center" }
const _hoisted_11 = ["disabled"]
const _hoisted_12 = { class: "d-flex flex-row" }
const _hoisted_13 = { class: "list-inline d-inline mb-0 me-3" }
const _hoisted_14 = { class: "list-inline-item" }
const _hoisted_15 = { class: "list-inline-item" }
const _hoisted_16 = {
  key: 0,
  class: "spinner-border spinner-border-sm",
  role: "status"
}
const _hoisted_17 = { class: "list-inline-item" }
const _hoisted_18 = {
  key: 0,
  class: "spinner-border spinner-border-sm",
  role: "status"
}
const _hoisted_19 = { class: "list-inline-item" }
const _hoisted_20 = { class: "header-detail small" }
const _hoisted_21 = { class: "ms-auto" }
const _hoisted_22 = {
  key: 0,
  class: "ms-auto me-2"
}
const _hoisted_23 = ["disabled"]
const _hoisted_24 = ["disabled"]
const _hoisted_25 = { class: "col-auto my-auto" }
const _hoisted_26 = ["onerror", "src"]
const _hoisted_27 = { class: "bg-white" }
const _hoisted_28 = { key: 0 }
const _hoisted_29 = {
  key: 0,
  class: "section-title h3 text-center my-3"
}
const _hoisted_30 = {
  key: 0,
  class: "d-inline-block",
  "data-bs-toggle": "dropdown"
}
const _hoisted_31 = { class: "dropdown dropdown-bubble" }
const _hoisted_32 = {
  "aria-expanded": "false",
  "aria-haspopup": "true",
  class: "d-block hover-orange-75 show-orange",
  "data-bs-toggle": "dropdown",
  href: "",
  id: "pageContextMenu",
  role: "button"
}
const _hoisted_33 = {
  "aria-labelledby": "pageContextMenu",
  class: "dropdown-menu dropdown-menu-end"
}
const _hoisted_34 = ["onClick"]
const _hoisted_35 = { class: "d-flex flex-column-reverse" }
const _hoisted_36 = ["innerHTML"]
const _hoisted_37 = {
  key: 1,
  class: "sort-options text-center col-10 col-lg-8 offset-1 offset-lg-2 mt-3"
}
const _hoisted_38 = {
  class: "btn-group select-group d-flex",
  role: "group"
}
const _hoisted_39 = ["onClick"]
const _hoisted_40 = {
  key: 1,
  class: "btn-group dropdown-bubble",
  role: "group"
}
const _hoisted_41 = { class: "dropdown" }
const _hoisted_42 = ["id", "disabled"]
const _hoisted_43 = ["aria-labelledby"]
const _hoisted_44 = ["onClick"]
const _hoisted_45 = { key: 1 }
const _hoisted_46 = { class: "d-none" }
const _hoisted_47 = { key: 3 }
const _hoisted_48 = { class: "row mb-3 text-primary" }
const _hoisted_49 = { class: "col text-start" }
const _hoisted_50 = { class: "col text-end" }
const _hoisted_51 = { id: "characteristics" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_choose_representative_photo = _resolveComponent("choose-representative-photo")!
  const _component_subnav = _resolveComponent("subnav")!
  const _component_on_page_help = _resolveComponent("on-page-help")!
  const _component_chart_grid = _resolveComponent("chart-grid")!
  const _component_spinner = _resolveComponent("spinner")!
  const _component_characteristics = _resolveComponent("characteristics")!
  const _component_bootstrap_modal = _resolveComponent("bootstrap-modal")!
  const _component_share = _resolveComponent("share")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("article", _hoisted_1, [
    _createElementVNode("section", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_ctx.ready && !_ctx.insights.is_data_available)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _cache[15] || (_cache[15] = _createElementVNode("p", null, "PersonaBuilder encountered an error while loading report data. Would you like to try again?", -1 /* HOISTED */)),
              _createElementVNode("button", {
                class: "btn btn-success btn-arrow-right",
                type: "button",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.go()))
              }, [
                _createVNode(_component_font_awesome_icon, { icon: ['solid', 'redo'] }),
                _cache[14] || (_cache[14] = _createTextVNode(" Reload Page "))
              ])
            ]))
          : _createCommentVNode("v-if", true),
        (_ctx.ready)
          ? (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: _normalizeClass(["report-container", {'bg-primary': !_ctx.printMode}])
            }, [
              _createElementVNode("div", _hoisted_5, [
                _createCommentVNode(" Header "),
                (!_ctx.printMode)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                      _createElementVNode("div", _hoisted_7, [
                        (_openBlock(), _createBlock(_Suspense, null, {
                          fallback: _withCtx(() => _cache[16] || (_cache[16] = [
                            _createElementVNode("div", {
                              role: "status",
                              class: "text-primary spinner-border spinner-border-sm p-2 mx-3"
                            }, [
                              _createElementVNode("span", { class: "visually-hidden" }, "Loading...")
                            ], -1 /* HOISTED */)
                          ])),
                          default: _withCtx(() => [
                            _withMemo([_ctx.persona.userPhoto?.id, _ctx.persona.photoCharacteristics], () => _createVNode(_component_choose_representative_photo, {
                              insights: _ctx.insights,
                              object: _ctx.persona,
                              onUpdateUserPhoto: _ctx.updateUserPhoto
                            }, null, 8 /* PROPS */, ["insights", "object", "onUpdateUserPhoto"]), _cache, 1)
                          ], undefined, true),
                          _: 1 /* STABLE */
                        }))
                      ]),
                      _createElementVNode("div", _hoisted_8, [
                        _createElementVNode("form", {
                          onSubmit: _cache[5] || (_cache[5] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.updatePersonaName && _ctx.updatePersonaName(...args)), ["prevent"]))
                        }, [
                          _createElementVNode("div", _hoisted_9, [
                            _cache[17] || (_cache[17] = _createElementVNode("div", { class: "py-0 align-self-center" }, [
                              _createElementVNode("strong", null, "Persona:")
                            ], -1 /* HOISTED */)),
                            _createElementVNode("div", _hoisted_10, [
                              _withDirectives(_createElementVNode("input", {
                                class: _normalizeClass(["form-control mx-n1 px-1 w-100", {'is-invalid': _ctx.personaNameValidation.error ? _ctx.personaNameValidation.error.message : false, 'bg-white': _ctx.sharedMode}]),
                                "data-action": "update-build-name",
                                disabled: _ctx.sharedMode ? true : null || _ctx.personaNameLoading ? true : null,
                                maxlength: "100",
                                type: "text",
                                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.editablePersonaName) = $event)),
                                onBlur: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.blurUpdatePersonaName && _ctx.blurUpdatePersonaName(...args))),
                                onFocus: _cache[4] || (_cache[4] = ($event: any) => (_ctx.allowUpdatePersonaName = true))
                              }, null, 42 /* CLASS, PROPS, NEED_HYDRATION */, _hoisted_11), [
                                [_vModelText, _ctx.editablePersonaName]
                              ])
                            ]),
                            _withDirectives(_createElementVNode("div", { class: "py-0 px-1 align-self-center text-danger" }, _toDisplayString(_ctx.personaNameValidation.error.message), 513 /* TEXT, NEED_PATCH */), [
                              [_vShow, _ctx.personaNameValidation.error.message.length]
                            ])
                          ])
                        ], 32 /* NEED_HYDRATION */),
                        _createElementVNode("div", _hoisted_12, [
                          _createElementVNode("ul", _hoisted_13, [
                            _createElementVNode("li", _hoisted_14, [
                              _createElementVNode("a", {
                                href: "",
                                onClick: _cache[6] || (_cache[6] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.showSummaryInfo && _ctx.showSummaryInfo(...args)), ["prevent"]))
                              }, [
                                _createVNode(_component_font_awesome_icon, {
                                  icon: ['solid', 'circle-info'],
                                  "fixed-width": ""
                                })
                              ])
                            ]),
                            _createElementVNode("li", _hoisted_15, [
                              (_ctx.pdfSharing)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_16, _cache[18] || (_cache[18] = [
                                    _createElementVNode("span", { class: "visually-hidden" }, "Loading...", -1 /* HOISTED */)
                                  ])))
                                : _withDirectives((_openBlock(), _createElementBlock("a", {
                                    key: 1,
                                    href: "",
                                    title: `Download or Share PDF`,
                                    onClick: _cache[7] || (_cache[7] = _withModifiers(($event: any) => (_ctx.shareModal('pdf', 'show')), ["prevent"]))
                                  }, [
                                    _createVNode(_component_font_awesome_icon, {
                                      icon: ['regular', 'file-pdf'],
                                      "fixed-width": ""
                                    })
                                  ])), [
                                    [_directive_tooltip]
                                  ])
                            ]),
                            _createElementVNode("li", _hoisted_17, [
                              (_ctx.reportSharing)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_18, _cache[19] || (_cache[19] = [
                                    _createElementVNode("span", { class: "visually-hidden" }, "Loading...", -1 /* HOISTED */)
                                  ])))
                                : _withDirectives((_openBlock(), _createElementBlock("a", {
                                    key: 1,
                                    href: "",
                                    title: `Share this Report`,
                                    onClick: _cache[8] || (_cache[8] = _withModifiers(($event: any) => (_ctx.shareModal('report', 'show')), ["prevent"]))
                                  }, [
                                    _createVNode(_component_font_awesome_icon, {
                                      icon: ['solid', 'share-from-square'],
                                      "fixed-width": ""
                                    })
                                  ])), [
                                    [_directive_tooltip]
                                  ])
                            ]),
                            _createElementVNode("li", _hoisted_19, [
                              _withDirectives((_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.sharedMode ? 'span' : 'router-link'), {
                                title: 'Build this Audience',
                                to: _ctx.sharedMode ? undefined : {
                                                    name: 'audienceBuild',
                                                    params: {
                                                        id: _ctx.persona.id,
                                                        tab: _ctx.audienceTabs.CONFIRMATION,
                                                    },
                                                    query: {
                                                        mode: _ctx.audienceMode.NEW,
                                                        // subnav: false,
                                                    }
                                                }
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_font_awesome_icon, {
                                    icon: ['regular', 'bullseye-arrow'],
                                    "fixed-width": ""
                                  })
                                ], undefined, true),
                                _: 1 /* STABLE */
                              }, 8 /* PROPS */, ["to"])), [
                                [_directive_tooltip]
                              ])
                            ])
                          ]),
                          _createElementVNode("em", _hoisted_20, "Built on " + _toDisplayString(_ctx.buildDate), 1 /* TEXT */),
                          _createElementVNode("div", _hoisted_21, [
                            (_ctx.allowUpdatePersonaName)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
                                  _createElementVNode("a", {
                                    href: "#",
                                    class: "no-color text-success",
                                    disabled: _ctx.personaNameLoading ? true : null,
                                    onClick: _cache[9] || (_cache[9] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.updatePersonaName && _ctx.updatePersonaName(...args)), ["prevent"]))
                                  }, [
                                    _createVNode(_component_font_awesome_icon, {
                                      icon: ['regular', 'circle-check'],
                                      size: "sm"
                                    })
                                  ], 8 /* PROPS */, _hoisted_23),
                                  _createElementVNode("a", {
                                    href: "#",
                                    class: "ms-1 no-color text-danger",
                                    disabled: _ctx.personaNameLoading ? true : null,
                                    onClick: _cache[10] || (_cache[10] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.updatePersonaNameCancel && _ctx.updatePersonaNameCancel(...args)), ["prevent"]))
                                  }, [
                                    _createVNode(_component_font_awesome_icon, {
                                      icon: ['regular', 'circle-xmark'],
                                      size: "sm"
                                    })
                                  ], 8 /* PROPS */, _hoisted_24)
                                ]))
                              : _createCommentVNode("v-if", true)
                          ])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_25, [
                        _createElementVNode("img", {
                          class: "brand-logo",
                          onerror: _ctx.Utils.imageFallback(_ctx.defaultBrandLogoPath),
                          src: _ctx.logoPath
                        }, null, 8 /* PROPS */, _hoisted_26)
                      ])
                    ]))
                  : _createCommentVNode("v-if", true),
                _createCommentVNode(" END header "),
                _createCommentVNode(" Subnav moved per SDSPOT-73 "),
                (!_ctx.printMode)
                  ? _withMemo([_ctx.subnavLinks], () => (_openBlock(), _createBlock(_component_subnav, {
                      key: 1,
                      class: "border-top",
                      "nav-links": _ctx.subnavLinks,
                      placement: "page",
                      "show-disabled-links": false
                    }, null, 8 /* PROPS */, ["nav-links"])), _cache, 11)
                  : _createCommentVNode("v-if", true),
                _createElementVNode("section", _hoisted_27, [
                  (_ctx.reportReady)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_28, [
                        (!_ctx.printMode)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_29, [
                              _createTextVNode(_toDisplayString(_ctx.sectionTitle) + " ", 1 /* TEXT */),
                              (_ctx.pageContextMenu)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_30, [
                                    _createElementVNode("div", _hoisted_31, [
                                      _createElementVNode("a", _hoisted_32, [
                                        _createTextVNode(_toDisplayString(_ctx.pageContextLabel) + " ", 1 /* TEXT */),
                                        _createVNode(_component_font_awesome_icon, {
                                          class: "ms-2 align-middle",
                                          icon: "angle-down",
                                          "fixed-width": ""
                                        })
                                      ]),
                                      _createElementVNode("div", _hoisted_33, [
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pageContextMenu, (option, index) => {
                                          return (_openBlock(), _createElementBlock("a", {
                                            key: index,
                                            class: _normalizeClass(["dropdown-item", {'router-link-active active disabled': option.context === _ctx.pageContext}]),
                                            href: "",
                                            onClick: _withModifiers(($event: any) => (_ctx.pageContext = option.context), ["prevent"])
                                          }, _toDisplayString(option.label), 11 /* TEXT, CLASS, PROPS */, _hoisted_34))
                                        }), 128 /* KEYED_FRAGMENT */))
                                      ])
                                    ])
                                  ]))
                                : _createCommentVNode("v-if", true),
                              _createVNode(_component_on_page_help, { context: _ctx.pageContext }, null, 8 /* PROPS */, ["context"])
                            ]))
                          : _createCommentVNode("v-if", true),
                        _createElementVNode("div", _hoisted_35, [
                          _createCommentVNode(" Charts "),
                          _createElementVNode("div", null, [
                            _createCommentVNode(" Non-standard custom layout component, e.g. for geographic data "),
                            (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.customLayout.component), {
                              "chart-data": _ctx.customLayout.chartData,
                              detail: _ctx.customLayout.detail || {},
                              key: _ctx.customComponentKey,
                              params: _ctx.customLayout.params || {},
                              ref: "customComponent",
                              "report-ready": _ctx.reportReady,
                              onSetSortGroup: _cache[12] || (_cache[12] = ($event: any) => (_ctx.sortGroup = $event))
                            }, _createSlots({ _: 2 /* DYNAMIC */ }, [
                              (_ctx.customLayout.afterChart)
                                ? {
                                    name: "afterChart",
                                    fn: _withCtx(() => [
                                      _createElementVNode("div", {
                                        innerHTML: _ctx.customLayout.afterChart
                                      }, null, 8 /* PROPS */, _hoisted_36)
                                    ]),
                                    key: "0"
                                  }
                                : undefined
                            ]), 1064 /* PROPS, NEED_HYDRATION, DYNAMIC_SLOTS */, ["chart-data", "detail", "params", "report-ready"]))
                          ]),
                          (!_ctx.customLayout)
                            ? (_openBlock(), _createBlock(_component_chart_grid, {
                                "chart-wrapper-class": {
                                            'offset-lg-3': _ctx.layoutColumns === 1,
                                            'col-lg-6': _ctx.layoutColumns > 0,
                                            'col': _ctx.layoutColumns < 0
                                        },
                                charts: _ctx.chartListData,
                                "charts-associated-data": _ctx.chartListAssociatedData,
                                key: _ctx.sectionKey
                              }, null, 8 /* PROPS */, ["chart-wrapper-class", "charts", "charts-associated-data"]))
                            : _createCommentVNode("v-if", true),
                          _createCommentVNode(" TODO: factor out into a component if needed "),
                          (_ctx.sortGroupOptions.length)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_37, [
                                _createElementVNode("div", _hoisted_38, [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sortGroupOptions, (button, index) => {
                                    return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                                      (button.hasOwnProperty('group'))
                                        ? (_openBlock(), _createElementBlock("button", {
                                            key: 0,
                                            class: _normalizeClass(["btn media-body w-100", [`select-${button.color}`, {active: _ctx.sortGroup === button.group}]]),
                                            onClick: ($event: any) => (_ctx.sortGroup = button.group)
                                          }, _toDisplayString(button.label), 11 /* TEXT, CLASS, PROPS */, _hoisted_39))
                                        : _createCommentVNode("v-if", true),
                                      _createCommentVNode(" Dropdown filters "),
                                      (button.hasOwnProperty('items'))
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_40, [
                                            _createElementVNode("div", _hoisted_41, [
                                              _createElementVNode("button", {
                                                id: button.id,
                                                class: _normalizeClass([`select-${button.color}`, "btn btn-secondary dropdown-toggle media-body"]),
                                                disabled: _ctx.chartDataLoading,
                                                type: "button",
                                                "data-bs-toggle": "dropdown",
                                                "aria-expanded": "false"
                                              }, _toDisplayString(button.label), 11 /* TEXT, CLASS, PROPS */, _hoisted_42),
                                              _withDirectives(_createElementVNode("ul", {
                                                class: "dropdown-menu",
                                                "aria-labelledby": button.id
                                              }, [
                                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(button.items, (item, index) => {
                                                  return (_openBlock(), _createElementBlock("li", { key: index }, [
                                                    _createElementVNode("a", {
                                                      class: "dropdown-item pointer",
                                                      href: undefined,
                                                      onClick: _withModifiers(($event: any) => (_ctx.filterSettings.count = item.value), ["prevent"])
                                                    }, _toDisplayString(item.label), 9 /* TEXT, PROPS */, _hoisted_44)
                                                  ]))
                                                }), 128 /* KEYED_FRAGMENT */))
                                              ], 8 /* PROPS */, _hoisted_43), [
                                                [_vShow, !_ctx.chartDataLoading]
                                              ])
                                            ])
                                          ]))
                                        : _createCommentVNode("v-if", true)
                                    ], 64 /* STABLE_FRAGMENT */))
                                  }), 128 /* KEYED_FRAGMENT */))
                                ])
                              ]))
                            : _createCommentVNode("v-if", true)
                        ])
                      ]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_45, [
                        _createVNode(_component_spinner, { text: "Building..." })
                      ]))
                ]),
                _createCommentVNode(" Font Awesome elements for late-binding in Highcharts "),
                _createElementVNode("div", _hoisted_46, [
                  _createVNode(_component_font_awesome_icon, {
                    "data-intent": "font-awesome-icon",
                    "data-type": "twitterMention",
                    icon: ['solid', 'at']
                  }),
                  _createVNode(_component_font_awesome_icon, {
                    "data-intent": "font-awesome-icon",
                    "data-type": "twitterHashtag",
                    icon: ['solid', 'hashtag']
                  }),
                  _createVNode(_component_font_awesome_icon, {
                    "data-intent": "font-awesome-icon",
                    "data-type": "twitterTerm",
                    icon: ['solid', 'quote-left']
                  })
                ])
              ]),
              (!_ctx.printMode)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass(["row p-4", {'mt-n4': !_ctx.params.tab}])
                  }, _cache[20] || (_cache[20] = [
                    _createElementVNode("div", { class: "col-lg-2 offset-lg-5" }, [
                      _createElementVNode("img", {
                        alt: "Powered by GRAPHMASSIVE®",
                        class: "img-fluid",
                        src: "/assets/images/powered-by-graphmassive-gray-25.svg"
                      })
                    ], -1 /* HOISTED */)
                  ]), 2 /* CLASS */))
                : _createCommentVNode("v-if", true)
            ], 2 /* CLASS */))
          : (_ctx.containsInactiveMarket)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                _createCommentVNode(" Error states "),
                _cache[21] || (_cache[21] = _createElementVNode("div", null, [
                  _createElementVNode("div", { class: "alert alert-warning" }, " This report was created with market affinity data that we have since purged from our system. If you want to view the report, you'll need to rebuild it. ")
                ], -1 /* HOISTED */))
              ], 2112 /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */))
            : (_ctx.containsSensitiveData)
              ? (_openBlock(), _createElementBlock("div", _hoisted_47, _cache[22] || (_cache[22] = [
                  _createElementVNode("div", { class: "alert alert-warning" }, " This report was created with sensitive data that we have since purged from our system. If you want to view the report, you'll need to rebuild it. ", -1 /* HOISTED */)
                ])))
              : (_openBlock(), _createBlock(_component_spinner, {
                  key: 4,
                  id: "personaReportMainLoader",
                  text: "Building..."
                }))
      ]),
      _createVNode(_component_bootstrap_modal, {
        "body-class": "text-normal",
        class: "modal-xl",
        "content-class": "bg-blue-25 border-primary",
        "hide-on-backdrop": false,
        "title-class": "text-primary",
        id: _ctx.summaryInfoModalId
      }, {
        title: _withCtx(() => _cache[23] || (_cache[23] = [
          _createTextVNode("Persona Summary Information")
        ])),
        body: _withCtx(() => [
          _createElementVNode("div", _hoisted_48, [
            _createElementVNode("div", _hoisted_49, [
              _cache[24] || (_cache[24] = _createTextVNode(" Persona Built By: ")),
              _createElementVNode("strong", null, _toDisplayString(_ctx.personaBuiltBy), 1 /* TEXT */)
            ]),
            _createElementVNode("div", _hoisted_50, [
              _cache[25] || (_cache[25] = _createTextVNode(" Persona based on ")),
              _createElementVNode("strong", null, _toDisplayString(_ctx.personaCount), 1 /* TEXT */),
              _cache[26] || (_cache[26] = _createTextVNode(" people "))
            ])
          ]),
          _createElementVNode("div", _hoisted_51, [
            _withMemo([_ctx.decoratedPersona], () => _createVNode(_component_characteristics, {
              class: "text-muted px-0",
              "inner-container-class": "card-body mt-n3 bg-blue-10",
              mode: _ctx.personaMode.REPORT,
              persona: _ctx.decoratedPersona
            }, null, 8 /* PROPS */, ["mode", "persona"]), _cache, 13)
          ])
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["id"])
    ]),
    _createVNode(_component_share, {
      ref: "share",
      "content-title": _ctx.editablePersonaName,
      "content-type": "Persona",
      context: _ctx.shareContext,
      link: _ctx.shareLink(_ctx.shareContext)
    }, null, 8 /* PROPS */, ["content-title", "context", "link"])
  ]))
}