<template>
    <section class="container-fluid px-0 pt-4 bg-comparison-report">
        <div class="row">
            <div v-for="(personaChartData, personaIndex) of chartData"
                 class="col-6"
                 :key="`personaChartData_${personaIndex}`"
            >
                <!-- Demographics -->
                <summary-section>
                    <template #icon>
                        <font-awesome-icon :icon="['duotone', FeatureIcons.DEMOGRAPHICS]" size="lg"/>
                    </template>
                    <template #title>
                        <router-link :to="summaryLink('demographics')">Demographics</router-link>
                    </template>
                    <template #content>
                        <div class="row g-0 m-n3">
                            <div v-for="(chart, index) of personaChartData.demographics.slice(0, 2)"
                                :key="index"
                                class="col-6"
                                data-chart-container
                            >
                                <highcharts
                                    :options="chart"
                                />
                            </div>
                        </div>

                        <!-- Hidden demographics collapse section -->
                        <div class="row g-0 collapse" id="additionalDemographicsCollapse">
                            <div v-for="(chart, index) of personaChartData.demographics.slice(2)"
                                :key="index"
                                class="col-6"
                                data-chart-container
                            >
                                <highcharts
                                    :options="chart"
                                />
                            </div>
                        </div>

                        <div class="w-100 text-center my-n3">
                            <button class="btn btn-link margin-auto"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#additionalDemographicsCollapse"
                                    @click="demographicsExpanded = !demographicsExpanded"
                            >
                                <font-awesome-icon
                                    v-show="demographicsExpanded"
                                    :icon="['solid', 'angle-up']"
                                />
                                <font-awesome-icon
                                    v-show="!demographicsExpanded"
                                    :icon="['solid', 'angle-down']"
                                />
                            </button>
                        </div>
                    </template>
                </summary-section>

                <!-- Unique Facts -->
                <summary-section>
                    <template #icon>
                        <img class="logo-icon"
                             :src="iconPath['personaBuilder']"
                             alt=""
                             tabindex="-1"
                        >
                    </template>
                    <template #title>
                        Unique Facts
                    </template>
                    <template #content>
                        <wedge-chart-section :data="{color: sectionColors[personaIndex], items: personaChartData.uniqueFacts}"/>
                    </template>
                </summary-section>

                <!-- Predicted Spend -->
                <summary-section v-if="personaChartData.predictedSpendActivity.length > 0">
                    <template #icon>
                        <font-awesome-icon :icon="['duotone', FeatureIcons.PREDICTED_SPEND]" size="lg"/>
                    </template>
                    <template #title>
                        <router-link :to="summaryLink('predicted-spend')">Predicted Spend</router-link>
                    </template>
                    <template #content>
                        <highcharts
                            v-for="(predictedSpendChart, index) of personaChartData.predictedSpendActivity"
                            :key="`consumer_spend_${index}`"
                            :options="predictedSpendChart"
                        />
                    </template>
                </summary-section>

                <!-- High-level RFM -->
                <summary-section v-if="personaChartData.hasOwnProperty('highLevelRfm')">
                    <template #icon>
                        <font-awesome-icon :icon="['duotone', FeatureIcons.HIGH_LEVEL_RFM]" size="lg"/>
                    </template>
                    <template #title>
                        <router-link :to="summaryLink('rfm')">RFM</router-link>
                    </template>
                    <template #content>
                        <highcharts
                            :options="personaChartData.highLevelRfm"
                        />
                    </template>
                </summary-section>

                <section>
                    <!-- Conglomerate RFM / IND33 / Market Affinity-->
                    <summary-section v-if="personaChartData.conglomerateRfmMarket">
                        <template #icon>
                            <font-awesome-icon :icon="['duotone', FeatureIcons.CONGLOMERATE_RFM_MARKETS]" size="lg"/>
                        </template>
                        <template #title>
                            <router-link :to="summaryLink('market-affinity')">Market Affinity, by Spend</router-link>
                        </template>
                        <template #content>
                            <div data-chart-container>
                                <highcharts :options="personaChartData.conglomerateRfmMarket"/>
                            </div>
                        </template>
                    </summary-section>

                    <!-- Past Purchases -->
                    <summary-section v-else>
                        <template #icon>
                            <font-awesome-icon :icon="['duotone', FeatureIcons.PAST_PURCHASES]" size="lg"/>
                        </template>
                        <template #title>
                            <router-link :to="summaryLink('past-purchases')">Past Purchase Activity</router-link>
                        </template>
                        <template #content>
                            <highcharts
                                v-for="(pastPurchaseChart, index) of personaChartData.pastPurchaseActivity"
                                :key="`past_purchase_${index}`"
                                :options="pastPurchaseChart"
                            />
                        </template>
                    </summary-section>
                </section>

                <!-- Weekly social activity -->
                <summary-section>
                    <template #icon>
                        <font-awesome-icon fixed-width :icon="['duotone', FeatureIcons.SOCIAL_AFFINITY]"/>
                    </template>
                    <template #title>
                        <router-link :to="summaryLink('social-activity')">Weekly Activity</router-link>
                    </template>
                    <template #content>
                        <highcharts
                            :options="personaChartData.socialWeeklyActivity"
                        />
                    </template>
                </summary-section>

                <!-- Political social engagement -->
                <summary-section>
                    <template #icon>
                        <font-awesome-icon :icon="['duotone', FeatureIcons.POLITICAL]" size="lg"/>
                    </template>
                    <template #title>
                        <router-link :to="summaryLink('interests')">Political Social Engagement</router-link>
                    </template>
                    <template #content>
                        <div class="row">
                            <template v-if="personaChartData.politicalSocialSummary.hasOwnProperty('twitter')">
                                <summary-widget
                                    v-show="itemContext['politicalSocialEngagement'] === 'twitter'"
                                    class="col-6"
                                    :summaryItems="[personaChartData.politicalSocialSummary.twitter[1]]"
                                    summaryType="twitter"
                                    tooltipPattern="[[ percent ]] of the group has engaged"
                                />
                                <summary-widget
                                    v-show="itemContext['politicalSocialEngagement'] === 'twitter'"
                                    class="col-6"
                                    :summaryItems="[personaChartData.politicalSocialSummary.twitter[0]]"
                                    summaryType="twitter"
                                    tooltipPattern="[[ percent ]] of the group has engaged"
                                />
                            </template>
                        </div>
                    </template>
                </summary-section>

                <!-- Political party affiliation -->
                <summary-section>
                    <template #icon>
                        <font-awesome-icon :icon="['duotone', FeatureIcons.POLITICAL]" size="lg"/>
                    </template>
                    <template #title>
                        <router-link
                            :to="summaryLink('demographics', 'politics-job-residence')">Political Party Affiliation
                        </router-link>
                    </template>
                    <template #content>
                        <highcharts
                            :options="personaChartData.politicalPartyAffiliation"
                        />
                    </template>
                </summary-section>

                <!-- Brand & Interest Summary -->
                <div class="row col-padding-half">
                    <div class="col-6">
                        <summary-section>
                            <template #icon>
                                <font-awesome-icon fixed-width :icon="['duotone', FeatureIcons.SOCIAL_AFFINITY]"/>
                            </template>
                            <template #title>
                                <router-link :to="summaryLink('brands')">Brand Summary</router-link>
                            </template>
                            <template #content>
                                <summary-widget
                                    v-if="personaChartData.brandSummary.hasOwnProperty('twitter')"
                                    v-show="itemContext['brand'] === 'twitter'"
                                    :summaryItems="personaChartData.brandSummary.twitter"
                                    summaryType="twitter"
                                    tooltipPattern="[[ percent ]] of the group is interested"
                                />
                            </template>
                        </summary-section>
                    </div>

                    <div class="col-6">
                        <summary-section>
                            <template #icon>
                                <font-awesome-icon fixed-width :icon="['duotone', FeatureIcons.SOCIAL_AFFINITY]"/>
                            </template>
                            <template #title>
                                <router-link :to="summaryLink('interests')">Interest Summary</router-link>
                            </template>
                            <template #content>
                                <summary-widget
                                    v-if="personaChartData.interestSummary.hasOwnProperty('twitter')"
                                    v-show="itemContext['interest'] === 'twitter'"
                                    :summaryItems="personaChartData.interestSummary.twitter"
                                    summaryType="twitter"
                                    tooltipPattern="[[ percent ]] of the group is interested"
                                />
                            </template>
                        </summary-section>
                    </div>
                </div>

                <!-- Topics -->
                <div class="row col-padding-half">
                    <div v-for="topic of personaChartData.socialTopics.slice(0, 2)"
                         class="col-6"
                         :key="topic.key"
                    >
                        <summary-section>
                            <template #icon>
                                <font-awesome-icon fixed-width :icon="['duotone', FeatureIcons.SOCIAL_AFFINITY]"/>
                            </template>
                            <template #title>
                                <router-link
                                    :to="summaryLink(topic.tab, topic.section || null)">{{ topic.category || topic.name }}
                                </router-link>
                            </template>
                            <template #content>
                                <persona-topic-widget
                                    :topic="topic"
                                    :context="itemContext[`topic-${topic.key}`]"
                                />
                            </template>
                        </summary-section>
                    </div>
                </div>
                <div v-if="personaChartData.socialTopics.length > 2" class="row col-padding-half">
                    <div v-for="topic of personaChartData.socialTopics.slice(2, 4)"
                         class="col-6"
                         :key="topic.key"
                    >
                        <summary-section>
                            <template #icon>
                                <font-awesome-icon fixed-width :icon="['duotone', FeatureIcons.SOCIAL_AFFINITY]"/>
                            </template>
                            <template #title>
                                <router-link
                                    :to="summaryLink(topic.tab, topic.section || null)">{{ topic.category || topic.name }}
                                </router-link>
                            </template>
                            <template #content>
                                <persona-topic-widget
                                    :topic="topic"
                                    :context="itemContext[`topic-${topic.key}`]"
                                />
                            </template>
                        </summary-section>
                    </div>
                </div>

                <!-- Geography -->
                <summary-section>
                    <template #icon>
                        <font-awesome-icon fixed-width :icon="['duotone', FeatureIcons.GEOGRAPHY]"/>
                    </template>
                    <template #title>
                        <router-link :to="summaryLink('geographic-areas')">Geography</router-link>
                    </template>
                    <template #content>
                        <highcharts
                            constructor-type="mapChart"
                            :options="personaChartData.geography.chart"
                            style="overflow: visible !important;"
                        />

                        <!-- Detail sidebar -->
                        <div class="row">
                            <div v-for="detailSection of personaChartData.geography.detail" class="col">
                                <div class="card bg-blue-10 border-0 p-3 mb-3">
                                    <u class="h5 text-primary mb-3 mx-auto">{{ detailSection.name }}</u>
                                    <div style="max-height: 17em; overflow: auto">
                                        <table class="text-primary w-100">
                                            <tr v-for="item of detailSection.items.index"
                                                :key="item.label">
                                                <td class="align-top">{{ item.index }}. {{ item.label }}</td>
                                                <td class="align-top text-end text-nowrap">{{ item.value }}</td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </summary-section>
            </div>
        </div>
    </section>
</template>

<script lang="ts">
    import {Vue, Component, Prop, toNative} from 'vue-facing-decorator';
    import Highcharts from 'highcharts';
    import highchartsMore from 'highcharts/highcharts-more';
    import {Chart} from 'highcharts-vue';
    import mapInit from 'highcharts/modules/map';
    import solidGaugeInit from 'highcharts/modules/solid-gauge';
    import * as Utils from 'Utilities/utils';
    import {iconPath} from 'Utilities/reports';
    import FontAwesomeIcon from 'Components/common/font-awesome-icon.vue';
    import SummarySection from 'Components/common/report/summary-section.vue';
    import SummaryWidget from 'Components/common/report/summary-widget.vue';
    import PersonaTopicWidget from 'Components/persona/report/custom-layout/persona-topic-widget.vue';
    import {FeatureIcons, useAppStore} from 'Stores/common/app';
    import {sectionColors} from 'Stores/comparison';
    import WedgeChartSection from 'Components/common/report/wedge-chart-section.vue';

    highchartsMore(Highcharts);
    mapInit(Highcharts);
    solidGaugeInit(Highcharts);

    @Component<ComparisonPersonaSummary>({
        components: {
            WedgeChartSection,
            highcharts: Chart,
            FontAwesomeIcon,
            PersonaTopicWidget,
            SummaryWidget,
            SummarySection,
        }
    })
    class ComparisonPersonaSummary extends Vue {
        @Prop({
            type: Array,
            default: () => {
                return {};
            }
        })
        chartData: any[];

        @Prop({
            type: Array,
            default: () => {
                return [];
            }
        })
        detail;

        @Prop({
            type: Object,
            default: () => {
            }
        })
        params;

        defaultSocialContext = 'twitter';
        demographicsExpanded: boolean = false;
        itemContext = {
            brand: this.defaultSocialContext,
            interest: this.defaultSocialContext,
            politicalSocialEngagement: this.defaultSocialContext,
        };

        /* Can be removed when moving to Composition API */
        iconPath = iconPath;
        sectionColors = sectionColors;
        FeatureIcons = FeatureIcons;
        Utils = Utils;

        // itemContextIcon(context: string) {
        //     return ['brand', this.itemContext[context]];
        // }

        // setItemContext(context: string, value: string) {
        //     this.itemContext[context] = value;
        // }

        summaryLink(tab: string, section?: string) {
            let link = {
                disabled: false,
                name: 'comparisonReport',
                params: {
                    tab,
                    section
                },
            };

            if (!section) {
                switch (tab) {
                    case 'brands':
                    case 'predicted-spend':
                    case 'interests':
                        link.params.section = 'summary';
                        break;
                    case 'custom-topics':
                        link.params.section = 'social-characteristics';
                        break;
                    case 'demographics':
                        link.params.section = 'age-income-home';
                        break;
                    case 'geographic-areas':
                        link.params.section = 'media-markets';
                        break;
                    case 'past-purchases':
                        link.params.section = 'clothing-children';
                        break;
                    case 'rfm':
                        link.params.section = 'revenue';
                        break;
                }
            }

            // TODO: update as we add more sections
            if (['past-purchases', 'social-activity'].includes(tab)) {
                link.disabled = true;
            }

            return link;
        }

        created() {
            // Initialize item contexts
            for (const personaChartData of this.chartData) {
                for (const socialTopic of personaChartData.socialTopics) {
                    this.itemContext[`topic-${socialTopic.key}`] = this.defaultSocialContext;
                }
            }
        }
    }
    export default toNative(ComparisonPersonaSummary);
</script>

<!--<style scoped lang="scss" src="../../../persona/report/custom-layout/persona-summary.scss"/>-->
