<template>
    <div class="col">
        <div v-show="personas.length < comparisonActivityCount" class="required is-invalid mb-3">
            {{ errorMessageNoPersonasSelected }}
        </div>
        <div class="row" data-reset="persona-counter">
            <div v-for="index in comparisonActivityCount" class="col">
                <h6>
                    <i v-if="personaAtIndex(index)">{{ personaAtIndex(index).name }} characteristics</i>
                    <span v-else>&nbsp;</span>
                </h6>
            </div>
        </div>
        <div class="row flex-row">
            <template v-for="index in comparisonActivityCount" :key="index">
                <div class="col" v-if="personaAtIndex(index)">
                    <div class="row">
                    <persona-characteristics
                        inner-container-class="card-body mt-n3 bg-blue-10"
                        :activities-IGNORE="activities"
                        :key="personaAtIndex(index).id"
                        :mode="PersonaMode.REPORT"
                        :persona="personaAtIndex(index)"
                        :persona-index="index"
                        :show-topics="false"
                    />
                    </div>
                </div>
                <div v-else class="col">
                    <div class="card overflow-hidden h-100">
                        <div class="card-body mt-n3 bg-gray-10 pt-3">
                            <div class="mt-3 is-invalid">
                                None selected yet
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </div>

        <div class="mt-3">
            <div v-if="!Utils.isEmptyObject(intersectionTopics)">
                <!--            <i>Topics for Analysis and Reporting</i>-->
                <i>These custom Topics are common between the Personas and are included in this comparison:</i>
                <div class="card topics bg-transparent border-0 mt-3">
                    <div class="card-body p-0">
                        <div v-for="(topicChunk, index) in intersectionTopicChunks" :key="index"
                             class="d-flex flex-row flex-fill"
                        >
                            <div v-for="topic in topicChunk" :key="topic.id"
                                 class="topic position-relative"
                                 :class="`topic-display-${topicStyle}`"
                            >
                                <button role="button" class="btn btn-lg detail-button filter-btn" disabled>
                                    <span>{{ topic.name }}</span>
                                </button>
                                <span class="details" :class="{selected: topic.selected}">
                                    <font-awesome-icon
                                        class="detail-icon"
                                        :icon="[ 'solid', 'circle-info' ]"
                                        @click.prevent="$refs['personaCharacteristics'].showSelectedTopicModal(topic)"
                                    />
                                </span>
                            </div>
                            <div v-if="topicStyle !== 'text'"
                                 v-for="n in (topicsPerRow - topicChunk.length)" :key="n"
                                 class="topic-spacer"
                            ></div>
                        </div>
                    </div>
                </div>
            </div>
            <i v-else class="fst-italic">
                No custom Topics are included in this Comparison
            </i>
        </div>

    </div>
</template>

<script lang="ts">
    import {Vue, Component, Prop, Watch, toNative} from 'vue-facing-decorator';
    import chunk from 'lodash-es/chunk';
    import cloneDeep from 'lodash-es/cloneDeep';
    import * as Utils from 'Utilities/utils';
    import {VueTagsInput} from 'Components/common/tag/vue-tags-input/publish';
    import FontAwesomeIcon from 'Components/common/font-awesome-icon.vue';
    import {PersonaMode} from 'Stores/persona';
    import {useComparisonStore, comparisonActivityCount} from 'Stores/comparison';
    import {Topic} from "Stores/topic";
    import {Error} from "Stores/common/models";
    import {defineAsyncComponent} from 'vue';

    @Component({
        components: {
            FontAwesomeIcon,
            PersonaCharacteristics: defineAsyncComponent(() =>
                import('Components/persona/characteristics.vue')
            ),
            VueTagsInput,
        },
        setup() {
            const comparisonStore = useComparisonStore();
            return {comparisonStore};
        },
    })
    class Characteristics extends Vue {
        @Prop({
            type: Object,
            default: null
        })
        comparison;

        @Prop({
            type: String,
            default: 'mt-3'
        })
        itemWrapperClass!: string;

        @Prop({
            type: String,
            default: ''
        })
        mode;

        @Prop({
            type: String,
            default: 'card overflow-hidden'
        })
        outerContainerClass!: string;

        comparisonActivityCount = comparisonActivityCount;
        errorMessageNoPersonasSelected: string = `You must select ${comparisonActivityCount} Personas before you can build this comparison.`;
        errorsIGNORE: Error[] = [];
        PersonaMode = PersonaMode;
        topicsPerRow: number = 5;
        topicStyle = 'button';
        Utils = Utils;

        get activities() {
            return this.comparisonStore.getActivities;
        }

        get intersectionTopics(): Topic[] {
            let topicIntersections = [];
            for (const persona of this.personas) {
                if (persona) {
                    // console.log(`PERSONA ${persona.name} - TOPICS: ${persona.topics.map(topic => topic.name).join('; ')}`);
                    for (const personaTopic of persona.topics) {
                        const existingTopic = topicIntersections.find(t => t.id === personaTopic.id);
                        if (existingTopic) {
                            ++existingTopic._intersectionCount;
                        } else {
                            topicIntersections.push(Object.assign({}, personaTopic, {_intersectionCount: 1}));
                        }
                    }
                }
            }

            return topicIntersections.filter(topic => topic._intersectionCount === comparisonActivityCount);
        }

        get intersectionTopicChunks() {
            return chunk(this.intersectionTopics, this.topicsPerRow);
        }

        get personas() {
            return this.comparisonStore.getPersonas;
        }

        personaAtIndex(index: number) {
            // TODO: return a blank Persona if there isn't one set?
            return this.personas[index - 1] ? cloneDeep(this.personas[index - 1]) : false;
        }
    }
    export default toNative(Characteristics);
</script>

<!--<style scoped lang="scss" src="./characteristics.scss"></style>-->
<!--<style scoped lang="scss" src="../../persona/characteristics.scss"></style>-->
<!--<style lang="scss" src="../topics/topics.scss"></style>-->
