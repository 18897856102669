import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex summary-section-header" }
const _hoisted_2 = { class: "summary-body d-flex p-3 align-middle align-content-center align-items-center border-right" }
const _hoisted_3 = { class: "p-3 overflow-ellipsis static" }


export default /*@__PURE__*/_defineComponent({
  __name: 'summary-section',
  props: {
        bodyClasses: {
            default: [],
            type: Array,
        },
        headerClasses: {
            default: [],
            type: Array,
        },
        wrapperClasses: {
            default: ['mb-4'],
            type: Array,
        },
    },
  setup(__props) {

    const props = __props;


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["card rounded-0", __props.wrapperClasses]),
    "data-frame": "summary-section"
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["card-header bg-transparent p-0", __props.headerClasses])
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _renderSlot(_ctx.$slots, "icon")
        ]),
        _createElementVNode("div", _hoisted_3, [
          _renderSlot(_ctx.$slots, "title")
        ])
      ])
    ], 2 /* CLASS */),
    _createElementVNode("div", {
      class: _normalizeClass(["card-body", __props.bodyClasses])
    }, [
      _renderSlot(_ctx.$slots, "content", {}, () => [
        _cache[0] || (_cache[0] = _createTextVNode("[PLEASE PROVIDE CONTENT VIA THE 'CONTENT' SLOT]"))
      ])
    ], 2 /* CLASS */)
  ], 2 /* CLASS */))
}
}

})