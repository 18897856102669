import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, withModifiers as _withModifiers, normalizeClass as _normalizeClass, withMemo as _withMemo, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, createSlots as _createSlots } from "vue"

const _hoisted_1 = {
  class: "container-fluid p-3",
  "data-content-type": "COMPARISON",
  id: "comparisonReport"
}
const _hoisted_2 = { class: "bg-white" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = {
  key: 0,
  class: "bg-comparison-report"
}
const _hoisted_6 = { class: "report-section bg-white" }
const _hoisted_7 = { key: 2 }
const _hoisted_8 = {
  key: 0,
  class: "section-title h3 text-center my-3"
}
const _hoisted_9 = { class: "dropdown dropdown-bubble" }
const _hoisted_10 = {
  "aria-expanded": "false",
  "aria-haspopup": "true",
  class: "d-block hover-orange-75 show-orange",
  "data-bs-toggle": "dropdown",
  href: "",
  id: "pageContextMenu",
  role: "button"
}
const _hoisted_11 = {
  "aria-labelledby": "pageContextMenu",
  class: "dropdown-menu dropdown-menu-end"
}
const _hoisted_12 = ["onClick"]
const _hoisted_13 = { class: "d-flex flex-column-reverse" }
const _hoisted_14 = { key: 0 }
const _hoisted_15 = ["innerHTML"]
const _hoisted_16 = { key: 1 }
const _hoisted_17 = { key: 2 }
const _hoisted_18 = { class: "filter-options text-center col-10 col-lg-8 offset-1 offset-lg-2 mt-3" }
const _hoisted_19 = {
  class: "btn-group select-group d-flex",
  role: "group"
}
const _hoisted_20 = ["onClick"]
const _hoisted_21 = {
  key: 1,
  class: "btn-group dropdown-bubble",
  role: "group"
}
const _hoisted_22 = ["id"]
const _hoisted_23 = ["aria-labelledby"]
const _hoisted_24 = ["onClick"]
const _hoisted_25 = { key: 3 }
const _hoisted_26 = { class: "sort-options text-center col-10 col-lg-8 offset-1 offset-lg-2 mt-3" }
const _hoisted_27 = {
  class: "btn-group select-group d-flex",
  role: "group"
}
const _hoisted_28 = ["onClick"]
const _hoisted_29 = {
  key: 1,
  class: "btn-group dropdown-bubble",
  role: "group"
}
const _hoisted_30 = ["id"]
const _hoisted_31 = ["aria-labelledby"]
const _hoisted_32 = ["onClick"]
const _hoisted_33 = { key: 3 }
const _hoisted_34 = {
  key: 0,
  class: "p-4 bg-white"
}
const _hoisted_35 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_spinner = _resolveComponent("spinner")!
  const _component_comparison_header = _resolveComponent("comparison-header")!
  const _component_subnav = _resolveComponent("subnav")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_on_page_help = _resolveComponent("on-page-help")!
  const _component_chart_grid = _resolveComponent("chart-grid")!

  return (_openBlock(), _createElementBlock("article", _hoisted_1, [
    _createElementVNode("section", _hoisted_2, [
      (_ctx.comparisonReportLoading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_spinner, { text: "Building..." })
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_4, [
            (_ctx.ready)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  _createElementVNode("div", _hoisted_6, [
                    (!_ctx.printMode)
                      ? (_openBlock(), _createBlock(_component_comparison_header, {
                          key: 0,
                          comparison: _ctx.comparison,
                          insights: _ctx.insights,
                          mode: _ctx.ComparisonMode.REPORT,
                          "share-token": _ctx.shareToken,
                          onUpdateShareToken: _cache[0] || (_cache[0] = (token) => _ctx.shareToken = token)
                        }, null, 8 /* PROPS */, ["comparison", "insights", "mode", "share-token"]))
                      : _createCommentVNode("v-if", true),
                    _createCommentVNode(" Subnav moved per SDSPOT-73 "),
                    (!_ctx.printMode)
                      ? (_openBlock(), _createBlock(_component_subnav, {
                          key: 1,
                          class: "border-top",
                          "nav-links": _ctx.subnavLinks,
                          placement: "page",
                          "show-disabled-links": false
                        }, null, 8 /* PROPS */, ["nav-links"]))
                      : _createCommentVNode("v-if", true),
                    (_ctx.reportReady)
                      ? (_openBlock(), _createElementBlock("section", _hoisted_7, [
                          (!_ctx.printMode)
                            ? (_openBlock(), _createElementBlock("h1", _hoisted_8, [
                                _createTextVNode(_toDisplayString(_ctx.sectionTitle) + " ", 1 /* TEXT */),
                                (_ctx.pageContextMenu)
                                  ? _withMemo([_ctx.pageContextMenu, _ctx.pageContext], () => (_openBlock(), _createElementBlock("div", {
                                      key: 0,
                                      class: "d-inline-block",
                                      "data-bs-toggle": "dropdown"
                                    }, [
                                      _createElementVNode("div", _hoisted_9, [
                                        _createElementVNode("a", _hoisted_10, [
                                          _createTextVNode(_toDisplayString(_ctx.pageContextLabel) + " ", 1 /* TEXT */),
                                          _createVNode(_component_font_awesome_icon, {
                                            class: "ms-2 align-middle",
                                            icon: "angle-down",
                                            "fixed-width": ""
                                          })
                                        ]),
                                        _createElementVNode("div", _hoisted_11, [
                                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pageContextMenu, (option, index) => {
                                            return (_openBlock(), _createElementBlock("a", {
                                              key: index,
                                              class: _normalizeClass(["dropdown-item", {'router-link-active active disabled': option.context === _ctx.pageContext}]),
                                              href: "",
                                              onClick: _withModifiers(($event: any) => (_ctx.pageContext = option.context), ["prevent"])
                                            }, _toDisplayString(option.label), 11 /* TEXT, CLASS, PROPS */, _hoisted_12))
                                          }), 128 /* KEYED_FRAGMENT */))
                                        ])
                                      ])
                                    ])), _cache, 1)
                                  : _createCommentVNode("v-if", true),
                                _createVNode(_component_on_page_help, { context: _ctx.pageContext }, null, 8 /* PROPS */, ["context"])
                              ]))
                            : _createCommentVNode("v-if", true),
                          _createElementVNode("div", _hoisted_13, [
                            _createCommentVNode(" Charts "),
                            (_ctx.hasCustomLayout)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                                  _createCommentVNode(" Non-standard custom layout component, e.g. for geographic data "),
                                  (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.customLayout.component), {
                                    "chart-data": _ctx.customLayout.chartData,
                                    detail: _ctx.customLayout.detail || {},
                                    key: _ctx.customComponentKey,
                                    params: _ctx.customLayout.params || {},
                                    ref: "customComponent",
                                    "report-ready": _ctx.reportReady
                                  }, _createSlots({ _: 2 /* DYNAMIC */ }, [
                                    (_ctx.customLayout.afterChart)
                                      ? {
                                          name: "afterChart",
                                          fn: _withCtx(() => [
                                            _createElementVNode("div", {
                                              innerHTML: _ctx.customLayout.afterChart
                                            }, null, 8 /* PROPS */, _hoisted_15)
                                          ]),
                                          key: "0"
                                        }
                                      : undefined
                                  ]), 1032 /* PROPS, DYNAMIC_SLOTS */, ["chart-data", "detail", "params", "report-ready"]))
                                ]))
                              : (_openBlock(), _createElementBlock("div", _hoisted_16, [
                                  (_openBlock(), _createBlock(_component_chart_grid, {
                                    "chart-wrapper-class": {'offset-lg-3': _ctx.layoutColumns === 1, 'col-lg-6': _ctx.layoutColumns > 0, 'col': _ctx.layoutColumns < 0},
                                    charts: _ctx.chartListData,
                                    "charts-associated-data": _ctx.chartListAssociatedData,
                                    key: _ctx.sectionKey
                                  }, null, 8 /* PROPS */, ["chart-wrapper-class", "charts", "charts-associated-data"]))
                                ])),
                            _createCommentVNode(" TODO: factor out into a component if needed "),
                            (_ctx.filterGroupOptions.length)
                              ? (_openBlock(), _createElementBlock("section", _hoisted_17, [
                                  _createElementVNode("div", _hoisted_18, [
                                    _createElementVNode("div", _hoisted_19, [
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filterGroupOptions, (button, index) => {
                                        return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                                          (button.hasOwnProperty('group'))
                                            ? (_openBlock(), _createElementBlock("button", {
                                                key: 0,
                                                class: _normalizeClass(["btn media-body w-100", [`select-${button.color}`, {active: _ctx.filterGroup === button.group}]]),
                                                onClick: ($event: any) => (_ctx.filterGroup = button.group)
                                              }, _toDisplayString(button.label), 11 /* TEXT, CLASS, PROPS */, _hoisted_20))
                                            : _createCommentVNode("v-if", true),
                                          _createCommentVNode(" Dropdown filters "),
                                          (button.hasOwnProperty('items'))
                                            ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                                                _createElementVNode("button", {
                                                  "aria-haspopup": "true",
                                                  "aria-expanded": "false",
                                                  class: _normalizeClass(["btn dropdown-toggle media-body", `select-${button.color}`]),
                                                  "data-bs-toggle": "dropdown",
                                                  id: button.id
                                                }, _toDisplayString(button.label), 11 /* TEXT, CLASS, PROPS */, _hoisted_22),
                                                _createElementVNode("div", {
                                                  class: "dropdown-menu",
                                                  "aria-labelledby": button.id
                                                }, [
                                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(button.items, (item, index) => {
                                                    return (_openBlock(), _createElementBlock("a", {
                                                      key: index,
                                                      class: "dropdown-item",
                                                      href: "",
                                                      onClick: _withModifiers(item.callback, ["prevent"])
                                                    }, _toDisplayString(item.label), 9 /* TEXT, PROPS */, _hoisted_24))
                                                  }), 128 /* KEYED_FRAGMENT */))
                                                ], 8 /* PROPS */, _hoisted_23)
                                              ]))
                                            : _createCommentVNode("v-if", true)
                                        ], 64 /* STABLE_FRAGMENT */))
                                      }), 128 /* KEYED_FRAGMENT */))
                                    ])
                                  ])
                                ]))
                              : _createCommentVNode("v-if", true),
                            (_ctx.sortGroupOptions.length)
                              ? (_openBlock(), _createElementBlock("section", _hoisted_25, [
                                  _createElementVNode("div", _hoisted_26, [
                                    _createElementVNode("div", _hoisted_27, [
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sortGroupOptions, (button, index) => {
                                        return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                                          (button.hasOwnProperty('group'))
                                            ? (_openBlock(), _createElementBlock("button", {
                                                key: 0,
                                                class: _normalizeClass(["btn media-body w-100", [`select-${button.color}`, {active: _ctx.sortGroup === button.group}]]),
                                                onClick: ($event: any) => (_ctx.sortGroup = button.group)
                                              }, _toDisplayString(button.label), 11 /* TEXT, CLASS, PROPS */, _hoisted_28))
                                            : _createCommentVNode("v-if", true),
                                          _createCommentVNode(" Dropdown filters "),
                                          (button.hasOwnProperty('items'))
                                            ? (_openBlock(), _createElementBlock("div", _hoisted_29, [
                                                _createElementVNode("button", {
                                                  "aria-haspopup": "true",
                                                  "aria-expanded": "false",
                                                  class: _normalizeClass(["btn dropdown-toggle media-body", `select-${button.color}`]),
                                                  "data-bs-toggle": "dropdown",
                                                  id: button.id
                                                }, _toDisplayString(button.label), 11 /* TEXT, CLASS, PROPS */, _hoisted_30),
                                                _createElementVNode("div", {
                                                  class: "dropdown-menu",
                                                  "aria-labelledby": button.id
                                                }, [
                                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(button.items, (item, index) => {
                                                    return (_openBlock(), _createElementBlock("a", {
                                                      key: index,
                                                      class: "dropdown-item",
                                                      href: "",
                                                      onClick: _withModifiers(item.callback, ["prevent"])
                                                    }, _toDisplayString(item.label), 9 /* TEXT, PROPS */, _hoisted_32))
                                                  }), 128 /* KEYED_FRAGMENT */))
                                                ], 8 /* PROPS */, _hoisted_31)
                                              ]))
                                            : _createCommentVNode("v-if", true)
                                        ], 64 /* STABLE_FRAGMENT */))
                                      }), 128 /* KEYED_FRAGMENT */))
                                    ])
                                  ])
                                ]))
                              : _createCommentVNode("v-if", true)
                          ])
                        ]))
                      : (_openBlock(), _createElementBlock("section", _hoisted_33, [
                          _createVNode(_component_spinner, { text: "Building..." })
                        ]))
                  ]),
                  (!_ctx.printMode)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_34, _cache[2] || (_cache[2] = [
                        _createElementVNode("div", { class: "row" }, [
                          _createElementVNode("div", { class: "col-lg-2 offset-lg-5" }, [
                            _createElementVNode("img", {
                              alt: "Powered by GRAPHMASSIVE®",
                              class: "img-fluid",
                              src: "/assets/images/powered-by-graphmassive-gray-50.svg"
                            })
                          ])
                        ], -1 /* HOISTED */)
                      ])))
                    : _createCommentVNode("v-if", true)
                ]))
              : (_ctx.containsSensitiveData)
                ? (_openBlock(), _createElementBlock("div", _hoisted_35, _cache[3] || (_cache[3] = [
                    _createElementVNode("div", { class: "alert alert-warning" }, " This report was created with sensitive data that we have since purged from our system. If you want to view the report, you'll need to rebuild it. ", -1 /* HOISTED */)
                  ])))
                : _createCommentVNode("v-if", true)
          ]))
    ])
  ]))
}