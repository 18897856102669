import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createBlock as _createBlock, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = {
  class: "mb-n5",
  "data-intent": "wedge-chart-section"
}
const _hoisted_2 = {
  key: 0,
  style: {"height":"0"}
}
const _hoisted_3 = ["id"]
const _hoisted_4 = { class: "fact-description clearfix" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = {
  key: 0,
  class: "svg-container"
}
const _hoisted_7 = {
  xmlns: "http://www.w3.org/2000/svg",
  version: "1.1",
  class: "scale",
  x: "0",
  y: "0",
  viewBox: "0 0 408.7 39.8",
  "xml:space": "preserve"
}
const _hoisted_8 = ["fill"]
const _hoisted_9 = ["fill"]
const _hoisted_10 = ["id"]
const _hoisted_11 = ["offset", "stop-color"]
const _hoisted_12 = ["offset"]
const _hoisted_13 = ["id", "title"]
const _hoisted_14 = { opacity: "0.75" }
const _hoisted_15 = ["id"]
const _hoisted_16 = ["id"]
const _hoisted_17 = ["xlink:href"]
const _hoisted_18 = ["clip-path"]
const _hoisted_19 = ["clip-path"]
const _hoisted_20 = {
  key: 1,
  class: "axis-labels mt-1 w-100"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.uuid)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(), _createElementBlock("svg", null, [
            _createElementVNode("pattern", {
              height: "8",
              id: `diagonal_stripes_${_ctx.uuid}`,
              patternTransform: "rotate(40)",
              patternUnits: "userSpaceOnUse",
              width: "8",
              x: "0",
              y: "0"
            }, _cache[0] || (_cache[0] = [
              _createElementVNode("rect", {
                x: "0",
                y: "0",
                width: "2",
                height: "8",
                style: {"stroke":"none","fill":"#eaeaea"}
              }, null, -1 /* HOISTED */),
              _createElementVNode("rect", {
                x: "2",
                y: "0",
                width: "2",
                height: "8",
                style: {"stroke":"none","fill":"white"}
              }, null, -1 /* HOISTED */),
              _createElementVNode("rect", {
                x: "4",
                y: "0",
                width: "2",
                height: "8",
                style: {"stroke":"none","fill":"#eaeaea"}
              }, null, -1 /* HOISTED */),
              _createElementVNode("rect", {
                x: "6",
                y: "0",
                width: "2",
                height: "8",
                style: {"stroke":"none","fill":"white"}
              }, null, -1 /* HOISTED */)
            ]), 8 /* PROPS */, _hoisted_3)
          ]))
        ]))
      : _createCommentVNode("v-if", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.items, (dataItem, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: dataItem.key,
        class: "wedge-chart-display mb-5 text-start"
      }, [
        _createElementVNode("div", _hoisted_4, [
          (dataItem.tooltip.info?.length)
            ? _withDirectives((_openBlock(), _createBlock(_component_font_awesome_icon, {
                key: 0,
                icon: ['light', 'circle-question'],
                class: "float-end",
                title: dataItem.tooltip.info
              }, null, 8 /* PROPS */, ["title"])), [
                [_directive_tooltip]
              ])
            : _createCommentVNode("v-if", true),
          _createElementVNode("div", {
            innerHTML: dataItem.description
          }, null, 8 /* PROPS */, _hoisted_5),
          _createCommentVNode("                {{ dataItem.description }}")
        ]),
        (_ctx.uuid)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              (_openBlock(), _createElementBlock("svg", _hoisted_7, [
                _createElementVNode("path", {
                  d: "M406.7 39.8H2c-2.6 0-2.7-3.8-0.2-4L406.5 0c1.2-0.1 2.2 0.8 2.2 2v35.8C408.7 38.9 407.8 39.8 406.7 39.8",
                  fill: `url(#diagonal_stripes_${_ctx.uuid})`
                }, null, 8 /* PROPS */, _hoisted_8),
                _createElementVNode("path", {
                  d: "M406.7 39.8H2c-2.6 0-2.7-3.8-0.2-4L406.5 0c1.2-0.1 2.2 0.8 2.2 2v35.8C408.7 38.9 407.8 39.8 406.7 39.8",
                  fill: `url(#${dataItem.key}_grad_${_ctx.uuid})`
                }, null, 8 /* PROPS */, _hoisted_9),
                _createElementVNode("defs", null, [
                  _createElementVNode("linearGradient", {
                    id: `${dataItem.key}_grad_${_ctx.uuid}`,
                    x1: "0",
                    x2: "100%",
                    y1: "0",
                    y2: "0"
                  }, [
                    _createElementVNode("stop", {
                      offset: `${dataItem.wedgeStop}`,
                      "stop-color": _ctx.wedgeColor(dataItem)
                    }, null, 8 /* PROPS */, _hoisted_11),
                    _createElementVNode("stop", {
                      offset: `${dataItem.wedgeStop}`,
                      "stop-color": "transparent"
                    }, null, 8 /* PROPS */, _hoisted_12)
                  ], 8 /* PROPS */, _hoisted_10)
                ])
              ])),
              _withDirectives((_openBlock(), _createElementBlock("svg", {
                class: "user-indicator",
                "enable-background": "new 0 0 16.438 20",
                height: "20",
                id: `USER_INDICATOR_${index}_${_ctx.uuid}`,
                style: _normalizeStyle(`left: ${dataItem.wedgeStop}`),
                title: dataItem.tooltip.graph,
                version: "1.1",
                viewBox: "0 0 16.44 20",
                width: "16.44",
                x: "0",
                "xml:space": "preserve",
                xmlns: "http://www.w3.org/2000/svg",
                "xmlns:xlink": "http://www.w3.org/1999/xlink",
                y: "0"
              }, [
                _createElementVNode("g", _hoisted_14, [
                  _createElementVNode("defs", null, [
                    _createElementVNode("rect", {
                      id: `USER_INDICATOR_${index}_A_${_ctx.uuid}`,
                      width: "16.44",
                      height: "20"
                    }, null, 8 /* PROPS */, _hoisted_15)
                  ]),
                  _createElementVNode("clipPath", {
                    id: `USER_INDICATOR_${index}_B_${_ctx.uuid}`
                  }, [
                    _createElementVNode("use", {
                      "xlink:href": `#USER_INDICATOR_${index}_A_${_ctx.uuid}`,
                      overflow: "visible"
                    }, null, 8 /* PROPS */, _hoisted_17)
                  ], 8 /* PROPS */, _hoisted_16),
                  _createElementVNode("path", {
                    "clip-path": `url(#USER_INDICATOR_${index}_B_${_ctx.uuid})`,
                    fill: "#FFFFFF",
                    d: "M2.9 19c-1.02 0-1.87-0.83-1.9-1.85 -0.05-2.08 0.6-3.87 1.89-5.19 0.53-0.54 1.16-0.99 1.86-1.32C3.54 9.63 2.78 8.12 2.78 6.45 2.78 3.44 5.22 1 8.22 1c3 0 5.44 2.44 5.44 5.45 0 1.67-0.77 3.19-1.97 4.19 0.7 0.34 1.33 0.78 1.86 1.33 1.28 1.31 1.93 3.1 1.89 5.19C15.41 18.17 14.56 19 13.54 19H2.9z"
                  }, null, 8 /* PROPS */, _hoisted_18),
                  _createElementVNode("path", {
                    "clip-path": `url(#USER_INDICATOR_${index}_B_${_ctx.uuid})`,
                    fill: "#FFFFFF",
                    d: "M8.22 2c2.45 0 4.44 2 4.44 4.45 0 2.35-1.83 4.28-4.13 4.44 1.73 0.07 3.25 0.69 4.31 1.78 1.09 1.11 1.64 2.66 1.6 4.46C14.43 17.61 14.03 18 13.54 18H2.9c-0.49 0-0.89-0.39-0.9-0.88 -0.04-1.81 0.51-3.35 1.6-4.46 1.06-1.08 2.58-1.71 4.31-1.77 -2.31-0.16-4.14-2.09-4.14-4.44C3.78 4 5.77 2 8.22 2M8.22 0C4.67 0 1.78 2.89 1.78 6.45c0 1.49 0.51 2.87 1.38 3.97 -0.35 0.25-0.68 0.53-0.98 0.84 -1.47 1.51-2.22 3.55-2.17 5.91C0.04 18.73 1.34 20 2.9 20h10.64c1.56 0 2.86-1.27 2.9-2.83 0.06-2.35-0.7-4.4-2.17-5.91 -0.3-0.31-0.63-0.59-0.98-0.84 0.86-1.1 1.38-2.48 1.38-3.97C14.66 2.89 11.77 0 8.22 0"
                  }, null, 8 /* PROPS */, _hoisted_19)
                ]),
                _cache[1] || (_cache[1] = _createElementVNode("path", {
                  fill: "#454545",
                  d: "M8.53 10.89c2.31-0.16 4.14-2.09 4.14-4.44C12.66 4 10.67 2 8.22 2S3.78 4 3.78 6.45c0 2.35 1.83 4.28 4.14 4.44 -1.73 0.07-3.25 0.69-4.31 1.78 -1.09 1.11-1.64 2.66-1.6 4.46C2.01 17.61 2.41 18 2.9 18h10.64c0.49 0 0.89-0.39 0.9-0.88 0.04-1.81-0.51-3.35-1.6-4.46C11.77 11.58 10.26 10.95 8.53 10.89"
                }, null, -1 /* HOISTED */))
              ], 12 /* STYLE, PROPS */, _hoisted_13)), [
                [_directive_tooltip]
              ])
            ]))
          : _createCommentVNode("v-if", true),
        (index === 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_20, _cache[2] || (_cache[2] = [
              _createElementVNode("div", { "data-intent": "min" }, "Unlikely", -1 /* HOISTED */),
              _createElementVNode("div", { "data-intent": "median" }, "Average", -1 /* HOISTED */),
              _createElementVNode("div", { "data-intent": "max" }, "Likely", -1 /* HOISTED */)
            ])))
          : _createCommentVNode("v-if", true)
      ]))
    }), 128 /* KEYED_FRAGMENT */))
  ]))
}