import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withMemo as _withMemo, createElementVNode as _createElementVNode, Suspense as _Suspense, withCtx as _withCtx, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, toDisplayString as _toDisplayString, vShow as _vShow, withModifiers as _withModifiers, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, resolveDynamicComponent as _resolveDynamicComponent, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  class: "container-fluid p-3",
  "data-content-type": "AUDIENCE"
}
const _hoisted_2 = { class: "container-fluid p-0" }
const _hoisted_3 = {
  key: 1,
  class: "report-container"
}
const _hoisted_4 = { class: "report-section bg-white" }
const _hoisted_5 = {
  key: 0,
  class: "row p-2"
}
const _hoisted_6 = {
  class: "col-auto position-relative align-self-center",
  "data-intent": "representative-photo"
}
const _hoisted_7 = { class: "col ps-0 align-self-center" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { class: "text-primary header-title d-flex" }
const _hoisted_10 = { class: "py-0 px-1 flex-grow-1 align-self-center" }
const _hoisted_11 = { class: "d-flex flex-row" }
const _hoisted_12 = { class: "list-inline d-inline mb-0 me-3" }
const _hoisted_13 = { class: "list-inline-item" }
const _hoisted_14 = { class: "list-inline-item" }
const _hoisted_15 = {
  key: 0,
  class: "spinner-border spinner-border-sm",
  role: "status"
}
const _hoisted_16 = { class: "list-inline-item" }
const _hoisted_17 = {
  key: 0,
  class: "spinner-border spinner-border-sm",
  role: "status"
}
const _hoisted_18 = { class: "list-inline-item" }
const _hoisted_19 = { class: "header-detail small" }
const _hoisted_20 = { class: "ms-auto" }
const _hoisted_21 = {
  key: 0,
  class: "ms-auto me-2"
}
const _hoisted_22 = { key: 1 }
const _hoisted_23 = { class: "text-primary header-title d-flex" }
const _hoisted_24 = { class: "py-0 px-1 flex-grow-1 align-self-center" }
const _hoisted_25 = { class: "col-auto my-auto" }
const _hoisted_26 = ["onerror", "src"]
const _hoisted_27 = { key: 2 }
const _hoisted_28 = {
  key: 0,
  class: "section-title h3 text-center my-3"
}
const _hoisted_29 = { class: "d-flex flex-column-reverse" }
const _hoisted_30 = { key: 1 }
const _hoisted_31 = {
  key: 3,
  class: "p-4"
}
const _hoisted_32 = { class: "d-block py-4" }
const _hoisted_33 = { class: "warning p-2" }
const _hoisted_34 = { key: 4 }
const _hoisted_35 = { class: "d-none" }
const _hoisted_36 = { key: 2 }
const _hoisted_37 = { class: "row mb-3 text-primary" }
const _hoisted_38 = { class: "col text-start" }
const _hoisted_39 = { id: "characteristics" }
const _hoisted_40 = { class: "card bg-blue-10" }
const _hoisted_41 = { class: "card-body selected-list-files" }
const _hoisted_42 = {
  key: 0,
  class: "text-gray-50"
}
const _hoisted_43 = { class: "text-uppercase" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_spinner = _resolveComponent("spinner")!
  const _component_choose_representative_photo = _resolveComponent("choose-representative-photo")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_subnav = _resolveComponent("subnav")!
  const _component_on_page_help = _resolveComponent("on-page-help")!
  const _component_audience_summary_pdf = _resolveComponent("audience-summary-pdf")!
  const _component_audience_summary = _resolveComponent("audience-summary")!
  const _component_underlying_persona = _resolveComponent("underlying-persona")!
  const _component_distribution_history = _resolveComponent("distribution-history")!
  const _component_audience_characteristics = _resolveComponent("audience-characteristics")!
  const _component_bootstrap_modal = _resolveComponent("bootstrap-modal")!
  const _component_share = _resolveComponent("share")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("article", _hoisted_1, [
    _createElementVNode("section", null, [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.audienceReportLoading)
          ? (_openBlock(), _createBlock(_component_spinner, {
              key: 0,
              class: "bg-white",
              text: "Building..."
            }))
          : _createCommentVNode("v-if", true),
        (_ctx.ready)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createCommentVNode(" Header "),
                (!_ctx.printMode)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                      _createElementVNode("div", _hoisted_6, [
                        (_openBlock(), _createBlock(_Suspense, null, {
                          fallback: _withCtx(() => _cache[10] || (_cache[10] = [
                            _createElementVNode("div", {
                              role: "status",
                              class: "text-primary spinner-border spinner-border-sm p-2 mx-3"
                            }, [
                              _createElementVNode("span", { class: "visually-hidden" }, "Loading...")
                            ], -1 /* HOISTED */)
                          ])),
                          default: _withCtx(() => [
                            _withMemo([_ctx.audience.userPhoto?.id, _ctx.audience.photoCharacteristics], () => _createVNode(_component_choose_representative_photo, {
                              insights: _ctx.insights,
                              object: _ctx.audience
                            }, null, 8 /* PROPS */, ["insights", "object"]), _cache, 0)
                          ], undefined, true),
                          _: 1 /* STABLE */
                        }))
                      ]),
                      _createElementVNode("div", _hoisted_7, [
                        (_ctx.audience.isActive)
                          ? (_openBlock(), _createElementBlock("section", _hoisted_8, [
                              _createElementVNode("form", {
                                onSubmit: _cache[4] || (_cache[4] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.updateAudienceName && _ctx.updateAudienceName(...args)), ["prevent"]))
                              }, [
                                _createElementVNode("div", _hoisted_9, [
                                  _cache[11] || (_cache[11] = _createElementVNode("div", { class: "py-0 align-self-center" }, [
                                    _createElementVNode("strong", null, "Audience:")
                                  ], -1 /* HOISTED */)),
                                  _createElementVNode("div", _hoisted_10, [
                                    _withDirectives(_createElementVNode("input", {
                                      class: _normalizeClass(["form-control mx-n1 px-1 w-100", {'is-invalid': _ctx.audienceNameValidation.error.message}]),
                                      "data-action": "update-build-name",
                                      maxlength: "100",
                                      type: "text",
                                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.editableAudienceName) = $event)),
                                      onFocus: _cache[2] || (_cache[2] = ($event: any) => (_ctx.allowUpdateAudienceName = true)),
                                      onBlur: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.blurUpdateAudienceName && _ctx.blurUpdateAudienceName(...args)))
                                    }, null, 34 /* CLASS, NEED_HYDRATION */), [
                                      [_vModelText, _ctx.editableAudienceName]
                                    ])
                                  ]),
                                  _withDirectives(_createElementVNode("div", { class: "py-0 px-1 align-self-center text-danger" }, _toDisplayString(_ctx.audienceNameValidation.error.message), 513 /* TEXT, NEED_PATCH */), [
                                    [_vShow, _ctx.audienceNameValidation.error.message.length]
                                  ])
                                ])
                              ], 32 /* NEED_HYDRATION */),
                              _createElementVNode("div", _hoisted_11, [
                                _createElementVNode("ul", _hoisted_12, [
                                  _createElementVNode("li", _hoisted_13, [
                                    _createElementVNode("a", {
                                      href: "",
                                      onClick: _cache[5] || (_cache[5] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.showSummaryInfo && _ctx.showSummaryInfo(...args)), ["prevent"]))
                                    }, [
                                      _createVNode(_component_font_awesome_icon, {
                                        icon: ['solid', 'circle-info'],
                                        "fixed-width": ""
                                      })
                                    ])
                                  ]),
                                  _createElementVNode("li", _hoisted_14, [
                                    (_ctx.pdfSharing)
                                      ? (_openBlock(), _createElementBlock("div", _hoisted_15, _cache[12] || (_cache[12] = [
                                          _createElementVNode("span", { class: "visually-hidden" }, "Loading...", -1 /* HOISTED */)
                                        ])))
                                      : _withDirectives((_openBlock(), _createElementBlock("a", {
                                          key: 1,
                                          href: "",
                                          title: `Download or Share PDF`,
                                          onClick: _cache[6] || (_cache[6] = _withModifiers(($event: any) => (_ctx.shareModal('pdf', 'show')), ["prevent"]))
                                        }, [
                                          _createVNode(_component_font_awesome_icon, {
                                            icon: ['regular', 'file-pdf'],
                                            "fixed-width": ""
                                          })
                                        ])), [
                                          [_directive_tooltip]
                                        ])
                                  ]),
                                  _createElementVNode("li", _hoisted_16, [
                                    (_ctx.reportSharing)
                                      ? (_openBlock(), _createElementBlock("div", _hoisted_17, _cache[13] || (_cache[13] = [
                                          _createElementVNode("span", { class: "visually-hidden" }, "Loading...", -1 /* HOISTED */)
                                        ])))
                                      : _withDirectives((_openBlock(), _createElementBlock("a", {
                                          key: 1,
                                          href: "",
                                          title: `Share this Report`,
                                          onClick: _cache[7] || (_cache[7] = _withModifiers(($event: any) => (_ctx.shareModal('report', 'show')), ["prevent"]))
                                        }, [
                                          _createVNode(_component_font_awesome_icon, {
                                            icon: ['solid', 'share-from-square'],
                                            "fixed-width": ""
                                          })
                                        ])), [
                                          [_directive_tooltip]
                                        ])
                                  ]),
                                  _createElementVNode("li", _hoisted_18, [
                                    _withDirectives((_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.sharedMode ? 'span' : 'router-link'), {
                                      title: "Send this Audience",
                                      to: _ctx.sharedMode ? undefined : {
                                                        name: 'audienceSend',
                                                        params: {
                                                            id: _ctx.audience.id
                                                        }
                                                    }
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_font_awesome_icon, {
                                          icon: ['solid', 'truck-fast'],
                                          "fixed-width": ""
                                        })
                                      ], undefined, true),
                                      _: 1 /* STABLE */
                                    }, 8 /* PROPS */, ["to"])), [
                                      [_directive_tooltip]
                                    ])
                                  ])
                                ]),
                                _createElementVNode("em", _hoisted_19, "Built on " + _toDisplayString(_ctx.buildDate), 1 /* TEXT */),
                                _createElementVNode("div", _hoisted_20, [
                                  (_ctx.allowUpdateAudienceName)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                                        _createElementVNode("a", {
                                          href: "#",
                                          class: "no-color text-success",
                                          onClick: _cache[8] || (_cache[8] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.updateAudienceName && _ctx.updateAudienceName(...args)), ["prevent"]))
                                        }, [
                                          _createVNode(_component_font_awesome_icon, {
                                            icon: ['regular', 'circle-check'],
                                            size: "sm"
                                          })
                                        ]),
                                        _createElementVNode("a", {
                                          href: "#",
                                          class: "ms-1 no-color text-danger",
                                          onClick: _cache[9] || (_cache[9] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.updateAudienceNameCancel && _ctx.updateAudienceNameCancel(...args)), ["prevent"]))
                                        }, [
                                          _createVNode(_component_font_awesome_icon, {
                                            icon: ['regular', 'circle-xmark'],
                                            size: "sm"
                                          })
                                        ])
                                      ]))
                                    : _createCommentVNode("v-if", true)
                                ])
                              ])
                            ]))
                          : (_openBlock(), _createElementBlock("section", _hoisted_22, [
                              _createElementVNode("div", _hoisted_23, [
                                _cache[14] || (_cache[14] = _createElementVNode("div", { class: "py-0 px-1 align-self-center" }, [
                                  _createElementVNode("strong", null, "Audience:")
                                ], -1 /* HOISTED */)),
                                _createElementVNode("div", _hoisted_24, _toDisplayString(_ctx.audience.name), 1 /* TEXT */)
                              ])
                            ]))
                      ]),
                      _createElementVNode("div", _hoisted_25, [
                        _createElementVNode("img", {
                          class: "brand-logo",
                          onerror: _ctx.Utils.imageFallback(_ctx.defaultBrandLogoPath),
                          src: _ctx.logoPath
                        }, null, 8 /* PROPS */, _hoisted_26)
                      ])
                    ]))
                  : _createCommentVNode("v-if", true),
                _createCommentVNode(" END header "),
                _createCommentVNode(" Subnav moved per SDSPOT-73 "),
                (_ctx.audience.isActive && !_ctx.printMode)
                  ? (_openBlock(), _createBlock(_component_subnav, {
                      key: 1,
                      class: "border-top",
                      navLinks: _ctx.subnavLinks,
                      placement: "page"
                    }, null, 8 /* PROPS */, ["navLinks"]))
                  : _createCommentVNode("v-if", true),
                (_ctx.audience.isActive && _ctx.reportReady)
                  ? (_openBlock(), _createElementBlock("section", _hoisted_27, [
                      (!_ctx.printMode)
                        ? (_openBlock(), _createElementBlock("h1", _hoisted_28, [
                            _createTextVNode(_toDisplayString(_ctx.sectionTitle) + " ", 1 /* TEXT */),
                            _createVNode(_component_on_page_help)
                          ]))
                        : _createCommentVNode("v-if", true),
                      _createElementVNode("div", _hoisted_29, [
                        (_ctx.printMode)
                          ? (_openBlock(), _createBlock(_component_audience_summary_pdf, {
                              key: 0,
                              audience: _ctx.audience,
                              "chart-associated-data": _ctx.chartListAssociatedData,
                              "chart-data": _ctx.customLayout,
                              insights: _ctx.insights,
                              "original-persona": _ctx.persona,
                              persona: _ctx.decoratedPersona,
                              sentences: _ctx.sentences
                            }, null, 8 /* PROPS */, ["audience", "chart-associated-data", "chart-data", "insights", "original-persona", "persona", "sentences"]))
                          : (_openBlock(), _createElementBlock("section", _hoisted_30, [
                              (_ctx.isUndefinedOrNullOrEmpty(_ctx.$route.params.tab))
                                ? (_openBlock(), _createBlock(_component_audience_summary, {
                                    key: 0,
                                    audience: _ctx.audience,
                                    "chart-associated-data": _ctx.chartListAssociatedData,
                                    "chart-data": _ctx.chartListData,
                                    onSetSegmentId: _ctx.setSegmentId
                                  }, null, 8 /* PROPS */, ["audience", "chart-associated-data", "chart-data", "onSetSegmentId"]))
                                : _createCommentVNode("v-if", true),
                              (_ctx.$route.params.tab === 'persona')
                                ? (_openBlock(), _createBlock(_component_underlying_persona, {
                                    key: 1,
                                    audience: _ctx.audience,
                                    insights: _ctx.insights,
                                    job: _ctx.job,
                                    persona: _ctx.decoratedPersona,
                                    sentences: _ctx.sentences,
                                    "shared-mode": _ctx.sharedMode
                                  }, null, 8 /* PROPS */, ["audience", "insights", "job", "persona", "sentences", "shared-mode"]))
                                : _createCommentVNode("v-if", true),
                              (_ctx.$route.params.tab === 'distributions')
                                ? (_openBlock(), _createBlock(_component_distribution_history, {
                                    key: 2,
                                    audience: _ctx.audience
                                  }, null, 8 /* PROPS */, ["audience"]))
                                : _createCommentVNode("v-if", true)
                            ]))
                      ]),
                      (!_ctx.printMode)
                        ? (_openBlock(), _createElementBlock("div", {
                            key: 1,
                            class: _normalizeClass(["row p-4 bg-primary", {'mt-n4': _ctx.params.tab === 'persona'}])
                          }, _cache[15] || (_cache[15] = [
                            _createElementVNode("div", { class: "col-lg-2 offset-lg-5" }, [
                              _createElementVNode("img", {
                                alt: "Powered by GRAPHMASSIVE®",
                                class: "img-fluid",
                                src: "/assets/images/powered-by-graphmassive-gray-25.svg"
                              })
                            ], -1 /* HOISTED */)
                          ]), 2 /* CLASS */))
                        : _createCommentVNode("v-if", true)
                    ]))
                  : (!_ctx.audience.isActive)
                    ? (_openBlock(), _createElementBlock("section", _hoisted_31, [
                        _createElementVNode("div", _hoisted_32, [
                          _createElementVNode("i", _hoisted_33, [
                            _createVNode(_component_font_awesome_icon, {
                              flip: "horizontal",
                              size: "lg",
                              "fixed-width": "",
                              icon: ['solid', 'message-exclamation']
                            })
                          ]),
                          _cache[16] || (_cache[16] = _createTextVNode(" The Audience that you are looking for has been removed from the system. Consider updating your bookmarks. "))
                        ])
                      ]))
                    : (_openBlock(), _createElementBlock("section", _hoisted_34, [
                        _createVNode(_component_spinner)
                      ])),
                _createCommentVNode(" Font Awesome elements for late-binding in Highcharts "),
                _createElementVNode("div", _hoisted_35, [
                  _createVNode(_component_font_awesome_icon, {
                    "data-intent": "font-awesome-icon",
                    "data-type": "twitterMention",
                    icon: ['solid', 'at']
                  }),
                  _createVNode(_component_font_awesome_icon, {
                    "data-intent": "font-awesome-icon",
                    "data-type": "twitterHashtag",
                    icon: ['solid', 'hashtag']
                  }),
                  _createVNode(_component_font_awesome_icon, {
                    "data-intent": "font-awesome-icon",
                    "data-type": "twitterTerm",
                    icon: ['solid', 'quote-left']
                  })
                ])
              ])
            ]))
          : (_ctx.containsSensitiveData)
            ? (_openBlock(), _createElementBlock("div", _hoisted_36, _cache[17] || (_cache[17] = [
                _createElementVNode("div", { class: "alert alert-warning" }, " This report was created with sensitive data that we have since purged from our system. If you want to view the report, you'll need to rebuild it. ", -1 /* HOISTED */)
              ])))
            : _createCommentVNode("v-if", true)
      ]),
      _createVNode(_component_bootstrap_modal, {
        "body-class": "text-normal",
        class: "modal-xl",
        "content-class": "bg-blue-25 border-primary",
        "hide-on-backdrop": false,
        "title-class": "text-primary",
        id: _ctx.summaryInfoModalId
      }, {
        title: _withCtx(() => _cache[18] || (_cache[18] = [
          _createTextVNode("Audience Summary Information")
        ])),
        body: _withCtx(() => [
          _createElementVNode("div", _hoisted_37, [
            _createElementVNode("div", _hoisted_38, [
              _cache[19] || (_cache[19] = _createTextVNode("Audience built by: ")),
              _createElementVNode("strong", null, _toDisplayString(_ctx.audienceBuiltBy), 1 /* TEXT */)
            ])
          ]),
          _createElementVNode("div", _hoisted_39, [
            _createVNode(_component_audience_characteristics, {
              audience: _ctx.audience,
              class: "text-muted px-0",
              "inner-container-class": "card-body mt-n3 bg-blue-10",
              mode: _ctx.audienceMode.REPORT
            }, null, 8 /* PROPS */, ["audience", "mode"])
          ]),
          _cache[20] || (_cache[20] = _createElementVNode("div", { class: "mt-3" }, [
            _createElementVNode("h6", null, [
              _createElementVNode("i", null, "Suppression file to filter specific people out of your Audience")
            ])
          ], -1 /* HOISTED */)),
          _createElementVNode("div", _hoisted_40, [
            _createElementVNode("div", _hoisted_41, [
              (_ctx.Utils.isEmptyArray(_ctx.listFiles))
                ? (_openBlock(), _createElementBlock("i", _hoisted_42, "No file selected"))
                : _createCommentVNode("v-if", true),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.listFiles, (file, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "text-danger",
                  key: index
                }, [
                  _createVNode(_component_font_awesome_icon, {
                    class: "me-2",
                    icon: ['regular', 'file-lines']
                  }),
                  _createElementVNode("span", _hoisted_43, _toDisplayString(file.name), 1 /* TEXT */)
                ]))
              }), 128 /* KEYED_FRAGMENT */))
            ])
          ])
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["id"])
    ]),
    _createVNode(_component_share, {
      ref: "share",
      "content-title": _ctx.audience.name,
      "content-type": "Audience",
      context: _ctx.shareContext,
      link: _ctx.shareLink(_ctx.shareContext)
    }, null, 8 /* PROPS */, ["content-title", "context", "link"])
  ]))
}