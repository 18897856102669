import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createBlock as _createBlock, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withMemo as _withMemo, vShow as _vShow } from "vue"

const _hoisted_1 = ["accept"]
const _hoisted_2 = ["data-content-type", "id"]
const _hoisted_3 = { class: "modal-dialog modal-dialog-centered" }
const _hoisted_4 = { class: "modal-content border-primary" }
const _hoisted_5 = { class: "modal-body" }
const _hoisted_6 = { class: "container-fluid" }
const _hoisted_7 = { class: "d-block modal-close text-end mb-0" }
const _hoisted_8 = { class: "row" }
const _hoisted_9 = { class: "col-6" }
const _hoisted_10 = { class: "card-body overflow-hidden" }
const _hoisted_11 = {
  class: "mb-3 photo-lg",
  "data-intent": "representative-photo"
}
const _hoisted_12 = { class: "text-center" }
const _hoisted_13 = { class: "table table-borderless mt-3 text-size-md" }
const _hoisted_14 = { class: "text-center" }
const _hoisted_15 = { class: "text-end" }
const _hoisted_16 = { class: "text-center" }
const _hoisted_17 = { class: "text-end" }
const _hoisted_18 = { class: "text-center" }
const _hoisted_19 = { class: "text-end" }
const _hoisted_20 = { class: "text-center" }
const _hoisted_21 = { class: "text-end" }
const _hoisted_22 = { class: "text-center" }
const _hoisted_23 = { class: "text-end" }
const _hoisted_24 = {
  key: 0,
  class: "text-size-md"
}
const _hoisted_25 = { class: "col-6" }
const _hoisted_26 = { class: "card-body overflow-hidden" }
const _hoisted_27 = { class: "text-center mb-3 photo-lg" }
const _hoisted_28 = { class: "input-group mb-5" }
const _hoisted_29 = { class: "input-group-prepend" }
const _hoisted_30 = ["disabled"]
const _hoisted_31 = ["disabled", "value", "title"]
const _hoisted_32 = ["title"]
const _hoisted_33 = {
  class: "invalid-feedback mb-n100",
  id: "validationFeedbackImageValid"
}
const _hoisted_34 = {
  class: "mt-3 overflow-hidden",
  style: {"aspect-ratio":"1 / 1"}
}
const _hoisted_35 = {
  class: "text-start mt-3",
  style: {"aspect-ratio":"1 / 1"}
}
const _hoisted_36 = { class: "text-end mt-3" }
const _hoisted_37 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_representative_photo = _resolveComponent("representative-photo")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_cropper = _resolveComponent("cropper")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("a", {
      "aria-label": "Choose photo",
      "data-action": "choose-picture",
      href: "",
      onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.manageChoosePhotoModal('show')), ["prevent"]))
    }, [
      _createVNode(_component_representative_photo, {
        characteristics: _ctx.photoCharacteristics(),
        "photo-source": _ctx.object.userPhoto?.filePathUri || null
      }, null, 8 /* PROPS */, ["characteristics", "photo-source"]),
      _cache[8] || (_cache[8] = _createElementVNode("div", {
        class: "text-uppercase",
        "data-intent": "hover-text"
      }, "Edit", -1 /* HOISTED */))
    ]),
    _createElementVNode("input", {
      accept: _ctx.acceptedImageTypes,
      class: "d-none",
      name: "image",
      ref: "customImageInput",
      type: "file",
      onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.prepareCustomImage && _ctx.prepareCustomImage(...args)))
    }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_1),
    _createElementVNode("div", {
      "aria-labelledby": "Choose Photo Modal",
      class: "modal modal-lg",
      "data-content-type": _ctx.objectType.toUpperCase(),
      id: _ctx.choosePhotoModalId
    }, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("a", {
                  href: "",
                  onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.manageChoosePhotoModal('hide')), ["prevent"]))
                }, "Close")
              ]),
              _cache[17] || (_cache[17] = _createElementVNode("h3", { class: "text-center mb-3 fw-bold" }, "Representative Photo Image", -1 /* HOISTED */)),
              _createElementVNode("p", null, "Select a photo to represent this " + _toDisplayString(_ctx.objectType) + ".", 1 /* TEXT */),
              _createElementVNode("div", _hoisted_8, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.imageTypes, ({type, label}) => {
                  return (_openBlock(), _createElementBlock("div", _hoisted_9, [
                    _createElementVNode("div", {
                      class: _normalizeClass(["card rounded h-100", {'border-green hover-border-green bg-green-25': _ctx.isCurrentPhoto(type)}])
                    }, [
                      _createElementVNode("div", _hoisted_10, [
                        _createElementVNode("div", _hoisted_11, [
                          _createVNode(_component_representative_photo, {
                            characteristics: _ctx.photoCharacteristics(type),
                            class: "mx-auto"
                          }, null, 8 /* PROPS */, ["characteristics"])
                        ]),
                        _createElementVNode("h5", _hoisted_12, _toDisplayString(label), 1 /* TEXT */),
                        _createElementVNode("table", _hoisted_13, [
                          _createElementVNode("tbody", null, [
                            _createElementVNode("tr", null, [
                              _cache[9] || (_cache[9] = _createElementVNode("td", { class: "text-start" }, "Age Range", -1 /* HOISTED */)),
                              _createElementVNode("td", _hoisted_14, _toDisplayString(_ctx.insightsDisplay('age_range', type, 'description')), 1 /* TEXT */),
                              _createElementVNode("td", _hoisted_15, _toDisplayString(_ctx.insightsDisplay('age_range', type, 'difference')), 1 /* TEXT */)
                            ]),
                            _createElementVNode("tr", null, [
                              _cache[10] || (_cache[10] = _createElementVNode("td", { class: "text-start" }, "Gender", -1 /* HOISTED */)),
                              _createElementVNode("td", _hoisted_16, _toDisplayString(_ctx.insightsDisplay('gender', type, 'description')), 1 /* TEXT */),
                              _createElementVNode("td", _hoisted_17, _toDisplayString(_ctx.insightsDisplay('gender', type, 'difference')), 1 /* TEXT */)
                            ]),
                            _createElementVNode("tr", null, [
                              _cache[11] || (_cache[11] = _createElementVNode("td", { class: "text-start" }, "Urbanicity", -1 /* HOISTED */)),
                              _createElementVNode("td", _hoisted_18, _toDisplayString(_ctx.insightsDisplay('county_size', type, 'description')), 1 /* TEXT */),
                              _createElementVNode("td", _hoisted_19, _toDisplayString(_ctx.insightsDisplay('county_size', type, 'difference')), 1 /* TEXT */)
                            ]),
                            _createElementVNode("tr", null, [
                              _cache[12] || (_cache[12] = _createElementVNode("td", { class: "text-start" }, "Married", -1 /* HOISTED */)),
                              _createElementVNode("td", _hoisted_20, _toDisplayString(_ctx.insightsDisplay('marital_status', type, 'description')), 1 /* TEXT */),
                              _createElementVNode("td", _hoisted_21, _toDisplayString(_ctx.insightsDisplay('marital_status', type, 'difference')), 1 /* TEXT */)
                            ]),
                            _createElementVNode("tr", null, [
                              _cache[13] || (_cache[13] = _createElementVNode("td", { class: "text-start" }, "Children Present in Household", -1 /* HOISTED */)),
                              _createElementVNode("td", _hoisted_22, _toDisplayString(_ctx.insightsDisplay('children_present_in_hh', type, 'description')), 1 /* TEXT */),
                              _createElementVNode("td", _hoisted_23, _toDisplayString(_ctx.insightsDisplay('children_present_in_hh', type, 'difference')), 1 /* TEXT */)
                            ])
                          ])
                        ]),
                        (type === 'index')
                          ? (_openBlock(), _createElementBlock("em", _hoisted_24, "compared to baseline"))
                          : _createCommentVNode("v-if", true)
                      ])
                    ], 2 /* CLASS */)
                  ]))
                }), 256 /* UNKEYED_FRAGMENT */)),
                _createCommentVNode(" USER-SPECIFIED PHOTO "),
                _createElementVNode("div", _hoisted_25, [
                  _createElementVNode("div", {
                    class: _normalizeClass(["card rounded h-100", {'border-green hover-border-green bg-green-25': _ctx.isCurrentPhoto('user')}])
                  }, [
                    _createElementVNode("div", _hoisted_26, [
                      _createElementVNode("div", _hoisted_27, [
                        (_ctx.hasNewUserPhoto || _ctx.hasUserPhoto)
                          ? (_openBlock(), _createBlock(_component_representative_photo, {
                              key: 0,
                              class: _normalizeClass(["mx-auto", {'border-danger': _ctx.customImageError && _ctx.customImageError.code}]),
                              detail: false,
                              "photo-source": _ctx.hasNewUserPhoto ? _ctx.croppedImage : _ctx.object.userPhoto.filePathUri
                            }, null, 8 /* PROPS */, ["class", "photo-source"]))
                          : (_openBlock(), _createElementBlock("a", {
                              key: 1,
                              class: "text-success",
                              "data-action": "select-custom-picture",
                              href: "",
                              onClick: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.selectCustomPhoto && _ctx.selectCustomPhoto(...args)), ["prevent"]))
                            }, [
                              _createVNode(_component_font_awesome_icon, {
                                class: "photo",
                                "fixed-width": "",
                                icon: ['solid', 'circle-plus']
                              })
                            ]))
                      ]),
                      _cache[15] || (_cache[15] = _createElementVNode("h5", { class: "text-center" }, "Custom Photo", -1 /* HOISTED */)),
                      _createElementVNode("div", _hoisted_28, [
                        _createElementVNode("div", _hoisted_29, [
                          _createElementVNode("button", {
                            class: "btn btn-primary rounded-start",
                            disabled: _ctx.photoSelectionPending,
                            type: "button",
                            onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.selectCustomPhoto && _ctx.selectCustomPhoto(...args)))
                          }, [
                            _createVNode(_component_font_awesome_icon, {
                              class: "me-2",
                              icon: ['solid', 'image'],
                              size: "lg"
                            }),
                            _cache[14] || (_cache[14] = _createTextVNode(" Upload New File "))
                          ], 8 /* PROPS */, _hoisted_30)
                        ]),
                        _withDirectives(_createElementVNode("input", {
                          "aria-describedby": "validationFeedbackImageValid",
                          "aria-label": "readonly input example",
                          class: _normalizeClass(["form-control pe-5", {'is-invalid': _ctx.customImageError && _ctx.customImageError.code}]),
                          disabled: _ctx.photoSelectionPending,
                          readonly: "",
                          type: "text",
                          value: _ctx.imageFilename,
                          title: _ctx.customImageFilename
                        }, null, 10 /* CLASS, PROPS */, _hoisted_31), [
                          [_directive_tooltip]
                        ]),
                        (_ctx.allowDeleteUserImage)
                          ? _withDirectives((_openBlock(), _createElementBlock("a", {
                              key: 0,
                              class: "me-1 position-absolute center-vertical snap-right",
                              href: "",
                              title: `Remove ${_ctx.objectType} image`,
                              onClick: _cache[5] || (_cache[5] = _withModifiers(($event: any) => (_ctx.clearUserPhoto(true)), ["prevent"]))
                            }, [
                              _createVNode(_component_font_awesome_icon, {
                                action: "clear",
                                "fixed-width": "",
                                icon: ['duotone', 'trash-can'],
                                size: "lg"
                              })
                            ], 8 /* PROPS */, _hoisted_32)), [
                              [_directive_tooltip]
                            ])
                          : _createCommentVNode("v-if", true),
                        _createElementVNode("div", _hoisted_33, _toDisplayString(_ctx.customImageError.message), 1 /* TEXT */)
                      ]),
                      _withDirectives(_createElementVNode("div", _hoisted_34, [
                        _createCommentVNode("                                                <vue-cropper"),
                        _createCommentVNode("                                                    alt=\"Source Image\""),
                        _createCommentVNode("                                                    :aspect-ratio=\"1\""),
                        _createCommentVNode("                                                    class=\"h-100 w-100 overflow-hidden\""),
                        _createCommentVNode("                                                    :min-container-height=\"325\""),
                        _createCommentVNode("                                                    :min-container-width=\"325\""),
                        _createCommentVNode("                                                    ref=\"cropper\""),
                        _createCommentVNode("                                                    :src=\"customImageSource\""),
                        _createCommentVNode("                                                    :view-mode=\"2\""),
                        _createCommentVNode("                                                    :crop=\"crop\""),
                        _createCommentVNode("                                                    :ready=\"cropperReady\""),
                        _createCommentVNode("                                                />"),
                        _withMemo([_ctx.customImageSource, _ctx.maxImageSize, _ctx.minImageSize], () => _createVNode(_component_cropper, {
                          ref: "cropper",
                          alt: "Source Image",
                          class: "h-100 w-100 overflow-hidden",
                          "image-restriction": "stencil",
                          "auto-zoom": false,
                          "max-height": _ctx.maxImageSize,
                          "max-width": _ctx.maxImageSize,
                          "min-height": _ctx.minImageSize,
                          "min-width": _ctx.minImageSize,
                          src: _ctx.customImageSource,
                          "stencil-props": {
                                                        aspectRatio: 1,
                                                        handlers: {},
                                                        movable: false,
                                                        resizable: true,
                                                    },
                          onChange: _ctx.cropImage
                        }, null, 8 /* PROPS */, ["max-height", "max-width", "min-height", "min-width", "src", "onChange"]), _cache, 6)
                      ], 512 /* NEED_PATCH */), [
                        [_vShow, _ctx.customImageSource && _ctx.isCustomImageValid]
                      ]),
                      _withDirectives(_createElementVNode("div", _hoisted_35, [
                        _createTextVNode(" .PNG, .JPG, and .GIF files are accepted. Minimum image size is " + _toDisplayString(_ctx.minImageSize) + "x" + _toDisplayString(_ctx.minImageSize) + ". ", 1 /* TEXT */),
                        (_ctx.hasUserPhoto)
                          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                              _createTextVNode(" You may upload a new custom photo, or remove the current one to use the default (largest percentage) representative photo. ")
                            ], 64 /* STABLE_FRAGMENT */))
                          : _createCommentVNode("v-if", true)
                      ], 512 /* NEED_PATCH */), [
                        [_vShow, !_ctx.hasNewUserPhoto]
                      ])
                    ])
                  ], 2 /* CLASS */)
                ])
              ]),
              _createElementVNode("div", _hoisted_36, [
                _createElementVNode("button", {
                  class: "btn btn-success btn-arrow-right",
                  disabled: !_ctx.allowPhotoSelection,
                  tabindex: "3",
                  onClick: _cache[7] || (_cache[7] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.savePhotoSelection && _ctx.savePhotoSelection(...args)), ["prevent"]))
                }, [
                  _createVNode(_component_font_awesome_icon, { icon: ['duotone', 'save'] }),
                  _cache[16] || (_cache[16] = _createTextVNode(" Save photo selection "))
                ], 8 /* PROPS */, _hoisted_37)
              ])
            ])
          ])
        ])
      ])
    ], 8 /* PROPS */, _hoisted_2)
  ]))
}