import { createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "row g-0 chart-display mt-4 mb-3" }
const _hoisted_2 = ["data-chart-id"]
const _hoisted_3 = { class: "w-100" }
const _hoisted_4 = { class: "text-center" }
const _hoisted_5 = { class: "d-flex align-items-end mt-3" }
const _hoisted_6 = { class: "additional-info p-3 m-3 w-100 bg-blue-10" }
const _hoisted_7 = { class: "container-fluid mb-3" }
const _hoisted_8 = { class: "row row-eq-height" }
const _hoisted_9 = { class: "col-xl pe-xl-0" }
const _hoisted_10 = { class: "col-xl-auto d-flex flex-column justify-content-center pt-xl-5 mt-xl-3 mb-n3" }
const _hoisted_11 = { class: "card bg-blue-10 border-0 p-3 mb-3" }
const _hoisted_12 = ["innerHTML"]
const _hoisted_13 = { style: {"max-height":"17em","overflow":"auto"} }
const _hoisted_14 = { class: "text-primary w-100" }
const _hoisted_15 = { class: "align-top" }
const _hoisted_16 = ["innerHTML"]
const _hoisted_17 = {
  key: 0,
  class: "container-fluid"
}
const _hoisted_18 = ["data-chart-id"]
const _hoisted_19 = { class: "card bg-blue-10 border-0 p-3 mb-3" }
const _hoisted_20 = ["innerHTML"]
const _hoisted_21 = { class: "text-primary w-100" }
const _hoisted_22 = { class: "align-top" }
const _hoisted_23 = ["innerHTML"]
const _hoisted_24 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_highcharts = _resolveComponent("highcharts")!
  const _component_chart_associated_data = _resolveComponent("chart-associated-data")!

  return (_openBlock(), _createElementBlock("section", null, [
    _createCommentVNode(" Top section "),
    _createElementVNode("div", _hoisted_1, [
      _createCommentVNode(" Dynamically render charts in flex grid "),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.chartData['topSection'], (chartElements) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "d-flex flex-column col-lg-6",
          "data-chart-id": chartElements.chart.id,
          key: chartElements.chart.id
        }, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_highcharts, {
                options: chartElements.chart
              }, null, 8 /* PROPS */, ["options"])
            ])
          ]),
          _createCommentVNode(" Associated data, if available "),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              (chartElements.associatedData !== false)
                ? (_openBlock(), _createBlock(_component_chart_associated_data, {
                    key: 0,
                    data: chartElements.associatedData
                  }, null, 8 /* PROPS */, ["data"]))
                : _createCommentVNode("v-if", true)
            ])
          ])
        ], 8 /* PROPS */, _hoisted_2))
      }), 128 /* KEYED_FRAGMENT */))
    ]),
    _createCommentVNode(" Middle section "),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("div", _hoisted_9, [
          _createVNode(_component_highcharts, {
            options: _ctx.chartData['middleSection'].chart
          }, null, 8 /* PROPS */, ["options"])
        ]),
        _createCommentVNode(" Detail sidebar "),
        _createElementVNode("div", _hoisted_10, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.chartData['middleSection'].detail, (detailSection) => {
            return (_openBlock(), _createElementBlock("div", _hoisted_11, [
              _createElementVNode("u", {
                class: "h5 text-primary mb-3 mx-auto text-nowrap",
                innerHTML: detailSection.name
              }, null, 8 /* PROPS */, _hoisted_12),
              _createElementVNode("div", _hoisted_13, [
                _createElementVNode("table", _hoisted_14, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(detailSection.items, (item) => {
                    return (_openBlock(), _createElementBlock("tr", {
                      key: item.index
                    }, [
                      _createElementVNode("td", _hoisted_15, _toDisplayString(item.index) + ". " + _toDisplayString(item.label), 1 /* TEXT */),
                      _createElementVNode("td", {
                        class: "align-top text-end text-nowrap",
                        innerHTML: item.value
                      }, null, 8 /* PROPS */, _hoisted_16)
                    ]))
                  }), 128 /* KEYED_FRAGMENT */))
                ])
              ])
            ]))
          }), 256 /* UNKEYED_FRAGMENT */))
        ])
      ])
    ]),
    _createCommentVNode(" Bottom section "),
    (_ctx.displayTopSocialContent)
      ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
          _cache[2] || (_cache[2] = _createElementVNode("h5", { class: "text-gray-75 text-center mb-3" }, "Top Social Content Posted by Persona", -1 /* HOISTED */)),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.chartData['bottomSection'], (chartElements, index) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "row",
              "data-chart-id": chartElements.chart.id,
              key: chartElements.chart.id
            }, [
              _createElementVNode("div", {
                class: _normalizeClass(["col-lg-7", index % 2 !== 0 ? 'order-1' : 'order-2'])
              }, [
                _createVNode(_component_highcharts, {
                  options: chartElements.chart
                }, null, 8 /* PROPS */, ["options"])
              ], 2 /* CLASS */),
              _createCommentVNode(" Detail sidebar "),
              _createElementVNode("div", {
                class: _normalizeClass(["col-lg-5", index % 2 !== 0 ? 'order-2' : 'order-1'])
              }, [
                _createElementVNode("div", _hoisted_19, [
                  _createElementVNode("u", {
                    class: "h5 text-primary mb-3 mx-auto text-nowrap",
                    innerHTML: `${chartElements.detail.contentTypeLabel} Used by Persona`
                  }, null, 8 /* PROPS */, _hoisted_20),
                  _createElementVNode("table", _hoisted_21, [
                    _createElementVNode("thead", null, [
                      _createElementVNode("tr", null, [
                        _createElementVNode("th", null, [
                          _createElementVNode("u", null, _toDisplayString(chartElements.detail.contentTypeLabel), 1 /* TEXT */)
                        ]),
                        _cache[0] || (_cache[0] = _createElementVNode("th", { class: "text-center" }, [
                          _createElementVNode("u", null, "Index")
                        ], -1 /* HOISTED */)),
                        _cache[1] || (_cache[1] = _createElementVNode("th", { class: "text-center" }, [
                          _createElementVNode("u", null, "Total Post Count")
                        ], -1 /* HOISTED */))
                      ])
                    ]),
                    _createElementVNode("tbody", null, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(chartElements.detail.items, (item) => {
                        return (_openBlock(), _createElementBlock("tr", {
                          key: item.index
                        }, [
                          _createElementVNode("td", _hoisted_22, _toDisplayString(item.index) + ". " + _toDisplayString(item.label), 1 /* TEXT */),
                          _createElementVNode("td", {
                            class: "align-top text-center",
                            innerHTML: item.indexValue
                          }, null, 8 /* PROPS */, _hoisted_23),
                          _createElementVNode("td", {
                            class: "align-top text-center",
                            innerHTML: item.countValue
                          }, null, 8 /* PROPS */, _hoisted_24)
                        ]))
                      }), 128 /* KEYED_FRAGMENT */))
                    ])
                  ])
                ])
              ], 2 /* CLASS */)
            ], 8 /* PROPS */, _hoisted_18))
          }), 128 /* KEYED_FRAGMENT */))
        ]))
      : _createCommentVNode("v-if", true)
  ]))
}