import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "container-fluid bg-comparison-report p-0 pt-3" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "bg-white mb-3" }
const _hoisted_4 = ["data-chart-id"]
const _hoisted_5 = { class: "w-100" }
const _hoisted_6 = { class: "text-center" }
const _hoisted_7 = { class: "d-flex align-items-end mt-3" }
const _hoisted_8 = { class: "additional-info p-3 m-3 w-100 bg-blue-10" }
const _hoisted_9 = { class: "bg-white mb-3" }
const _hoisted_10 = { class: "p-3" }
const _hoisted_11 = { class: "mb-n3" }
const _hoisted_12 = { class: "card bg-blue-10 border-0 p-3 mb-3" }
const _hoisted_13 = ["innerHTML"]
const _hoisted_14 = { style: {"max-height":"17em","overflow":"auto"} }
const _hoisted_15 = { class: "text-primary w-100" }
const _hoisted_16 = { class: "align-top" }
const _hoisted_17 = ["innerHTML"]
const _hoisted_18 = {
  key: 0,
  class: "bg-white pt-3"
}
const _hoisted_19 = { class: "text-gray-75 text-center mb-3" }
const _hoisted_20 = ["data-chart-id"]
const _hoisted_21 = { class: "card bg-blue-10 border-0 p-3" }
const _hoisted_22 = ["innerHTML"]
const _hoisted_23 = { class: "text-primary w-100" }
const _hoisted_24 = { class: "align-top" }
const _hoisted_25 = ["innerHTML"]
const _hoisted_26 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_highcharts = _resolveComponent("highcharts")!
  const _component_chart_associated_data = _resolveComponent("chart-associated-data")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.chartData, (chartSection, personaIndex) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "col-6",
          key: personaIndex
        }, [
          _createCommentVNode(" Top section "),
          _createElementVNode("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(chartSection.topSection, (chartElements) => {
              return (_openBlock(), _createElementBlock("div", {
                "data-chart-id": `${chartElements.chart.id}:${personaIndex}`,
                key: `${chartElements.chart.id}:${personaIndex}`
              }, [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("div", _hoisted_6, [
                    _createVNode(_component_highcharts, {
                      options: chartElements.chart
                    }, null, 8 /* PROPS */, ["options"])
                  ])
                ]),
                _createCommentVNode(" Associated data, if available "),
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("div", _hoisted_8, [
                    (chartElements.associatedData !== false)
                      ? (_openBlock(), _createBlock(_component_chart_associated_data, {
                          key: 0,
                          data: chartElements.associatedData
                        }, null, 8 /* PROPS */, ["data"]))
                      : _createCommentVNode("v-if", true)
                  ])
                ])
              ], 8 /* PROPS */, _hoisted_4))
            }), 128 /* KEYED_FRAGMENT */))
          ]),
          _createCommentVNode(" Middle section "),
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_component_highcharts, {
              options: chartSection.middleSection.chart
            }, null, 8 /* PROPS */, ["options"]),
            _createCommentVNode(" Detail sidebar "),
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("div", _hoisted_11, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(chartSection.middleSection.detail, (detailSection) => {
                  return (_openBlock(), _createElementBlock("div", _hoisted_12, [
                    _createElementVNode("u", {
                      class: "h5 text-primary mb-3 mx-auto text-nowrap",
                      innerHTML: detailSection.name
                    }, null, 8 /* PROPS */, _hoisted_13),
                    _createElementVNode("div", _hoisted_14, [
                      _createElementVNode("table", _hoisted_15, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(detailSection.items, (item) => {
                          return (_openBlock(), _createElementBlock("tr", {
                            key: item.index
                          }, [
                            _createElementVNode("td", _hoisted_16, _toDisplayString(item.index) + ". " + _toDisplayString(item.label), 1 /* TEXT */),
                            _createElementVNode("td", {
                              class: "align-top text-end text-nowrap",
                              innerHTML: item.value
                            }, null, 8 /* PROPS */, _hoisted_17)
                          ]))
                        }), 128 /* KEYED_FRAGMENT */))
                      ])
                    ])
                  ]))
                }), 256 /* UNKEYED_FRAGMENT */))
              ])
            ])
          ]),
          _createCommentVNode(" Bottom section "),
          (_ctx.displayTopSocialContent)
            ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                _createElementVNode("h5", _hoisted_19, "Top Social Content Posted by Persona " + _toDisplayString(_ctx.Utils.numberToAlpha(personaIndex + 1)), 1 /* TEXT */),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(chartSection.bottomSection, (chartElements) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "pb-3 m-3",
                    "data-chart-id": `${chartElements.chart.id}:${personaIndex}`,
                    key: `${chartElements.chart.id}:${personaIndex}`
                  }, [
                    _createVNode(_component_highcharts, {
                      options: chartElements.chart
                    }, null, 8 /* PROPS */, ["options"]),
                    _createCommentVNode(" Detail sidebar "),
                    _createElementVNode("div", _hoisted_21, [
                      _createElementVNode("u", {
                        class: "h5 text-primary mb-3 mx-auto text-nowrap",
                        innerHTML: `${chartElements.detail.contentTypeLabel} Used by Persona`
                      }, null, 8 /* PROPS */, _hoisted_22),
                      _createElementVNode("table", _hoisted_23, [
                        _createElementVNode("thead", null, [
                          _createElementVNode("tr", null, [
                            _createElementVNode("th", null, [
                              _createElementVNode("u", null, _toDisplayString(chartElements.detail.contentTypeLabel), 1 /* TEXT */)
                            ]),
                            _cache[0] || (_cache[0] = _createElementVNode("th", { class: "text-center" }, [
                              _createElementVNode("u", null, "Index")
                            ], -1 /* HOISTED */)),
                            _cache[1] || (_cache[1] = _createElementVNode("th", { class: "text-center" }, [
                              _createElementVNode("u", null, "Total Post Count")
                            ], -1 /* HOISTED */))
                          ])
                        ]),
                        _createElementVNode("tbody", null, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(chartElements.detail.items, (item) => {
                            return (_openBlock(), _createElementBlock("tr", {
                              key: item.index
                            }, [
                              _createElementVNode("td", _hoisted_24, _toDisplayString(item.index) + ". " + _toDisplayString(item.label), 1 /* TEXT */),
                              _createElementVNode("td", {
                                class: "align-top text-center",
                                innerHTML: item.indexValue
                              }, null, 8 /* PROPS */, _hoisted_25),
                              _createElementVNode("td", {
                                class: "align-top text-center",
                                innerHTML: item.countValue
                              }, null, 8 /* PROPS */, _hoisted_26)
                            ]))
                          }), 128 /* KEYED_FRAGMENT */))
                        ])
                      ])
                    ])
                  ], 8 /* PROPS */, _hoisted_20))
                }), 128 /* KEYED_FRAGMENT */))
              ]))
            : _createCommentVNode("v-if", true)
        ]))
      }), 128 /* KEYED_FRAGMENT */))
    ])
  ]))
}