import { createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, isMemoSame as _isMemoSame, withMemo as _withMemo, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, createBlock as _createBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = {
  class: "px-0 pt-4 bg-primary mb-n4",
  id: "personaSummary"
}
const _hoisted_2 = { class: "row" }
const _hoisted_3 = {
  class: "row g-0 collapse",
  id: "additionalDemographicsCollapse"
}
const _hoisted_4 = { class: "w-100 text-center my-n3" }
const _hoisted_5 = {
  class: "row col-padding-half",
  "data-masonry": "{\"percentPosition\": true}"
}
const _hoisted_6 = { class: "col-lg-6" }
const _hoisted_7 = ["src"]
const _hoisted_8 = {
  key: 0,
  class: "col-lg-6"
}
const _hoisted_9 = {
  key: 1,
  class: "col-lg-6"
}
const _hoisted_10 = { "data-chart-container": "" }
const _hoisted_11 = {
  key: 2,
  class: "col-lg-6"
}
const _hoisted_12 = { "data-chart-container": "" }
const _hoisted_13 = {
  key: 3,
  class: "col-lg-6"
}
const _hoisted_14 = { class: "col-lg-6" }
const _hoisted_15 = { class: "col-lg-6" }
const _hoisted_16 = { class: "row" }
const _hoisted_17 = { class: "col-lg-6" }
const _hoisted_18 = { class: "col-lg-6" }
const _hoisted_19 = { class: "row col-padding-half" }
const _hoisted_20 = { class: "col-6 mb-0" }
const _hoisted_21 = { class: "col-6 mb-0" }
const _hoisted_22 = { class: "row col-padding-half" }
const _hoisted_23 = { class: "text-gray-75" }
const _hoisted_24 = { class: "row" }
const _hoisted_25 = { class: "col" }
const _hoisted_26 = { class: "col-3" }
const _hoisted_27 = { class: "card bg-blue-10 border-0 p-3 mb-3" }
const _hoisted_28 = { class: "h5 text-primary mb-3 mx-auto" }
const _hoisted_29 = { style: {"max-height":"17em","overflow":"auto"} }
const _hoisted_30 = { class: "text-primary w-100" }
const _hoisted_31 = { class: "align-top" }
const _hoisted_32 = { class: "align-top text-end text-nowrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_highcharts = _resolveComponent("highcharts")!
  const _component_summary_section = _resolveComponent("summary-section")!
  const _component_wedge_chart_section = _resolveComponent("wedge-chart-section")!
  const _component_summary_widget = _resolveComponent("summary-widget")!
  const _component_persona_topic_widget = _resolveComponent("persona-topic-widget")!

  return (_openBlock(), _createElementBlock("article", _hoisted_1, [
    _createCommentVNode(" Demographics summary "),
    _createVNode(_component_summary_section, null, {
      icon: _withCtx(() => [
        _createVNode(_component_font_awesome_icon, {
          icon: ['duotone', _ctx.FeatureIcons.DEMOGRAPHICS],
          size: "lg"
        }, null, 8 /* PROPS */, ["icon"])
      ]),
      title: _withCtx(() => [
        _createVNode(_component_router_link, {
          to: _ctx.summaryLink('demographics', 'age-income-home')
        }, {
          default: _withCtx(() => _cache[5] || (_cache[5] = [
            _createTextVNode("Demographics")
          ]), undefined, true),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["to"])
      ]),
      content: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.chartData.demographics.slice(0, 4), (chart, index, ___, _cached) => {
            const _memo = (_ctx.chartData)
            if (_cached && _cached.key === index && _isMemoSame(_cached, _memo)) return _cached
            const _item = (_openBlock(), _createElementBlock("div", {
              key: index,
              class: "col-sm-6 col-lg-3",
              "data-chart-container": ""
            }, [
              _createVNode(_component_highcharts, { options: chart }, null, 8 /* PROPS */, ["options"])
            ]))
            _item.memo = _memo
            return _item
          }, _cache, 0), 128 /* KEYED_FRAGMENT */))
        ]),
        _createCommentVNode(" Hidden demographics collapse section "),
        _createElementVNode("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.chartData.demographics.slice(4), (chart, index, ___, _cached) => {
            const _memo = (_ctx.chartData)
            if (_cached && _cached.key === index && _isMemoSame(_cached, _memo)) return _cached
            const _item = (_openBlock(), _createElementBlock("div", {
              key: index,
              class: "col-sm-6 col-lg-3",
              "data-chart-container": ""
            }, [
              _createVNode(_component_highcharts, { options: chart }, null, 8 /* PROPS */, ["options"])
            ]))
            _item.memo = _memo
            return _item
          }, _cache, 2), 128 /* KEYED_FRAGMENT */))
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("button", {
            class: "btn btn-link margin-auto",
            "data-bs-toggle": "collapse",
            "data-bs-target": "#additionalDemographicsCollapse",
            onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.demographicsExpanded = !_ctx.demographicsExpanded))
          }, [
            _withDirectives(_createVNode(_component_font_awesome_icon, { icon: ['solid', 'angle-up'] }, null, 512 /* NEED_PATCH */), [
              [_vShow, _ctx.demographicsExpanded]
            ]),
            _withDirectives(_createVNode(_component_font_awesome_icon, { icon: ['solid', 'angle-down'] }, null, 512 /* NEED_PATCH */), [
              [_vShow, !_ctx.demographicsExpanded]
            ])
          ])
        ])
      ]),
      _: 1 /* STABLE */
    }),
    _createElementVNode("div", _hoisted_5, [
      _createCommentVNode(" Unique Facts "),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_summary_section, null, {
          icon: _withCtx(() => [
            _createElementVNode("img", {
              class: "logo-icon",
              src: _ctx.iconPath['personaBuilder'],
              alt: "",
              tabindex: "-1"
            }, null, 8 /* PROPS */, _hoisted_7)
          ]),
          title: _withCtx(() => _cache[6] || (_cache[6] = [
            _createTextVNode(" Unique Facts ")
          ])),
          content: _withCtx(() => [
            _createVNode(_component_wedge_chart_section, {
              data: {items: _ctx.chartData.uniqueFacts}
            }, null, 8 /* PROPS */, ["data"])
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _createCommentVNode(" Predicted Spend "),
      (_ctx.chartData.predictedSpendActivity.length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
            _createVNode(_component_summary_section, null, {
              icon: _withCtx(() => [
                _createVNode(_component_font_awesome_icon, {
                  icon: ['duotone', _ctx.FeatureIcons.PREDICTED_SPEND],
                  size: "lg"
                }, null, 8 /* PROPS */, ["icon"])
              ]),
              title: _withCtx(() => [
                _createVNode(_component_router_link, {
                  to: _ctx.summaryLink('predicted-spend', 'summary')
                }, {
                  default: _withCtx(() => _cache[7] || (_cache[7] = [
                    _createTextVNode("Predicted Spend")
                  ]), undefined, true),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["to"])
              ]),
              content: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.chartData.predictedSpendActivity, (predictedSpendChart, index) => {
                  return (_openBlock(), _createBlock(_component_highcharts, {
                    key: `consumer_spend_${index}`,
                    options: predictedSpendChart
                  }, null, 8 /* PROPS */, ["options"]))
                }), 128 /* KEYED_FRAGMENT */))
              ]),
              _: 1 /* STABLE */
            })
          ]))
        : _createCommentVNode("v-if", true),
      _createCommentVNode(" High-level RFM "),
      (_ctx.chartData.highLevelRfm)
        ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
            _createVNode(_component_summary_section, null, {
              icon: _withCtx(() => [
                _createVNode(_component_font_awesome_icon, {
                  icon: ['duotone', _ctx.FeatureIcons.HIGH_LEVEL_RFM],
                  size: "lg"
                }, null, 8 /* PROPS */, ["icon"])
              ]),
              title: _withCtx(() => [
                _createVNode(_component_router_link, {
                  to: _ctx.summaryLink('rfm')
                }, {
                  default: _withCtx(() => _cache[8] || (_cache[8] = [
                    _createTextVNode("RFM")
                  ]), undefined, true),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["to"])
              ]),
              content: _withCtx(() => [
                _createElementVNode("div", _hoisted_10, [
                  _createVNode(_component_highcharts, {
                    options: _ctx.chartData.highLevelRfm
                  }, null, 8 /* PROPS */, ["options"])
                ])
              ]),
              _: 1 /* STABLE */
            })
          ]))
        : _createCommentVNode("v-if", true),
      _createCommentVNode(" Conglomerate RFM / IND33 / Market Affinity"),
      (_ctx.chartData.conglomerateRfmMarket)
        ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
            _createVNode(_component_summary_section, null, {
              icon: _withCtx(() => [
                _createVNode(_component_font_awesome_icon, {
                  icon: ['duotone', _ctx.FeatureIcons.CONGLOMERATE_RFM_MARKETS],
                  size: "lg"
                }, null, 8 /* PROPS */, ["icon"])
              ]),
              title: _withCtx(() => [
                _createVNode(_component_router_link, {
                  to: _ctx.summaryLink('market-affinity')
                }, {
                  default: _withCtx(() => _cache[9] || (_cache[9] = [
                    _createTextVNode("Market Affinity, by Spend")
                  ]), undefined, true),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["to"])
              ]),
              content: _withCtx(() => [
                _createElementVNode("div", _hoisted_12, [
                  _createVNode(_component_highcharts, {
                    options: _ctx.chartData.conglomerateRfmMarket
                  }, null, 8 /* PROPS */, ["options"])
                ])
              ]),
              _: 1 /* STABLE */
            })
          ]))
        : _createCommentVNode("v-if", true),
      _createCommentVNode(" Past Purchases "),
      (!_ctx.chartData.conglomerateRfmMarket)
        ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
            _createVNode(_component_summary_section, null, {
              icon: _withCtx(() => [
                _createVNode(_component_font_awesome_icon, {
                  icon: ['duotone', _ctx.FeatureIcons.PAST_PURCHASES],
                  size: "lg"
                }, null, 8 /* PROPS */, ["icon"])
              ]),
              title: _withCtx(() => [
                _createVNode(_component_router_link, {
                  to: _ctx.summaryLink('past-purchases')
                }, {
                  default: _withCtx(() => _cache[10] || (_cache[10] = [
                    _createTextVNode(" Past Purchase Activity ")
                  ]), undefined, true),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["to"])
              ]),
              content: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.chartData.pastPurchaseActivity, (pastPurchaseChart, index) => {
                  return (_openBlock(), _createBlock(_component_highcharts, {
                    key: `past_purchase_${index}`,
                    options: pastPurchaseChart
                  }, null, 8 /* PROPS */, ["options"]))
                }), 128 /* KEYED_FRAGMENT */))
              ]),
              _: 1 /* STABLE */
            })
          ]))
        : _createCommentVNode("v-if", true),
      _createCommentVNode(" Weekly social activity "),
      _createElementVNode("div", _hoisted_14, [
        _createVNode(_component_summary_section, null, {
          icon: _withCtx(() => [
            _createVNode(_component_font_awesome_icon, {
              "fixed-width": "",
              icon: ['duotone', _ctx.FeatureIcons.SOCIAL_AFFINITY]
            }, null, 8 /* PROPS */, ["icon"])
          ]),
          title: _withCtx(() => [
            _createVNode(_component_router_link, {
              to: _ctx.summaryLink('social-activity')
            }, {
              default: _withCtx(() => _cache[11] || (_cache[11] = [
                _createTextVNode("Weekly Activity")
              ]), undefined, true),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["to"])
          ]),
          content: _withCtx(() => [
            _createVNode(_component_highcharts, {
              options: _ctx.chartData.socialWeeklyActivity
            }, null, 8 /* PROPS */, ["options"])
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _createCommentVNode(" Political social engagement "),
      _createElementVNode("div", _hoisted_15, [
        _createVNode(_component_summary_section, null, {
          icon: _withCtx(() => [
            _createVNode(_component_font_awesome_icon, {
              icon: ['duotone', _ctx.FeatureIcons.POLITICAL],
              size: "lg"
            }, null, 8 /* PROPS */, ["icon"])
          ]),
          title: _withCtx(() => [
            _createVNode(_component_router_link, {
              to: _ctx.summaryLink('interests', 'summary')
            }, {
              default: _withCtx(() => _cache[12] || (_cache[12] = [
                _createTextVNode("Political Social Engagement")
              ]), undefined, true),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["to"])
          ]),
          content: _withCtx(() => [
            _createElementVNode("div", _hoisted_16, [
              (_ctx.chartData.politicalSocialSummary.hasOwnProperty('twitter'))
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _withDirectives(_createVNode(_component_summary_widget, {
                      class: "col-6",
                      summaryItems: [_ctx.chartData.politicalSocialSummary.twitter[1]],
                      summaryType: "twitter",
                      tooltipPattern: "[[ percent ]] of the group has engaged"
                    }, null, 8 /* PROPS */, ["summaryItems"]), [
                      [_vShow, _ctx.itemContext['politicalSocialEngagement'] === 'twitter']
                    ]),
                    _withDirectives(_createVNode(_component_summary_widget, {
                      class: "col-6",
                      summaryItems: [_ctx.chartData.politicalSocialSummary.twitter[0]],
                      summaryType: "twitter",
                      tooltipPattern: "[[ percent ]] of the group has engaged"
                    }, null, 8 /* PROPS */, ["summaryItems"]), [
                      [_vShow, _ctx.itemContext['politicalSocialEngagement'] === 'twitter']
                    ])
                  ], 64 /* STABLE_FRAGMENT */))
                : _createCommentVNode("v-if", true)
            ])
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _createCommentVNode(" Political party affiliation "),
      _createElementVNode("div", _hoisted_17, [
        _createVNode(_component_summary_section, null, {
          icon: _withCtx(() => [
            _createVNode(_component_font_awesome_icon, {
              icon: ['duotone', _ctx.FeatureIcons.POLITICAL],
              size: "lg"
            }, null, 8 /* PROPS */, ["icon"])
          ]),
          title: _withCtx(() => [
            _createVNode(_component_router_link, {
              to: _ctx.summaryLink('demographics', 'politics-job-residence')
            }, {
              default: _withCtx(() => _cache[13] || (_cache[13] = [
                _createTextVNode("Political Party Affiliation ")
              ]), undefined, true),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["to"])
          ]),
          content: _withCtx(() => [
            _createVNode(_component_highcharts, {
              options: _ctx.chartData.politicalPartyAffiliation
            }, null, 8 /* PROPS */, ["options"])
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _createCommentVNode(" Brand & Interest Summary "),
      _createElementVNode("div", _hoisted_18, [
        _createElementVNode("div", _hoisted_19, [
          _createElementVNode("div", _hoisted_20, [
            _createVNode(_component_summary_section, null, {
              icon: _withCtx(() => [
                _createVNode(_component_font_awesome_icon, {
                  "fixed-width": "",
                  icon: ['duotone', _ctx.FeatureIcons.SOCIAL_AFFINITY]
                }, null, 8 /* PROPS */, ["icon"])
              ]),
              title: _withCtx(() => [
                _createVNode(_component_router_link, {
                  to: _ctx.summaryLink('brands', 'summary')
                }, {
                  default: _withCtx(() => _cache[14] || (_cache[14] = [
                    _createTextVNode("Brand Summary")
                  ]), undefined, true),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["to"])
              ]),
              content: _withCtx(() => [
                (_ctx.chartData.brandSummary.hasOwnProperty('twitter'))
                  ? _withDirectives((_openBlock(), _createBlock(_component_summary_widget, {
                      key: 0,
                      summaryItems: _ctx.chartData.brandSummary.twitter,
                      summaryType: "twitter",
                      tooltipPattern: "[[ percent ]] of the group is interested"
                    }, null, 8 /* PROPS */, ["summaryItems"])), [
                      [_vShow, _ctx.itemContext['brand'] === 'twitter']
                    ])
                  : _createCommentVNode("v-if", true)
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _createElementVNode("div", _hoisted_21, [
            _createVNode(_component_summary_section, null, {
              icon: _withCtx(() => [
                _createVNode(_component_font_awesome_icon, {
                  "fixed-width": "",
                  icon: ['duotone', _ctx.FeatureIcons.SOCIAL_AFFINITY]
                }, null, 8 /* PROPS */, ["icon"])
              ]),
              title: _withCtx(() => [
                _createVNode(_component_router_link, {
                  to: _ctx.summaryLink('interests', 'summary')
                }, {
                  default: _withCtx(() => _cache[15] || (_cache[15] = [
                    _createTextVNode("Interest Summary")
                  ]), undefined, true),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["to"])
              ]),
              content: _withCtx(() => [
                (_ctx.chartData.interestSummary.hasOwnProperty('twitter'))
                  ? _withDirectives((_openBlock(), _createBlock(_component_summary_widget, {
                      key: 0,
                      summaryItems: _ctx.chartData.interestSummary.twitter,
                      summaryType: "twitter",
                      tooltipPattern: "[[ percent ]] of the group is interested"
                    }, null, 8 /* PROPS */, ["summaryItems"])), [
                      [_vShow, _ctx.itemContext['interest'] === 'twitter']
                    ])
                  : _createCommentVNode("v-if", true)
              ]),
              _: 1 /* STABLE */
            })
          ])
        ])
      ])
    ]),
    _createCommentVNode(" Topics "),
    _createElementVNode("div", _hoisted_22, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.chartData.socialTopics, (topic) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "col-lg-3",
          key: topic.key
        }, [
          _createVNode(_component_summary_section, null, {
            icon: _withCtx(() => [
              _createElementVNode("span", _hoisted_23, [
                _createVNode(_component_font_awesome_icon, {
                  icon: ['duotone', _ctx.FeatureIcons.SOCIAL_AFFINITY],
                  size: "lg"
                }, null, 8 /* PROPS */, ["icon"])
              ])
            ]),
            title: _withCtx(() => [
              _createVNode(_component_router_link, {
                to: _ctx.summaryLink(
                                topic.tab,
                                topic.tab === 'custom-topics' ? topic.section : 'categories',
                                topic.tab !== 'custom-topics' ? {context: topic.section} : null
                            )
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(topic.category || topic.name), 1 /* TEXT */)
                ], undefined, true),
                _: 2 /* DYNAMIC */
              }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["to"])
            ]),
            content: _withCtx(() => [
              _createVNode(_component_persona_topic_widget, {
                topic: topic,
                context: _ctx.itemContext[`topic-${topic.key}`]
              }, null, 8 /* PROPS */, ["topic", "context"])
            ]),
            _: 2 /* DYNAMIC */
          }, 1024 /* DYNAMIC_SLOTS */)
        ]))
      }), 128 /* KEYED_FRAGMENT */))
    ]),
    _createCommentVNode(" Geography "),
    _createVNode(_component_summary_section, null, {
      icon: _withCtx(() => [
        _createVNode(_component_font_awesome_icon, {
          icon: ['duotone', _ctx.FeatureIcons.GEOGRAPHY],
          size: "lg"
        }, null, 8 /* PROPS */, ["icon"])
      ]),
      title: _withCtx(() => [
        _createVNode(_component_router_link, {
          to: _ctx.summaryLink('geography')
        }, {
          default: _withCtx(() => _cache[16] || (_cache[16] = [
            _createTextVNode("Geography")
          ]), undefined, true),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["to"])
      ]),
      content: _withCtx(() => [
        _createElementVNode("div", _hoisted_24, [
          _createElementVNode("div", _hoisted_25, [
            _createVNode(_component_highcharts, {
              "constructor-type": "mapChart",
              options: _ctx.chartData.geography.chart,
              style: {"overflow":"visible !important"}
            }, null, 8 /* PROPS */, ["options"])
          ]),
          _createCommentVNode(" Detail sidebar "),
          _createElementVNode("div", _hoisted_26, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.chartData.geography.detail, (detailSection) => {
              return (_openBlock(), _createElementBlock("div", _hoisted_27, [
                _createElementVNode("u", _hoisted_28, _toDisplayString(detailSection.name), 1 /* TEXT */),
                _createElementVNode("div", _hoisted_29, [
                  _createElementVNode("table", _hoisted_30, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(detailSection.items.index, (item) => {
                      return (_openBlock(), _createElementBlock("tr", {
                        key: item.label
                      }, [
                        _createElementVNode("td", _hoisted_31, _toDisplayString(item.index) + ". " + _toDisplayString(item.label), 1 /* TEXT */),
                        _createElementVNode("td", _hoisted_32, _toDisplayString(item.value), 1 /* TEXT */)
                      ]))
                    }), 128 /* KEYED_FRAGMENT */))
                  ])
                ])
              ]))
            }), 256 /* UNKEYED_FRAGMENT */))
          ])
        ])
      ]),
      _: 1 /* STABLE */
    }),
    _cache[17] || (_cache[17] = _createElementVNode("div", null, " ", -1 /* HOISTED */))
  ]))
}