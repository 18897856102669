import api from 'Server/api';
import cloneDeep from 'lodash-es/cloneDeep';
import BaseColors from 'Utilities/colors';
import { defineStore } from 'pinia';
import { User, UserTimestamp, defaultUserTimestamp, useUserStore } from 'Stores/user';
import { Activity } from 'Stores/activity';
import { Persona as PersonaInterface, PersonaJob as PersonaJobInterface } from 'Stores/persona';
import { useJobStore } from 'Stores/job';
import { useAccountStore } from 'Stores/account';
import { AxiosResponse } from 'axios';
import { axiosResponse } from 'Utilities/utils';

export interface ComparisonState {
    comparison: Comparison,
    originalComparison: any,
    serviceComparison: any,
    newComparison: any,
    activities: any[],
    personaJobs: any[],
    personas: any[],
}

export interface Comparison {
    id: string;
    accountId: string;
    account: {
        id: string;
    },
    personaJobId1: string;
    personaJobId2: string;
    personaJob1CompletedAt?: string; // TODO: Refactor Comparison interface to have a job obj at some point...
    personaJob2CompletedAt?: string; // TODO: Refactor Comparison interface to have a job obj at some point...
    personaId1: string;
    personaId2: string;
    containsSensitiveData?: boolean;
    userId: string;
    name: string;
    mode: string;
    tags: string[];
    created: UserTimestamp;
    updated: UserTimestamp;
    fetchedAt: string;
}

export enum ComparisonMode {
    NEW = 'new',
    DRAFT = 'draft',
    COPY = 'copy',
    BUILD = 'build',
    REBUILD = 'rebuild',
    EDIT = 'edit',
    DELETE = 'delete',
    RESET = 'reset',
    REPORT = 'report',
    PRINT = 'print'
}

export enum ComparisonTabs {
    PERSONAS = 'personas',
    CONFIRMATION = 'confirmation',
}

export enum ComparisonLoaders {
    PREP = 'preparing comparison build',
    SAVING = 'saving comparison',
    VALIDATING = 'validating comparison',
}

export const comparisonActivityCount = 2;
export const sectionColors = [
    BaseColors.blue['75'],
    BaseColors.yellow['100'],
]

export const defaultApiAudienceDistribution = {
    id: '',
    userId: '',     // Temp until post audienceDistribution no longer needs
    accountId: '',  // Temp until post audienceDistribution no longer needs
    account: {
        id: '',
        name: 'Delivery Testing',
    },
    created: cloneDeep(defaultUserTimestamp),
    updated: cloneDeep(defaultUserTimestamp),
    name: '',
    buildConfig: {
        segmentStrategy: []
    },
    tuningProfileId: '',
    tags: [''],
    suppressionUploadId: '',
    personaJobId: '',
    distributions: []
}

export const useComparisonStore = defineStore('comparison', {
    state: (): ComparisonState => ({
        comparison: {
            id: '',
            accountId: '',
            account: {
                id: ''
            },
            personaJobId1: '',
            personaJobId2: '',
            personaJob1CompletedAt: '',
            personaJob2CompletedAt: '',
            personaId1: '',
            personaId2: '',
            containsSensitiveData: false,
            userId: '',
            name: '',
            mode: '',
            tags: [],
            created: cloneDeep(defaultUserTimestamp),
            updated: cloneDeep(defaultUserTimestamp),
            fetchedAt: '',
            // UX use only - not supported in API
            mode: '',
            personaId1: '',
            personaId2: ''
        },
        activities: [],
        personas: [],
        personaJobs: [],
        serviceComparison: {
            account: {
                id: '',
            },
            id: '',
            userId: '',
            personaJobId1: '',
            personaJobId2: '',
            personaId1: '',
            personaId2: '',
            containsSensitiveData: false,
            name: '',
            tags: ['']
        },
        originalComparison: {},
        newComparison: {}
    }),

    getters: {
        getActivities: ( state: ComparisonState ) => state.activities,
        getComparison: ( state: ComparisonState ) => state.comparison,
        getOriginalComparison: ( state: ComparisonState ) => state.originalComparison,
        getPersonaJobs: ( state: ComparisonState ) => state.personaJobs,
        getPersonas: ( state: ComparisonState ) => state.personas,
    },

    actions: {
        async addComparisonActivity(params: any) {
            try {
                if (!this.activities) {
                    this.activities = [];
                }
                this.activities[this.activities.length] = params.activity;
                // Always pull the related Persona from the Activity's Job definition
                useJobStore().setOriginatorRoot('personas');
                useJobStore().setOriginatorId(params.activity.id);
                const personaJob = await useJobStore().getJobByIdOrLatest(params);
                await this.addComparisonPersonaJob(personaJob);

                return personaJob;
            } catch (error) {
                console.error(error);
                return false;
            }

        },

        async addComparisonPersona(comparisonPersona: PersonaInterface) {
            try {
                const persona = cloneDeep(comparisonPersona);
                // console.debug(`Adding persona to comparison: ${persona.name} -> (${persona.businessUseTypeIdentifier})`, persona);

                if (!this.personas.some((existingPersona: PersonaInterface) => persona.id === existingPersona.id)) {
                    this.personas[this.personas.length] = persona;
                    const personaIndex = `personaId${this.personas.length}`;
                    this.comparison[personaIndex] = persona.id;
                }

                return true;
            } catch (error) {
                console.error(error);
                return false;
            }
        },

        async addComparisonPersonaJob(comparison: PersonaJobInterface) {
            try {
                const personaJob = cloneDeep(comparison);

                if (!this.personaJobs.some(existingPersonaJob => personaJob.id === existingPersonaJob.id)) {
                    this.personaJobs[this.personaJobs.length] = personaJob;
                    const personaJobIndex = `${this.personaJobs.length}`;
                    const personaJobIdIndex = `personaJobId${personaJobIndex}`;
                    this.comparison[personaJobIdIndex] = personaJob.id;
                    this.comparison[`personaJob${personaJobIndex}CompletedAt`] = personaJob.completedAt;
                }

                return true;
            } catch (error) {
                console.error(error);
                return false;
            }
        },

        async clearComparisonState() {
            try {
                await useJobStore().setJobId(''); // VERY important! leftover job IDs cause massive issues!
                await this.clearComparisonActivityState();
                this.comparison.mode = '';
                this.comparison.name = '';
                this.comparison.personaId1 = '';
                this.comparison.personaId2 = '';
                this.comparison.personaJobId1 = '';
                this.comparison.personaJobId2 = '';
                this.comparison.personaJob1CompletedAt = '';
                this.comparison.personaJob2CompletedAt = '';
                this.comparison = cloneDeep( this.comparison);
                this.comparison.tags = [];
                this.originalComparison = {};
                return true;
            } catch (error) {
                console.error(error);
                return false;
            }
        },

        async clearComparisonActivityState() {
            try {
                this.activities = [];
                this.personaJobs = [];
                this.personas = [];
                return true;
            } catch (error) {
                console.error(error);
                return false;
            }
        },

        async deleteComparison(params: any) {
            let deleted: boolean = false;
            const accountId = useAccountStore().getActiveAccount.id;

            try {
                const response = await api.getAxiosInstance.delete(`/api/accounts/${accountId}/comparisons/${params.id}`);

                if (response.data) {
                    deleted = true;
                }
            } catch (error) {
                console.error(`/api/accounts/${accountId}/comparisons/${params.id}`, error);
            }

            return deleted;
        },


        async deleteComparisonActivity(activity: Activity) {
            try {
                // Always pull the related Persona from the Activity's Job definition
                useJobStore().setOriginatorRoot('personas');
                useJobStore().setOriginatorId(activity.id);
                const personaJob = await useJobStore().getJobByIdOrLatest();

                // Find matching job ID and shift all later jobs/personas up in the list
                for (let i = 1; i <= comparisonActivityCount; ++i) {
                    const personaJobIndex = `personaJobId${i}`;
                    if (this.comparison[personaJobIndex] === personaJob?.id) {
                        const personaIndex = `personaId${i}`;
                        this.comparison[personaIndex] = '';
                        this.comparison[personaJobIndex] = '';

                        if (i < comparisonActivityCount) {
                            // TODO: shift remaining indices up
                            while (i < comparisonActivityCount) {
                                const personaIndexPlus = `personaId${i + 1}`;
                                const personaJobIndexPlus = `personaJobId${i + 1}`;
                                this.comparison[personaIndex] = this.comparison[personaIndexPlus];
                                this.comparison[personaIndexPlus] = '';
                                this.comparison[personaJobIndex] = this.comparison[personaJobIndexPlus];
                                this.comparison[personaJobIndexPlus] = '';

                                ++i;
                            }
                        }
                        break;
                    }
                }

                this.personas.splice(this.personas.findIndex(existingPersona => existingPersona?.id === personaJob?.persona?.id), 1);
                this.personaJobs.splice(this.personaJobs.findIndex(existingPersonaJob => existingPersonaJob?.id === personaJob?.id), 1);
                this.activities.splice(this.activities.findIndex(existingActivity => existingActivity?.id === activity.id), 1);
                return true;
            } catch (error) {
                console.error(error);
                return false;
            }
        },

        async generateComparisonShareToken(comparison: Comparison) {
            const body: any = {};

            try {
                const response = await api.getAxiosInstance.post(`/api/accounts/${comparison.account.id}/comparisons/${comparison.id}/share`, body);
                return response.data?.data || {};
            } catch (error) {
                console.error(error);
                return null;
            }
        },

        async getComparisonById(params: any = {shared: false}) {
            try {
                const accountId: string = params.accountId || useAccountStore().getActiveAccount.id;
                const shared: boolean = params.shared === 'true';
                const queryString: string = shared ? `?shared=true` : ``;
                const response = await api.getAxiosInstance.get(`/api/accounts/${accountId}/comparisons/${params.id}${queryString}`);
                return response.data?.data || null;
            } catch (error) {
                console.error(error);
                return false;
            }
        },

        async getComparisonNameValidation(value: string) {
            const accountId = useAccountStore().getActiveAccount.id;
            const name: string = encodeURIComponent(value);

            try {
                const response = await api.getAxiosInstance.get(`/api/accounts/${accountId}/comparisons/names/${name}`);

                return response.data?.data;
            } catch (error) {
                console.error(error);
                return false;
            }
        },

        async mapComparisonDefinitionApi(comparison: Comparison) {
            try {
                let newComparison: any = cloneDeep(this.serviceComparison);
                newComparison.userId = comparison.userId;
                newComparison.personaJobId1 = comparison.personaJobId1;
                newComparison.personaJobId2 = comparison.personaJobId2;
                newComparison.name = comparison.name;
                newComparison.tags = comparison.tags;

                // Remove UX-only params
                delete (newComparison.mode);

                // Remove GET-only params
                delete (newComparison.account);
                delete (newComparison.created);
                delete (newComparison.updated);

                if (!comparison.id && newComparison.hasOwnProperty('id')) {
                    delete newComparison.id;
                }

                this.newComparison = newComparison;
                return true;
            } catch (error) {
                console.error(error);
                return false;
            }
        },

        async saveComparison(comparison: Comparison) {
            try {
                const currentUser: User = useUserStore().getUser;
                let comparisonResponse: any = {};
                let newComparison: Comparison = cloneDeep( comparison );
                newComparison.userId = currentUser.id;
                newComparison.accountId = currentUser.currentAccountId;
                this.comparison = newComparison;

                await this.mapComparisonDefinitionApi(newComparison);
                const body: any = newComparison;
                let response: AxiosResponse<any> = axiosResponse();
                response = await api.getAxiosInstance.post(`/api/accounts/${newComparison.accountId}/comparisons`, body);
                comparisonResponse = response.data;
                this.comparison.id = comparisonResponse.data.id;
                this.comparison.accountId = comparisonResponse.data.account.id;
                this.comparison.name = comparisonResponse.data.name;

                return comparisonResponse;
            } catch (error) {
                console.error(error);
                return false;
            }
        },

        async saveComparisonName(name: string) {
            try {
                this.comparison.name = name;

                // Hit the API with the new name
                const accountId = useAccountStore().getActiveAccount.id;
                const endpoint = `/api/accounts/${accountId}/personas/${this.comparison.personaId}/jobs/${this.comparison.personaJobId}/comparisons/${this.comparison.id}`;
                const body = {name: this.comparison.name};

                return await api.getAxiosInstance.patch(endpoint, body);
            } catch (error) {
                console.error(error);
                return false;
            }
        },

        async setComparison(comparison: Comparison) {
            try {
                this.comparison = comparison;
                return true;
            } catch (error) {
                console.error(error);
                return false;
            }
        },

        async setComparisonEdit(originalComparison) {
            try {
                this.comparison.id = originalComparison.id;
                this.comparison.name = originalComparison.name;
                this.comparison.fetchedAt = originalComparison.updated.timestamp;
                this.comparison.mode = originalComparison.mode;
                return true;
            } catch (error) {
                console.error(error);
                return false;
            }
        },

        async setComparisonName(name: string) {
            try {
                this.comparison.name = name;
                return true;
            } catch (error) {
                console.error(error);
                return false;
            }
        },

        async setOriginalComparison( params: any) {
            try {
                let originalComparison: any;

                if (params.refresh) {
                    const comparison = await this.getComparisonById(params);
                    if (comparison) {
                        originalComparison = comparison;
                        originalComparison.mode = params.mode;
                    } else {
                        return false;
                    }
                } else if (typeof params.comparison === 'object') {
                    // Comparison object has already been supplied
                    originalComparison = params.comparison;
                }

                this.originalComparison = originalComparison;
                await this.setComparisonEdit(originalComparison);
                return true;
            } catch (error) {
                console.error(error);
                return false;
            }
        }
    }
})
