import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex align-items-center justify-content-center pb-spinner-frame" }
const _hoisted_2 = { class: "flex-fill text-start ps-2 pb-spinner-text" }
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["pb-spinner-container", _ctx.getClasses()])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _cache[0] || (_cache[0] = _createElementVNode("div", { class: "flex-fill text-end" }, [
        _createElementVNode("div", { class: "position-relative pb-spinner" }, [
          _createElementVNode("img", {
            class: "pb-spinner-inner",
            src: "/assets/images/spinner-foreground.svg"
          })
        ])
      ], -1 /* HOISTED */)),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("span", {
          innerHTML: _ctx.textLabel,
          class: _normalizeClass(_ctx.textClassList)
        }, null, 10 /* CLASS, PROPS */, _hoisted_3)
      ])
    ])
  ], 2 /* CLASS */))
}