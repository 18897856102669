import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = ["data-bs-backdrop", "data-bs-keyboard", "id"]
const _hoisted_2 = {
  key: 1,
  "aria-label": "Close",
  class: "btn-close",
  "data-bs-dismiss": "modal",
  type: "button",
  title: `Close`
}

import {onMounted, computed} from 'vue';

    
export default /*@__PURE__*/_defineComponent({
  __name: 'modal',
  props: {
        backdrop: {
            default: true,
            type: Boolean,
        },
        bodyClass: {
            default: '',
            type: String,
        },
        centered: {
            default: false,
            type: Boolean,
        },
        closeButton: {
            default: true,
            type: Boolean,
        },
        contentClass: {
            default: '',
            type: String,
        },
        fade: {
            default: true,
            type: Boolean,
        },
        footerClass: {
            default: '',
            type: String,
        },
        headerClass: {
            default: '',
            type: String,
        },
        hideOnBackdrop: {
            default: true,
            type: Boolean,
        },
        hideOnEscape: {
            default: true,
            type: Boolean,
        },
        id: {
            required: true,
            type: String,
        },
        scrollable: {
            default: false,
            type: Boolean,
        },
        titleClass: {
            default: '',
            type: String,
        },
    },
  emits: ['hidden', 'hide', 'show', 'shown'],
  setup(__props, { emit: __emit }) {

    const emit = __emit;
    const props = __props;

    const backdropValue = computed(() => {
        if (!props.hideOnBackdrop) {
            return 'static';
        }

        return props.backdrop;
    });

    const modalContainerClasses = computed(() => {
        return {
            fade: props.fade,
        };
    });

    const modalDialogClasses = computed(() => {
        return {
            'modal-dialog-centered': props.centered,
            'modal-dialog-scrollable': props.scrollable,
        };
    });

    onMounted(() => {
        const modalElement = document.getElementById(props.id);
        if (modalElement) {
            modalElement.addEventListener('hidden.bs.modal', event => {
                emit('hidden');
            });
            modalElement.addEventListener('hide.bs.modal', event => {
                emit('hide');
            });
            modalElement.addEventListener('show.bs.modal', event => {
                emit('show');
            });
            modalElement.addEventListener('shown.bs.modal', event => {
                emit('shown');
            });
        }
    });

return (_ctx: any,_cache: any) => {
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["modal", modalContainerClasses.value]),
    "data-bs-backdrop": backdropValue.value,
    "data-bs-keyboard": __props.hideOnEscape.toString(),
    id: __props.id,
    tabindex: "-1"
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["modal-dialog", modalDialogClasses.value])
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(["modal-content", __props.contentClass])
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(["modal-header", __props.headerClass])
        }, [
          (_ctx.$slots.title)
            ? (_openBlock(), _createElementBlock("h3", {
                key: 0,
                class: _normalizeClass(["modal-title strong", __props.titleClass])
              }, [
                _renderSlot(_ctx.$slots, "title")
              ], 2 /* CLASS */))
            : _createCommentVNode("v-if", true),
          _createCommentVNode("                    <slot name=\"close\">"),
          (__props.closeButton)
            ? _withDirectives((_openBlock(), _createElementBlock("button", _hoisted_2, null, 512 /* NEED_PATCH */)), [
                [_directive_tooltip]
              ])
            : _createCommentVNode("v-if", true),
          _createCommentVNode("                    </slot>")
        ], 2 /* CLASS */),
        _createElementVNode("div", {
          class: _normalizeClass(["modal-body", __props.bodyClass])
        }, [
          _renderSlot(_ctx.$slots, "body")
        ], 2 /* CLASS */),
        (_ctx.$slots.footer)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(["modal-footer", __props.footerClass])
            }, [
              _renderSlot(_ctx.$slots, "footer")
            ], 2 /* CLASS */))
          : _createCommentVNode("v-if", true)
      ], 2 /* CLASS */)
    ], 2 /* CLASS */)
  ], 10 /* CLASS, PROPS */, _hoisted_1))
}
}

})