import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { "data-intent": "admin-debug-tools" }
const _hoisted_2 = { class: "fa-stack fa-lg" }
const _hoisted_3 = { class: "overflow-auto" }
const _hoisted_4 = { class: "w-100 table table-hover table-sm table-striped" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = ["innerHTML"]

import {computed, nextTick, onMounted, onUpdated, ref} from 'vue';
    import {useRoute} from 'vue-router';
    import {Modal} from 'bootstrap';
    import clone from 'lodash/clone';
    import pick from 'lodash/pick';
    import BootstrapModal from 'Components/common/bootstrap/modal.vue';
    import FontAwesomeIcon from 'Components/common/font-awesome-icon.vue';
    import {useHelpStore} from 'Stores/help';
    import {jsonPretty} from 'Utilities/utils';

    
export default /*@__PURE__*/_defineComponent({
  __name: 'debug',
  setup(__props) {

    const helpStore = useHelpStore();

    /* Debug modal */
    let debugModal: Modal | null = null;
    const debugModalId = ref<string>('adminDebugToolsModal');

    const showDebugTools = () => {
        debugModal?.show();
    };

    onMounted(() => {
        // console.debug(`🐝 Debug component mounted - help ID: ${helpId.value}`);
        nextTick(() => {
            const debugModalElement: HTMLElement | null = document.getElementById(debugModalId.value);

            if (debugModalElement) {
                debugModal = new Modal(debugModalElement);
            }
        });
    });

    /* On-page help */
    const helpId = computed(() => {
        return helpStore.getOnPageHelpId || '<em>no on-page help provided for this route</em>';
    });


    onUpdated(() => {
        // console.debug(`🐝 Debug component updated - help ID: ${helpId.value}`);
    });


    /* Route */
    const route = useRoute();
    const currentRoute = computed(() => {
        let pickedRoute = pick(clone(route), ['name', 'path', 'params', 'query', 'meta']);
        return jsonPretty(pickedRoute);
    });
    // const filteredRoute = ref<any>(pick(
    //     clone(route),
    //     ['meta', 'name', 'params', 'query', 'section', 'tab']
    // ));
    const filteredRoute = ref<any>(clone(route));

return (_ctx: any,_cache: any) => {
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _withDirectives((_openBlock(), _createElementBlock("a", {
      href: "",
      "aria-controls": "debug",
      class: "text-info opacity-50 opacity-hover-100",
      title: `Show admin debug tools`,
      onClick: _withModifiers(showDebugTools, ["prevent"])
    }, [
      _createElementVNode("span", _hoisted_2, [
        _createVNode(FontAwesomeIcon, {
          class: "fa-stack-2x",
          icon: ['solid', 'circle']
        }),
        _createVNode(FontAwesomeIcon, {
          class: "fa-stack-1x fa-inverse",
          icon: ['duotone', 'screwdriver-wrench']
        })
      ])
    ])), [
      [_directive_tooltip]
    ]),
    _createVNode(BootstrapModal, {
      class: "modal-xl fs-5",
      id: debugModalId.value
    }, {
      title: _withCtx(() => _cache[0] || (_cache[0] = [
        _createTextVNode("Debug")
      ])),
      body: _withCtx(() => [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("table", _hoisted_4, [
            _createElementVNode("tbody", null, [
              _createElementVNode("tr", null, [
                _cache[1] || (_cache[1] = _createElementVNode("td", null, [
                  _createElementVNode("strong", { class: "text-nowrap" }, "On-Page Help ID:")
                ], -1 /* HOISTED */)),
                _createElementVNode("td", null, [
                  _createElementVNode("span", { innerHTML: helpId.value }, null, 8 /* PROPS */, _hoisted_5)
                ])
              ]),
              _createElementVNode("tr", null, [
                _cache[2] || (_cache[2] = _createElementVNode("td", null, [
                  _createElementVNode("strong", { class: "text-nowrap" }, "Route:")
                ], -1 /* HOISTED */)),
                _createElementVNode("td", null, [
                  _createElementVNode("pre", { innerHTML: currentRoute.value }, null, 8 /* PROPS */, _hoisted_6)
                ])
              ])
            ])
          ])
        ])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["id"])
  ]))
}
}

})