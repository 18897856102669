import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, KeepAlive as _KeepAlive, Suspense as _Suspense, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, Teleport as _Teleport } from "vue"

const _hoisted_1 = ["data-is-loading"]
const _hoisted_2 = {
  key: 0,
  class: "nav-fixed-top",
  id: "appNavigation"
}
const _hoisted_3 = {
  id: "appView",
  class: "container-fluid p-0"
}
const _hoisted_4 = ["id"]
const _hoisted_5 = { class: "modal-dialog modal-dialog-centered" }
const _hoisted_6 = { class: "modal-content border-danger" }
const _hoisted_7 = { class: "modal-body" }
const _hoisted_8 = { class: "d-block text-center" }
const _hoisted_9 = { class: "mb-3" }
const _hoisted_10 = { class: "d-block" }
const _hoisted_11 = { class: "row row-btn-arrow" }
const _hoisted_12 = { class: "col-md-6 d-flex align-items-center justify-content-center" }
const _hoisted_13 = { class: "col-md-6 d-flex justify-content-center" }
const _hoisted_14 = { class: "d-block text-center" }
const _hoisted_15 = { class: "d-block text-center" }
const _hoisted_16 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_debug = _resolveComponent("debug")!
  const _component_spinner = _resolveComponent("spinner")!
  const _component_navigation = _resolveComponent("navigation")!
  const _component_navigation_anonymous = _resolveComponent("navigation-anonymous")!
  const _component_router_view = _resolveComponent("router-view")!

  return _withDirectives((_openBlock(), _createElementBlock("main", {
    class: "app h-100",
    "data-is-loading": _ctx.isLoading,
    onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.keepBuilding && _ctx.keepBuilding(...args))),
    onContextmenu: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.keepBuilding && _ctx.keepBuilding(...args)))
  }, [
    (_ctx.adminDebugEnabled)
      ? (_openBlock(), _createBlock(_component_debug, { key: 0 }))
      : _createCommentVNode("v-if", true),
    (_ctx.accountLoading)
      ? (_openBlock(), _createBlock(_component_spinner, {
          key: 1,
          text: "Switching Account..."
        }))
      : _createCommentVNode("v-if", true),
    (_ctx.logoutPending)
      ? (_openBlock(), _createBlock(_component_spinner, {
          key: 2,
          text: "Logging out...",
          "text-class-list": _ctx.$route.path.includes('/report') ? ['text-light'] : []
        }, null, 8 /* PROPS */, ["text-class-list"]))
      : _createCommentVNode("v-if", true),
    _withDirectives(_createElementVNode("section", null, [
      (_ctx.routeHasNavigation)
        ? (_openBlock(), _createElementBlock("header", _hoisted_2, [
            (_ctx.showFullNavigation)
              ? (_openBlock(), _createBlock(_component_navigation, {
                  key: 0,
                  onLogout: _ctx.logout
                }, null, 8 /* PROPS */, ["onLogout"]))
              : (_openBlock(), _createBlock(_component_navigation_anonymous, { key: 1 }))
          ]))
        : _createCommentVNode("v-if", true),
      _createElementVNode("section", _hoisted_3, [
        (_ctx.navigating)
          ? (_openBlock(), _createBlock(_component_spinner, {
              key: 0,
              classList: _ctx.spinnerClasses,
              text: _ctx.logoutPending ? 'Logging out...' : 'Loading...'
            }, null, 8 /* PROPS */, ["classList", "text"]))
          : _createCommentVNode("v-if", true),
        _createCommentVNode(" Note: Have to use show vs else to maintain page meta title "),
        _createVNode(_component_router_view, null, {
          default: _withCtx(({Component, route}) => [
            (Component && !_ctx.navigating)
              ? (_openBlock(), _createBlock(_Suspense, { key: 0 }, {
                  default: _withCtx(() => [
                    (_openBlock(), _createBlock(_KeepAlive, null, [
                      (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
                        class: _normalizeClass({ admin: ( _ctx.$route.name === 'account' || _ctx.$route.name === 'user' ) }),
                        event: _ctx.appEvent,
                        key: _ctx.routeKey,
                        onLoading: _ctx.setSectionLoading,
                        onKeepBuilding: _ctx.keepBuilding,
                        onLogout: _ctx.logout,
                        onSaveDraft: _ctx.setSaveStatus
                      }, null, 40 /* PROPS, NEED_HYDRATION */, ["class", "event", "onLoading", "onKeepBuilding", "onLogout", "onSaveDraft"]))
                    ], 1024 /* DYNAMIC_SLOTS */))
                  ], undefined, true),
                  _: 2 /* DYNAMIC */
                }, 1024 /* DYNAMIC_SLOTS */))
              : _createCommentVNode("v-if", true)
          ]),
          _: 1 /* STABLE */
        })
      ])
    ], 512 /* NEED_PATCH */), [
      [_vShow, !_ctx.accountLoading && !_ctx.logoutPending]
    ]),
    (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
      _createElementVNode("div", {
        id: _ctx.sessionTimeoutModalId,
        class: "modal modal-lg timeout",
        "aria-labelledby": "Session Timeout",
        tabindex: "-1"
      }, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _cache[6] || (_cache[6] = _createElementVNode("div", { class: "modal-header" }, null, -1 /* HOISTED */)),
            _createElementVNode("div", _hoisted_7, [
              (_openBlock(), _createBlock(_Suspense, null, {
                fallback: _withCtx(() => [
                  _createElementVNode("div", _hoisted_14, [
                    _createElementVNode("h4", _hoisted_15, [
                      _createElementVNode("i", { innerHTML: _ctx.logoutModalMessage }, null, 8 /* PROPS */, _hoisted_16)
                    ]),
                    _createVNode(_component_spinner, {
                      class: "my-4",
                      "no-label": true
                    })
                  ])
                ]),
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("h3", _hoisted_9, [
                      _createElementVNode("i", null, [
                        _cache[4] || (_cache[4] = _createElementVNode("span", { class: "text-danger" }, [
                          _createElementVNode("strong", null, "Alert:")
                        ], -1 /* HOISTED */)),
                        _createTextVNode(" You will be logged out in: " + _toDisplayString(_ctx.session.countdown) + " seconds", 1 /* TEXT */)
                      ])
                    ]),
                    _cache[5] || (_cache[5] = _createElementVNode("h6", null, [
                      _createTextVNode(" Choose "),
                      _createElementVNode("span", { class: "text-success text-uppercase" }, "keep building"),
                      _createTextVNode(" to stay active or "),
                      _createElementVNode("span", { class: "text-danger text-uppercase" }, "logout now"),
                      _createTextVNode(" to save any current work and secure your account."),
                      _createElementVNode("br"),
                      _createElementVNode("i", null, " If you take no action you will be logged out. ")
                    ], -1 /* HOISTED */)),
                    _createElementVNode("div", _hoisted_10, [
                      _createElementVNode("div", _hoisted_11, [
                        _createElementVNode("div", _hoisted_12, [
                          _createElementVNode("button", {
                            class: "btn btn-success btn-arrow-left text-light",
                            type: "button",
                            onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.keepBuilding && _ctx.keepBuilding(...args)), ["prevent"]))
                          }, " Keep building ")
                        ]),
                        _createElementVNode("div", _hoisted_13, [
                          _createElementVNode("button", {
                            class: "btn btn-danger btn-arrow-right",
                            type: "button",
                            onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.logout()), ["prevent"]))
                          }, " Log out now ")
                        ])
                      ])
                    ])
                  ])
                ], undefined, true),
                _: 1 /* STABLE */
              }))
            ]),
            _cache[7] || (_cache[7] = _createElementVNode("div", { class: "modal-footer" }, null, -1 /* HOISTED */))
          ])
        ])
      ], 8 /* PROPS */, _hoisted_4)
    ]))
  ], 40 /* PROPS, NEED_HYDRATION */, _hoisted_1)), [
    [_vShow, _ctx.userAuthenticated || !_ctx.$route.meta.requiresAuth]
  ])
}