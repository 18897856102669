import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  id: "navbarTop",
  class: "shadow-sm"
}
const _hoisted_2 = { class: "container-fluid" }
const _hoisted_3 = { class: "d-lg-none flex-grow-1" }
const _hoisted_4 = { class: "d-lg-flex flex-lg-row-reverse w-100" }
const _hoisted_5 = { class: "d-none d-lg-inline-block align-self-center w-100" }
const _hoisted_6 = {
  id: "navbarSupportedContent",
  class: "collapse navbar-collapse"
}
const _hoisted_7 = { class: "navbar-nav me-auto nav-tabs w-100" }
const _hoisted_8 = {
  key: 0,
  class: "nav-item dropdown dropdown-bubble p-0",
  "data-action": "account"
}
const _hoisted_9 = {
  "aria-expanded": "false",
  "aria-haspopup": "true",
  class: "nav-link dropdown-toggle",
  "data-bs-toggle": "dropdown",
  href: "#",
  id: "navbarDropdown",
  role: "button"
}
const _hoisted_10 = {
  class: "dropdown-menu",
  "aria-labelledby": "navbarDropdown"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_navigation_extra = _resolveComponent("navigation-extra")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_subnav = _resolveComponent("subnav")!

  return (_openBlock(), _createElementBlock("section", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_openBlock(), _createElementBlock("nav", {
          class: "navbar navbar-expand-lg navbar-light d-flex w-100",
          key: _ctx.navbarKey
        }, [
          _createVNode(_component_router_link, {
            class: "navbar-brand flex-shrink-1",
            to: _ctx.navLink('activity'),
            tabindex: "-1",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.updateNavigation('activity')))
          }, {
            default: _withCtx(() => _cache[6] || (_cache[6] = [
              _createElementVNode("img", {
                src: "/assets/images/logo.svg",
                alt: "",
                tabindex: "-1"
              }, null, -1 /* HOISTED */)
            ]), undefined, true),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["to"]),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_navigation_extra, { onLogout: _ctx.logout }, null, 8 /* PROPS */, ["onLogout"])
          ]),
          _cache[12] || (_cache[12] = _createElementVNode("button", {
            "aria-controls": "navbarSupportedContent",
            "aria-expanded": "false",
            "aria-label": "Toggle navigation",
            class: "navbar-toggler flex-shrink-1",
            "data-bs-target": "#navbarSupportedContent",
            "data-bs-toggle": "collapse",
            type: "button"
          }, [
            _createElementVNode("span", { class: "navbar-toggler-icon" })
          ], -1 /* HOISTED */)),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_navigation_extra, { onLogout: _ctx.logout }, null, 8 /* PROPS */, ["onLogout"])
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("ul", _hoisted_7, [
                (_ctx.accounts.length > 1)
                  ? (_openBlock(), _createElementBlock("li", _hoisted_8, [
                      _createElementVNode("a", _hoisted_9, [
                        _createVNode(_component_font_awesome_icon, { icon: ['solid', 'user'] })
                      ]),
                      _createElementVNode("div", _hoisted_10, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.accounts, (account) => {
                          return (_openBlock(), _createBlock(_component_router_link, {
                            class: _normalizeClass(["dropdown-item", {'active disabled': _ctx.isActiveAccount(account.id)}]),
                            key: account.id,
                            to: {name: 'activity', params: {accountId: account.id}},
                            onClick: ($event: any) => (_ctx.setAccount(account.id))
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(account.name), 1 /* TEXT */)
                            ], undefined, true),
                            _: 2 /* DYNAMIC */
                          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["class", "to", "onClick"]))
                        }), 128 /* KEYED_FRAGMENT */))
                      ])
                    ]))
                  : _createCommentVNode("v-if", true),
                _createElementVNode("li", {
                  class: _normalizeClass(["nav-item", _ctx.navLinkClasses('activity')]),
                  "data-nav-target": "activity",
                  "data-toggle": "tab"
                }, [
                  _createVNode(_component_router_link, {
                    class: "nav-link",
                    to: _ctx.navLink('activity'),
                    tabindex: "-1",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.updateNavigation('activity')))
                  }, {
                    default: _withCtx(() => _cache[7] || (_cache[7] = [
                      _createTextVNode(" Activity ")
                    ]), undefined, true),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["to"])
                ], 2 /* CLASS */),
                _createElementVNode("li", {
                  class: _normalizeClass(["nav-item", _ctx.navLinkClasses('personaBuild')]),
                  "data-nav-target": "personaBuild",
                  "data-toggle": "tab"
                }, [
                  _createVNode(_component_router_link, {
                    class: "nav-link",
                    to: _ctx.navLink('personaBuild'),
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.updateNavigation('personaBuild')))
                  }, {
                    default: _withCtx(() => _cache[8] || (_cache[8] = [
                      _createTextVNode(" Build a Persona ")
                    ]), undefined, true),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["to"])
                ], 2 /* CLASS */),
                _createElementVNode("li", {
                  class: _normalizeClass(["nav-item", _ctx.navLinkClasses('comparisonBuild')]),
                  "data-nav-target": "comparisonBuild",
                  "data-toggle": "tab"
                }, [
                  _createVNode(_component_router_link, {
                    class: "nav-link",
                    to: _ctx.navLink('comparisonBuild'),
                    onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.updateNavigation('comparisonBuild')))
                  }, {
                    default: _withCtx(() => _cache[9] || (_cache[9] = [
                      _createTextVNode(" Compare Personas ")
                    ]), undefined, true),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["to"])
                ], 2 /* CLASS */),
                _createElementVNode("li", {
                  class: _normalizeClass(["nav-item", _ctx.navLinkClasses('audienceBuild')]),
                  "data-nav-target": "audienceBuild",
                  "data-toggle": "tab"
                }, [
                  _createVNode(_component_router_link, {
                    class: "nav-link",
                    to: _ctx.navLink('audienceBuild'),
                    onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.updateNavigation('audienceBuild')))
                  }, {
                    default: _withCtx(() => _cache[10] || (_cache[10] = [
                      _createTextVNode(" Build an Audience ")
                    ]), undefined, true),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["to"])
                ], 2 /* CLASS */),
                _createElementVNode("li", {
                  class: _normalizeClass(["nav-item nav-item-last", _ctx.navLinkClasses('audienceSend')]),
                  "data-nav-target": "audienceSend",
                  "data-toggle": "tab"
                }, [
                  _createVNode(_component_router_link, {
                    class: "nav-link",
                    to: _ctx.navLink('audienceSend'),
                    onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.updateNavigation('audienceSend')))
                  }, {
                    default: _withCtx(() => _cache[11] || (_cache[11] = [
                      _createTextVNode(" Send an Audience ")
                    ]), undefined, true),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["to"])
                ], 2 /* CLASS */)
              ])
            ])
          ])
        ]))
      ])
    ]),
    _createVNode(_component_subnav, {
      class: _normalizeClass(_ctx.$route.name),
      placement: "top"
    }, null, 8 /* PROPS */, ["class"])
  ]))
}