import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "navbarTop",
  class: "nav-empty shadow-sm"
}
const _hoisted_2 = { class: "container-fluid" }
const _hoisted_3 = { class: "navbar navbar-expand-lg navbar-light row d-flex justify-content-between w-100" }
const _hoisted_4 = { class: "d-lg-none flex-grow-1" }
const _hoisted_5 = ["title", "onerror", "src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("section", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("nav", _hoisted_3, [
          _createVNode(_component_router_link, {
            class: "navbar-brand",
            to: "/",
            tabindex: "-1"
          }, {
            default: _withCtx(() => _cache[0] || (_cache[0] = [
              _createElementVNode("img", {
                src: "/assets/images/logo.svg",
                alt: "",
                tabindex: "-1"
              }, null, -1 /* HOISTED */)
            ]), undefined, true),
            _: 1 /* STABLE */
          }),
          _createElementVNode("div", _hoisted_4, [
            _withDirectives(_createElementVNode("img", {
              class: "px-3",
              "data-intent": "client-logo",
              title: _ctx.account ? _ctx.account.name : '',
              onerror: _ctx.Utils.imageFallback(_ctx.defaultBrandLogoPath),
              src: _ctx.logoPath
            }, null, 8 /* PROPS */, _hoisted_5), [
              [_directive_tooltip]
            ])
          ])
        ])
      ])
    ])
  ]))
}