import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "row p-2",
  "data-reset": "persona-counter"
}
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-auto position-relative align-self-center" }
const _hoisted_4 = { "data-intent": "representative-photo" }
const _hoisted_5 = { class: "col ps-0 align-self-center" }
const _hoisted_6 = { class: "persona-name header-title d-flex" }
const _hoisted_7 = { class: "align-self-center" }
const _hoisted_8 = {
  key: 0,
  class: "list-inline d-inline mb-0 me-3"
}
const _hoisted_9 = { class: "list-inline-item" }
const _hoisted_10 = { class: "list-inline-item" }
const _hoisted_11 = {
  key: 0,
  class: "spinner-border spinner-border-sm",
  role: "status"
}
const _hoisted_12 = { class: "list-inline-item" }
const _hoisted_13 = {
  key: 0,
  class: "spinner-border spinner-border-sm",
  role: "status"
}
const _hoisted_14 = { class: "header-detail small" }
const _hoisted_15 = {
  key: 0,
  class: "col-auto my-auto"
}
const _hoisted_16 = ["onerror", "src"]
const _hoisted_17 = { class: "row mb-3 text-primary" }
const _hoisted_18 = { class: "col text-start" }
const _hoisted_19 = { id: "characteristics" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_representative_photo = _resolveComponent("representative-photo")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_characteristics = _resolveComponent("characteristics")!
  const _component_bootstrap_modal = _resolveComponent("bootstrap-modal")!
  const _component_share = _resolveComponent("share")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.comparisonPersonas, (persona, index) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "col position-relative align-self-center",
        "data-intent": "persona-info",
        key: persona.id
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_representative_photo, {
                characteristics: persona.photoCharacteristics,
                "photo-source": persona.userPhoto?.filePathUri || null
              }, null, 8 /* PROPS */, ["characteristics", "photo-source"])
            ])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _cache[3] || (_cache[3] = _createElementVNode("strong", null, "Persona:", -1 /* HOISTED */)),
                _createTextVNode(" " + _toDisplayString(persona.name), 1 /* TEXT */)
              ])
            ]),
            _createElementVNode("div", null, [
              (_ctx.mode !== _ctx.ComparisonMode.PRINT && index === 0)
                ? (_openBlock(), _createElementBlock("ul", _hoisted_8, [
                    _createElementVNode("li", _hoisted_9, [
                      _createElementVNode("a", {
                        href: "",
                        onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.showSummaryInfo && _ctx.showSummaryInfo(...args)), ["prevent"]))
                      }, [
                        _createVNode(_component_font_awesome_icon, {
                          icon: ['solid', 'circle-info'],
                          "fixed-width": ""
                        })
                      ])
                    ]),
                    _createElementVNode("li", _hoisted_10, [
                      (_ctx.pdfSharing)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_11, _cache[4] || (_cache[4] = [
                            _createElementVNode("span", { class: "visually-hidden" }, "Loading...", -1 /* HOISTED */)
                          ])))
                        : _withDirectives((_openBlock(), _createElementBlock("a", {
                            key: 1,
                            href: "",
                            title: `Download or Share PDF`,
                            onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.shareModal('pdf', 'show')), ["prevent"]))
                          }, [
                            _createVNode(_component_font_awesome_icon, {
                              icon: ['regular', 'file-pdf'],
                              "fixed-width": ""
                            })
                          ])), [
                            [_directive_tooltip]
                          ])
                    ]),
                    _createElementVNode("li", _hoisted_12, [
                      (_ctx.reportSharing)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_13, _cache[5] || (_cache[5] = [
                            _createElementVNode("span", { class: "visually-hidden" }, "Loading...", -1 /* HOISTED */)
                          ])))
                        : _withDirectives((_openBlock(), _createElementBlock("a", {
                            key: 1,
                            href: "",
                            title: `Share this Report`,
                            onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.shareModal('report', 'show')), ["prevent"]))
                          }, [
                            _createVNode(_component_font_awesome_icon, {
                              icon: ['solid', 'share-from-square'],
                              "fixed-width": ""
                            })
                          ])), [
                            [_directive_tooltip]
                          ])
                    ])
                  ]))
                : _createCommentVNode("v-if", true),
              _createElementVNode("em", _hoisted_14, "Built on " + _toDisplayString(_ctx.getBuildDate( index+1 )), 1 /* TEXT */)
            ])
          ]),
          (index === (_ctx.comparisonActivityCount - 1))
            ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                _createElementVNode("img", {
                  class: "brand-logo",
                  onerror: _ctx.Utils.imageFallback(_ctx.defaultBrandLogoPath),
                  src: _ctx.logoPath
                }, null, 8 /* PROPS */, _hoisted_16)
              ]))
            : _createCommentVNode("v-if", true)
        ])
      ]))
    }), 128 /* KEYED_FRAGMENT */)),
    _createVNode(_component_bootstrap_modal, {
      "body-class": "text-normal",
      class: "modal-xl",
      "content-class": "bg-blue-25 border-primary",
      "hide-on-backdrop": false,
      "title-class": "text-primary",
      id: _ctx.summaryInfoModalId
    }, {
      title: _withCtx(() => _cache[6] || (_cache[6] = [
        _createTextVNode("Comparison Summary Information")
      ])),
      body: _withCtx(() => [
        _createElementVNode("div", _hoisted_17, [
          _createElementVNode("div", _hoisted_18, [
            _cache[7] || (_cache[7] = _createTextVNode("Comparison Built By: ")),
            _createElementVNode("strong", null, _toDisplayString(_ctx.comparisonBuiltBy), 1 /* TEXT */)
          ]),
          _createCommentVNode("                                <div class=\"col text-end\">Persona based on <strong>{{ personaCount }}</strong> people"),
          _createCommentVNode("                                </div>")
        ]),
        _createCommentVNode("                            <h6><i>Comparison Characteristics</i></h6>"),
        _createElementVNode("div", _hoisted_19, [
          _createVNode(_component_characteristics, {
            mode: _ctx.ComparisonMode.REPORT
          }, null, 8 /* PROPS */, ["mode"])
        ])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["id"]),
    (_ctx.mode !== _ctx.ComparisonMode.PRINT)
      ? (_openBlock(), _createBlock(_component_share, {
          key: 0,
          "content-title": _ctx.comparison.name,
          "content-type": `Comparison`,
          context: _ctx.shareContext,
          link: _ctx.shareLink(_ctx.shareContext),
          ref: "share"
        }, null, 8 /* PROPS */, ["content-title", "context", "link"]))
      : _createCommentVNode("v-if", true)
  ]))
}